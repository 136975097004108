.app-map-control-street-view-close{
    position: absolute;
    top: 10px;
    right: 60px;
    width: 48px;
    height: 48px;
    line-height: 48px;    
    z-index: 103;
}

.app-map-control-background{
    position: absolute;    
    z-index: 101;
    top: 15px;
    left: 95px;

    .app-map-control-background-top{
        @include border-normal;
        @include shadow-medium;
        width: 610px;
        height: 43px;
        background: $color-primary10;
        border-radius: 5px;
        border-bottom-left-radius: 0;
    }

    &.app-map-control-background-rotate{
        .app-map-control-background-top{
            width: 671px;
        }
    }

    .app-map-control-background-bottom{
        @include border-normal;
        @include shadow-medium;
        border-top: none;
        position: absolute;
        top: 44px;
        width: 43px;
        height: 105px;
        background: $color-primary10;
        border-bottom-left-radius: 5px;      
        border-bottom-right-radius: 5px;      
    }

    .app-map-control-current-map{
        @include border-normal;
        @include shadow-medium;
        background: $color-primary10;
        position: absolute;
        padding: 7px;
        left: 60px;
        top: 55px;
        width: 535px;        
        border-radius: 5px;        
    }

    .app-map-control-current-map-default{
        margin-right: 10px; 
    }
    
    .app-map-control-current-map-actions{
        position: absolute;
        right: 5px;
        top: 8px;

        .app-map-control-current-map-actions-share,
        .app-map-control-current-map-actions-publish,
        .app-map-control-current-map-actions-search,
        .app-map-control-current-map-actions-close{
            display: inline-block;
        }

        .app-map-control-current-map-actions-share,
        .app-map-control-current-map-actions-publish,
        .app-map-control-current-map-actions-search{
            margin-right: 5px;
        }
    }
}

.app-map-control-left{
    position: absolute;
    top: 20px;
    left: 100px;
    z-index: 101;

    .app-map-control-vertical{
        top: 40px !important;
    }

    .app-map-control-actions{  
        position: absolute;      
        left: 102px;
        top: 0;
        width: 570px;
    }

    .app-map-control-actions.no-rotate{          
        left: 40px;
    }
}

.app-map-control-left-collapsed{
    left: 20px;
    
    &.app-map-control-background{
        left: 15px;
    }
}

.app-map-control-left-active{
    left: 425px;
    &.app-map-control-background{
        left: 420px;
    }
}

.app-map-control-right{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 101;
}

.app-map-control-button{
    margin-left: 7px;
}

.app-map-control-vertical{
    position: absolute;
    top: 20px;
    z-index: 101;

    div {
        display: block;    
    }

    .app-map-control-button{
        margin: 7px 0 0 0;
    }

    .app-map-control-button-zoom-in{
        .app-button{
            border-bottom: none;
        }
        
    }

    .app-map-control-button-zoom{
        .app-button{
            border-top: none;
            border-bottom: none;
        }        
    }

    .app-map-control-button-zoom-out{
        .app-button{
            border-top: none;
        }
    }
}

.app-map-control-location{
    @include text-secondary-link;
    @include shadow-light;
    position: absolute;
    border-radius: $border-radius-light;
    padding: 7px;
    bottom: 20px;
    right: 20px;
    z-index: 101;
    text-align: center;
    background-color: $color-white;
    color: $color-dark-gray;

    .app-map-control-location-text{
        display: inline-block;
        width: 170px;
    }

    .app-map-control-location-icon{
        color: $color-dark-gray;        
        font-size: 16px;
    }
}

.app-map-control-current-map-share-popover{
    .dx-popup-content{
        overflow: hidden;
    }
}

.app-viewer{
    .app-map-control-current-map{
        width: 437px;
    }

    .app-map-control-background-top {        
        width: 510px;
    }

    .app-map-control-background-rotate{
        .app-map-control-background-top{
            width: 570px;
        }

        .app-map-control-current-map{
            width: 495px;
        }
    }

    .app-map-control-background-fixed{    
        .app-map-control-background-top {        
            width: 470px;
            border-radius: 5px;
        }

        .app-map-control-current-map 
        {
            left: 10px;
        }

        .app-map-control-actions{
            left: 0px;
        }

        &.app-map-control-background-rotate{
            .app-map-control-background-top{
                width: 470px;
            }
    
            .app-map-control-current-map{
                width: 437px;
            }
        }
    }
}

