.app-map-tooltip {
    position: absolute;
    border-radius: $border-radius-light;
    z-index: 10000;
}

.app-map-tooltip-title{
    @include border-normal($color-dark-gray);
    @include shadow-light;
    color: $color-white;
    font-size: 18px;
    background-color: $color-secondary; 
    padding: 5px;
    padding-left: 10px;    
    height: 26px;
    line-height: 26px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
}

.app-map-tooltip-section {    
    @include text-sub-header1;
    @include shadow-light;
    @include border-normal($color-dark-gray);
    position: relative;
    padding: 10px;
    color: $color-white;
    background-color: $color-secondary75;
    border-radius: 3px;    
    border-top: none;
    margin-top: 5px;    
}

.app-map-tooltip-first {        
    margin-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.app-map-tooltip-close {
    float: right;
    margin-left: 10px;
}

//todo following specific to CI, move to CI UI component
.compInsightVeryLow {
    .app-map-tooltip-title,
    .app-map-tooltip-section{
        border: 1px solid rgb(232,167,141);
        background: rgb(252,187,161);
        font-weight: bold;
    }    
}

.compInsightLow {
    .app-map-tooltip-title,
    .app-map-tooltip-section{
        border: 1px solid rgb(231,86,54);
        background: rgb(251,106,74);
        font-weight: bold;
    }    
}

.compInsightMedium {
    .app-map-tooltip-title,
    .app-map-tooltip-section{
        border: 1px solid rgb(219,39,24);
        background: rgb(239,59,44);
        font-weight: bold;
    }    
}

.compInsightHigh {
    .app-map-tooltip-title,
    .app-map-tooltip-section{
        border: 1px solid rgb(183,4,9);
        background: rgb(203,24,29);
        font-weight: bold;
    }    
}

.compInsightVeryHigh {
    .app-map-tooltip-title,
    .app-map-tooltip-section{
        border: 1px solid rgb(83,0,3);
        background: rgb(103,20,23);
        font-weight: bold;
    }    
}

.app-circle-size-tooltip td {
    padding: 0px 5px;
}