.app-multimedia-url-edit{
    flex-flow: column;
}

.app-multimedia-header{
    @include border-bottom-normal;
    padding: 0px 15px;
}

.app-multimedia-url-edit-button{
    margin-left: 15px;
    margin-bottom: 20px;
}

.app-multimedia-url-edit-rows{    
    display: flex;
    flex-flow: row;
}

.app-multimedia-url-edit-actions-rows{
    margin-top: 20px;
    display: flex;
    flex-flow: row;
    justify-content: right;
}

.app-multimedia-url-edit-inputs{
    margin: 5px 15px;
    width: 260px;
}

.app-multimedia-url-edit-inputs-url{
    margin: 5px 15px;
    width: 720px;
}

.app-multimedia-url-edit-inputs-description{
    margin: 5px 15px;
    width: 720px;
}