.app-selectable-items-body{
    @include fill-height;
    @include vertical-scroll-color;
    height:0;
    flex-grow: 1;
}

.app-selectable-items-body-no-footer{
    @include fill-height;
    @include vertical-scroll;
    height:0;
    flex-grow: 1;
}

.app-selectable-items-actions{
    @include border-bottom-normal;
    height: 55px;
    line-height: 55px;    
    text-align: center;
}

.app-selectable-items-actions-button{
    margin: 0 10px;
}

.app-selectable-items-footer{    
    @include border-bottom-normal;
    height: 75px;
}

.app-selectable-items-selected{
    @include border-bottom-normal;
    @include vertical-scroll;
    background-color: $color-white;
    width: 100%;
    padding-bottom: 10px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.app-selectable-items-confim{
    padding: 12px;
}

.app-selectable-items-close-button{
    margin: 0 11px;
}

.app-selectable-items-tag{
    margin-top: 10px;
    margin-left: 10px;    
}

.app-selectable-items-item{
    padding-left: 40px
}