.app-description {
    @include text-sub-header2;
    @include border-normal($color-secondary);
    border-radius: $border-radius-light;
    background-color: $color-secondary75;
    color: $color-white;
    text-align: center;
    text-wrap: wrap;
    padding: 5px;
}

.app-description-secondary {
    @include text-sub-header2;
    @include border-normal($color-primary10);
    border-radius: $border-radius-light;
    background-color: $color-primary5;
    color: $color-black;
    text-align: center;
    text-wrap: wrap;
    padding: 5px;
}

.app-description-left {
    text-align: left;
}

.app-description-right {
    text-align: right;
}

.app-decription-error {
    background-color: #d9534f;
    border-color: $color-septenary;
}
