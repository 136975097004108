.app-trade-area-drop-down {
    .dx-dropdownbox{

        color: $color-dark-gray !important;
        border-color: $color-dark-gray !important;    
    
        &:hover{
            border-color: $color-secondary !important;
        }
    
        .dx-label-before,
        .dx-label,
        .dx-label-after{
            color: $color-dark-gray !important;
            border-color: $color-dark-gray !important;
            
        }
    
        &.dx-state-hover{
            .dx-label-before,
            .dx-label,
            .dx-label-after{
                color: $color-secondary !important;
                border-color: $color-secondary !important;     
            } 
        }
    }
}