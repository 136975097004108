.app-clickable{
    cursor: pointer;

    &.app-clickable-pressed{
        @include shadow-inset;
    }
    
    &.app-clickable-hovered{
        @include shadow-light;
    }

    &.app-clickable-disabled{
        @include disabled;
    }

    &.app-clickable-not-licensed{
        @include disabled;
    }

    &.app-clickable-coming-soon{
        @include disabled;
    }
}