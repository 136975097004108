.app-polygon-style-swatch{
    width: 24px;
    height: 22px;
    margin: 4px;
    border-radius: 3px;
    cursor: pointer;

    &.app-clickable-hovered{
        @include shadow-medium;
    }

    .app-polygon-style-swatch-transparent{
        display: inline-block;
        background-image: url('https://tasonline.com/jpicker/images/Transparent.png');
        background-repeat: repeat;        
        border: 1px solid #CCCCCC;        
        line-height: 0px;
        border-radius: 3px;

        .app-polygon-style-swatch-color{
            width: 23px;
            height: 20px;
        }

        .app-polygon-style-swatch-text{
            top: 14px;
            left: 12px;
            position: absolute;
            font-size: 16px;
            font-weight: bold;
        }
    }
}


.app-polygon-style-auto-label{
    .app-button{
        padding: 5px;
    }
}