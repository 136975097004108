.app-driving-directions-direction-settings{
    width: 100%;

    .app-bar{
        @include text-bar;
        @include border-normal;
        background-color: $color-white;   
        border-radius: $border-radius-none;
    }

    .app-bar-body{
        border: 0px;
        height: 100%;
    }
}

.app-driving-directions-direction-settings-label{
    @include text-sub-header2;
    padding: 10px 10px;        
}

.app-driving-directions-direction-settings-item{
    
    margin-bottom: 7px;
    padding: 5px 5px; 

    .app-clickable{        
        width: 243px;
        margin-left: 25px;   
        background-color: $color-light-gray;   
        border-color: $color-primary3;
    }

    .app-bar{
        background-color: $color-primary3;   
        border-color: $color-light-gray;
    }

    .app-clickable-active{
        background-color: $color-tertiary20;
        color: $color-primary;
        border-color: $color-tertiary;
    }    
} 

.app-driving-directions-direction-settings-units{
    
    margin-bottom: 7px;
    padding: 5px 5px; 

    .app-clickable{
        width: 105px;  
        background-color: $color-light-gray;   
        border-color: $color-primary3;
    }

    .app-bar{
        background-color: $color-primary3;   
        border-color: $color-light-gray;
    }

    .app-clickable-active{
        background-color: $color-tertiary20;
        color: $color-primary;
        border-color: $color-tertiary;
        border-radius: $border-radius-normal;
    }    

}

.app-driving-directions-direction-settings-units {
    display: flex;
    margin-left: 25px;
  }
     