.adp-listheader {
    display: none;
  }

.adp-list {
    border: 0px !important;
    padding: 0px !important;
}

.adp-listinfo {
    @include text-sub-header0;
    color: $color-primary;
    padding: 10px 10px 10px 10px;
    display: inline-block;    
    border-bottom: 1px solid $color-light-gray;
}  

  .adp-listsel {
    background-color: $color-tertiary50 !important;
  }

  .adp-marker2 {
    background-color: $color-white;
    padding: 5px;
  }

  .adp-text { 
    @include text-sub-header1;
    padding: 10px 10px 10px 10px;
    background-color: $color-white;
  }

  .adp-placemark {
    border: 0px !important;
  }

  .adp button {
    border-right: 0px !important;
    border-left: 0px !important;
    border-bottom: 1px solid $color-light-gray !important;
    border-top: 1px solid $color-light-gray !important;   
  }

  .adp-summary {
    padding: 10px 10px 10px 10px !important;
  }