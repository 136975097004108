.app-map-map-control-search-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.app-map-map-control-search-button-container {
    align-self: flex-end;
    margin-bottom: 10px; 
}

.app-map-map-control-search-datalist-container {
    width: 100%;
}

.app-map-map-control-search-text{
    padding-left: 15px;
    width: 140px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.app-map-map-control-search-text-description{
    width: 210px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    color: $color-gray;
}

.app-map-map-control-search-text-date{
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: $font-size7;
    color: $color-gray;
    padding-left: 20px;
}

.app-map-map-control-search-icon{
    height: 20px;
    width: 20px;    
    color: $color-dark-gray;
}

.app-map-map-control-search-folder-text{
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.app-map-map-control-search-folder-shared-text{
    font-size: $font-size7;
    color: $color-gray;
    padding-left: 10px;
}

.app-map-map-control-search-recent-container{
    padding-bottom: 30px;
}

.app-map-map-control-search-recent-text{
    font-weight: $font-weight1;
    padding-bottom: 10px;
}

.app-map-map-control-search-loader-container {
    height: 500px; 
    top: 60px;
    position: absolute;
    background-color: $color-white !important;
    border-radius: $border-radius-normal;
}