.app-map-tab-bar{
    @include text-sub-header2;    
    height: 35px;
    padding-left: 80px;
    line-height: 35px;    
}

.app-map-tab-bar-collapsed{
    padding-left: 0px;
}

.app-map-tab-bar-active{
    padding-left: 400px;
}

.app-map-tab-bar-tab{
    background-color: $color-tertiary20;
    width: 200px;
    line-height: 35px;
}