.app-market-opt-wizard-header{
    @include border-bottom-normal;
    padding: 7px;
    text-align: center;
}

.app-market-opt-wizard-body{
    @include fill-height;
    @include vertical-scroll;
    height: 90%;

    .app-market-opt-wizard-row{
        display: flex;
        flex-direction: row;
        justify-content: center;    
    }

    .app-bar-container{
        margin: 7px;
    }

    .app-market-opt-inputs{
        margin: 7px;
    }

    .app-market-opt-dropdowns{
        margin: 7px 7px 14px 7px;
    }

    .app-market-opt-textbox{
        margin: 7px;
        width: 200px;
    }

    .app-market-opt-label{
        @include text-caption;
        margin: 18px 0 0 7px;
        flex-grow: 2;
    }

    .app-market-opt-distance{
        margin-right: 0;
        width: 150px;

        .dx-numberbox{
            text-align: center;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    
    .app-market-opt-measurement{
        margin: 7px 7px 7px 0;
        width: 73px;

        .dx-selectbox{
            border-left: none !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }
    
    .app-button-secondary.app-button-medium{
        width: 144px;
    }

    .app-description{
        white-space: pre-wrap;
        text-align: left;
    }

    .app-market-opt-error{
        margin: 7px 14px;
    }
}

.app-market-opt-forced-seed-points {
    margin: 7px 7px 14px 7px;

    .app-market-opt-forced-seed-point-list-container {
        padding-top: 15px;
    }

    .app-market-opt-forced-seed-point-label {
        padding:5px;
    }

    .app-market-opt-forced-seed-point-list {
        border-style: solid;
        border-width: 1px;
        border-color: $color-light-gray;
    }

    .app-market-opt-forced-seed-point-list-button {
        padding-left: 10px;
        float: right;
    }

    .app-market-opt-forced-seed-points-buttons-container{
        margin-top: 7px;
        display: block;
        text-align: right;
    
        .app-button-container{
            margin-left: 5px;
        }
    }

    .app-market-opt-forced-seed-points-button{
        margin-top: 5px;
        margin-left: 7px;
        
        .app-button{
            padding: 7px;
            .app-button-text{
                margin-left: 3px;
            }
        }
    
        &.app-button-primary{
            .app-button{
                padding: 9px;
            }
        }
    }
    
    .app-market-opt-forced-seed-point-address-text {
        display: inline-block;
        max-width: 225px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dx-autocomplete.dx-texteditor{
        border: 0;
    }
}

.app-market-opt-optional-filters {
    margin: 7px 7px 14px 7px;

    .app-market-opt-criteria-filter-text{
        padding: 10px 0px;
    }

    .app-market-opt-optional-filters-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;        
    }

    .right-aligned-button {
        margin-left: auto;
    }

}



