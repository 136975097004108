.app-photos-edit-details {
    position: fixed;
    display:block;
    margin:auto;
    color: $color-white;
    left: 25%;
    top: 25%;
    z-index: 1000;
  }

.app-photos-details-textbox-title{
    width: 1000px;
}

.app-photos-details-textbox-description{
    width: 1000px;
}

.app-photos-details-textbox-tags{
    width: 1000px;
}

.app-photos-edit-details-actions-section {
    padding-top: 20px;
    float: right;
}

.app-photos-edit-details-actions-section-icon {
    padding-left: 15px;
 }