.app-user-badge{
    @include border-normal($color-gray);
    background-color: $color-white;
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 8px; 
    z-index: 102;
    top: 50px;
    right: 65px;
    text-align: center;
    border-width: 1px;
    border-style: solid;
}

.app-user-badge-title{
    @include border-bottom-normal($color-gray);
    @include text-sub-header1;
    background-color: $color-primary10;
    color: $color-primary;
    height: 55px;
    line-height: 55px;
    padding-left: 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    text-align: left;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.app-user-badge-body{
    padding: 15px;
}

.app-user-badge-userName{
    @include text-header3;
    color: $color-primary;
}

.app-user-badge-accountName{
    @include text-sub-header1;
    margin-Top: 10px;       
}

.app-user-badge-userName,
.app-user-badge-accountName{
    margin-Top: 10px;       
}

.app-user-badge-footer{
    margin-top: 20px;
}

.app-user-badge-footer .app-button{
    margin: 0 5px;
}

.app-user-badge-userImage{
    @include default-user-image;
    margin-top: 30px;
    border-radius: 50%;
    height: 100px;
    width: 100px;    
    border: 1px solid $color-primary;
}