.app-nav {    
    @include vertical-scroll-dark-color(auto);
    background-color: $color-primary15;
    padding-bottom: 15px;    
    margin-bottom: 80px;  
}

.app-nav-container {
    @include fill-height;
    background-color: $color-primary15;
    position: absolute;
    z-index: 102;
    width: 85px;    
}

.app-nav-divider {
    @include border-top-normal;
    margin: 20px 5px 0px 5px;
}

.app-nav-collapser {
    position: absolute;
    bottom: 0px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    margin: 20px 16px;
    z-index: 103;
}

.app-nav-section{
    position: absolute;
    left: 85px;
    
    .app-bar-container{
        margin: 7px 7px 7px 7px;
    }
}

.app-analytics-nav-section{
    @extend .app-nav-section;    
}

.app-driving-direction-nav-section{
    @extend .app-nav-section;
    width: 330px;
    left: 0 !important;

    .app-bar-container{
        margin: 0px;
    }    
}

.app-navigation-button{
    display: block;
    margin-top: 15px;
}

.app-navigation-button-badge{
    top: -10px;
    position: absolute;
    font-size: 16px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 100px;
    color: $color-white;
}

.app-navigation-button-badge-processing{
    @include border-normal($color-primary);
    left: -7px;
    background: $color-primary;
}

.app-navigation-button-badge-complete{
    @include border-normal($color-secondary);
    right: -7px;
    background: $color-secondary;    
}