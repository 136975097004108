@import "./taskDetail/taskDetail";
@import "./taskList/taskList"; 
@import "./taskShare/taskShare";

.app-tasks {
    display: flex;
    flex-flow: row;
    flex-grow: 1;
    border : 5px solid $color-primary;
    height:0;

    .app-task-list-section {
        display: flex;
        flex-direction: column;
        justify-content: start;
        flex: 1;
        padding:5px;
        border-right: 5px solid $color-primary;
        max-height: 100%;
    }

    .app-datagrid-container {
        display:flex;
        flex-flow: column;
        flex-grow: 1;
        height: 0;
    }

    .app-task-support-section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    
        .app-task-section-empty-header{
            @include text-header3;
            padding-bottom: 5px;
        }

        .app-task-detail-section {
            display:flex;
            flex-direction: column;
            padding: 10px;
            border-bottom: 5px solid $color-primary;
            height: 480px; 

            .app-task-detail{
                padding: 0px;

                .app-task-detail-header{
                    padding-left: 0px;
                }
            }
        }

        .app-task-action-section {
            display:flex;
            flex-flow: column;
            flex-grow: 1;
            padding: 10px;
            position: relative;

            .app-task-history-header {
                display: flex;
                justify-content: space-between;
                padding-right: 5px;
                padding-bottom: 5px;
        
                .app-task-history-title{
                    @include text-header3;
                }
        
                .app-task-detail-header-actions {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    
                    .app-button-container:last-child{
                        margin-left: 5px;
                    }
                }
            }

            .app-task-history-container{
                display:flex;
                flex-flow:column;
                flex-grow: 1;
                height: 0;

                .app-memo-message{
                    font-size: $font-size4;
                    font-weight: $font-weight3;
                    letter-spacing: $letter-spacing3;
                }

                .app-memo-date,
                .app-memo-user{
                    font-size: $font-size5;
                }
            }

            .dx-list-item.dx-state-hover{
                background-color: $color-tertiary50;
            }

            .dx-list-item.dx-state-focused,
            .dx-list-item.dx-list-item-selected{
                background-color: $color-tertiary20 !important;
                color: #333;
            }
        }
    }

    .app-task-body-null{
        display:flex;
        flex-flow: column;
        background: #F4F5F9;
        border: 1px solid #CCC;
        border-radius: 5px;
        padding: 5px;
    }
    
    .app-task-section-empty-body{
        display: flex;
        flex-grow:1;
    }

    .app-task-body-empty {
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0.18px;
        color: #666666;
        text-align: center;
        margin: auto;
    }
}



.app-tasks-user-image {
    @include user-image-small;
}