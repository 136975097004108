.app-location-chat-shield{
    color: $color-secondary;
    margin-top: 10px;
    float: right;
    font-size: 26px;
    cursor: pointer;
    width: 215px;
}

.app-location-chat-shield-float{
    float:right;
    margin-right: 5px;
}

.app-location-chat-shield-table{
    width: 100%;
    
}

.app-location-chat-shield-text{
    font-size: 14px;
    line-height: 26px;
    margin-top: 5px;
    color: $color-secondary;
}


.app-location-chat-panel{
    .app-loader-dot{
        background-color: $color-black !important;
    }
}

.app-location-chat-button-disclaimer{
    margin-top: 3px;
    font-size: 14px;
    text-align: right;
}

.app-location-chat{
    @include fill-height;
    padding: 10px;   
    .app-description{
        text-align: left;
    }
}

.app-location-chat-button{
    margin: 5px 0;
    text-align: right;
}

.app-location-chat-body-null{
    @include fill-height;
    background: #F4F5F9;
    border: 1px solid #CCC;
    border-radius: 5px;
    padding: 5px;
}

.app-location-chat-body-empty {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.18px;
    color: #666666;
    text-align: center;
    margin: auto;
}

.app-location-chat-body{
    @include fill-height;
    @include vertical-scroll;
    height: 60%;
    border-bottom: 1px solid #CCC;

    .app-simple-table{
        border-top: none;
    }
}