.app-option-selector{
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 10px;    
}

.app-option-selector-small{
    position: relative;
    display: inline-block;
    vertical-align: top;   
}

.app-option-selector-check{
    color: $color-white;
    background-color: $color-secondary;
    border-radius: $border-radius-circle;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.app-option-selector-small{    
    .app-option-selector-check{
        width: 20px;
        height: 20px;
        line-height: 20px;
        top: 0px;
    }
}

.app-option-selector-icon{
    @include text-header1;
    @include border-normal($color-light-gray);
    color: $color-tertiary;
    border-radius: $border-radius-normal;
    padding: 30px;
    margin-bottom: 10px;    
}

.app-option-selector-text{
    @include text-secondary-link;
    color: $color-black;
    width: 125px;
    word-wrap: break-word;
    text-align: center;
    margin: 0 5px;
}