@import './mapBookError/mapBookError';

.app-map-book-container{
    @include vertical-scroll-color;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
}

.app-map-book-builder-panel-container{
    display: flex;
    flex-flow: column;
}

.app-map-book-adhoc-map-panel-container{
    display: flex;
    flex-flow: column;
    height: 100%;
}

.app-map-book-builder-content-defintion-container{
    display: flex;
    flex-flow: row;
    width: 100%;
}

.app-map-book-builder-panel-row{
    display: flex;
    flex-flow: row;
    margin: 7px 12px 7px 12px;
}

.app-map-book-builder-panel-stretch{
    flex-grow: 2;
}

.app-map-book-builder-row-button{
    margin-top: 3px;
    border-width: 1px;
    border-style: solid;
    border-color: $color-black;
    border-radius: $border-radius-light;
    flex-grow: 2;

        &:hover{
            border-color: $color-secondary !important;
        }
}

.app-map-book-builder-pages-container{
    display: flex;
    flex-flow: column;
}

.app-map-book-pages-header{
    @include text-header3;
    display: flex;
    justify-content: center;
    margin-top:5px
}

.app-map-book-builder-button-container{
    margin: 15px 0 0 0;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    border-bottom: 1px solid #E1E1E1;
    padding: 0 20px 15px 20px;
}

.app-map-book-builder-action-button{
    margin-top: 5px;
    margin-left: 15px;
}

.app-map-book-builder-button{
    margin-top: 5px;
    margin-left: 5px;
}

.app-map-book-builder-page-move{
    cursor: grab;
}

.app-map-book-builder-pages-table{
    widtH: 100%;
}

.app-map-book-builder-pages-container{
    .dx-list-item-after-bag{
        display: none;
    }
}

.app-map-book-builder-pages-container{
    .dx-list-item{
        min-height: 285px;
    }
}

.app-map-book-builder-error{
    width: 100%;
    background: $color-translucent;
    overflow: visible;
    padding-top: 25px;

    .app-modal-card-body {
        @include shadow-medium;
        height: 430px;
        width: 448px;
        margin: auto;
        background: $color-white;
        border: 1px solid #CCC;
        overflow: visible;
    }
}