.app-competitive-insights-report-object{
    padding: 10px 25px; 
}

.app-competitive-insights-report-object-instructions{
  padding: 5px;
  text-align: left;
  overflow: hidden;    
  display: inline-block;
  width: 525px;
}


.app-competitive-insights-report-object-actions{
    float: right;
    padding: 20px 8px;
    .app-button{
        margin-left: 10px;
    }
}

.app-competitive-insights-report-object-reason{
    display: inline-block;
    margin-top: 20px;
    width: 695px;
}

.app-competitive-insights-report-object-description{
    margin-top: 20px;
    width: 695px;
}