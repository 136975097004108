.app-trip-2-trade-body{
    padding: 10px 25px;
}

.app-trip-2-trade-name{
    padding-bottom: 10px;
}

.app-trip-2-trade-renderer-wizard-name{
    display: inline-block;
    width: 695px;
}

.app-trip-2-trade-renderer-wizard-description{
    width: 695px;
}

.app-trip-2-trade-renderer-wizard-provider-type{
    display: inline-block;
    width: 695px;
}

.app-trip-2-trade-renderer-wizard-grid-type{
    display: inline-block;
    width: 250px;
}

.app-trip-2-trade-renderer-wizard-grid-size{
    display: inline-block;
    width: 435px;
    margin-left: 10px;
}

.app-trip-2-trade-renderer-wizard-visualization-type{
    display: inline-block;
    width: 695px;
}

.app-trip-2-trade-renderer-class-breaks-body{
    @include fill-height;
    @include vertical-scroll;
    padding: 15px;
}


.app-trip-2-trade-renderer-class-breaks-add{
    float: right;
    padding: 10px;
}

.app-trip-2-trade-renderer-class-breaks-percentile{
    display: inline-block;
    width: 250px;
    padding-left: 45px;
}

.app-trip-2-trade-renderer-class-breaks-color{
    display: inline-block;
    width: 300px;
    padding-left: 15px;
}

.app-trip-2-trade-renderer-class-breaks-action{
    padding-left: 25px;
}

.app-trip-2-trade-renderer-wizard-filter-type{
    width: 720px;
}

.app-trip-2-trade-renderer-wizard-filter-limit{
    display: inline-block;
    width: 610px;
}

.app-trip-2-trade-renderer-wizard-filter-limit-units{
    display: inline-block;
    width: 100px;
}

.app-trip-2-trade-renderer-wizard-day-parts{
    width: 720px;
}

.app-trip-2-trade .app-large-panel{  //todo temp fix until LargePanel is removed from the wizard base component
    width: 100%;
}

.app-trip-2-trade-renderer-day-parts-body{
    width: 700px;  
    height: 400px; 
    @include vertical-scroll;
}

.app-trip-2-trade-renderer-day-parts-scheduler{
    padding: 10px 10px 10px 50px;
}