.app-geofence-job{
    display: flex;
    flex-flow: column;
    background-color: $color-white;

    .app-description{
        padding: 8px;
        margin: 10px 0 0 0;
    }
}

.app-geofence-job-header{
    @include border-bottom-normal;
    padding: 10px 20px;
    display: flex;
    flex-flow: row;
}

.app-geofence-job-datagrid{
    margin: 17px 27px;
}
  
.app-geofence-job-title{
    @include text-sub-header2;
    display: flex;
    align-items: center;
    flex-grow: 2;    
}

.app-geofence-job-maintenance{
    margin: 0 30px;
}
  
.app-geofence-job-actions{

    .app-button{
        margin-left: 10px;
    }
}

.app-geofence-job-status{
    @include border-normal;
    border-radius: $border-radius-circle;
    margin-left: 16px;
    width: 14px;
    height: 14px;
}

.app-geofence-job-status.pending{
    background-color: $color-gray;
}

.app-geofence-job-status.processing{
    background-color: $color-orange;
}

.app-geofence-job-status.completed{
    background-color: $color-green;
}

.app-geofence-job-status.completed-with-errors{
    background-color: $color-yellow;
}

.app-geofence-job-status.failed{
    background-color: $color-red;
}
