.app-drop-down-container {
    display: flex;
    align-items: center;
    .required {
        width: 10px;
        color: $color-red;
        font-size: 10px;
        line-height: 1.35715;
    
        .app-form-required {
            padding-left: 5px;
            padding-top: 5px;
            padding-right: 5px;
        }
    }    
}

.app-drop-down {
    .dx-selectbox{
        width: 100%;
        color: $color-dark-gray !important;
        border-color: $color-dark-gray !important;    
    
        &:hover{
            border-color: $color-secondary !important;
        }
    
        .dx-label-before,
        .dx-label,
        .dx-label-after{
            color: $color-dark-gray !important;
            border-color: $color-dark-gray !important;
            
        }
    
        &.dx-state-hover{
            .dx-label-before,
            .dx-label,
            .dx-label-after{
                color: $color-secondary !important;
                border-color: $color-secondary !important;            
            }
        }
    }
    // .required {
    //     width: 10px;
    //     color: $color-red;
    //     font-size: 10px;
    //     line-height: 1.35715;
    
    //     .app-form-required {
    //         padding-left: 5px;
    //         padding-top: 5px;
    //         padding-right: 5px;
    //     }
    // }
}

.dx-colorbox{
    color: $color-dark-gray !important;
    border-color: $color-dark-gray !important;    

    &:hover{
        border-color: $color-secondary !important;
    }

    .dx-label-before,
    .dx-label,
    .dx-label-after{
        color: $color-dark-gray !important;
        border-color: $color-dark-gray !important;
        
    }

    &.dx-state-hover{
        .dx-label-before,
        .dx-label,
        .dx-label-after{
            color: $color-secondary !important;
            border-color: $color-secondary !important;            
        }
    }
}

.app-drop-down-image{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .dx-textbox{
        display: none;
    }
}

.app-drop-down-image-src{
    text-align: center;
}

.app-drop-down-color-swatch{
    @include border-normal($color-black);
    margin: 0px auto; 
    margin-top: 2px;
    width: 20px;
    height: 20px;
}

.app-drop-down-item-disabled{
    .dx-list-items .dx-state-disabled{
        background-color: $color-secondary;
        color: $color-white;
    }
}

.dx-tag-content{
    border-radius: 4px;
    background-color: $color-tertiary50;
}
