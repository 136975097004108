@import './filterCreate';

.app-filters-large-panel
{
    z-index: 100;
}

.app-filters-header
{
    margin: 10px 15px 0px 15px;
    
    .app-filters-instructions
    {
        float: left;
        width: 1002px;
    }

    .app-filters-actions {
        display: flex;
        flex-flow: row;
        justify-content: right;
    
        .app-button-container {
            margin: 0px 0px 0px 10px;
        }
    }
}

.app-filters-divided-container
{
    @include fill-height;
    display: flex;
    flex-flow: row;
    margin: 15px 0px 5px 0px;

    .app-filters-divided-column
    {
        @include fill-height;
        display: flex;
        flex-flow: column;

        &.right {
            margin-right: 15px;

            .dx-datagrid-expand
            {
                .dx-datagrid-group-opened, .dx-datagrid-group-closed
                {
                    visibility: hidden;
                }
            }

            .app-description-secondary
            {
                text-align: left;
            }

            .app-filters-button-container
            {
                margin: -10px 0px 0px 0px;
                position: fixed;
                z-index: 100;
            }
        }
    }
    
    .app-filters-column-text
    {
        text-overflow: ellipsis;
        overflow: hidden;  
    }
    
    .app-filters-user-image
    {
        @include user-image-small; 
    }
    
    .app-filters-username
    {
        padding-left: 10px;
    }
}
