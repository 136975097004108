.app-geofence-job-create-body{
    display: flex;
    flex-flow: column;
    padding: 10px 10px;
}

.app-geofence-job-create-row{
    padding: 8px;
    margin-bottom: 5px;
}

.app-geofence-job-create-dates{
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
}

.app-geofence-job-create-label{
    @include text-sub-header2;
    padding: 3px;
}

.app-geofence-job-create-warning{
    @include text-caption;
    color: $color-septenary;
    display: flex;
    align-items: center;
    flex-grow: 2;    
}

.app-geofence-job-create-confirm-header{
    justify-content: right;
}

.app-geofence-job-create-confirm-warning{
    @include text-sub-header2;
    color: $color-septenary;
}
