.app-reports-point-detail-trade-area{
    display: inline-block;
    width: 210px;
    margin-left: 10px;
}

.app-reports-point-detail-trade-area-header{
  background-color: $color-primary5;
  border-top: 1px solid $color-gray;
  border-bottom: 1px solid $color-gray;
  padding: 2px 2px 7px 2px;
}

.app-reports-point-detail-output{
    display: inline-block;
    width: 170px;
    margin-left: 10px;
}

.app-reports-point-detail-name{
    display: inline-block;
    width: 305px;
    margin-left: 10px;
}

.app-reports-point-detail-row-delete{
  display: inline-block;  
  margin-left: 5px;

  .app-button-icon{
    font-size: 20px !important;
  }
}

.app-reports-point-detail-actions{
    padding-left: 10px;
}

.app-reports-point-add{
  float: right;
  margin: 10px;
}

  .app-reports-point-actions{
    display: flex;
    align-items: center;
  }
  
  .app-reports-point-actions-buttons {
    position: absolute;
  }
  
  .app-reports-point-actions-button {
    margin-left: 5px;
    color: $color-dark-gray;
  }  

  .app-reports-point-detail-empty{
    @include text-header3;
    color: $color-dark-gray;
    text-align: center;
    margin: auto;
}

.app-reports-point-detail-row{
  @include border-top-normal;
  @include border-bottom-normal;
  background-color: $color-white;
  padding: 7px;
      
  &:nth-child(2n+1){
      border: none;
      background-color: $color-primary3;

      .app-reports-point-detail-job-label{
        background: $color-primary3 !important;
    }
  }

  .app-button-icon{
      color: $color-dark-gray;
  }

  .app-reports-point-detail-trade-area{
    width: 285px;
    margin: 0;
  }

  .app-reports-point-detail-output{
    width: 285px;
    margin: 0;
  }

  .app-bar-body{
    padding: 7px;
  }
}

.app-reports-point-detail-row-advanced{
  margin: 7px 0 0 0 !important;
  width: 300px;
  display: inline-block;
}

.app-reports-point-detail-row-void{
  margin: 7px 0 0 7px !important;
  width: 300px;
  display: inline-block;  
}

.app-reports-point-detail-actions{
  display: inline-block;

  .app-button{
    height: 44px;
    line-height: 44px;
  }  
  
}

.app-reports-point-detail-actions-primary{
  display: inline-block;
  margin-left: 7px;

  .app-button{
    height: 47px;
    line-height: 47px;
  }  
  
}

.app-reports-point-detail-query{    
  display: inline-block;
  width: 300px;
}

.app-reports-point-detail-report{
    display: inline-block;
    width: 300px;
    margin-left: 7px;
}

.app-reports-point-detail-report-name{
  display: inline-block;
  width: 350px;
  margin-left: 7px;
}

.app-reports-point-detail-job{
  display: inline-block;
  position: relative;
  @include border-normal($color-dark-gray);
  border-radius: $border-radius-light;
  margin-left: 10px;
  height: 45px;
  width: 55px;
  top: 0px;
  text-align: center;

  .app-reports-point-detail-job-label{
      position: absolute;
      top: -8px;
      left: 8px;
      color: #000;
      font-size: 12px;
      background: #FFF;
  }
  
  .app-reports-point-detail-job-color{
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: $border-radius-light;
      border: 1px solid #000;
      background-color:  rgba(0,0,0,.6);
      display: inline-block;
      height: 26px;
      width: 26px;
  }

  .app-reports-point-detail-job-text{
      position: absolute;
      top: 5px;
      left: 40px;
      border-radius: $border-radius-light;
      border: 1px solid #000;
      background-color: #000;
      text-align: center;
      color: #FFF;
      display: inline-block;
      height: 26px;
      width: 26px;
      line-height: 26px;
  }
}

.app-reports-point-detail-job-select{
  padding-top: 13px;
}

.app-reports-point-detail-body{
  @include vertical-scroll-color;
  background-color: $color-primary3;
  border: 1px solid $color-gray;
  margin: 0 10px 20px 10px;
  display:flex;
  flex-flow: column;
  flex-grow:1;
}

.app-reports-point-detail-content{
  @include fill-height;  
  @include vertical-scroll; 
}

.app-reports-point-detail-void-aggregate,
.app-reports-point-detail-void-measurement{
  margin-left: 5px;
}

.app-reports-point-detail-body-null{
    @include fill-height;
    background: #F4F5F9;
    border: 1px solid #CCC;
    border-radius: 5px;
    padding: 5px;
    margin: 15px;
}

.app-reports-point-detail-body-empty {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.18px;
    color: #666666;
    text-align: center;
    margin: auto;
}