.app-uploader-file-region{
    @include fill-height;
    @include border-normal;
    background-color: $color-primary3;
    overflow-y: auto;
    margin-top: 10px;
    color: $color-dark-gray;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.app-uploader-drop-zone {
    @include fill-height;
    @include text-header3;
    justify-content: center;
    align-items: center; 
    width:100%;
    cursor: pointer;
}

.app-uploader-file {
    display: flex;
    width:100%;
    height:55px;
    background: $color-white;
    border-bottom: 1px solid $color-light-gray;
}

.app-uploader-file-table {
    width:100%;
    margin:10px;
}

.app-uploader-file-button-container {
    float: inline-end;
}

.app-uploader-button-container{
    padding-top: 15px;
    float: inline-end;
}