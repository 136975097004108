.app-tabbed-content{
    display: flex;
    flex-flow: row;
}

.app-tabbed-content-tabs{
    display: flex;
    flex-flow: column;
    width: 54px;
    background-color: $color-primary5;
    padding: 0 5px;

    .app-navigation-button{

        .app-button-sub-text{
            font-size: 10px;
        }
    }
}

.app-tabbed-content-cards{
    flex-grow: 2;    
    margin: 20px 20px 20px 10px;
    overflow: hidden;
}
