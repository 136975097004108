.app-bar{
    @include text-sub-header2;
    @include border-normal;
    background-color: $color-primary10;
    color: $color-dark-gray;
    border-radius: $border-radius-light;
    min-height: 30px;
    padding: 5px 5px 5px 10px;

    &.app-clickable-disabled{
        @include border-normal($color-gray);
        color: $color-gray;
        background-color: $color-light-gray;
    }

    &.app-clickable-coming-soon{
        @include coming-soon;
    }

    &.app-clickable-not-licensed{
        @include not-licensed;
    }

    &.app-bar-empty{
        @include border-normal;
        background-color: $color-primary10;
    }

    &.app-clickable-hovered{
        background-color: $color-tertiary50;
    }

    &.app-bar-open{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom: none;

        &.app-clickable-hovered{
            @include shadow-none;
        }
    }
}

.app-bar-body{
    @include border-normal;
    border-radius: $border-radius-light;

    &.app-bar-open{
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}

.app-bar-table{
    min-height: 30px;
    width: 100%;
}

.app-bar-table td{
    vertical-align: middle;
}

.app-bar-icon-container{
    text-align: center;
    padding-right: 10px;
    width: 20px;
    font-size:20px;
}

.app-bar-text{
    word-break: break-word;
}

.app-bar-sub-text{
    @include text-caption;
    margin-top: 5px;
}

.app-bar-action-container{
   text-align: right;
   white-space: nowrap;

   .app-button-container{
        width: 24px;

        .app-button-icon{
            font-size: 16px;
        }
   }
}

.app-bar-color{
    width: 24px;
    height: 24px;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
}

.app-bar-expandable{
    cursor: pointer;
}

.app-bar-empty{
    cursor: initial;
}

.app-bar-action-expander{
    width: 20px;
}