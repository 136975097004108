@import './infoForm/infoForm';
@import './inputForm/inputForm';
@import './addForm/addForm';
@import './tmxForm/tmxForm';
@import './pushpinEditForm/pushPinEditForm';

.app-form{
    @include fill-height;
    overflow: hidden;
}

.app-form-body{
    @include fill-height;
    position: relative;
}

.app-form-no-tabs{
    .app-form-body-left-column{
        display:none;
    }
}

.app-form-tabs{
    @include border-bottom-normal;
    height: 45px;
    line-height: 45px;
}

.app-form-tab{
    background-color: $color-tertiary20;
    cursor: pointer;
    width: 75px;
}

.app-form-content{
    @include fill-height;
    @include vertical-scroll-color;
    position: relative;
    padding: 7px;
    padding-bottom: 0 !important;

    .app-bar-container{
        margin-bottom: 7px;
        
        .app-bar{
            padding: 0px 10px;    
        }        
    }
}

.app-form-content-section{
    width: 100%;

    .app-form-content-row{
        .app-form-content-column{
            padding: 3px;

            &:first-child{
                padding-left: 10px;
            }

            &:last-child{
                padding-right: 10px;
            }
        }

        &.app-form-content-row-first{
            .app-form-content-column{
                padding-top: 10px;
            }
        }

        &.app-form-content-row-last{
            .app-form-content-column{
                padding-bottom: 10px;
            }
        }
    }
}

.app-form-page{    
    padding: 10px;  
 }

 .app-form-page-flatten{    
    padding: 0;  
 }

.app-form-content-tabs{
    @include fill-height;
    border-right: 1px solid $color-light-gray;
    background-color: $color-primary5;
    padding: 5px 3px 5px 3px;
}

.app-form-tab-container{
    position: relative;

    .app-form-content-tab{
        margin: 6px 5px 0 5px;
    
        img {
            margin-top: -2px;
            max-width: 22px;
            max-height: 22px;
        }
    
        .app-bar{
            background-color: $color-primary10;
            min-height: 24px;
    
            .app-bar-table{
                min-height: 27px;
            }
        }
    
        .app-clickable-active{
            background-color: $color-tertiary20;
        } 
    
        .app-bar-text{
            font-size: 13px;
            max-width: 150px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .app-form-content-tab-error{
        border: 1px solid $color-red;
        border-radius: 3px;
    }
    
    .app-form-content-tab-warning{
        border: 1px solid $color-orange;
        border-radius: 3px;
    }
    
    .app-form-content-tab-badge{
        top: 0px;
        right: 0px;
        position: absolute;
        font-size: 12px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 100px;
        color: $color-white;
        display: flex;
        justify-content: center
    }

    .app-form-content-tab-badge-error{
        @include border-normal($color-red);
        background: $color-red;
    }
    
    .app-form-content-tab-badge-warning{
        @include border-normal($color-orange);
        background: $color-orange;    
    }
}

.app-form-image{
    
    .app-form-content-tab{

        .app-bar-icon-container{
            padding-right: 0;
        }

        .app-bar-text{
            display: none;
        }
    }    
}

.app-form-text{
    .app-form-content-tab{
        .app-bar-icon-container{
            display: none;
        }
    }    
}

.app-form-body-table{
    width: 100%;
    height: 100%;
}

.app-form-body-left-column{
    width: 1px;
    vertical-align: top;
}

.app-form-label-container{
    width: 1px;
}

.app-form-label{
    white-space: nowrap;
    padding: 5px 7px 5px 7px;
    border-radius: 3px;
}

.app-form-label-flatten{    
    padding: 5px 0 0 0;
    white-space: normal;
}

.app-form-value{
    padding: 5px 7px 5px 7px;
    border-radius: 3px;
    white-space: pre-wrap;
}

.app-form-content-page{
    margin: 10px 10px 10px 0px;
}

.app-form-loader{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.app-form-photos-gallery{
    width: 100px;
    height: 100px;
}

.app-form-photos-right-nav {
    padding-left: 70px;
 }

 .app-edit-form-header{
    @include border-bottom-normal;
    padding: 7px;
    text-align: right;
    display: flex;
    justify-content: space-between;

    .app-edit-form-header-title{
        @include text-header3;
    }

    .app-edit-form-header-actions{
        .app-button-container:last-child{
            margin-left: 5px;
        }
    }
 }