.app-ad-hoc-creation-step-container{
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    padding: 15px;
}

.app-ad-hoc-creation-data-labels{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.bold{
        font-weight: bold;
    }
}

.app-ad-hoc-creation-field-header{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.app-ad-hoc-creation-geocoder-container{
    display: flex;
    flex-flow: column;
    background-color: $color-primary3;
    border: 1px solid $color-light-gray;
    border-radius: 8px;
    margin-bottom: 10px;
}

.app-ad-hoc-creation-geocoder-row{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 10px;
}

.app-ad-hoc-right-aligned-button-container{
    float: inline-end;
}

.app-ad-hoc-columns-table-container{
    @include border-normal;
    display:flex;
    flex-grow: 1;
    border-radius: 8px;
    background-color: $color-primary3;
    overflow-y: auto;
    margin-top: 10px;
    height: 0;
    .dx-list-item-after-bag{
        display: none;
    }
}

.app-ad-hoc-columns-table-empty{
    @include text-header3;
    color: $color-dark-gray;
    text-align: center;
    margin: auto;
}

.app-ad-hoc-columns-table{
    border: 1px solid $color-light-gray;
    border-radius: 8px;
    padding: 15px;
    background: $color-primary5;

    &:nth-child(2n+1){
        border: none;
        background: $color-white;
    }
}

.app-ad-hoc-columns-table-column {
    padding: 0 3px;

    .app-bar-container{
        margin: 0;
    }

    .app-option-selector{
        margin: 0px;
    }

    .app-bar{
        padding: 0px;
        padding-left: 10px;
        margin-top: 5px;
    }
}

.app-ad-hoc-columns-bar-button {
    width: 165px;
    margin: 0;
}

.app-ad-hoc-column-actions{
    display: flex;        
    justify-content: center;
    align-items: center;
    height: 55px;

    .app-button-container{
        margin-right: 5px;
    }
} 

.app-ad-hoc-column-move{
    width: 25px;
    height: 40px;
    border-width: 0;
    background: transparent;
    cursor: grab;
} 

.app-ad-hoc-creation-step3-textbox{
    width:400px;
}

.app-ad-hoc-creation-step3-dropdown {
    width:400px;
}

.app-ad-hoc-creation-review-block{
    border: 1px solid $color-light-gray;
    border-radius: 8px;
    padding:15px;
    margin: 15px;
    background: $color-primary5;
    height: 40px;
    width: 300px;
}

.app-ad-hoc-creation-review-table{
    width:600px;
}

.app-ad-hoc-import-container{
    padding: 15px;
    display: flex;
    flex-flow: column;
}

.app-ad-hoc-spatial-import-dropdown{
    padding-right:10px;
}

.app-ad-hoc-spatial-import-textbox{
    padding-right:10px;
}

.app-ad-hoc-spatial-import-textarea{
    padding-top: 10px;
    padding-bottom: 10px;
}

