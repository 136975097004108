.app-tools-custom-channels-editor-medium-panel{
    max-width: 450px;
}

.app-tools-custom-channels-editor-button-container{
    margin: 15px 0 0 0;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    border-bottom: 1px solid #E1E1E1;
    padding: 0 20px 15px 20px;
}

.app-tools-custom-channels-editor-action-button{
    margin-top: 5px;
    margin-left: 15px;
}

.app-tools-custom-channels-editor-container{
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    height: 0;
}

.app-tools-custom-channels-editor-panel-container{
    display: flex;
    flex-flow: column;
}

.app-tools-custom-channels-editor-panel-row{
    display: flex;
    flex-flow: row;
    margin: 7px 12px 7px 12px;
}

.app-tools-custom-channels-editor-panel-stretch{
    flex-grow: 2;
}