.app-photos-edit-photo-upload-photo {
    border-radius: $border-radius-normal;
    width: 118.5px;
    height: 100px;
    padding-right: 20px;
}

.app-photos-edit-photo-upload-photo-img {
    border-radius: $border-radius-normal;
    width: 90px;
    height: 80px;
}

.app-photos-edit-photo-upload-edit-details {
	display: flex;
}

.app-photos-edit-photo-upload-edit-details-textbox-description {
    width: 500px;
}

.app-photos-edit-photo-upload-edit-details-actions{
    width: 120px;
  }

  .app-photos-edit-photo-upload-edit-details-actions-button {
    margin-left: 10px;
    color: $color-dark-gray;
  }

  .app-photos-edit-photo-upload-edit-details-file{
    margin-left: 10px;
    color: $color-dark-gray;
    width: 40px;
    height: 40px;
}