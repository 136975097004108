.app-shared-users-modal-card{
    width: 100%;
    background: $color-translucent;
    overflow: visible;
    padding-top: 25px;

    .app-modal-card-body {
        @include shadow-medium;
        height: 600px;
        width: 700px;
        margin: auto;
        background: $color-white;
        border: 1px solid #CCC;
        overflow: visible;
    }
}

.app-shared-users-header{
    @include border-bottom-normal;
    height: 60px;
    padding: 10px 16px 10px 0; 
}

.app-shared-users-title{
    float: left;
    padding-left: 5px;
    padding-top: 10px;
}

.app-shared-users-actions{
    float: right;

    .app-button{
        margin-left: 10px;
    }
}

.app-shared-users-actions-button {
    padding-left: 15px;
    padding-top: 5px;

}

.app-shared-users-container {
    padding:10px;
}

.app-shared-users-rows{    
    display: flex;
    flex-flow: row;
    align-items: center;
}

.app-shared-users-dropdown {
    padding-top: 15px;
}

.app-shared-users-selected-label {
    margin-left: 10px;
}

.app-shared-users-selected-count {
    margin-left: 10px;
    line-height: 26px; 
    vertical-align: middle;
}

.app-shared-peer-types-dropdown {
    padding-bottom: 10px;
    width: 150px;
}

.app-shared-users-user-image{
    @include user-image-small;
}
.app-shared-users-username{
    padding-left: 10px;
}

