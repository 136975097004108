.app-window {
    .dx-popup-title{
        @include text-sub-header2;
        color: $color-white;
        background-color: $color-primary;    
        line-height: 25px;
        min-height: 46px;
    }
    
    .dx-popup-content{
        padding: 0;
        overflow: visible;        
    }
    
    .dx-overlay-content.dx-popup-normal{
        overflow: visible;
    }
    
    .app-window-content{
        @include fill-height;
    }
    
    .app-window-close-button{
        float: right;
    }

    .app-window-restore-button{
        float: right;
        margin-right: 5px;
    }

    .app-window-minimize-button{
        float: right;
        margin-right: 5px;
    }

    &.dx-overlay-shader {
        background-color: $color-black-translucent!important;
    }
}