.app-button-container {

    text-align: center;
    white-space: nowrap;
    display: inline-block;
    position: relative;

    .app-button {
        border-radius: $border-radius-normal;
        position: relative;

        &.app-clickable-disabled{
            color: $color-gray;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }

        &.app-button-show-expander{
            padding-right: 20px;
        }

        .app-button-text {
            margin-left: 10px;
            display: inline-block;
        }

        .app-button-icon {            
            @include text-sub-header2;    
            padding: 3px;
            margin-top: -2px;
            vertical-align: middle;
        }

        .app-button-expander {
            color: $color-dark-gray;
            position: absolute;
            right: 4%;
            top: 35%;
        }
        
        .app-button-selected {
            color: $color-astral-blue;
            margin-left: 10px;
        }
    }
}

.app-button-action{
    
    height: 24px;
    width: 24px;
    line-height: 24px;

    .app-button-icon {            
        @include text-sub-header2;
    }

    &.app-button-medium{
        height: 34px;
        width: 34px;
        line-height: 34px;

        .app-button-icon {
            font-size: 18px;
        }
    }

    .app-button {
        @include text-overline;
        @include border-normal($color-dark-gray);
        color: $color-dark-gray;
        background-color: $color-white;
        border-radius: $border-radius-light;       

        &.app-clickable-disabled{
            @include border-normal;
            background-color: $color-light-gray;
        }

        &.app-clickable-coming-soon{
            @include coming-soon;
        }

        &.app-clickable-not-licensed{
            @include not-licensed;
        }

        &.app-clickable-active{
            border-color: $color-tertiary;
            background-color: $color-tertiary20;

            .app-button-icon {                
                color: $color-dark-gray;
            }
        }
    }
}

.app-button-chat{

    width: 55px;
    height: 55px;
    line-height: 55px;

    .app-button {
        @include text-header3;
        color: $color-white;
        background-color: $color-primary;
        border-radius: $border-radius-circle;        
    }
}

.app-button-header{
    .app-button {
        @include text-sub-header2;

        &.app-clickable-hovered,
        &.app-clickable-active{
            @include shadow-none;
            color: $color-tertiary;
        }

        &.app-clickable-pressed{
            color: $color-tertiary50;
        }

        &.app-clickable-disabled{
            color: $color-gray;
        }

        &.app-clickable-coming-soon{
            @include coming-soon;
        }

        &.app-clickable-not-licensed{
            @include not-licensed;
        }

        .app-button-image{
            @include user-image-small;
        }

        .app-button-icon{
            font-size: 20px;
        }
    }

    .app-button-active-element{
        background-color: $color-tertiary;        
        position: absolute;
        width: 100%;
        height: 5px;        
        bottom: 0;
    }
}

.app-button-primary{
    &.app-button-large{
        width: 204px;
        height: 59px;
        line-height: 59px;
    }

    &.app-button-medium{
        width: 132px;
        height: 49px;
        line-height: 49px;
    }

    &.app-button-small{
        width: 79px;
        height: 39px;
        line-height: 39px;
    }

    &.app-button-tiny{
        width: 50px;
        height: 32px;
        line-height: 32px;
        
        .app-button {
            border-radius: 5px;
        }
    }

    .app-button {
        color: $color-white;
        background-color: $color-secondary;

        &.app-clickable-disabled{
            color: $color-dark-gray;
            background-color: $color-secondary50;
        }

        &.app-clickable-coming-soon{
            @include coming-soon;
        }

        &.app-clickable-not-licensed{
            @include not-licensed;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }
    }    
}

.app-button-secondary{
    &.app-button-large{
        width: 200px;
        height: 55px;
        line-height: 55px;
    }

    &.app-button-medium{
        width: 128px;
        height: 45px;
        line-height: 45px;

        .app-button-icon {
            font-size: 22px;
        }
    }

    &.app-button-small{
        width: 75px;
        height: 39px;
        line-height: 35px;
    }

    &.app-button-tiny{
        width: 46px;
        height: 28px;
        line-height: 28px;
    }

    &.app-button-extra-small{
        height: 30px;
        width: 34px;
        line-height: 30px;

        .app-button-icon {
            font-size: 18px;
        }
    }

    .app-button {
        @include border-thick($color-secondary);
        color: $color-secondary;
        background-color: $color-white;

        &.app-clickable-disabled{
            @include border-thick($color-gray);
            color: $color-gray;
            background-color: $color-light-gray;
        }

        &.app-clickable-coming-soon{
            @include coming-soon;
        }

        &.app-clickable-not-licensed{
            @include not-licensed;
        }

        &.app-clickable-active{
            background-color: $color-tertiary20;
            color: $color-tertiary;
            border-color: $color-tertiary;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }
    }
}

.app-button-tertiary{

    &.app-button-large{
        width: 185px;
        height: 55px;
        line-height: 55px;
    }

    &.app-button-medium{
        width: 75px;
        height: 55px;
        line-height: 55px;
    }

    &.app-button-small{
        width: 55px;
        height: 55px;
        line-height: 55px;
    }

    .app-button {
        @include border-thick($color-secondary);
        color: $color-secondary;
        background-color: $color-white;

        &.app-clickable-disabled{
            @include border-thick($color-gray);
            color: $color-gray;
            background-color: $color-light-gray;
        }

        &.app-clickable-coming-soon{
            @include coming-soon;
        }

        &.app-clickable-not-licensed{
            @include not-licensed;
        }

        &.app-clickable-active{
            background-color: $color-tertiary20;
            color: $color-primary;
            border-color: $color-tertiary;
            border-radius: $border-radius-normal;
        }       

        &.app-clickable-pressed{
            @include shadow-inset;
        }

        .app-button-icon {            
            @include text-header3;
        }
    }
}

.app-button-map{
    
    .app-button {

        border: 1px solid #E1E1E1;
        color: $color-dark-gray;
        background-color: $color-white;
        width: 32px;
        height: 32px;
        line-height: 32px;

        &.app-button-show-drop-down{
            width: 40px;
        }       

        &.app-clickable-hovered{
            @include shadow-medium;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }

        &.app-clickable-disabled{
            background-color: $color-light-gray;
        }

        &.app-clickable-coming-soon{
            @include coming-soon;
        }

        &.app-clickable-not-licensed{
            @include not-licensed;
        }

        &.app-clickable-active{
            border-color: $color-tertiary;
            background-color: $color-tertiary50;

            .app-button-icon {                
                color: $color-secondary;
            }
        }
    }
}

.app-button-navigation{
    .app-button {
        @include text-header3;
        color: $color-primary;
        background-color: $color-white;
        border-radius: $border-radius-circle;
        width: 40px;
        height: 40px;
        line-height: 40px;
        display: inline-block;

        &.app-clickable-disabled{
            background-color: $color-light-gray;
        }

        &.app-clickable-coming-soon{
            @include coming-soon;
        }

        &.app-clickable-not-licensed{
            @include not-licensed;
        }

        &.app-clickable-active{
            background-color: $color-tertiary;
            border-radius: $border-radius-normal;
        }

        .app-button-icon {            
            @include text-body1;
        }
    }

    .app-button-active-element{
        background-color: $color-tertiary;
        border-top-right-radius: $border-radius-normal;
        border-bottom-right-radius: $border-radius-normal;
        position: absolute;
        left: 0px;
        width: 5px;
        height: 40px;
    }

    .app-button-sub-text {
        @include text-caption;
        color: $color-primary;
        margin: 5px 5px 0px 5px;
        word-wrap: break-word;
        white-space: normal;
        line-height: normal;
        -webkit-line-clamp: 2;
        line-clamp: 2;
    }
}

.app-button-row{
    display: block;

    .app-button {
        @include text-sub-header2;
        @include shadow-none;
        border-radius: $border-radius-none;
        padding: 9px 10px;
        text-align: left;        
        line-height: normal;
        width:auto;
        height:auto;

        &.app-clickable-hovered{
            @include shadow-none;
            background-color: $color-tertiary50;
        }

        .app-button-image{
            width: 20px;
            height: 20px;
        }

        .app-button-text{
            margin-left: 5px;
        }

        .app-button-icon{
            color: $color-dark-gray;
        }

        &.app-clickable-disabled{            
            .app-button-icon{
                color: $color-light-gray;
            }            
        }

        &.app-clickable-coming-soon{
            @include coming-soon;
        }

        &.app-clickable-not-licensed{
            @include not-licensed;
        }
    }
}

.app-button-simple{

    .app-button {
        &.app-clickable-active{
            color: $color-secondary;
        }
    
        &.app-clickable-disabled{
            .app-button-icon{
                color: $color-gray;
            }        
        }

        &.app-clickable-coming-soon{
            @include coming-soon;
        }

        &.app-clickable-not-licensed{
            @include not-licensed;
        }
    
        &.app-clickable-hovered{
            @include shadow-none;
            @include shadow-icon;
        }
    
        &.app-clickable-pressed{
            @include shadow-none;
            @include shadow-icon-inset;
        }
    }    
}

.app-button-tab{
    .app-button {
        @include text-sub-header2;
        color: $color-black;

        &.app-clickable-hovered,
        &.app-clickable-active{
            @include shadow-none;

            .app-button-icon{
                color: $color-tertiary;
            }
        }

        &.app-clickable-pressed{
            color: $color-tertiary50;
        }

        &.app-clickable-disabled{
            color: $color-dark-gray;
            background-color: $color-light-gray;
        }

        &.app-clickable-coming-soon{
            @include coming-soon;
        }

        &.app-clickable-not-licensed{
            @include not-licensed;
        }

        .app-button-image{
            @include user-image-small;
        }
    }

    .app-button-active-element{
        background-color: $color-tertiary;
        position: absolute;
        width: 100%;
        height: 5px;        
        bottom: 0;
    }
}

.app-button-warning {
    .app-button {
        background-color: $color-orange;
    }
}