.app-loader-container{
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    text-align: center;
    
    &.app-loader-primary{
        background-color: $color-primary;
        
        .app-loader-dot{
            background-color: $color-white;
        }
    }

    &.app-loader-default{     
        background-color: $color-white;
        
        .app-loader-dot{
            background-color: $color-primary;
        }
    }

    &.app-loader-primary{
        background-color: $color-primary;
        
        .app-loader-dot{
            background-color: $color-white;
        }
    }

    &.app-loader-generate{
        background-color: $color-translucent;
        
        .app-loader-dot{
            background-color: $color-primary;
        }
    }
}

.app-loader-dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0 7px;
}

.app-loader{
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;    
    height: max-content;

    .app-loader-logo {
        vertical-align: middle;
        height: 100px;
        margin-bottom: 10px;
        opacity: 1;
    }
    
    &.app-loader-large {
        .app-loader-dot {
            width: 8px;
            height: 8px;
        }
    }    
}

.app-loader-dot1 {
    animation: jump 1.6s -0.64s linear infinite;
}
.app-loader-dot2 {
    animation: jump 1.6s -0.48s linear infinite;
}
.app-loader-dot3 {
    animation: jump 1.6s -0.32s linear infinite;
}
.app-loader-dot4 {
    animation: jump 1.6s -0.16s linear infinite;
}
.app-loader-dot5 {
    animation: jump 1.6s linear infinite;
}

@keyframes jump {
    0%, 80%, 100% {
      -webkit-transform: scale(.2);
      transform: scale(.2);
    }
    40% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }
}