.app-projections{
    @include fill-height;    
}

.app-projections-toolbar{
    border-bottom: 1px solid #CCC;    
    padding-left: 5px;
    padding-bottom: 9px;
    padding-top: 5px;

    .app-projections-toolbar-action{
        height: 28px;
        width: 28px;
        line-height: 28px;
        margin-right: 5px;

        &.app-button-primary{
            height: 30px;
            width: 30px;
            line-height: 30px;
        }

        &.app-projections-toolbar-action-dropdown{
            width: 32px;
        }
    }

    .app-projections-toolbar-execute{
        height: 28px;
        width: 28px;
        line-height: 28px;       

        &.app-button-primary{
            height: 30px;
            width: 30px;
            line-height: 30px;
        }

        .app-button{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 1px solid #CCC;
        }        
    }

    .app-projections-toolbar-execute-load{
        height: 28px;
        width: 32px;
        line-height: 28px;
        margin-right: 5px;

        &.app-button-primary{
            height: 30px;
            width: 30px;
            line-height: 30px;
        }
        
        .app-button{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }       
    }

    .app-projections-dropdown{
        display: inline-block;
        margin-right: 5px;
    }

    .app-option-selector-small{
        margin-right: 5px;

        .app-bar{
            padding: 0px 7px;
            margin-top: 6px;
        }

        .app-option-selector-check{
            z-index: 1;
            right: -5px;
        }
    }    

    .app-projections-selection-tools{
        margin-right: 5px;

        .app-button-action{
            height: 28px;
            width: 28px;
            line-height: 28px;
        }

        .app-radio-button:first-child{
            .app-button{
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }

        .app-radio-button:last-child{
            .app-button{
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-left: none;
            }
        }
    }  
}

.app-projections-content{
    @include fill-height;
    padding: 10px;
    position: relative;
}

.app-standard-geography{
    .app-projections-content{ 
        padding-bottom: 40px;
    }    
}

.app-proposed-site-header{
    @include border-bottom-normal;
    padding: 7px;
    text-align: right;

    .app-button-container{
        margin-left: 7px;
    }
}

.app-projections-geography-toggle{
    &.app-button-container{
        width: 100%;
        height: 34px;
        text-align: left;

        .app-button{
            width: auto;
            padding: 5px;
            font-size: 16px;
        }
    }    
}

.app-projections-geography-toggle-container{
    display: block;
    margin-bottom: 5px;   
}

.app-projections-execute-job{
    margin-bottom: 10px;
    text-align: right;
}

.app-projections-edit-window{
    .dx-popup-content{
        @include fill-height;
        padding: 0;
    }

    .dx-popup-content-scrollable{
        overflow: visible;
    }
}

.app-projections-save-shape-window{
    .dx-popup-content{
        @include fill-height;
        padding: 0px;
    }

    .app-radio-button-set{
        margin-left: 12px;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .app-projections-save-shape-section-button{
        padding: 10px;
        border-bottom: 1px solid #CCC;
        text-align: right;
    }
    
    .app-projections-save-shape-section{
        @include text-sub-header0;
        padding: 7px 0;
    }

    .app-projections-save-shape-body{
        padding: 10px;
    }

    .dx-popup-content-scrollable{
        overflow: visible;
    }
}

.app-projections-debug-content{
    @include fill-height;
    margin: 10px;
}

.app-projections-scenarios{
    background: $color-primary3;
    border-bottom: 1px solid #CCC;
    padding: 5px;

    .app-button-container{
        margin-right: 5px;
        
        &.app-button-action{
            height: auto;
            width: auto;
        }

        .app-button{
            padding: 3px;

            .app-button-text{
                margin-left: 2px;
            }
        }
    }
}

.app-projections-scenario-name{
    display: inline-block;
    margin-left: 5px;
}

.app-projections-scenario-save{
    margin-bottom: 10px;
    text-align: right;
    
    .app-button{
        padding: 5px;
        text-align: left;
        margin-left: 5px;
    }
}

.app-projections-polygon{    

    .app-projections-polygon-content{
        padding: 7px;
        
        .app-simple-table{
            width: 100%;
        }
    }    
}

.app-projections-polygon-generated{
    .app-projections-define-trade-area{
        display:none;
    }
}

.app-projections-define-trade-area{    
    @include text-header3;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
    background: $color-white;
    color: $color-dark-gray;
    text-align: center;
    padding-top: 10%;
}