.app-point-scenarios-title{
    @include text-sub-header1;
    position: relative;
    padding: 10px 0 10px 10px;
    border-bottom: 1px solid #CCC;
}

.app-point-scenarios-close{
    float: right;
    margin-right: 10px;
}

.app-projections-scenario-name{    
    font-size: 16px;
    margin-bottom: 5px;
}

.app-job-management{
    margin: 10px;

    .dx-datagrid-nowrap{
        overflow: visible;

        .dx-scrollable-container{
            overflow: visible;
        }

        .app-data-grid-column {            
            overflow: visible;
        }
    }
}

.app-point-scenarios-create-button{
    float: right;
    margin-top: 10px;
    margin-right: 10px;
}

.app-point-scenarios-create{

    .app-modal-card-body{
        width: 400px;
        height: 305px;
    }

    .app-point-scenarios-create-title{
        font-size: 18px;
        font-weight: 500;
        padding: 10px;
        line-height: 38px;
        border: 1px solid #CCC;        

        .app-button-container{
            float: right;
            margin-left: 5px;
        }
    }

    .app-point-scenarios-create-body{
        padding: 10px;
    }
}