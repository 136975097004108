.app-map-book-job-textBox{
    padding:5px 15px; 
}

.app-map-book-job-dropdown{
    padding:5px 15px;
}

.app-map-book-job-point-selections{
    padding:5px 15px;
}

.app-map-book-job-row-button{
    margin-right: 10px;
    margin-top: 10px;
    float: right;
}  

.app-map-book-job-bar-buttons{    
    
    .app-bar-table{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }

    .app-clickable-active{
        background-color: $color-tertiary20;
        color: $color-primary;    

        &.app-clickable-hovered{
            @include shadow-light;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }        
    }    
}

.app-map-book-job-bar-short{    
    width: 166px;
}

// .app-map-book-job-container{
//     display: flex;
//     flex-flow: row;
//     margin: 10px 10px 10px 10px;
//     text-align: center;
//     justify-content: center;

//     .app-option-selector{
//         margin: 4px 10px 0 0;
//     }
// }