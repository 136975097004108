@import './layersMenu/layersMenu';
@import './layer/Layer';

.app-nav-section-layers{
    .app-panel-body{
        overflow:visible;
    }
}

.app-legend-actions{
    @include border-bottom-normal;    
    background-color: $color-primary3;
    color: $color-dark-gray;
    padding: 8px;
    
    .app-legend-menu-button{
        margin-right: 7px;
        margin-bottom: 5px;
    }    
}

.app-layers-favorite-add{
    margin: 7px;

    .app-button{
        height: 30px;
        line-height: 28px;
        width: 30px;
        border-radius: 3px;
    }
}

.app-layers-favorite-add-text{
    display: inline-block;
    width: 270px;
}

.app-layers-favorite-star{
    color: $color-quaternary;
}

.app-legend-filters{
    @include border-normal($color-gray);
    border-radius: $border-radius-light;
    background-color: $color-white;
    color: $color-dark-gray;
    padding: 5px 15px;

    .app-button-container{
        margin: 0 7px;
        .app-button{
       
            .app-button-icon {
                font-size: 18px;
            }
        }  
    }

    .app-button-split-container{
        margin: 0 7px;
        .app-button-container{
            margin: 0;
            .app-button{
           
                .app-button-icon {
                    font-size: 18px;
                }
            }  
        }  
    }
}

.app-legend-actions-button{
    margin: 0 5px;
}

.app-legend-layers-scroll{
    @include fill-height;
    @include vertical-scroll-color;
    height:0;
    flex-grow: 1;
}

.app-legend-filtered{
    .app-bar{
        background-color: $color-primary5;
        border-style: dashed;
        border-width: 1px;
        font-size: 12px;
        color: $color-gray;

        &.app-clickable-hovered{
            background-color: $color-tertiary50;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }
    }
}