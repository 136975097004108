.dx-tabs {
    .dx-tab .dx-icon,
    .dx-tab.dx-tab-selected {
        color: $color-primary;
    }
}

.app-tabs{
    @include vertical-scroll;
    overflow-y: auto; 
} 