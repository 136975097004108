.app-tag{
    @include text-sub-header2;
    color: $color-primary;    
    border-radius: $border-radius-circle;
    background-color: $color-tertiary;
    display: inline-block;
    padding: 10px 20px;    
}

.app-tag-text{
    display: inline-block;    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: text-bottom;
}

.app-tag-close{    
    margin-left:15px;
}