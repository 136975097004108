.add-notes-container{
    display: flex;
    flex-flow: column;
    height: 100%;

    .app-notes-container{
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
    
        &::-webkit-scrollbar {
            width: 0px;
        }

        .app-analog-note{
            margin-bottom: 3px;
        }

        .app-no-notes{
            display: flex;
            flex-grow: 1;
            justify-content: center;
            height:100%;
            align-items: center;
        }
    }
    
    .app-new-note-container{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding-bottom: 10px;

        .app-new-note-input{
            margin: auto;
            width: 100%;
            padding-right: 5px;
        }

    }
}

