@import './adHocCreationWizard/adHocCreationWizard';

.app-ad-hoc-manager-header{
    padding: 2px;
}

.app-ad-hoc-manager-header .app-button-menu-container{
    float:inline-end;
    margin-right: 5px;
    margin-top: 2px;
}

.app-ad-hoc-manager-header .app-description{
    float: inline-start;
    width: 825px;
    text-align: left;
    padding: 5px 5px;
    margin: 5px;
}