.app-trade-areas{
    @include fill-height;
}

.app-trade-areas-actions{
    margin: 0 10px 10px 0;
    text-align: right;

    .app-button{
        padding: 5px;
    }

    .app-trade-areas-zoom-bar{    
        width: 150px;
        margin: 0 7px 0 0 !important;
        
        .app-bar{
            padding: 5px;
        }
    
        .app-bar-table{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
        }
    
        .app-clickable-active{
            background-color: $color-tertiary20;
            color: $color-primary;    
    
            &.app-clickable-hovered{
                @include shadow-light;
            }
    
            &.app-clickable-pressed{
                @include shadow-inset;
            }        
        }    
    }
}

.app-trade-areas-header{
    @include border-bottom-normal;
    padding: 10px;
    display: flex;
    flex-flow: column;

    .app-trade-areas-name{
        display: inline-block;
        margin-right: 5px;
        width: 185px;
    }

    .app-trade-areas-generation{
        display: flex;
        justify-content: flex-end;
    }

    .app-trade-areas-template{
        display: inline-block;
        width: 200px;
        vertical-align: bottom;

        .dx-selectbox .dx-label-after{
            border-right: none !important;
            border-radius: 0 !important;
        }

        .dx-selectbox .dx-label-before{
            border-right: none !important;
            border-radius: 0 !important;
        }
     }

    .app-button-container.app-button-action{
        height: auto;
        width: auto;
        line-height: normal;
        margin-top: 7px;

        .app-button{
            padding: 5px;
        }
    }

    .app-trade-areas-template-favorite{
        .app-button{
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .app-trade-areas-template-reload,
    .app-trade-areas-template-clear,
    .app-trade-areas-template-save{
        .app-button{            
            border-radius: 0;
            border-right: none;
        }
    }

    .app-trade-areas-template-delete{
        .app-button{            
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.app-trade-areas-body{
    @include fill-height;
    padding: 0 10px 10px 10px;
}

.app-trade-areas-content{
    @include fill-height;
    @include border-normal;
    background-color: $color-primary3;
    height: 294px;
}

.app-trade-area-row{
    @include border-top-normal;
    @include border-bottom-normal;
    background-color: $color-white;
    padding: 10px;
        
    &:nth-child(2n+1){
        border: none;
        background-color: $color-primary3;
    }

    .app-button-icon{
        color: $color-dark-gray;
    }
}

.app-trade-area-type{    
    display: inline-block;
    width: 75px;
    margin-left: 10px;

    .dx-selectbox .dx-label-after{
        border-right: none !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.app-trade-area-row-user-drawn,
.app-trade-area-row-saved-shape{
    .app-trade-area-type{
        width: 200px;

        .dx-selectbox .dx-label-after{
            border-right: 1px solid !important;
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }    
}

.app-trade-area-distance{
    display: inline-block;
    width: 52px;

    .dx-numberbox{
        text-align: center;
        border-right: none;
        border-left: none;
        border-radius: 0;
    }
}

.app-trade-area-minutes{
    display: inline-block;
    width: 73px;

    .dx-textbox{
        text-align: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-color: #666666;

        input{
            cursor: pointer !important;
            background-color: #CCC;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        
    }
    
}

.app-trade-area-measurement{
    display: inline-block;
    width: 73px;

    .dx-selectbox{
        border-left: none !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

.app-trade-area-geography{
    display: inline-block;
    width: 125px;    

    .dx-selectbox .dx-label-before{
        border-left: none !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

.app-trade-area-name,
.app-trade-area-settings{
    display: inline-block;
    width: 170px;
    margin: 0px 0px 0 10px;
}

.app-trade-area-name{
    width: 155px;
}

.app-trade-area-row-ring,
.app-trade-area-row-user-drawn{
    .app-trade-area-name{
        width: 335px;
    }    
}

.app-trade-area-style{
    display: inline-block;
    position: relative;
    @include border-normal($color-dark-gray);
    border-radius: $border-radius-light;
    margin: 0 10px;
    height: 30px;
    width: 34px;    
    text-align: center;

    .app-trade-area-style-label{
        position: absolute;
        top: -12px;
        left: 2px;
        color: #000;
        font-size: 12px;
        background: #FFF;
    }
    
    .app-trade-area-style-color{
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: $border-radius-light;
        border: 1px solid #000;
        background-color:  rgba(0,0,0,.6);
        display: inline-block;
        height: 26px;
        width: 26px;
    }

    .app-trade-area-style-text{
        position: absolute;
        top: 5px;
        left: 40px;
        border-radius: $border-radius-light;
        border: 1px solid #000;
        background-color: #000;
        text-align: center;
        color: #FFF;
        display: inline-block;
        height: 26px;
        width: 26px;
        line-height: 26px;
    }
}

.app-trade-area-row {
            
    &:nth-child(2n+1){
        .app-trade-area-style{        
            .app-trade-area-style-label{                
                background: $color-primary3;
            }
        }
    }
}

.app-trade-areas-empty{
    @include text-header3;
    color: $color-dark-gray;
    text-align: center;
    margin: auto;
}

.app-trade-area-actions{
    display: inline-block;
}