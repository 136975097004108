.app-driving-directions-destination-move{
    width: 25px;
    height: 25px;
    border-width: 0;
    padding-top: 10px;
    background: transparent;
    cursor: grab;
} 

.app-driving-directions-destination{
    display: inline-block;
    width: 250px !important;
    margin: 0 10px;
}

.app-driving-directions-destination-row{
    background-color: $color-white;
    padding: 2px 0px 10px 5px;
        
    .app-button-icon{
        color: $color-dark-gray;
    }
}
