.app-button-split-container{
    position: relative;
    display: inline-block;

    .app-button-split-left{
        .app-button{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .app-button-split-right{
        width: 13px; 
        .app-button{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left:none;
            width: 13px; 
        }
    }
}