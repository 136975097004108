.app-notifications{
    @include border-normal($color-gray);
    display: flex;
    flex-flow: column;
    background-color: $color-white;
    position: absolute;
    width: 450px;
    height: 75%;
    border-radius: 8px; 
    z-index: 102;
    top: 50px;
    right: 65px;
    border-width: 1px;
    border-style: solid;
}

.app-notification-container{
    display: flex;
    flex-flow: row;
    min-height: 95px;

    .app-clickable.app-clickable-hovered{
        background-color: $color-tertiary50;
        @include shadow-none;
    }
}

.app-notification-container-expand{
    height: auto;
}

.app-notifications-title-container{
    @include border-bottom-normal($color-gray);
    @include text-sub-header1;
    background-color: $color-primary10;
    color: $color-primary;
    height: 55px;
    line-height: 55px;
    padding-left: 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    flex-flow: row;
}

.app-notifications-title{
    text-align: left;
    flex-grow: 2;
}

.app-notifications-close-button{
    padding-right: 10px;
}

.app-notifications-actions{
    @include border-bottom-normal($color-light-gray);
    display: flex;
    flex-flow: row;
    padding: 10px;
    justify-content: flex-end;
}

.app-notifications-filters{
    flex-grow: 2;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-content: center;
}

.app-notifications-mark-read{
    padding-right: 5px;
}

.app-notifications-body{
    display: flex;
    flex-flow: column;
    flex-grow: 2;
    @include vertical-scroll;
    padding-bottom: 0 !important;

    .dx-list-item.dx-state-focused{
        background-color: $color-tertiary20 !important;
        color: #333 !important;
    }

    .dx-list-item-content{
        padding: 0 !important;
    }
}

.app-notifications-footer{
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    flex-grow: 2;
    align-content: center;
    padding-bottom: 5px;
}

.app-map-notification{
    display: flex;
    flex-flow: row;
    flex-grow: 2;
    margin: 0;
    padding: 15px;    
    
    &.app-clickable-hovered{
        background-color: $color-tertiary50;
        @include shadow-none;
    }
}

.app-notification-new{
    background-color: $color-secondary20;
}

.app-notification-viewed-container{
    padding: 35px 5px 0 5px;
    background-color: $color-primary3;
}

.app-notification-announcement{
    width: 340px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.app-notification-button-badge{
    top: 5px;
    position: absolute;
    font-size: 16px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 100px;
    color: $color-white;
    @include border-normal($color-secondary);
    right: -7px;
    background: $color-secondary;    
}
