.app-dashboards{
    @include fill-height;
    margin: 30px;
}

.app-dashboards-dropdown{
    margin-bottom: 20px;
}

.app-dashboards-powerbi{
    flex: 2;
}
