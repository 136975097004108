.app-reports-management-reports-criteria-container{
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  height: 100%;
}

.app-reports-management-reports-criteria-container-panel-container{
  display: flex;
  flex-flow: column;
}
 
.app-reports-management-reports-criteria-empty{
  @include text-header3;
  color: $color-dark-gray;
  text-align: center;
  margin: auto;
}

.management-reports-date-range{
    margin: 10px;
}


.app-reports-management-reports-criteria-item {
  margin: 10px;
}