.app-small-demographic-report-container{
    padding: 10px;
}

.app-small-demographic-report-trade-area{
    display: inline-block;
    width: 300px;
    margin-left: 80px;
}

.app-small-demographic-report-title{
    margin-left: 10px;
    width: 300px;
}

.app-small-demographic-report-output{
    display: inline-block;
    width: 150px;
    margin-left: 10px;
}

.app-small-demographic-report-save{
    margin-right: 10px;
    margin-top: 5px;
}

.app-small-demographic-report-header{
    display: flex;
    justify-content: space-between;
}

.app-small-demographic-report-scrolling-container{
    @include vertical-scroll-color;
    overflow-y: scroll;
    height:100%;
}

.app-small-demographic-report-scrolling-container * {
    overflow-y: visible;
}
