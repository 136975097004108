.app-demographic-report-container{
    padding: 10px;
}

.app-demographic-report-trade-area{
    display: inline-block;
    width: 255px;
    margin-left: 80px;
}

.app-demographic-report-title{
    display: inline-block;
    margin-left: 10px;
    width: 255px;
}

.app-demographic-report-output{
    display: inline-block;
    width: 150px;
    margin-left: 10px;
}