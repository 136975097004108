.app-symbol-manager{
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
}

.app-symbol-manager-pickers{
    display: flex;
    flex-flow: column;
    margin: 0 10px 10px 10px;
}

.app-symbol-manager-pickers-first{
    margin: 0 10px 10px 0;
}

.app-symbol-manager-pickers-last{
    margin: 0 0 10px 10px;
}

.app-symbol-manager-swatches{
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 0 0 20px 10px;
}

.app-symbol-manager-inputs{
    width: 139px;
    
    .dx-selectbox{
        margin-bottom: 20px;
    }    
}

.app-symbol-manager-label{
    @include text-caption;
    margin: 5px 0 15px 0;
}

.app-symbol-preview{
    @include border-normal($color-dark-gray);
    border-radius: $border-radius-normal;
    width: 159px;
    height: 109px;
    display: flex;
    align-items: center;
    justify-content: center;
}
