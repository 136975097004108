.app-geo-feeds-creation-wizard-container{
    @include fill-height;
    padding: 15px;
    display: flex;
    flex-flow: column;


    .app-description{
        padding: 10px;
        margin-top: 20px;
        text-align: left;
        margin-bottom: 15px;
    }
}
 
.app-geo-feeds-creation-wizard-bar-buttons{    
    
    .app-bar-table{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }

    .app-clickable-active{
        background-color: $color-tertiary20;
        color: $color-primary;    

        &.app-clickable-hovered{
            @include shadow-light;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }        
    }    
}

.app-geo-feeds-creation-wizard-bar-long{    
    width: 150px;
}

.app-geo-feeds-creation-wizard-source-type-icon{
    display: flex;
    align-items: center; 
}

.app-geo-feeds-creation-wizard-source-type{
    padding-top: 5px;
    color: $color-dark-gray;
    width: 25px;
    height: 25px;
}

.app-geo-feeds-creation-wizard-photo-upload{
    height: 350px;
}

.app-geo-feeds-creation-wizard-sources{
    @include fill-height;
    @include vertical-scroll; 

    .app-geo-feeds-creation-wizard-source-row{    
        display: flex;
        flex-flow: row;
    
        border-top: 1px solid #E1E1E1;
        border-bottom: 1px solid #E1E1E1;
        background-color: #FFFFFF;
        padding: 10px;
    }
}

.app-geo-feeds-creation-wizard-add{
    margin: 15px;
}

.app-geo-feeds-creation-wizard-text {
    display: flex;
    align-items: center;
}

.app-geo-feeds-creation-wizard-actions {
    display: flex;
    margin-left: auto;
    align-items: center;

    .app-button {
        padding-left: 5px;
    }
}

.app-geo-feeds-creation-wizard-row {
    border-top: 1px solid #E1E1E1;
    border-bottom: 1px solid #E1E1E1;
    background-color: #FFFFFF;
    padding: 10px;
}

.app-geo-feeds-creation-wizard-name{
    margin: 5px 15px;
    width: 300px;    
}

.app-geo-feeds-creation-wizard-url{
    margin: 5px 15px;
    width: 350px;    
}

.app-geo-feeds-creation-wizard-empty {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.18px;
    color: #666666;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.app-geo-feeds-creation-wizard-rows{    
    display: flex;
    flex-flow: row;
}

.app-geo-feeds-creation-wizard-geo-feed-name{
    margin: 5px 15px;
    width: 500px;    
}

.app-geo-feeds-creation-wizard-source-overlay-type{
    padding: 10px 0px;
    margin-left: 15px;
}