@import './geoFeedCreationWizard/geoFeedCreationWizard';

.app-geo-feeds{
    margin: 15px;
}

.app-geo-feeds-header {
    padding: 15px;
}

.app-large-panel-tool-bar{  //todo fix
    height: 70px;
}

.app-geo-feeds-instructions{
    float: left;
    width: 900px;
}

.app-geo-feeds-actions{
    float: right;
}

.app-geo-feeds-divided-container{
    display: flex;
    flex-flow: row;
}

.app-geo-feeds-divided-left-column{    
    display: flex;
    flex-flow: column;
}

.app-geo-feeds-divided-column{    
    display: flex;
    flex-flow: column;
}

.app-geo-feeds-column-text{
    text-overflow: ellipsis;  
    overflow: hidden;  
}

.app-geo-feeds-user-image{
    @include user-image-small; 
}

.app-geo-feeds-username {
    padding-left: 10px;
}
