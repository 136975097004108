.app-file-manager{
    @include fill-height;
    height: 99%;
    background-color: $color-white;
    width: 1000%;
    max-width: 1120px;
    overflow: hidden;  

    .dx-filemanager{
        border-bottom: 0px;
    }
}