.app-hdr{
    @include text-sub-header2;
    color: $color-white;
    background-color: $color-primary;
    position: relative;
    height: 55px;
    line-height: 55px;
    padding: 0 20px;
    z-index: 100;

    .app-button-container{
        margin: 0 10px;
    }

    .dx-placeholder{
        line-height: 1;
    }
}

.app-header-search-text{
    display: inline-block;
    width: 310px;
    overflow: hidden;
}

.app-header-search-subtext{
    color: $color-dark-gray;
    font-weight: bold;
}

.app-header-search-default{
    float: right;
}

.app-hdr-table{
    width: 100%;
}

.app-hdr-search-input{    
    width: 100%;
    display: inline-block;
    
    .dx-textbox{
        border-radius: 0;        
    }    
}
.app-hdr-search-container{
    display: block;
    overflow: hidden;
    padding-right:80px; 
}

.app-hdr-search-container-td{
    position: relative;
}

.app-hdr-search-filter {
    position: absolute;
    left: -35px;
    top: 14px;
    margin: 0 !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    
    .app-button{
        border-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 35px !important;
        height: 28px !important;
        line-height: 28px !important;
    }
}

.app-hdr-search-button {
    position: absolute;
    right: 1px;
    top: 14px;
    margin: 0 !important;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    
    .app-button{
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 35px !important;
        height: 28px !important;
        line-height: 28px !important;
    }
} 

.app-hdr-tab-icon{
    margin-left: 5px;
}

.app-hdr-account-image{
    @include user-image-small;
}

.app-viewer{
    .app-hdr-search-input{    
        width: 100%;
        
        .dx-textbox{
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px; 
        }    
    }  

    .app-hdr-search-container{        
        padding-right:30px !important; 
    }

    .app-hdr-search-button{
        right: -49px;
    }
    
}