.app-analogs{
    @include fill-height;    
    
    .dx-row .app-data-grid-column.focused,
    .dx-row-alt .app-data-grid-column.focused {
        background-color: $color-tertiary20 !important;
    }

    .dx-row .app-data-grid-column.highlight,
    .dx-row-alt .app-data-grid-column.highlight {
        background-color: $color-secondary20 !important;
    }
}

.app-analogs-toolbar{
    display: flex;
    flex-flow: row;
}

.app-analogs-template{
    @include text-sub-header2;
    padding: 12px 0 0 8px;
}

.app-analogs-inputs{
    flex-grow: 2;
    padding: 8px 8px 0 8px;
}

.app-analogs-outputs{
    padding: 8px 8px 0 0;
}

.app-analogs-format{
    @include text-sub-header2;
    padding: 0 8px 0 0;
}

.app-analogs-format-dropdown{
    width: 150px;
}

.app-analogs-zoom{
    margin-left: 4px;
    margin-bottom: 2px;
}

.app-analogs-popup-wrapper{
    .dx-popup-content{
        padding: 0;
        overflow: hidden;
    }
}

.app-analogs-popup-actions{
    @include border-bottom-normal;
    padding: 7px 10px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}

.app-analogs-popup-buttons{
    margin-left: 15px;
}

.app-analogs-popup-content{
    @include text-bar;
    display: flex;
    flex-flow: column;
    height: 90%;
    padding: 10px;
}

.app-analogs-popup-reason{
    @include text-sub-header1;
    margin-top: 20px;
    flex-grow: 2;

    .dx-editor-outlined.dx-texteditor-with-label{
        margin-top: 0;
        padding-top: 5px;
    }
}

.app-analogs-filter-recalculate{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding-left: 16px;
}

.app-analogs-filters{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 8px;
    height: 100%;
}

.app-analogs-filter-items{
    display: flex;
    flex-flow: column;
    min-width: 400px;
}

.app-analogs-filter-row{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    .app-button-action{
        margin: 5px;
    }

    .app-legend-menu-item{
        flex-grow: 2;
    }
}

.app-analogs-filter-details{
    display: flex;
    flex-flow: column;
    flex-grow: 2;
    padding: 8px;
}

.app-analogs-filter-details-content{
    display: flex;
    flex-flow: column;
    height: 100%;

    .app-description{
        white-space: pre-wrap;
        text-align: left;
    }
}

.app-analogs-filter-details-title{
    @include text-header3;
    padding-bottom: 8px;
}

.app-analogs-filter-details-input{
    margin: 12px 4px 15px 4px;
}

.app-analogs-filter-details-datalist{
    @include fill-height;
    height: calc(75% - 50px);
    margin: 12px 0 12px 0;
}

.app-analogs-filter-changes-warning-icon{
    padding-right: 4px;;
}

.app-analogs-filter-changes-error-text{
    @include text-tab;
    padding-top: 16px;
    color: $color-red;
}

.app-analogs-filter-changes-warning-text{
    @include text-tab;
    padding-top: 16px;
    color: $color-orange;
}

.app-analogs-filter-changes-error-button{

    .app-button{
        background-color: $color-red;
    }
}

.app-analogs-filter-changes-warning-button{

    .app-button{
        background-color: $color-orange;
    }
}