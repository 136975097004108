.app-geofence-job-details{
    display: flex;
    flex-flow: column;
}

.app-geofence-job-details-description{
    @include text-caption;
    color: $color-dark-gray;
}

.app-geofence-job-details-error{
    @include text-caption;
    color: $color-septenary;
    margin-top: 10px;
    margin-right: 20px;
    white-space: break-spaces;
}

.app-geofence-job-details-header{
    @include text-caption;
    border-radius: $border-radius-normal;
    background-color: $color-senary10;
    color: $color-primary;
    display: flex;
    flex-flow: row;
    padding: 8px;
    margin-top: 5px;
}

.app-geofence-job-details-info{
    display: flex;
    flex-flow: column;
    flex-grow: 2;    
}

.app-geofence-job-details-row{
    display: flex;
    flex-flow: row;
}

.app-geofence-job-details-btn-row{    
    display: flex;
    flex-flow: row;
    margin-top: 10px;
    justify-content: right;
}

.app-geofence-job-details-label{
    padding: 3px;
    width: 150px;
    display: flex;
    justify-content: right;
}

.app-geofence-job-details-value{
    padding: 3px;
    flex-grow: 2;    
}

.app-geofences-job-details-grid-actions{
    display: flex;
    align-items: center;  
    width: 126px;
}

.app-geofence-job-details-action-container{
    display: flex;
    flex-flow: column;
    margin-top: 5px;
}

.app-geofences-job-details-button{
    margin-left: 6px;
    color: $color-dark-gray;
  }
  
.app-geofence-job-details-action-row{
    flex-grow: 2;
    margin: 5px 0;
}
  
.app-geofence-job-details-bottom-row{
    margin-bottom: 30px;;
}
