.app-thumb-nail-photo-container{
    position: relative;  
}

.app-thumb-nail-photo{
    width: 170px;
    height: 127px;
    border-radius: $border-radius-normal;
    cursor: pointer;
}

.app-thumb-nail-photo-hover{
    width: 170px;
    height: 127px;
    position: absolute;
    top: 0px;
    color: $color-white;
    background: $color-black-translucent;
    border-radius: $border-radius-normal;
    pointer-events: none;  
}

.app-thumb-nail-photo-title{
    @include text-bar;
    padding: 10px 10px;
    width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.app-thumb-nail-photo-date{
    @include text-sub-header2;
    padding: 0px 10px;
}

.app-thumb-nail-photo-user-section{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 40px;
    padding-left: 10px;
}

.app-thumb-nail-photo-user-name{
    @include text-caption;
    padding-left: 15px;
    overflow-wrap: break-all;
}

.app-thumb-nail-photo-user-image{
    @include user-image-small; 
}

