.app-panel{
    @include fill-height;
    @include shadow-medium;
    background-color: $color-white;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 320px;
    z-index: 1;
}

.app-panel-right{
    left: inherit;
    right: 0px;
    z-index: 1000;
    box-shadow: -2px 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.app-panel-body{
    border-left: 1px solid $color-light-gray;
    display:flex;
    flex-flow:column;
    flex-grow:1;
    height: 0;
    overflow: hidden;

    .app-panel{
        @include shadow-none;
    } 
}

.app-panel-tool-bar{
    @include border-bottom-normal($color-light-gray);
    background-color: $color-primary5;
    padding: 5px;
}

.app-panel-title{
    @include text-sub-header1;
    @include border-bottom-normal($color-light-gray);
    background-color: $color-primary10;
    position: relative;
    font-size: 16px;
    padding: 20px 10px;
    font-weight: 500;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.app-panel-reload{
    position: absolute;
    top: 15px;
    right: 70px;
}

.app-panel-help{
    position: absolute;
    top: 15px;
    right: 40px;
}

.app-panel-close{
    position: absolute;
    top: 15px;
    right: 10px;
}

.app-panel-body-loader{
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 56px;
}