.app-custom-channels-selector-list,
.app-custom-chains-selector-list {
    @include fill-height;
    margin: 10px 0 20px;
    //height: 88%;
    height: 0;
    flex-grow: 1;
}

.dx-selection.dx-row:not(.dx-row-focused) > td  {
    border-color: transparent !important;
    background-color: transparent !important;
}

