.app-photos-upload-photos-description{
  display: inline-block;
  width: 550px;
  padding: 10px;
  margin-left: 10px;
  margin-top: 10px;
  border-style: none;
  height: 75px;
  text-align: left;
}

.app-photos-upload-photos-actions{
    float: right;
    padding: 24px 8px;
    .app-button{
        margin-left: 10px;
    }
}

#dropzone-external {
    height: 350px;
    background-color: rgba(183, 183, 183, 0.1);
    border-width: 2px;
    padding: 10px;
  }
  
  #dropzone-external > * {
    pointer-events: none;
  }
  
  #dropzone-external.dropzone-active {
    border-style: solid;
  }

  .dropzone-text-full {
    @include text-header3;
    color: $color-dark-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;    
  }

  .dropzone-text {
    @include text-header3;
    color: $color-dark-gray;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  
.app-photos-upload-photos{
    @include vertical-scroll;
    display: inline-block;
}

.app-photos-upload-photos-body{
    padding: 10px 10px;
    height: 300px;
}

.app-photos-upload-photos-content{
    border-radius: $border-radius-normal;
    background-color: $color-primary3;
}

.app-photos-edit-photo-upload-photo-row{
  @include border-top-normal;
  @include border-bottom-normal;
  background-color: $color-white;
  padding: 20px;
      
  &:nth-child(2n+1){
      border: none;
      background-color: $color-primary3;
  }

  .app-button-icon{
      color: $color-dark-gray;
  }
}
