.dx-header-row .app-data-grid-column .dx-datagrid-text-content{
    text-decoration: underline;
}

.dx-header-row .app-data-grid-column-no-link .dx-datagrid-text-content{
    text-decoration: none;
}

.dx-datagrid-icon-column{
    text-align: center;
}

.app-data-grid-column{
    vertical-align: middle !important;
}

.dx-header-row .app-data-grid-column{
    color: $color-primary;
}

.dx-header-row .app-data-grid-column-sum{
    background-color: $color-primary5;
}

.dx-header-row .app-data-grid-column,
.dx-header-row .dx-command-expand,
.dx-header-row .dx-command-select{ 
    background-color: $color-primary10
}

.dx-row-alt {
    background-color: $color-light-gray;
}

.dx-header-row .app-data-grid-column .dx-datagrid-text-content{
   @include text-sub-header1;
}

.dx-freespace-row {  
    height: 0px !important;  
 }  

 .dx-master-detail-row{
    .dx-toolbar{
        display: none;
        margin: 0;
    }

    
 }

 .dx-master-detail-cell{
    padding: 5px !important;
}

.app-data-grid-column-editable{
    background-color: $color-primary3;
}

.dx-row-alt{
    .app-data-grid-column-editable{
        background-color: $color-primary5 !important;
    }    
}