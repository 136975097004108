.app-geofence-job-renderers-row-options{
    display: flex;
    flex-flow: row;
    margin-top: 5px;
    justify-content: space-evenly;

    .app-option-selector{
        margin: 5px;
    }

    .app-selectable-items-body{
        width: 100%;
        margin: 7px;

        .app-option-selector-icon{
            display: flex;
            justify-content: center;
        }
    }
}

.app-geofence-job-renderers-col-options{
    display: flex;
    flex-flow: column;
}

.app-geofence-job-renderers-section{
    @include border-normal($color-light-gray);
    border-radius: $border-radius-normal;
    margin: 5px 0;
    padding: 8px;
}

.app-geofence-job-renderers-label{
    display: flex;
    justify-content: center;
    padding: 3px;
    width: 190px;
}

.app-geofence-job-renderers-type-label{
    width: 100px;
}

.app-geofence-job-renderers-warning{
    @include text-caption;
    color: $color-dark-gray;
}

.app-geofence-job-renderers-radio{
    margin: 5px;
}

.app-geofence-job-renderers-name{
    margin-left: 5px;
}

.app-geofence-job-renderers-bar-buttons{    
    width: 140px;
    
    .app-bar-table{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
    }

    .app-clickable-active{
        background-color: $color-tertiary20;
        color: $color-primary;    

        &.app-clickable-hovered{
            @include shadow-light;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }        
    }    
}
