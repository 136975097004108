.app-hot-spot-header{
    text-align: center;
    border-bottom: 1px solid #CCC;
    padding: 10px;
    padding-left: 0;

    .app-button-container{
        margin-left: 10px;
    }
}

.app-hot-spot-body{
    @include fill-height;
    padding: 10px;
    display:flex;
    flex-flow: column;
    flex-grow: 1;
    height: 0;
}

.app-hot-spot-fields{
    @include fill-height;
    border-radius: 5px;
    margin-top: 5px;
    border: 1px solid #CCC;
    background: $color-primary10;
    padding: 5px;
}

.app-hot-spot-fields-header{
    text-align: right;
    margin-bottom: 5px;
}

.app-hot-spot-fields-body{
    @include fill-height;
    @include vertical-scroll;
    background: $color-primary5;
    border: 1px solid #CCC;
    border-radius: 5px;
    display:flex;
    flex-flow:column;
    flex-grow:1;
    height:0;
   
    .app-hot-spot-fields-row{
        padding: 5px;
        padding-bottom: 7px;
        border-top: 1px solid #CCC;
        border-bottom: 1px solid #CCC;

        &:nth-child(2n+1){
            border: none;
            background-color: $color-white;
        }

        .app-hot-spot-fields-drop-down{
            display: inline-block;
            width: 170px;
            margin-right: 5px;
        }
    
        .app-hot-spot-fields-weight{
            display: inline-block;
            width: 75px;
        }

        .app-hot-spot-fields-delete{
            color: $color-dark-gray;
            margin-left: 5px;
            display: inline-block;
        }
    }    
}

.app-hot-spot-fields-body-empty{
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.18px;
    color: #666666;
    text-align: center;
    margin: auto;
}