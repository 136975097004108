.app-map-book-runner-header {
    padding:7px;
    display: flex;
    justify-content: space-between;
}

.app-map-book-runner-title {
    @include text-sub-header1;
}

.app-map-book-runner-actions-button {
    padding-left: 15px;
    float: right;
}
  
.app-map-book-runner-map-book-actions-button {
    padding-left: 10px;
    top: -10px;
    float: right;
    color: $color-dark-gray;
  }

.app-map-book-runner-list-name{
    padding-left: 10px;
}

.app-map-book-runner-list-item{
    display: flex;
    justify-content: space-between;
}

.app-map-book-runner-textBox{
    padding:7px;
}

.app-map-book-runner-list-container{
    padding: 7px;
    height: calc(100% - 100px);
    display: flex;
    flex-flow: column;
    position: relative;
}

.app-map-book-runner-error{
    .app-modal-card-body{
        width: 600px;
        height: 600px;
    }
}
