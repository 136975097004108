.app-map-book-error-title{
    font-size: 18px;
    font-weight: 500;
    padding: 20px 15px;
    border-bottom: 1px solid #CCC;        

    .app-button-container{
        float: right;
        margin-left: 5px;
    }
}

.app-map-book-error-container {
    margin: 10px;
}

.app-map-book-error-description {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 10px;
}

.app-map-book-error-list-container {
    @include vertical-scroll;
    
    .app-map-book-error-list {
        height: 250px;
    }
}

.app-map-book-error-mid {
    @include text-sub-header2;
    padding-bottom: 5px;
}

.app-map-book-error-mid-list {
    @include text-sub-header2;
    margin-left: 20px;
    padding-bottom: 5px;
}

.app-map-book-error-1st {
    @include text-sub-header1;
    padding-bottom: 5px;
}

.app-map-book-error-2nd {
    @include text-sub-header1;
    margin-left: 20px;
    padding-bottom: 5px;
}

.app-map-book-error-warning {
    color: $color-dark-gray;
    margin-left: 25px;
    margin-right: 10px;
}

.app-map-book-error-actions{
    margin: 20px 0px;
    float: right;   
}

.app-map-book-error-error-pages-container{
    margin-top: 10px;  
}
