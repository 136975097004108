.app-tools-custom-channels{
    margin: 10px;

    .dx-datagrid-nowrap{
        overflow: visible;

        .dx-scrollable-container{
            overflow: visible;
        }

        .app-data-grid-column {            
            overflow: visible;
        }
    }
}

.app-tools-custom-channels-action-table{
    width: 100%;
    color: $color-dark-gray;
}

.app-tools-custom-channels{
    margin: 15px;
}

.app-tools-custom-channels-header {
    padding: 15px;
}

.app-large-panel-tool-bar{
    height: 70px;
}

.app-tools-custom-channels-instructions{
    float: left;
    width: 900px;
}

.app-tools-custom-channels-actions{
    float: right;
}

.app-tools-custom-channels-column-text{
    text-overflow: ellipsis;  
    overflow: hidden;  
}
