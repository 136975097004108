.app-card {
    height: 100%;
    background-color: $color-white;
}

.app-card-body {
    @include border-normal($color-light-gray);
    border-radius: $border-radius-normal;
    height: 100%;
    @include vertical-scroll;
}

.app-card-body-loader {
    position: relative;
}
