.app-photos{
    @include fill-height;
}

.app-photos-header{
    @include border-bottom-normal;
    padding: 10px 10px;
}

.app-photos-search{
    float: left;
    padding-left: 5px;
}

.app-photos-actions{
    float: right;

    .app-button{
        margin-left: 10px;
    }
}

.app-photos-body{
    padding: 10px 0px 0px 15px;
    @include vertical-scroll;
}

.app-photos-container {
    display: grid;
    column-gap: 5px;
    row-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    height: 380px;
}

.app-photos-empty{
    @include text-header3;
    color: $color-dark-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.app-photos-search-input{
    height: 40px;
    width: 330px;
    display: inline-block;   
}

.app-photos-search-button {
    margin: 0 !important;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    width: 50px !important;

    .app-button{
        height: 40px !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.app-photos-actions-container {
    padding: 25px;
    position: absolute;
    right: 30px;
    z-index: 1000;
    padding-right: 310px;
  }

  .app-photos-actions-container-group {
    padding: 10px;
 }
  
  .app-photos-actions-container-group-icon {
     padding-left: 15px;
  }

  .app-photos-details {
    position: absolute;
    height: 200px;
    color: $color-white;
    bottom: 0px;
    z-index: 1000;
    pointer-events:none;
    width: 100%;
  }

  .app-photos-details-title {
    padding: 10px
  }  

  .app-photos-details-description {
    padding: 30px 10px;
   
  }  

  .app-photos-details-user-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 40px;
    padding-left: 10px;
  }  

  .app-photos-details-user-section-name {
    @include text-sub-header2;
    padding-left: 15px;
    overflow-wrap: break-all;
  } 
  
  .app-photos-details-user-section-date {
    @include text-sub-header2;
    padding-right: 100px;
    float: right;
  } 

.app-photos-details-user-image{
    @include user-image-small; 
}

.app-photos-list-view-column{
  vertical-align: middle!important;
}

.app-photos-list-view-column-text{
  @extend .app-photos-list-view-column; 
  text-overflow: ellipsis;  
  overflow: hidden;  
}

.app-photos-thumb-nail-photo{
  width: 65px;
  height: 55px;
  border-radius: $border-radius-normal;
}

.app-photos-list-view-actions{
  display: flex;
  align-items: center;
}

.app-photos-list-view-actions-buttons {
  position: absolute;
}

.app-photos-list-view-actions-button {
  margin-left: 10px;
  color: $color-dark-gray;
}

.app-photos-upload{
  height: 350px;
}

@import "~react-image-gallery/styles/scss/image-gallery.scss";
