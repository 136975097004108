.app-memo-container{
    display: flex;
    flex-flow: row;
}

.app-memo-icon{
    height: 40px;
    width: 40px;    
    color: $color-dark-gray;
}

.app-memo-body{
    @include text-caption;
    display: flex;
    flex-flow: column;
    justify-content: left;
    flex-grow: 2;
}

.app-memo-title{
    padding-top: 4px;
    white-space: break-spaces;
    font-weight: 700;
}

.app-memo-message{
    padding-top: 4px;
    white-space: break-spaces;
}

.app-memo-date{
    padding-top: 4px;
    font-size: $font-size7;
    color: $color-gray;
}

.app-memo-icon-container{
    margin: 4px 10px 0 0;
}

.app-memo-icon{
    height: 40px;
    width: 40px;    
    color: $color-dark-gray;
}

.app-memo-user{
    display: flex;
    flex-flow: row;
    padding-top: 4px;
}

.app-memo-user-image-container{
    margin: 4px 4px 0 0;
}

.app-memo-user-image{
    @include default-user-image;
    border-radius: 50%;
    height: 20px;
    width: 20px;    
    border: 1px solid $color-primary;
}

.app-memo-user-name-container{
    font-weight: 700;
    margin: 6px 4px 0 0;
}
