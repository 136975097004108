.app-driving-directions-travel-modes{
    padding: 10px 0px;
    margin-left: 15px;
}

.app-driving-directions-travel-mode-button{
    margin: 0 5px;
}

.app-driving-directions-buttons{    
    @include border-top-normal;
    height: 75px;
}

.app-driving-directions-confim{ //todo copied, needed to be seperate?
    padding: 12px;
}

.app-driving-directions-close-button{
    margin: 0 11px;
}

.app-driving-directions-destinations{
    @include vertical-scroll;
    min-height: 175px;
    max-height: 340px; 
}

.app-destinations-actions-button{
    color: $color-primary;
    margin: 10px 0px 10px 20px;
}

.app-driving-directions-panel{
    @include vertical-scroll;
}
