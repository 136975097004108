.app-form-component-table {
    width: 100%;
}

.app-form-required-column {

    width: 10px;
    display: inline-block;
    color: $color-red;
    font-size: 10px;
    line-height: 1.35715;

    .app-form-required {
        padding-left: 5px;
        padding-top: 5px;
        padding-right: 5px;
    }
}

.app-form-prefix-column-empty {
    display: none;
}

.app-form-prefix-column {
    display: inline-block;
    font-size: 20px;
    padding-top: 3px;
    padding-right: 3px;

    .app-form-required {
        color: $color-red;
    }

    .app-form-warning {
        color: $color-orange;
    }

    .app-form-tooltip {
        color: $color-blue;
    }
}

.app-form-content-jd-test {
    width: 100%;
}

.app-form-error {
    > * {
        border-color: $color-red;
    }

    .app-textbox,
    .app-date-box,
    .dx-textbox,
    .dx-numberbox {
        border-color: $color-red !important;
    }
}

.app-form-warning {
    > * {
        border-color: $color-orange;
    }

    .app-textbox,
    .app-date-box,
    .dx-textbox,
    .dx-numberbox {
        border-color: $color-orange !important;
    }
}

.app-form-error.app-date-box {
    margin-left: 5px;
}

.app-form-warning.app-date-box {
    margin-left: 5px;
}

.app-form-checkbox-container {
    width:1px;
}