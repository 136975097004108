.app-photos-edit-photo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-white;
    z-index: 1000;
  }
  
  .app-photos-edit-photo-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }
  
  .app-photos-edit-photo-controls {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 20px;
    height: 40px;
    align-items: center;
  }
  
  .app-photos-edit-photo-slider-label {
    padding-right: 10px;
  }
  
  .app-photos-edit-photo-slider-container {
    padding-right: 15px;
  }

  .app-photos-edit-photo-slider {
    padding: 22px 0px;
    caret-color: transparent;
  }

  .app-photos-edit-photo-button {
    border-radius: 0px;
    color: $color-dark-gray;
    background-color: transparent;
    width: 30px;
    height: 30px;
    line-height: 30px; 
  }

  .app-photos-edit-photo-save-button {
    position: absolute;
    right: 15px;
    bottom: 100px;
  }

  .app-photos-edit-photo-close-button {
    position: absolute;
    right: 15px;
    top: 15px;
  }
