.app-button-menu-container{
    position: relative;
    display: inline-block;
}

.app-button-menu-title{
    @include text-sub-header1;
    @include border-top-normal;
    @include border-bottom-normal;
    @include text-sub-header2;
    background-color: $color-primary5;
    padding: 10px 10px 5px 10px;
    line-height: normal;
    white-space: nowrap;
}

.app-button-menu{    
    @include border-normal;
    background-color: $color-white;
    position: absolute;
    border-radius: 8px;
    z-index: 100;
    text-align: left;
    border-top-left-radius: 0px;

    .app-button-menu-title:first-child{
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
    }

    .app-button-menu-title:last-child{
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }

    .app-button-row:first-child{
        .app-button{
            border-top-left-radius: 8px !important;
            border-top-right-radius: 8px !important;
        }
    }

    .app-button-row:last-child{
        .app-button{
            border-bottom-left-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
        }
    }


    .app-button-menu-title:first-child{
        border-top-left-radius: 0px !important;
        border-top-right-radius: 8px !important;
    }
}

.app-button-menu-right{
    @include border-normal;
    background-color: $color-white;
    position: absolute;
    border-radius: 8px;
    z-index: 100;    
    right: 0px;
    text-align: left;
    border-top-left-radius: 8px;
    border-top-right-radius: 0;

    .app-button-container{
        margin-left: 0px !important;
    }

    .app-button-menu-title:first-child{
        border-top-left-radius: 8px;
        border-top-right-radius: 0;
    }

    .app-button-menu-title:last-child{
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }

    .app-button-row:first-child{
        .app-button{
            border-top-left-radius: 8px;
            border-top-right-radius: 0;
        }
    }

    .app-button-row:last-child{
        .app-button{
            border-bottom-left-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
        }
    }
}

.app-button-menu-scroll{    
    @include vertical-scroll;
    padding-bottom: 0 !important;
    max-height: 308px;
}

.app-button-menu-open{
    .app-button{
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }    

    

    .app-button-menu{
        .app-button-menu-title:first-child{
            border-top-left-radius: 0;
            border-top-right-radius: 8px;
        }
    }

    .app-button-menu-right{
        .app-button-menu-title:first-child{
            border-top-left-radius: 8px;
            border-top-right-radius: 0;
        }
    }
}