.app-modal-card {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.0);
    z-index: 1000;
}

.app-modal-card-body {
    position: relative;
    border-radius: $border-radius-normal;
    height: 100%;
    @include vertical-scroll;
}

.app-modal-card-body-loader {
    position: relative;
}

.app-modal-card-default{
    @include fill-height;
    padding: 30px;
    background: $color-translucent;
    overflow: visible;

    .app-modal-card-body {
        @include fill-height;
        @include shadow-medium;
        width: 100%;
        margin: auto;
        background: $color-white;
        border: 1px solid #CCC;
        margin-bottom: 30px;
        overflow: visible;
    }
}