@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
#root,
body,
html {
  height: 100%;
}

#root {
  display: flex;
  flex-flow: column;
}

body {
  margin: 0;
  font-family: sans-serif !important;
  font-family: "Trebuchet MS" !important;
  font-family: "Raleway" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

input, textarea, .app-selectable-text {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
  font-family: sans-serif !important;
  font-family: "Trebuchet MS" !important;
  font-family: "Raleway" !important;
}

img {
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

table td {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.app-hideable-hidden {
  display: none !important;
}

.app-clickable {
  cursor: pointer;
}
.app-clickable.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}
.app-clickable.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-clickable.app-clickable-disabled {
  cursor: not-allowed !important;
}
.app-clickable.app-clickable-not-licensed {
  cursor: not-allowed !important;
}
.app-clickable.app-clickable-coming-soon {
  cursor: not-allowed !important;
}

.app-button-container {
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  position: relative;
}
.app-button-container .app-button {
  border-radius: 8px;
  position: relative;
}
.app-button-container .app-button.app-clickable-disabled {
  color: #898989;
}
.app-button-container .app-button.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}
.app-button-container .app-button.app-button-show-expander {
  padding-right: 20px;
}
.app-button-container .app-button .app-button-text {
  margin-left: 10px;
  display: inline-block;
}
.app-button-container .app-button .app-button-icon {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding: 3px;
  margin-top: -2px;
  vertical-align: middle;
}
.app-button-container .app-button .app-button-expander {
  color: #565656;
  position: absolute;
  right: 4%;
  top: 35%;
}
.app-button-container .app-button .app-button-selected {
  color: #337ab7;
  margin-left: 10px;
}

.app-button-action {
  height: 24px;
  width: 24px;
  line-height: 24px;
}
.app-button-action .app-button-icon {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
}
.app-button-action.app-button-medium {
  height: 34px;
  width: 34px;
  line-height: 34px;
}
.app-button-action.app-button-medium .app-button-icon {
  font-size: 18px;
}
.app-button-action .app-button {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.5px;
  border: 1px solid #565656;
  color: #565656;
  background-color: #FFFFFF;
  border-radius: 4px;
}
.app-button-action .app-button.app-clickable-disabled {
  border: 1px solid #E1E1E1;
  background-color: #E1E1E1;
}
.app-button-action .app-button.app-clickable-coming-soon {
  border: 2px solid #00A5D3;
  color: #00A5D3;
  background-color: #DEF1E8;
}
.app-button-action .app-button.app-clickable-not-licensed {
  border: 2px dashed #898989;
  color: #AAA;
  background-color: #F1F1F1;
}
.app-button-action .app-button.app-clickable-active {
  border-color: #C1D42F;
  background-color: #F3F6D5;
}
.app-button-action .app-button.app-clickable-active .app-button-icon {
  color: #565656;
}

.app-button-chat {
  width: 55px;
  height: 55px;
  line-height: 55px;
}
.app-button-chat .app-button {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #FFFFFF;
  background-color: #2B328C;
  border-radius: 100px;
}

.app-button-header .app-button {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
}
.app-button-header .app-button.app-clickable-hovered, .app-button-header .app-button.app-clickable-active {
  box-shadow: none;
  color: #C1D42F;
}
.app-button-header .app-button.app-clickable-pressed {
  color: #E0E997;
}
.app-button-header .app-button.app-clickable-disabled {
  color: #898989;
}
.app-button-header .app-button.app-clickable-coming-soon {
  border: 2px solid #00A5D3;
  color: #00A5D3;
  background-color: #DEF1E8;
}
.app-button-header .app-button.app-clickable-not-licensed {
  border: 2px dashed #898989;
  color: #AAA;
  background-color: #F1F1F1;
}
.app-button-header .app-button .app-button-image {
  border-radius: 100px;
  margin-top: -2px;
  width: 30px;
  height: 30px;
}
.app-button-header .app-button .app-button-icon {
  font-size: 20px;
}
.app-button-header .app-button-active-element {
  background-color: #C1D42F;
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
}

.app-button-primary.app-button-large {
  width: 204px;
  height: 59px;
  line-height: 59px;
}
.app-button-primary.app-button-medium {
  width: 132px;
  height: 49px;
  line-height: 49px;
}
.app-button-primary.app-button-small {
  width: 79px;
  height: 39px;
  line-height: 39px;
}
.app-button-primary.app-button-tiny {
  width: 50px;
  height: 32px;
  line-height: 32px;
}
.app-button-primary.app-button-tiny .app-button {
  border-radius: 5px;
}
.app-button-primary .app-button {
  color: #FFFFFF;
  background-color: #5AB88D;
}
.app-button-primary .app-button.app-clickable-disabled {
  color: #565656;
  background-color: #A6D5BF;
}
.app-button-primary .app-button.app-clickable-coming-soon {
  border: 2px solid #00A5D3;
  color: #00A5D3;
  background-color: #DEF1E8;
}
.app-button-primary .app-button.app-clickable-not-licensed {
  border: 2px dashed #898989;
  color: #AAA;
  background-color: #F1F1F1;
}
.app-button-primary .app-button.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-button-secondary.app-button-large {
  width: 200px;
  height: 55px;
  line-height: 55px;
}
.app-button-secondary.app-button-medium {
  width: 128px;
  height: 45px;
  line-height: 45px;
}
.app-button-secondary.app-button-medium .app-button-icon {
  font-size: 22px;
}
.app-button-secondary.app-button-small {
  width: 75px;
  height: 39px;
  line-height: 35px;
}
.app-button-secondary.app-button-tiny {
  width: 46px;
  height: 28px;
  line-height: 28px;
}
.app-button-secondary.app-button-extra-small {
  height: 30px;
  width: 34px;
  line-height: 30px;
}
.app-button-secondary.app-button-extra-small .app-button-icon {
  font-size: 18px;
}
.app-button-secondary .app-button {
  border: 2px solid #5AB88D;
  color: #5AB88D;
  background-color: #FFFFFF;
}
.app-button-secondary .app-button.app-clickable-disabled {
  border: 2px solid #898989;
  color: #898989;
  background-color: #E1E1E1;
}
.app-button-secondary .app-button.app-clickable-coming-soon {
  border: 2px solid #00A5D3;
  color: #00A5D3;
  background-color: #DEF1E8;
}
.app-button-secondary .app-button.app-clickable-not-licensed {
  border: 2px dashed #898989;
  color: #AAA;
  background-color: #F1F1F1;
}
.app-button-secondary .app-button.app-clickable-active {
  background-color: #F3F6D5;
  color: #C1D42F;
  border-color: #C1D42F;
}
.app-button-secondary .app-button.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-button-tertiary.app-button-large {
  width: 185px;
  height: 55px;
  line-height: 55px;
}
.app-button-tertiary.app-button-medium {
  width: 75px;
  height: 55px;
  line-height: 55px;
}
.app-button-tertiary.app-button-small {
  width: 55px;
  height: 55px;
  line-height: 55px;
}
.app-button-tertiary .app-button {
  border: 2px solid #5AB88D;
  color: #5AB88D;
  background-color: #FFFFFF;
}
.app-button-tertiary .app-button.app-clickable-disabled {
  border: 2px solid #898989;
  color: #898989;
  background-color: #E1E1E1;
}
.app-button-tertiary .app-button.app-clickable-coming-soon {
  border: 2px solid #00A5D3;
  color: #00A5D3;
  background-color: #DEF1E8;
}
.app-button-tertiary .app-button.app-clickable-not-licensed {
  border: 2px dashed #898989;
  color: #AAA;
  background-color: #F1F1F1;
}
.app-button-tertiary .app-button.app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
  border-color: #C1D42F;
  border-radius: 8px;
}
.app-button-tertiary .app-button.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}
.app-button-tertiary .app-button .app-button-icon {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
}

.app-button-map .app-button {
  border: 1px solid #E1E1E1;
  color: #565656;
  background-color: #FFFFFF;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.app-button-map .app-button.app-button-show-drop-down {
  width: 40px;
}
.app-button-map .app-button.app-clickable-hovered {
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
}
.app-button-map .app-button.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}
.app-button-map .app-button.app-clickable-disabled {
  background-color: #E1E1E1;
}
.app-button-map .app-button.app-clickable-coming-soon {
  border: 2px solid #00A5D3;
  color: #00A5D3;
  background-color: #DEF1E8;
}
.app-button-map .app-button.app-clickable-not-licensed {
  border: 2px dashed #898989;
  color: #AAA;
  background-color: #F1F1F1;
}
.app-button-map .app-button.app-clickable-active {
  border-color: #C1D42F;
  background-color: #E0E997;
}
.app-button-map .app-button.app-clickable-active .app-button-icon {
  color: #5AB88D;
}

.app-button-navigation .app-button {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #2B328C;
  background-color: #FFFFFF;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
}
.app-button-navigation .app-button.app-clickable-disabled {
  background-color: #E1E1E1;
}
.app-button-navigation .app-button.app-clickable-coming-soon {
  border: 2px solid #00A5D3;
  color: #00A5D3;
  background-color: #DEF1E8;
}
.app-button-navigation .app-button.app-clickable-not-licensed {
  border: 2px dashed #898989;
  color: #AAA;
  background-color: #F1F1F1;
}
.app-button-navigation .app-button.app-clickable-active {
  background-color: #C1D42F;
  border-radius: 8px;
}
.app-button-navigation .app-button .app-button-icon {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.app-button-navigation .app-button-active-element {
  background-color: #C1D42F;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  position: absolute;
  left: 0px;
  width: 5px;
  height: 40px;
}
.app-button-navigation .app-button-sub-text {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #2B328C;
  margin: 5px 5px 0px 5px;
  word-wrap: break-word;
  white-space: normal;
  line-height: normal;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.app-button-row {
  display: block;
}
.app-button-row .app-button {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  box-shadow: none;
  border-radius: 0px;
  padding: 9px 10px;
  text-align: left;
  line-height: normal;
  width: auto;
  height: auto;
}
.app-button-row .app-button.app-clickable-hovered {
  box-shadow: none;
  background-color: #E0E997;
}
.app-button-row .app-button .app-button-image {
  width: 20px;
  height: 20px;
}
.app-button-row .app-button .app-button-text {
  margin-left: 5px;
}
.app-button-row .app-button .app-button-icon {
  color: #565656;
}
.app-button-row .app-button.app-clickable-disabled .app-button-icon {
  color: #E1E1E1;
}
.app-button-row .app-button.app-clickable-coming-soon {
  border: 2px solid #00A5D3;
  color: #00A5D3;
  background-color: #DEF1E8;
}
.app-button-row .app-button.app-clickable-not-licensed {
  border: 2px dashed #898989;
  color: #AAA;
  background-color: #F1F1F1;
}

.app-button-simple .app-button.app-clickable-active {
  color: #5AB88D;
}
.app-button-simple .app-button.app-clickable-disabled .app-button-icon {
  color: #898989;
}
.app-button-simple .app-button.app-clickable-coming-soon {
  border: 2px solid #00A5D3;
  color: #00A5D3;
  background-color: #DEF1E8;
}
.app-button-simple .app-button.app-clickable-not-licensed {
  border: 2px dashed #898989;
  color: #AAA;
  background-color: #F1F1F1;
}
.app-button-simple .app-button.app-clickable-hovered {
  box-shadow: none;
  filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.25));
}
.app-button-simple .app-button.app-clickable-pressed {
  box-shadow: none;
  filter: drop-shadow(-1px 1px 0px rgba(0, 0, 0, 0.25));
}

.app-button-tab .app-button {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: #000000;
}
.app-button-tab .app-button.app-clickable-hovered, .app-button-tab .app-button.app-clickable-active {
  box-shadow: none;
}
.app-button-tab .app-button.app-clickable-hovered .app-button-icon, .app-button-tab .app-button.app-clickable-active .app-button-icon {
  color: #C1D42F;
}
.app-button-tab .app-button.app-clickable-pressed {
  color: #E0E997;
}
.app-button-tab .app-button.app-clickable-disabled {
  color: #565656;
  background-color: #E1E1E1;
}
.app-button-tab .app-button.app-clickable-coming-soon {
  border: 2px solid #00A5D3;
  color: #00A5D3;
  background-color: #DEF1E8;
}
.app-button-tab .app-button.app-clickable-not-licensed {
  border: 2px dashed #898989;
  color: #AAA;
  background-color: #F1F1F1;
}
.app-button-tab .app-button .app-button-image {
  border-radius: 100px;
  margin-top: -2px;
  width: 30px;
  height: 30px;
}
.app-button-tab .app-button-active-element {
  background-color: #C1D42F;
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
}

.app-button-warning .app-button {
  background-color: #ffa500;
}

.app-bar {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  border: 1px solid #E1E1E1;
  background-color: #EAEAF3;
  color: #565656;
  border-radius: 4px;
  min-height: 30px;
  padding: 5px 5px 5px 10px;
}
.app-bar.app-clickable-disabled {
  border: 1px solid #898989;
  color: #898989;
  background-color: #E1E1E1;
}
.app-bar.app-clickable-coming-soon {
  border: 2px solid #00A5D3;
  color: #00A5D3;
  background-color: #DEF1E8;
}
.app-bar.app-clickable-not-licensed {
  border: 2px dashed #898989;
  color: #AAA;
  background-color: #F1F1F1;
}
.app-bar.app-bar-empty {
  border: 1px solid #E1E1E1;
  background-color: #EAEAF3;
}
.app-bar.app-clickable-hovered {
  background-color: #E0E997;
}
.app-bar.app-bar-open {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
}
.app-bar.app-bar-open.app-clickable-hovered {
  box-shadow: none;
}

.app-bar-body {
  border: 1px solid #E1E1E1;
  border-radius: 4px;
}
.app-bar-body.app-bar-open {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.app-bar-table {
  min-height: 30px;
  width: 100%;
}

.app-bar-table td {
  vertical-align: middle;
}

.app-bar-icon-container {
  text-align: center;
  padding-right: 10px;
  width: 20px;
  font-size: 20px;
}

.app-bar-text {
  word-break: break-word;
}

.app-bar-sub-text {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  margin-top: 5px;
}

.app-bar-action-container {
  text-align: right;
  white-space: nowrap;
}
.app-bar-action-container .app-button-container {
  width: 24px;
}
.app-bar-action-container .app-button-container .app-button-icon {
  font-size: 16px;
}

.app-bar-color {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
}

.app-bar-expandable {
  cursor: pointer;
}

.app-bar-empty {
  cursor: initial;
}

.app-bar-action-expander {
  width: 20px;
}

.app-collapsible-section {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  background-color: #EAEAF3;
  border-radius: 8px;
  padding: 0 15px;
  margin-bottom: 5px;
  line-height: 40px;
}
.app-collapsible-section.app-clickable-hovered {
  background-color: #DFE0EE;
}

.app-collapsible-section-content {
  display: block;
}

.app-collapsible-section-icon {
  margin-right: 10px;
}

.app-collapsible-section-count {
  background-color: #CACCE2;
  display: inline-block;
  margin-right: 10px;
  border-radius: 100px;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 25px;
}

.app-collapsible-section-selected {
  float: right;
}

.app-collapsible-section-action {
  float: right;
  padding-left: 8px;
}
.app-collapsible-section-action .app-button {
  color: #565656;
}

.app-collapsible-section-drag-is-over {
  background-color: #E0E997;
}

.app-date-box-container {
  display: flex;
  align-items: center;
}
.app-date-box-container .required {
  width: 10px;
  color: #ff0000;
  font-size: 10px;
  line-height: 1.35715;
}
.app-date-box-container .required .app-form-required {
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
}

.app-date-box .dx-datebox {
  width: 100%;
  color: #565656 !important;
  border-color: #565656 !important;
}
.app-date-box .dx-datebox:hover {
  border-color: #5AB88D !important;
}
.app-date-box .dx-datebox .dx-label-before,
.app-date-box .dx-datebox .dx-label,
.app-date-box .dx-datebox .dx-label-after {
  color: #565656 !important;
  border-color: #565656 !important;
}
.app-date-box .dx-datebox.dx-state-hover .dx-label-before,
.app-date-box .dx-datebox.dx-state-hover .dx-label,
.app-date-box .dx-datebox.dx-state-hover .dx-label-after {
  color: #5AB88D !important;
  border-color: #5AB88D !important;
}
.app-date-box .dx-datebox.dx-state-readonly {
  border-style: solid !important;
}
.app-date-box .dx-datebox.dx-state-readonly .dx-label-before,
.app-date-box .dx-datebox.dx-state-readonly .dx-label,
.app-date-box .dx-datebox.dx-state-readonly .dx-label-after {
  color: #565656 !important;
  border-color: #565656 !important;
}
.app-date-box .dx-datebox.dx-state-readonly input {
  background-color: #E1E1E1 !important;
}
.app-date-box .dx-button.dx-button-textBoxButton {
  height: 25px;
  width: 25px;
  border: 0px;
  margin-right: 10px;
  background: transparent;
}
.app-date-box .dx-button.dx-button-textBoxButton .dx-svg-icon path {
  fill: #565656;
}

.dx-header-row .app-data-grid-column .dx-datagrid-text-content {
  text-decoration: underline;
}

.dx-header-row .app-data-grid-column-no-link .dx-datagrid-text-content {
  text-decoration: none;
}

.dx-datagrid-icon-column {
  text-align: center;
}

.app-data-grid-column {
  vertical-align: middle !important;
}

.dx-header-row .app-data-grid-column {
  color: #2B328C;
}

.dx-header-row .app-data-grid-column-sum {
  background-color: #F4F5F9;
}

.dx-header-row .app-data-grid-column,
.dx-header-row .dx-command-expand,
.dx-header-row .dx-command-select {
  background-color: #EAEAF3;
}

.dx-row-alt {
  background-color: #E1E1E1;
}

.dx-header-row .app-data-grid-column .dx-datagrid-text-content {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.dx-freespace-row {
  height: 0px !important;
}

.dx-master-detail-row .dx-toolbar {
  display: none;
  margin: 0;
}

.dx-master-detail-cell {
  padding: 5px !important;
}

.app-data-grid-column-editable {
  background-color: #F9F9FC;
}

.dx-row-alt .app-data-grid-column-editable {
  background-color: #F4F5F9 !important;
}

.app-drop-down-container {
  display: flex;
  align-items: center;
}
.app-drop-down-container .required {
  width: 10px;
  color: #ff0000;
  font-size: 10px;
  line-height: 1.35715;
}
.app-drop-down-container .required .app-form-required {
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
}

.app-drop-down .dx-selectbox {
  width: 100%;
  color: #565656 !important;
  border-color: #565656 !important;
}
.app-drop-down .dx-selectbox:hover {
  border-color: #5AB88D !important;
}
.app-drop-down .dx-selectbox .dx-label-before,
.app-drop-down .dx-selectbox .dx-label,
.app-drop-down .dx-selectbox .dx-label-after {
  color: #565656 !important;
  border-color: #565656 !important;
}
.app-drop-down .dx-selectbox.dx-state-hover .dx-label-before,
.app-drop-down .dx-selectbox.dx-state-hover .dx-label,
.app-drop-down .dx-selectbox.dx-state-hover .dx-label-after {
  color: #5AB88D !important;
  border-color: #5AB88D !important;
}

.dx-colorbox {
  color: #565656 !important;
  border-color: #565656 !important;
}
.dx-colorbox:hover {
  border-color: #5AB88D !important;
}
.dx-colorbox .dx-label-before,
.dx-colorbox .dx-label,
.dx-colorbox .dx-label-after {
  color: #565656 !important;
  border-color: #565656 !important;
}
.dx-colorbox.dx-state-hover .dx-label-before,
.dx-colorbox.dx-state-hover .dx-label,
.dx-colorbox.dx-state-hover .dx-label-after {
  color: #5AB88D !important;
  border-color: #5AB88D !important;
}

.app-drop-down-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.app-drop-down-image .dx-textbox {
  display: none;
}

.app-drop-down-image-src {
  text-align: center;
}

.app-drop-down-color-swatch {
  border: 1px solid #000000;
  margin: 0px auto;
  margin-top: 2px;
  width: 20px;
  height: 20px;
}

.app-drop-down-item-disabled .dx-list-items .dx-state-disabled {
  background-color: #5AB88D;
  color: #FFFFFF;
}

.dx-tag-content {
  border-radius: 4px;
  background-color: #E0E997;
}

.app-text-box-container {
  display: flex;
  align-items: center;
}
.app-text-box-container .required {
  width: 10px;
  color: #ff0000;
  font-size: 10px;
  line-height: 1.35715;
}
.app-text-box-container .required .app-form-required {
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
}

.app-text-box .dx-textbox,
.app-text-box .dx-numberbox {
  width: 100%;
  color: #565656 !important;
  border-color: #565656 !important;
}
.app-text-box .dx-textbox:hover,
.app-text-box .dx-numberbox:hover {
  border-color: #5AB88D !important;
}
.app-text-box .dx-textbox .dx-label-before,
.app-text-box .dx-textbox .dx-label,
.app-text-box .dx-textbox .dx-label-after,
.app-text-box .dx-numberbox .dx-label-before,
.app-text-box .dx-numberbox .dx-label,
.app-text-box .dx-numberbox .dx-label-after {
  color: #565656 !important;
  border-color: #565656 !important;
}
.app-text-box .dx-textbox.dx-state-hover .dx-label-before,
.app-text-box .dx-textbox.dx-state-hover .dx-label,
.app-text-box .dx-textbox.dx-state-hover .dx-label-after,
.app-text-box .dx-numberbox.dx-state-hover .dx-label-before,
.app-text-box .dx-numberbox.dx-state-hover .dx-label,
.app-text-box .dx-numberbox.dx-state-hover .dx-label-after {
  color: #5AB88D !important;
  border-color: #5AB88D !important;
}
.app-text-box .dx-textbox.dx-state-readonly,
.app-text-box .dx-numberbox.dx-state-readonly {
  border-style: solid !important;
}
.app-text-box .dx-textbox.dx-state-readonly .dx-label-before,
.app-text-box .dx-textbox.dx-state-readonly .dx-label,
.app-text-box .dx-textbox.dx-state-readonly .dx-label-after,
.app-text-box .dx-numberbox.dx-state-readonly .dx-label-before,
.app-text-box .dx-numberbox.dx-state-readonly .dx-label,
.app-text-box .dx-numberbox.dx-state-readonly .dx-label-after {
  color: #565656 !important;
  border-color: #565656 !important;
}
.app-text-box .dx-textbox.dx-state-readonly input,
.app-text-box .dx-numberbox.dx-state-readonly input {
  background-color: #E1E1E1 !important;
}
.app-text-box .dx-button.dx-button-textBoxButton {
  height: 25px;
  width: 25px;
  border: 0px;
  margin-right: 10px;
  background: transparent;
}
.app-text-box .dx-button.dx-button-textBoxButton .dx-svg-icon path {
  fill: #565656;
}

.app-icon-spin {
  animation: spin infinite 2s linear;
}

.app-icon-spin-slow {
  animation: spin infinite 4s linear;
}

.app-large-panel {
  height: 100%;
  display: flex;
  flex-flow: column;
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
  background-color: #FFFFFF;
  width: 1000%;
  max-width: 1120px;
  position: absolute;
  top: 0;
  left: 0;
}

.app-large-panel-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  background-color: #EAEAF3;
  text-align: center;
  padding: 15px 0;
  position: relative;
}

.app-large-panel-tool-bar {
  border-bottom: 1px solid #E1E1E1;
  background-color: #F4F5F9;
  height: 55px;
}

.app-large-panel-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
  flex-grow: 1;
  /*height: calc(100% - 55px);*/
}

.app-large-panel-back {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #2B328C;
}
.app-large-panel-back .app-large-panel-back-link {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #2B328C;
  text-decoration: none;
  cursor: pointer;
}
.app-large-panel-back .app-large-panel-back-link:hover {
  text-decoration: underline;
}

.app-large-panel-back-icon {
  margin-right: 5px;
}

.app-large-panel-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  /*height: calc(100% - 60px);*/
  flex-grow: 1;
  position: relative;
}

.app-loader-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  text-align: center;
}
.app-loader-container.app-loader-primary {
  background-color: #2B328C;
}
.app-loader-container.app-loader-primary .app-loader-dot {
  background-color: #FFFFFF;
}
.app-loader-container.app-loader-default {
  background-color: #FFFFFF;
}
.app-loader-container.app-loader-default .app-loader-dot {
  background-color: #2B328C;
}
.app-loader-container.app-loader-primary {
  background-color: #2B328C;
}
.app-loader-container.app-loader-primary .app-loader-dot {
  background-color: #FFFFFF;
}
.app-loader-container.app-loader-generate {
  background-color: rgba(125, 125, 125, 0.3);
}
.app-loader-container.app-loader-generate .app-loader-dot {
  background-color: #2B328C;
}

.app-loader-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 7px;
}

.app-loader {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: -moz-max-content;
  height: max-content;
}
.app-loader .app-loader-logo {
  vertical-align: middle;
  height: 100px;
  margin-bottom: 10px;
  opacity: 1;
}
.app-loader.app-loader-large .app-loader-dot {
  width: 8px;
  height: 8px;
}

.app-loader-dot1 {
  animation: jump 1.6s -0.64s linear infinite;
}

.app-loader-dot2 {
  animation: jump 1.6s -0.48s linear infinite;
}

.app-loader-dot3 {
  animation: jump 1.6s -0.32s linear infinite;
}

.app-loader-dot4 {
  animation: jump 1.6s -0.16s linear infinite;
}

.app-loader-dot5 {
  animation: jump 1.6s linear infinite;
}

@keyframes jump {
  0%, 80%, 100% {
    transform: scale(0.2);
  }
  40% {
    transform: scale(1.5);
  }
}
.app-radio-button-set {
  display: inline-block;
}
.app-radio-button-set .app-button {
  border-radius: 0;
}
.app-radio-button-set.app-radio-button-set-horizontal .app-radio-button:first-child .app-button {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: none;
}
.app-radio-button-set.app-radio-button-set-horizontal .app-radio-button:first-child .app-button-menu .app-button {
  border-radius: unset;
}
.app-radio-button-set.app-radio-button-set-horizontal .app-radio-button:not(:first-child):not(:last-child) .app-button {
  border-left: none;
  border-right: none;
}
.app-radio-button-set.app-radio-button-set-horizontal .app-radio-button:last-child .app-button {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: none;
}
.app-radio-button-set.app-radio-button-set-horizontal .app-radio-button:last-child .app-button.app-button-row {
  border-top-right-radius: 0;
}
.app-radio-button-set.app-radio-button-set-horizontal .app-radio-button:last-child .app-button-menu .app-button {
  border-radius: unset;
}
.app-radio-button-set.app-radio-button-set-vertical .app-radio-button:first-child .app-button:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.app-radio-button-set.app-radio-button-set-vertical .app-radio-button:last-child .app-button {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.app-radio-button-set .app-radio-button .app-button-row .app-button {
  border-radius: 0;
}

.app-button-menu-container {
  position: relative;
  display: inline-block;
}

.app-button-menu-title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  background-color: #F4F5F9;
  padding: 10px 10px 5px 10px;
  line-height: normal;
  white-space: nowrap;
}

.app-button-menu {
  border: 1px solid #E1E1E1;
  background-color: #FFFFFF;
  position: absolute;
  border-radius: 8px;
  z-index: 100;
  text-align: left;
  border-top-left-radius: 0px;
}
.app-button-menu .app-button-menu-title:first-child {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.app-button-menu .app-button-menu-title:last-child {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.app-button-menu .app-button-row:first-child .app-button {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.app-button-menu .app-button-row:last-child .app-button {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.app-button-menu .app-button-menu-title:first-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 8px !important;
}

.app-button-menu-right {
  border: 1px solid #E1E1E1;
  background-color: #FFFFFF;
  position: absolute;
  border-radius: 8px;
  z-index: 100;
  right: 0px;
  text-align: left;
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
}
.app-button-menu-right .app-button-container {
  margin-left: 0px !important;
}
.app-button-menu-right .app-button-menu-title:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
}
.app-button-menu-right .app-button-menu-title:last-child {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.app-button-menu-right .app-button-row:first-child .app-button {
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
}
.app-button-menu-right .app-button-row:last-child .app-button {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.app-button-menu-scroll {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  padding-bottom: 0 !important;
  max-height: 308px;
}
.app-button-menu-scroll::-webkit-scrollbar {
  width: 0px;
}
.app-button-menu-scroll::-webkit-scrollbar-track {
  background: transparent;
}
.app-button-menu-scroll::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-button-menu-scroll::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-button-menu-open .app-button {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.app-button-menu-open .app-button-menu .app-button-menu-title:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 8px;
}
.app-button-menu-open .app-button-menu-right .app-button-menu-title:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
}

.app-button-split-container {
  position: relative;
  display: inline-block;
}
.app-button-split-container .app-button-split-left .app-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.app-button-split-container .app-button-split-right {
  width: 13px;
}
.app-button-split-container .app-button-split-right .app-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  width: 13px;
}

.app-option-selector {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 10px;
}

.app-option-selector-small {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.app-option-selector-check {
  color: #FFFFFF;
  background-color: #5AB88D;
  border-radius: 100px;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.app-option-selector-small .app-option-selector-check {
  width: 20px;
  height: 20px;
  line-height: 20px;
  top: 0px;
}

.app-option-selector-icon {
  font-size: 60px;
  font-weight: 300;
  letter-spacing: -0.5px;
  border: 1px solid #E1E1E1;
  color: #C1D42F;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 10px;
}

.app-option-selector-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #000000;
  width: 125px;
  word-wrap: break-word;
  text-align: center;
  margin: 0 5px;
}

.app-panel {
  height: 100%;
  display: flex;
  flex-flow: column;
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
  background-color: #FFFFFF;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 320px;
  z-index: 1;
}

.app-panel-right {
  left: inherit;
  right: 0px;
  z-index: 1000;
  box-shadow: -2px 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.app-panel-body {
  border-left: 1px solid #E1E1E1;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}
.app-panel-body .app-panel {
  box-shadow: none;
}

.app-panel-tool-bar {
  border-bottom: 1px solid #E1E1E1;
  background-color: #F4F5F9;
  padding: 5px;
}

.app-panel-title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  border-bottom: 1px solid #E1E1E1;
  background-color: #EAEAF3;
  position: relative;
  font-size: 16px;
  padding: 20px 10px;
  font-weight: 500;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.app-panel-reload {
  position: absolute;
  top: 15px;
  right: 70px;
}

.app-panel-help {
  position: absolute;
  top: 15px;
  right: 40px;
}

.app-panel-close {
  position: absolute;
  top: 15px;
  right: 10px;
}

.app-panel-body-loader {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 56px;
}

.app-selectable-items-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-selectable-items-body::-webkit-scrollbar {
  width: 13px;
}
.app-selectable-items-body::-webkit-scrollbar-track {
  background: #F4F5F9;
}
.app-selectable-items-body::-webkit-scrollbar-thumb {
  background: #EAEAF3;
  cursor: pointer;
}
.app-selectable-items-body::-webkit-scrollbar-thumb:hover {
  background: #DFE0EE;
}

.app-selectable-items-body-no-footer {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-selectable-items-body-no-footer::-webkit-scrollbar {
  width: 0px;
}
.app-selectable-items-body-no-footer::-webkit-scrollbar-track {
  background: transparent;
}
.app-selectable-items-body-no-footer::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-selectable-items-body-no-footer::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-selectable-items-actions {
  border-bottom: 1px solid #E1E1E1;
  height: 55px;
  line-height: 55px;
  text-align: center;
}

.app-selectable-items-actions-button {
  margin: 0 10px;
}

.app-selectable-items-footer {
  border-bottom: 1px solid #E1E1E1;
  height: 75px;
}

.app-selectable-items-selected {
  border-bottom: 1px solid #E1E1E1;
  overflow-y: scroll;
  padding-bottom: 75px !important;
  background-color: #FFFFFF;
  width: 100%;
  padding-bottom: 10px;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.app-selectable-items-selected::-webkit-scrollbar {
  width: 0px;
}
.app-selectable-items-selected::-webkit-scrollbar-track {
  background: transparent;
}
.app-selectable-items-selected::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-selectable-items-selected::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-selectable-items-confim {
  padding: 12px;
}

.app-selectable-items-close-button {
  margin: 0 11px;
}

.app-selectable-items-tag {
  margin-top: 10px;
  margin-left: 10px;
}

.app-selectable-items-item {
  padding-left: 40px;
}

.app-simple-table {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  border: 1px solid #898989;
}
.app-simple-table tr {
  border-bottom: 1px solid #898989;
}
.app-simple-table td,
.app-simple-table th {
  padding: 10px;
  text-align: center;
}
.app-simple-table thead:first-child {
  background-color: #DFE0EE;
}
.app-simple-table tbody tr:nth-child(2n+1) {
  background-color: #F4F5F9;
}

.dx-tabs .dx-tab .dx-icon,
.dx-tabs .dx-tab.dx-tab-selected {
  color: #2B328C;
}

.app-tabs {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  overflow-y: auto;
}
.app-tabs::-webkit-scrollbar {
  width: 0px;
}
.app-tabs::-webkit-scrollbar-track {
  background: transparent;
}
.app-tabs::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-tabs::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-tag {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: #2B328C;
  border-radius: 100px;
  background-color: #C1D42F;
  display: inline-block;
  padding: 10px 20px;
}

.app-tag-text {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: text-bottom;
}

.app-tag-close {
  margin-left: 15px;
}

.app-card {
  height: 100%;
  background-color: #FFFFFF;
}

.app-card-body {
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-card-body::-webkit-scrollbar {
  width: 0px;
}
.app-card-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-card-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-card-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-card-body-loader {
  position: relative;
}

.app-modal-card {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  z-index: 1000;
}

.app-modal-card-body {
  position: relative;
  border-radius: 8px;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-modal-card-body::-webkit-scrollbar {
  width: 0px;
}
.app-modal-card-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-modal-card-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-modal-card-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-modal-card-body-loader {
  position: relative;
}

.app-modal-card-default {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 30px;
  background: rgba(125, 125, 125, 0.3);
  overflow: visible;
}
.app-modal-card-default .app-modal-card-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  margin: auto;
  background: #FFFFFF;
  border: 1px solid #CCC;
  margin-bottom: 30px;
  overflow: visible;
}

.app-tabbed-content {
  display: flex;
  flex-flow: row;
}

.app-tabbed-content-tabs {
  display: flex;
  flex-flow: column;
  width: 54px;
  background-color: #F4F5F9;
  padding: 0 5px;
}
.app-tabbed-content-tabs .app-navigation-button .app-button-sub-text {
  font-size: 10px;
}

.app-tabbed-content-cards {
  flex-grow: 2;
  margin: 20px 20px 20px 10px;
  overflow: hidden;
}

.app-description {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  border: 1px solid #5AB88D;
  border-radius: 4px;
  background-color: #6DC09A;
  color: #FFFFFF;
  text-align: center;
  text-wrap: wrap;
  padding: 5px;
}

.app-description-secondary {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  border: 1px solid #EAEAF3;
  border-radius: 4px;
  background-color: #F4F5F9;
  color: #000000;
  text-align: center;
  text-wrap: wrap;
  padding: 5px;
}

.app-description-left {
  text-align: left;
}

.app-description-right {
  text-align: right;
}

.app-decription-error {
  background-color: #d9534f;
  border-color: #902153;
}

.app-symbol-manager {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.app-symbol-manager-pickers {
  display: flex;
  flex-flow: column;
  margin: 0 10px 10px 10px;
}

.app-symbol-manager-pickers-first {
  margin: 0 10px 10px 0;
}

.app-symbol-manager-pickers-last {
  margin: 0 0 10px 10px;
}

.app-symbol-manager-swatches {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  flex-basis: 100%;
  margin: 0 0 20px 10px;
}

.app-symbol-manager-inputs {
  width: 139px;
}
.app-symbol-manager-inputs .dx-selectbox {
  margin-bottom: 20px;
}

.app-symbol-manager-label {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  margin: 5px 0 15px 0;
}

.app-symbol-preview {
  border: 1px solid #565656;
  border-radius: 8px;
  width: 159px;
  height: 109px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-color-swatch-container {
  border: 1px solid #FFFFFF;
  border-radius: 4px;
}
.app-color-swatch-container.app-clickable-active {
  border: 1px solid #565656;
  border-radius: 4px;
}

.app-color-swatch {
  border: 1px solid #E1E1E1;
  border-radius: 4px;
  margin: 5px;
  width: 24px;
  height: 24px;
}

.app-wizard-tool-bar .app-large-panel-tool-bar {
  height: 60px;
}

.app-wizard-header {
  display: flex;
  flex-flow: row;
  padding-top: 10px;
  padding-bottom: 10px;
}

.app-wizard-step-text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  padding: 7px 0 0 20px;
  width: 300px;
  align-self: center;
}

.app-wizard-step-progress {
  flex-grow: 2;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.app-wizard-step-indicator {
  border-radius: 4px;
  background-color: #CACCE2;
  margin: 5px;
  width: 48px;
  height: 5px;
  cursor: pointer;
}

.app-wizard-step-indicator-active {
  background-color: #2B328C;
}

.app-wizard-step-actions {
  display: flex;
  flex-flow: row;
  justify-content: right;
  align-items: center;
  width: 300px;
}

.app-wizard-step-progress-small {
  margin-top: 5px;
  flex-grow: 2;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.app-wizard-step-actions-small {
  margin-top: 5px;
  flex-grow: 2;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
.app-wizard-step-actions-small .app-button-container {
  margin: 3px;
}

.app-wizard-header .app-button-container {
  margin-right: 10px;
}

.app-file-manager {
  height: 100%;
  display: flex;
  flex-flow: column;
  height: 99%;
  background-color: #FFFFFF;
  width: 1000%;
  max-width: 1120px;
  overflow: hidden;
}
.app-file-manager .dx-filemanager {
  border-bottom: 0px;
}

.app-uploader-file-region {
  height: 100%;
  display: flex;
  flex-flow: column;
  border: 1px solid #E1E1E1;
  background-color: #F9F9FC;
  overflow-y: auto;
  margin-top: 10px;
  color: #565656;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-uploader-drop-zone {
  height: 100%;
  display: flex;
  flex-flow: column;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.app-uploader-file {
  display: flex;
  width: 100%;
  height: 55px;
  background: #FFFFFF;
  border-bottom: 1px solid #E1E1E1;
}

.app-uploader-file-table {
  width: 100%;
  margin: 10px;
}

.app-uploader-file-button-container {
  float: inline-end;
}

.app-uploader-button-container {
  padding-top: 15px;
  float: inline-end;
}

.app-polygon-style-swatch {
  width: 24px;
  height: 22px;
  margin: 4px;
  border-radius: 3px;
  cursor: pointer;
}
.app-polygon-style-swatch.app-clickable-hovered {
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
}
.app-polygon-style-swatch .app-polygon-style-swatch-transparent {
  display: inline-block;
  background-image: url("https://tasonline.com/jpicker/images/Transparent.png");
  background-repeat: repeat;
  border: 1px solid #CCCCCC;
  line-height: 0px;
  border-radius: 3px;
}
.app-polygon-style-swatch .app-polygon-style-swatch-transparent .app-polygon-style-swatch-color {
  width: 23px;
  height: 20px;
}
.app-polygon-style-swatch .app-polygon-style-swatch-transparent .app-polygon-style-swatch-text {
  top: 14px;
  left: 12px;
  position: absolute;
  font-size: 16px;
  font-weight: bold;
}

.app-polygon-style-auto-label .app-button {
  padding: 5px;
}

.app-work-bench {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 300px;
  background: #FFF;
  z-index: 102;
  width: 70%;
  margin: 0 20px;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.app-work-bench-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: gray;
}

.app-trade-area-drop-down .dx-dropdownbox {
  color: #565656 !important;
  border-color: #565656 !important;
}
.app-trade-area-drop-down .dx-dropdownbox:hover {
  border-color: #5AB88D !important;
}
.app-trade-area-drop-down .dx-dropdownbox .dx-label-before,
.app-trade-area-drop-down .dx-dropdownbox .dx-label,
.app-trade-area-drop-down .dx-dropdownbox .dx-label-after {
  color: #565656 !important;
  border-color: #565656 !important;
}
.app-trade-area-drop-down .dx-dropdownbox.dx-state-hover .dx-label-before,
.app-trade-area-drop-down .dx-dropdownbox.dx-state-hover .dx-label,
.app-trade-area-drop-down .dx-dropdownbox.dx-state-hover .dx-label-after {
  color: #5AB88D !important;
  border-color: #5AB88D !important;
}

.app-window .dx-popup-title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: #FFFFFF;
  background-color: #2B328C;
  line-height: 25px;
  min-height: 46px;
}
.app-window .dx-popup-content {
  padding: 0;
  overflow: visible;
}
.app-window .dx-overlay-content.dx-popup-normal {
  overflow: visible;
}
.app-window .app-window-content {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.app-window .app-window-close-button {
  float: right;
}
.app-window .app-window-restore-button {
  float: right;
  margin-right: 5px;
}
.app-window .app-window-minimize-button {
  float: right;
  margin-right: 5px;
}
.app-window.dx-overlay-shader {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.app-context-menu .dx-menu-items-container {
  padding: 0 !important;
}
.app-context-menu .dx-menu-items-container .dx-menu-item-content {
  padding: 0 !important;
}

.app-dual-panel {
  height: 100%;
  display: flex;
  flex-flow: column;
  box-shadow: 4px 5px 5px 0px rgba(0, 0, 0, 0.25);
  background-color: #FFFFFF;
  width: 1000%;
}
.app-dual-panel.left-panel {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 500px;
}
.app-dual-panel.right-panel {
  position: absolute;
  top: 0;
  left: 500px;
  border-left: 1px solid #ccc;
  background: #F9F9FC;
}

.app-dual-panel-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  background-color: #EAEAF3;
  text-align: center;
  padding: 15px 0;
  position: relative;
}

.app-dual-panel-tool-bar {
  border-bottom: 1px solid #E1E1E1;
  background-color: #F4F5F9;
  height: 55px;
}

.app-dual-panel-body {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.app-dual-panel-back {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #2B328C;
}
.app-dual-panel-back .app-dual-panel-back-link {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #2B328C;
  text-decoration: none;
  cursor: pointer;
}
.app-dual-panel-back .app-dual-panel-back-link:hover {
  text-decoration: underline;
}

.app-dual-panel-back-icon {
  margin-right: 5px;
}

.app-dual-panel-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
}

.app-drag-and-drop-content {
  border: 1px solid #E1E1E1;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 212px;
}

.app-drag-and-drop-header {
  display: flex;
  flex-flow: row;
  padding: 4px 12px 4px 12px;
  background-color: #EAEAF3;
}

.app-drag-and-drop-body {
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-drag-and-drop-body::-webkit-scrollbar {
  width: 0px;
}
.app-drag-and-drop-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-drag-and-drop-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-drag-and-drop-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-drag-and-drop-label {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  flex-grow: 2;
  align-content: center;
}

.app-drag-and-drop-row {
  display: flex;
  flex-flow: row;
}

.app-drag-and-drop-empty {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #565656;
  text-align: center;
  margin: auto;
  margin-top: 10px;
}

.app-drag-and-drop-item {
  flex-grow: 2;
}

.app-drag-and-drop-button {
  margin: 12px 0 0 0;
}

.app-drag-and-drop-move-button {
  width: 20px;
  height: 25px;
  border-width: 0;
  padding: 10px 0 0 7px;
  background: transparent;
  cursor: grab;
}

.app-drag-and-drop-basic-move-button {
  width: 20px;
  height: 25px;
  border-width: 0;
  padding-left: 10px;
  background: transparent;
  cursor: grab;
}

.hide-check-box .dx-select-checkbox {
  display: none;
}

.dx-selection.dx-row:not(.dx-row-focused) > td {
  background-color: transparent !important;
  border-color: transparent !important;
}

.app-memo-container {
  display: flex;
  flex-flow: row;
}

.app-memo-icon {
  height: 40px;
  width: 40px;
  color: #565656;
}

.app-memo-body {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  display: flex;
  flex-flow: column;
  justify-content: left;
  flex-grow: 2;
}

.app-memo-title {
  padding-top: 4px;
  white-space: break-spaces;
  font-weight: 700;
}

.app-memo-message {
  padding-top: 4px;
  white-space: break-spaces;
}

.app-memo-date {
  padding-top: 4px;
  font-size: 10px;
  color: #898989;
}

.app-memo-icon-container {
  margin: 4px 10px 0 0;
}

.app-memo-icon {
  height: 40px;
  width: 40px;
  color: #565656;
}

.app-memo-user {
  display: flex;
  flex-flow: row;
  padding-top: 4px;
}

.app-memo-user-image-container {
  margin: 4px 4px 0 0;
}

.app-memo-user-image {
  background-image: url("https://cdn.tradeareasystems.net/Images/Components/UserBadge/DefaultUser96x96.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 1px solid #2B328C;
}

.app-memo-user-name-container {
  font-weight: 700;
  margin: 6px 4px 0 0;
}

.app-smart-text-box {
  width: 100%;
}
.app-smart-text-box .app-smart-text-box-container {
  width: 100%;
  position: relative;
}
.app-smart-text-box .app-smart-text-box-container .app-smart-text-box-label {
  position: absolute;
  top: -8px;
  left: 10px;
  background: #FFFFFF;
  padding: 0px;
  font-size: 12px;
  color: #565656 !important;
  font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
  z-index: 1;
}
.app-smart-text-box .app-smart-text-box-container:hover .app-smart-text-box-label {
  color: #5AB88D !important;
}
.app-smart-text-box .app-smart-text-box-container .app-smart-input input {
  color: #565656;
  border-color: #565656;
  border-width: 1px;
  background-color: #FFFFFF;
  border-radius: 4px;
  min-height: 34px;
  font-weight: 400;
  font-size: 14px !important;
  font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif !important;
  line-height: 1.35715;
  padding-left: 7px;
}
.app-smart-text-box .app-smart-text-box-container .app-smart-input input:focus {
  outline: none;
}
.app-smart-text-box .app-smart-text-box-container .app-smart-input input:hover {
  border-color: #5AB88D !important;
}
.app-smart-text-box .app-smart-text-box-container .app-smart-input input::-moz-placeholder {
  color: #999;
}
.app-smart-text-box .app-smart-text-box-container .app-smart-input input::placeholder {
  color: #999;
}
.app-smart-text-box .app-smart-text-box-container .app-smart-input div div {
  padding-left: 7px;
  font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 34px;
}
.app-smart-text-box .app-smart-text-box-container .app-smart-input div div strong {
  background-color: #F3F6D5;
}
.app-smart-text-box .app-smart-text-box-container .mention-dropdown-item {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1000; /* Ensure it appears above other elements */
  overflow: visible; /* Allow overflow */
  max-height: 200px; /* Set a max height if needed */
  overflow-y: auto; /* Enable scrolling if the content exceeds max height */
}
.app-smart-text-box .app-smart-text-box-container .mention-dropdown-item--active {
  background-color: #F3F6D5;
}

.app-sign-in-container {
  height: 100%;
  position: relative;
}

.app-sign-in {
  height: 100%;
  position: absolute;
  width: 500px;
  background: #FFF;
  border-right: 1px solid #CCC;
}

.app-sign-in-logo {
  width: 150px;
  margin: 20px;
}

.app-sign-in-form {
  position: absolute;
  top: 30%;
  width: 460px;
  text-align: center;
  padding: 0 20px;
  margin-top: 50%;
}

.app-sign-in-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
}

.app-sign-in-description-container {
  text-align: left;
  font-size: 22px;
  margin-bottom: 20px;
  margin-left: 5px;
}

.app-sign-in-description {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-top: 10px;
}

.app-sign-in-splash {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url("https://cdn.tradeareasystems.net/Images/Rebrand/Login.png");
}

.app-sign-in-input {
  width: 430px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
}

.app-sign-in-button.app-button-large {
  width: 460px;
}
.app-sign-in-button .app-button {
  width: 460px;
}

.app-sign-in-footer {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  width: 460px;
  margin: 20px 20px 10px 20px;
}
.app-sign-in-footer:hover {
  text-decoration: underline;
}

.app-sign-in-footer-left {
  float: left;
}

.app-sign-in-footer-right {
  float: right;
  text-align: right;
}

.app-sign-in-footer-right a {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  margin-left: 10px;
}
.app-sign-in-footer-right a:hover {
  text-decoration: underline;
}

.app-sign-in-footer-version {
  clear: both;
  text-align: right;
  font-size: 10px;
  padding-top: 10px;
}

.app-analytics-projection-wizard.app-panel {
  /*width: 400px;*/
}

.modelWizardStep1InputSourceFilter {
  margin-bottom: 15px;
}

.modelWizardStep2SiteForm {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.modelWizardStep2SiteForm .app-bar-body {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.modelWizardStep2SiteForm .app-form-content {
  padding: 0;
}

.modelWizardHeader {
  border-bottom: 1px solid #E1E1E1;
  padding: 5px;
  text-align: center;
}
.modelWizardHeader .app-button-secondary.app-button-medium {
  width: 150px;
}

.modelWizardStep1 {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.modelWizardStep1 .modelWizardBody {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
  height: 90%;
}
.modelWizardStep1 .modelWizardBody::-webkit-scrollbar {
  width: 0px;
}
.modelWizardStep1 .modelWizardBody::-webkit-scrollbar-track {
  background: transparent;
}
.modelWizardStep1 .modelWizardBody::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.modelWizardStep1 .modelWizardBody::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.modelWizardStep1 .app-bar-container {
  margin-bottom: 0;
}
.modelWizardStep1 .app-bar-body {
  padding: 7px;
}
.modelWizardStep1 .dx-autocomplete.dx-texteditor {
  border: 0;
}

.modelWizardStep1Longitude {
  margin-top: 15px;
}

.modelWizardStep1Input {
  margin: 7px;
}

.modelWizardStep1InputSave {
  margin-top: 7px;
  display: block;
  text-align: right;
}
.modelWizardStep1InputSave .app-button-container {
  margin-left: 5px;
}

.modelWizardStep1Description {
  text-align: left;
}

.modelWizardStep2DescriptionRecap {
  margin: 5px;
}

.modelWizardStep1Mode {
  margin: 7px 0px;
}

.modelWizardStep1Button {
  margin-top: 5px;
  margin-left: 7px;
}
.modelWizardStep1Button .app-button {
  padding: 7px;
}
.modelWizardStep1Button .app-button .app-button-text {
  margin-left: 3px;
}
.modelWizardStep1Button.app-button-primary .app-button {
  padding: 9px;
}

.modelWizardStep1Options {
  font-size: 14px;
}
.modelWizardStep1Options td {
  padding: 5px;
}

.modelWizardStep2Options {
  margin: 7px 7px 0 7px !important;
}
.modelWizardStep2Options .modelWizardStep2OptionsTable {
  margin: 5px;
}
.modelWizardStep2Options .modelWizardStep2OptionsTable td {
  padding: 5px;
}
.modelWizardStep2Options .app-bar-body {
  padding: 7px;
}

.modelWizardStep2Description {
  text-align: left;
  margin: 2px;
}

.modelWizardSummaryTitle-nopadding {
  font-size: 18px;
}

.modelWizardSummaryTitle {
  font-size: 18px;
  padding-top: 15px !important;
}

.app-hot-spot-header {
  text-align: center;
  border-bottom: 1px solid #CCC;
  padding: 10px;
  padding-left: 0;
}
.app-hot-spot-header .app-button-container {
  margin-left: 10px;
}

.app-hot-spot-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 10px;
}

.app-hot-spot-fields {
  height: 100%;
  display: flex;
  flex-flow: column;
  border-radius: 5px;
  margin-top: 5px;
  border: 1px solid #CCC;
  background: #EAEAF3;
  padding: 5px;
}

.app-hot-spot-fields-header {
  text-align: right;
  margin-bottom: 5px;
}

.app-hot-spot-fields-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  background: #F4F5F9;
  border: 1px solid #CCC;
  border-radius: 5px;
}
.app-hot-spot-fields-body .app-hot-spot-fields-row {
  padding: 5px;
  padding-bottom: 7px;
  border-top: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
}
.app-hot-spot-fields-body .app-hot-spot-fields-row:nth-child(2n+1) {
  border: none;
  background-color: #FFFFFF;
}
.app-hot-spot-fields-body .app-hot-spot-fields-row .app-hot-spot-fields-drop-down {
  display: inline-block;
  width: 170px;
  margin-right: 5px;
}
.app-hot-spot-fields-body .app-hot-spot-fields-row .app-hot-spot-fields-weight {
  display: inline-block;
  width: 75px;
}
.app-hot-spot-fields-body .app-hot-spot-fields-row .app-hot-spot-fields-delete {
  color: #565656;
  margin-left: 5px;
  display: inline-block;
}

.app-hot-spot-fields-body-empty {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #666666;
  text-align: center;
  margin: auto;
}

.app-market-opt-wizard-header {
  border-bottom: 1px solid #E1E1E1;
  padding: 7px;
  text-align: center;
}

.app-market-opt-wizard-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
  height: 90%;
}
.app-market-opt-wizard-body::-webkit-scrollbar {
  width: 0px;
}
.app-market-opt-wizard-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-market-opt-wizard-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-market-opt-wizard-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.app-market-opt-wizard-body .app-market-opt-wizard-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.app-market-opt-wizard-body .app-bar-container {
  margin: 7px;
}
.app-market-opt-wizard-body .app-market-opt-inputs {
  margin: 7px;
}
.app-market-opt-wizard-body .app-market-opt-dropdowns {
  margin: 7px 7px 14px 7px;
}
.app-market-opt-wizard-body .app-market-opt-textbox {
  margin: 7px;
  width: 200px;
}
.app-market-opt-wizard-body .app-market-opt-label {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  margin: 18px 0 0 7px;
  flex-grow: 2;
}
.app-market-opt-wizard-body .app-market-opt-distance {
  margin-right: 0;
  width: 150px;
}
.app-market-opt-wizard-body .app-market-opt-distance .dx-numberbox {
  text-align: center;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.app-market-opt-wizard-body .app-market-opt-measurement {
  margin: 7px 7px 7px 0;
  width: 73px;
}
.app-market-opt-wizard-body .app-market-opt-measurement .dx-selectbox {
  border-left: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.app-market-opt-wizard-body .app-button-secondary.app-button-medium {
  width: 144px;
}
.app-market-opt-wizard-body .app-description {
  white-space: pre-wrap;
  text-align: left;
}
.app-market-opt-wizard-body .app-market-opt-error {
  margin: 7px 14px;
}

.app-market-opt-forced-seed-points {
  margin: 7px 7px 14px 7px;
}
.app-market-opt-forced-seed-points .app-market-opt-forced-seed-point-list-container {
  padding-top: 15px;
}
.app-market-opt-forced-seed-points .app-market-opt-forced-seed-point-label {
  padding: 5px;
}
.app-market-opt-forced-seed-points .app-market-opt-forced-seed-point-list {
  border-style: solid;
  border-width: 1px;
  border-color: #E1E1E1;
}
.app-market-opt-forced-seed-points .app-market-opt-forced-seed-point-list-button {
  padding-left: 10px;
  float: right;
}
.app-market-opt-forced-seed-points .app-market-opt-forced-seed-points-buttons-container {
  margin-top: 7px;
  display: block;
  text-align: right;
}
.app-market-opt-forced-seed-points .app-market-opt-forced-seed-points-buttons-container .app-button-container {
  margin-left: 5px;
}
.app-market-opt-forced-seed-points .app-market-opt-forced-seed-points-button {
  margin-top: 5px;
  margin-left: 7px;
}
.app-market-opt-forced-seed-points .app-market-opt-forced-seed-points-button .app-button {
  padding: 7px;
}
.app-market-opt-forced-seed-points .app-market-opt-forced-seed-points-button .app-button .app-button-text {
  margin-left: 3px;
}
.app-market-opt-forced-seed-points .app-market-opt-forced-seed-points-button.app-button-primary .app-button {
  padding: 9px;
}
.app-market-opt-forced-seed-points .app-market-opt-forced-seed-point-address-text {
  display: inline-block;
  max-width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-market-opt-forced-seed-points .dx-autocomplete.dx-texteditor {
  border: 0;
}

.app-market-opt-optional-filters {
  margin: 7px 7px 14px 7px;
}
.app-market-opt-optional-filters .app-market-opt-criteria-filter-text {
  padding: 10px 0px;
}
.app-market-opt-optional-filters .app-market-opt-optional-filters-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-market-opt-optional-filters .right-aligned-button {
  margin-left: auto;
}

.app-analytics-form {
  padding: 10px;
  text-align: right;
}
.app-analytics-form .app-analytics-form-element {
  margin-bottom: 10px;
}

.app-analytics-form-button {
  margin-right: 10px;
}

.app-activity-hub {
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: #FFFFFF;
  border-radius: 8px;
  position: absolute;
  top: 30%;
  left: 30%;
  width: 750px;
  height: 560px;
  z-index: 200;
}

.app-activity-hub-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
  overflow: hidden;
}

.app-activity-hub-body-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
  overflow: hidden;
}

.app-activity-hub-title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  border-bottom: 1px solid #E1E1E1;
  color: #FFFFFF;
  background-color: #2B328C;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
  position: relative;
  padding: 15px;
}

.app-activity-hub-footer-tab {
  width: 30px;
  height: 40px;
  margin: 0 5px;
}
.app-activity-hub-footer-tab .app-button {
  border-radius: 0px;
  color: #565656;
  background-color: transparent;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.app-activity-hub-footer-tab .app-button.app-clickable-disabled {
  background-color: transparent;
}
.app-activity-hub-footer-tab .app-button.app-clickable-hovered {
  box-shadow: none;
  color: #C1D42F;
}
.app-activity-hub-footer-tab .app-button.app-clickable-pressed {
  box-shadow: none;
  color: #E0E997;
}
.app-activity-hub-footer-tab .app-button.app-clickable-active {
  color: #2B328C;
}
.app-activity-hub-footer-tab .app-button.app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-activity-hub-footer-tab .app-button.app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}
.app-activity-hub-footer-tab .app-button .app-button-icon {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.app-activity-hub-footer-tab .app-button-active-element {
  border-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  left: initial;
  bottom: 0;
  width: 30px;
  height: 5px;
}

.app-activity-hub-footer-drown-down {
  margin: 0px 5px;
}
.app-activity-hub-footer-drown-down .app-button {
  border-radius: 0px;
  color: #565656;
  background-color: transparent;
}
.app-activity-hub-footer-drown-down .app-button.app-clickable-disabled {
  background-color: transparent;
}
.app-activity-hub-footer-drown-down .app-button.app-clickable-hovered {
  box-shadow: none;
  color: #C1D42F;
}
.app-activity-hub-footer-drown-down .app-button.app-clickable-pressed {
  box-shadow: none;
  color: #E0E997;
}
.app-activity-hub-footer-drown-down .app-button.app-clickable-active {
  color: #2B328C;
}
.app-activity-hub-footer-drown-down .app-button.app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-activity-hub-footer-drown-down .app-button.app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}
.app-activity-hub-footer-drown-down .app-button .app-button-icon {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.app-activity-hub-actions {
  border-top: 1px solid #E1E1E1;
  background-color: #F4F5F9;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding-top: 5px;
}

.app-activity-hub-close {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-right: 11px;
  margin-top: 11px;
}

.app-hdr {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: #FFFFFF;
  background-color: #2B328C;
  position: relative;
  height: 55px;
  line-height: 55px;
  padding: 0 20px;
  z-index: 100;
}
.app-hdr .app-button-container {
  margin: 0 10px;
}
.app-hdr .dx-placeholder {
  line-height: 1;
}

.app-header-search-text {
  display: inline-block;
  width: 310px;
  overflow: hidden;
}

.app-header-search-subtext {
  color: #565656;
  font-weight: bold;
}

.app-header-search-default {
  float: right;
}

.app-hdr-table {
  width: 100%;
}

.app-hdr-search-input {
  width: 100%;
  display: inline-block;
}
.app-hdr-search-input .dx-textbox {
  border-radius: 0;
}

.app-hdr-search-container {
  display: block;
  overflow: hidden;
  padding-right: 80px;
}

.app-hdr-search-container-td {
  position: relative;
}

.app-hdr-search-filter {
  position: absolute;
  left: -35px;
  top: 14px;
  margin: 0 !important;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.app-hdr-search-filter .app-button {
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 35px !important;
  height: 28px !important;
  line-height: 28px !important;
}

.app-hdr-search-button {
  position: absolute;
  right: 1px;
  top: 14px;
  margin: 0 !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.app-hdr-search-button .app-button {
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 35px !important;
  height: 28px !important;
  line-height: 28px !important;
}

.app-hdr-tab-icon {
  margin-left: 5px;
}

.app-hdr-account-image {
  border-radius: 100px;
  margin-top: -2px;
  width: 30px;
  height: 30px;
}

.app-viewer .app-hdr-search-input {
  width: 100%;
}
.app-viewer .app-hdr-search-input .dx-textbox {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.app-viewer .app-hdr-search-container {
  padding-right: 30px !important;
}
.app-viewer .app-hdr-search-button {
  right: -49px;
}

.app-map-tab-bar {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  height: 35px;
  padding-left: 80px;
  line-height: 35px;
}

.app-map-tab-bar-collapsed {
  padding-left: 0px;
}

.app-map-tab-bar-active {
  padding-left: 400px;
}

.app-map-tab-bar-tab {
  background-color: #F3F6D5;
  width: 200px;
  line-height: 35px;
}

.app-map-control-street-view-close {
  position: absolute;
  top: 10px;
  right: 60px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  z-index: 103;
}

.app-map-control-background {
  position: absolute;
  z-index: 101;
  top: 15px;
  left: 95px;
}
.app-map-control-background .app-map-control-background-top {
  border: 1px solid #E1E1E1;
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
  width: 610px;
  height: 43px;
  background: #EAEAF3;
  border-radius: 5px;
  border-bottom-left-radius: 0;
}
.app-map-control-background.app-map-control-background-rotate .app-map-control-background-top {
  width: 671px;
}
.app-map-control-background .app-map-control-background-bottom {
  border: 1px solid #E1E1E1;
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
  border-top: none;
  position: absolute;
  top: 44px;
  width: 43px;
  height: 105px;
  background: #EAEAF3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.app-map-control-background .app-map-control-current-map {
  border: 1px solid #E1E1E1;
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
  background: #EAEAF3;
  position: absolute;
  padding: 7px;
  left: 60px;
  top: 55px;
  width: 535px;
  border-radius: 5px;
}
.app-map-control-background .app-map-control-current-map-default {
  margin-right: 10px;
}
.app-map-control-background .app-map-control-current-map-actions {
  position: absolute;
  right: 5px;
  top: 8px;
}
.app-map-control-background .app-map-control-current-map-actions .app-map-control-current-map-actions-share,
.app-map-control-background .app-map-control-current-map-actions .app-map-control-current-map-actions-publish,
.app-map-control-background .app-map-control-current-map-actions .app-map-control-current-map-actions-search,
.app-map-control-background .app-map-control-current-map-actions .app-map-control-current-map-actions-close {
  display: inline-block;
}
.app-map-control-background .app-map-control-current-map-actions .app-map-control-current-map-actions-share,
.app-map-control-background .app-map-control-current-map-actions .app-map-control-current-map-actions-publish,
.app-map-control-background .app-map-control-current-map-actions .app-map-control-current-map-actions-search {
  margin-right: 5px;
}

.app-map-control-left {
  position: absolute;
  top: 20px;
  left: 100px;
  z-index: 101;
}
.app-map-control-left .app-map-control-vertical {
  top: 40px !important;
}
.app-map-control-left .app-map-control-actions {
  position: absolute;
  left: 102px;
  top: 0;
  width: 570px;
}
.app-map-control-left .app-map-control-actions.no-rotate {
  left: 40px;
}

.app-map-control-left-collapsed {
  left: 20px;
}
.app-map-control-left-collapsed.app-map-control-background {
  left: 15px;
}

.app-map-control-left-active {
  left: 425px;
}
.app-map-control-left-active.app-map-control-background {
  left: 420px;
}

.app-map-control-right {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 101;
}

.app-map-control-button {
  margin-left: 7px;
}

.app-map-control-vertical {
  position: absolute;
  top: 20px;
  z-index: 101;
}
.app-map-control-vertical div {
  display: block;
}
.app-map-control-vertical .app-map-control-button {
  margin: 7px 0 0 0;
}
.app-map-control-vertical .app-map-control-button-zoom-in .app-button {
  border-bottom: none;
}
.app-map-control-vertical .app-map-control-button-zoom .app-button {
  border-top: none;
  border-bottom: none;
}
.app-map-control-vertical .app-map-control-button-zoom-out .app-button {
  border-top: none;
}

.app-map-control-location {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  border-radius: 4px;
  padding: 7px;
  bottom: 20px;
  right: 20px;
  z-index: 101;
  text-align: center;
  background-color: #FFFFFF;
  color: #565656;
}
.app-map-control-location .app-map-control-location-text {
  display: inline-block;
  width: 170px;
}
.app-map-control-location .app-map-control-location-icon {
  color: #565656;
  font-size: 16px;
}

.app-map-control-current-map-share-popover .dx-popup-content {
  overflow: hidden;
}

.app-viewer .app-map-control-current-map {
  width: 437px;
}
.app-viewer .app-map-control-background-top {
  width: 510px;
}
.app-viewer .app-map-control-background-rotate .app-map-control-background-top {
  width: 570px;
}
.app-viewer .app-map-control-background-rotate .app-map-control-current-map {
  width: 495px;
}
.app-viewer .app-map-control-background-fixed .app-map-control-background-top {
  width: 470px;
  border-radius: 5px;
}
.app-viewer .app-map-control-background-fixed .app-map-control-current-map {
  left: 10px;
}
.app-viewer .app-map-control-background-fixed .app-map-control-actions {
  left: 0px;
}
.app-viewer .app-map-control-background-fixed.app-map-control-background-rotate .app-map-control-background-top {
  width: 470px;
}
.app-viewer .app-map-control-background-fixed.app-map-control-background-rotate .app-map-control-current-map {
  width: 437px;
}

.app-map-locator {
  animation: zoom-in-zoom-out 1s ease infinite;
  margin: 24px;
  width: 50px;
  height: 50px;
  border: 3px solid #C1D42F;
  background-color: rgba(193, 212, 47, 0.3);
  border-radius: 100px;
  position: absolute;
}

.app-map-tooltip {
  position: absolute;
  border-radius: 4px;
  z-index: 10000;
}

.app-map-tooltip-title {
  border: 1px solid #565656;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  color: #FFFFFF;
  font-size: 18px;
  background-color: #5AB88D;
  padding: 5px;
  padding-left: 10px;
  height: 26px;
  line-height: 26px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
}

.app-map-tooltip-section {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #565656;
  position: relative;
  padding: 10px;
  color: #FFFFFF;
  background-color: #6DC09A;
  border-radius: 3px;
  border-top: none;
  margin-top: 5px;
}

.app-map-tooltip-first {
  margin-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.app-map-tooltip-close {
  float: right;
  margin-left: 10px;
}

.compInsightVeryLow .app-map-tooltip-title,
.compInsightVeryLow .app-map-tooltip-section {
  border: 1px solid rgb(232, 167, 141);
  background: rgb(252, 187, 161);
  font-weight: bold;
}

.compInsightLow .app-map-tooltip-title,
.compInsightLow .app-map-tooltip-section {
  border: 1px solid rgb(231, 86, 54);
  background: rgb(251, 106, 74);
  font-weight: bold;
}

.compInsightMedium .app-map-tooltip-title,
.compInsightMedium .app-map-tooltip-section {
  border: 1px solid rgb(219, 39, 24);
  background: rgb(239, 59, 44);
  font-weight: bold;
}

.compInsightHigh .app-map-tooltip-title,
.compInsightHigh .app-map-tooltip-section {
  border: 1px solid rgb(183, 4, 9);
  background: rgb(203, 24, 29);
  font-weight: bold;
}

.compInsightVeryHigh .app-map-tooltip-title,
.compInsightVeryHigh .app-map-tooltip-section {
  border: 1px solid rgb(83, 0, 3);
  background: rgb(103, 20, 23);
  font-weight: bold;
}

.app-circle-size-tooltip td {
  padding: 0px 5px;
}

.app-map-entity-tooltip {
  border: 1px solid #E1E1E1;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  border-radius: 4px;
  z-index: 10000;
  min-width: 225px;
  min-height: 125px;
  background-color: #FFFFFF;
}

.app-map-entity-tooltip-title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  border-bottom: 1px solid #E1E1E1;
  color: #000000;
  background-color: #FFFFFF;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
  position: relative;
  padding: 10px;
}

.app-map-entity-tooltip-section {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  padding: 7px;
  min-height: 47px;
}
.app-map-entity-tooltip-section .app-map-entity-tooltip-section-new-line {
  margin-top: 5px;
}

.app-map-entity-tooltip-arrow-bottom {
  border: 1px solid #E1E1E1;
  border-top: none;
  border-right: none;
  width: 15px;
  height: 15px;
  bottom: -9px;
  left: 102px;
  position: absolute;
  background-color: #FFFFFF;
  transform: rotate(-45deg);
}

.app-map-entity-tooltip-arrow-top {
  border: 1px solid #E1E1E1;
  border-bottom: none;
  border-left: none;
  width: 15px;
  height: 15px;
  top: -9px;
  left: 102px;
  position: absolute;
  background-color: #FFFFFF;
  transform: rotate(-45deg);
}

.app-map-mode-banner {
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 1000;
}

.app-map-mode-banner-content {
  background: #5AB88D;
  color: #FFFFFF;
  width: 60%;
  margin: 10px auto;
  border-radius: 5px;
  border: 1px solid #666666;
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
}

.app-map-mode-banner-title {
  padding: 10px;
  border-bottom: 1px solid #666666;
  position: relative;
  height: 26px;
  line-height: 26px;
}

.app-map-mode-banner-body {
  padding: 10px;
  border-bottom: 1px solid #666666;
  background: #6DC09A;
}

.app-map-map-control-search-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.app-map-map-control-search-button-container {
  align-self: flex-end;
  margin-bottom: 10px;
}

.app-map-map-control-search-datalist-container {
  width: 100%;
}

.app-map-map-control-search-text {
  padding-left: 15px;
  width: 140px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-map-map-control-search-text-description {
  width: 210px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #898989;
}

.app-map-map-control-search-text-date {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  color: #898989;
  padding-left: 20px;
}

.app-map-map-control-search-icon {
  height: 20px;
  width: 20px;
  color: #565656;
}

.app-map-map-control-search-folder-text {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-map-map-control-search-folder-shared-text {
  font-size: 10px;
  color: #898989;
  padding-left: 10px;
}

.app-map-map-control-search-recent-container {
  padding-bottom: 30px;
}

.app-map-map-control-search-recent-text {
  font-weight: 700;
  padding-bottom: 10px;
}

.app-map-map-control-search-loader-container {
  height: 500px;
  top: 60px;
  position: absolute;
  background-color: #FFFFFF !important;
  border-radius: 8px;
}

.app-map-entity-label-editor {
  border: 1px solid #E1E1E1;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  border-radius: 4px;
  z-index: 1000;
  min-width: 462px;
  min-height: 162px;
  background-color: #FFFFFF;
  display: flex;
  flex-flow: column;
}

.app-map-entity-label-editor-style {
  padding: 10px 10px 0 10px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.app-map-entity-label-editor-font-size {
  margin-top: 2px;
}

.app-map-entity-label-editor-text {
  padding: 10px;
  display: flex;
  flex-flow: row;
}
.app-map-entity-label-editor-text .app-map-entity-label-editor-input {
  margin: auto;
  width: 100%;
}

.app-map-entity-label-editor-actions {
  border-bottom: 1px solid #E1E1E1;
  padding: 10px 10px 10px 5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
}

.app-map-entity-label-editor-grouped-inputs {
  flex-grow: 2;
  margin-top: 3px;
}

.app-map-entity-label-editor-spacer {
  margin-left: 10px;
}

.app-map-entity-label-editor-colorbox {
  margin-left: 10px;
  width: 70px;
}

.dx-popover-arrow {
  display: none;
}

.app-map-entity-label-editor-action-button {
  width: 30px;
  height: 32px;
  margin: 0 5px;
}
.app-map-entity-label-editor-action-button .app-button {
  color: #565656;
  background-color: transparent;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.app-map-entity-label-editor-action-button .app-button.app-clickable-disabled {
  background-color: transparent;
}
.app-map-entity-label-editor-action-button .app-button.app-clickable-hovered {
  box-shadow: none;
  color: #C1D42F;
}
.app-map-entity-label-editor-action-button .app-button.app-clickable-pressed {
  box-shadow: none;
  color: #E0E997;
}
.app-map-entity-label-editor-action-button .app-button.app-clickable-active {
  color: #2B328C;
}
.app-map-entity-label-editor-action-button .app-button.app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-map-entity-label-editor-action-button .app-button.app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}
.app-map-entity-label-editor-action-button .app-button .app-button-icon {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.app-map-entity-label-editor-action-button .app-button-active-element {
  border-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  left: initial;
  bottom: 0;
  width: 30px;
  height: 5px;
}

.app-map-entity-label-editor-arrow-bottom {
  border: 1px solid #E1E1E1;
  border-top: none;
  border-right: none;
  width: 15px;
  height: 15px;
  bottom: -9px;
  left: 220px;
  position: absolute;
  background-color: #FFFFFF;
  transform: rotate(-45deg);
}

.app-map-entity-label-editor-arrow-top {
  border: 1px solid #E1E1E1;
  border-bottom: none;
  border-left: none;
  width: 15px;
  height: 15px;
  top: -9px;
  left: 220px;
  position: absolute;
  background-color: #FFFFFF;
  transform: rotate(-45deg);
}

.app-map-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
}

.app-map-logo-container {
  float: right;
}
.app-map-logo-container .app-map-logo {
  position: absolute;
  border: none;
  top: 10px;
  right: 30px;
  z-index: 1;
}

.app-map-triview-row {
  display: flex;
  flex-flow: row;
  height: 100%;
  width: 100%;
}

.app-map-triview-column {
  height: 100%;
  width: 100%;
}

.app-map {
  height: 100%;
  width: 100%;
  cursor: grab;
}

.app-map-triview {
  height: 50%;
  width: 100%;
  cursor: grab;
}

.app-street-map {
  height: 50%;
  width: 100%;
  cursor: grab;
}

.app-aerial-map {
  height: 100%;
  width: 100%;
  cursor: grab;
}

.app-map-horizonatal-divider {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  width: 100%;
  height: 5px;
}

.app-map-vertical-divider {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  width: 8px;
  height: 100%;
}

.app-nav {
  overflow-y: auto;
  padding-bottom: 75px !important;
  background-color: #DFE0EE;
  padding-bottom: 15px;
  margin-bottom: 80px;
}
.app-nav::-webkit-scrollbar {
  width: 13px;
}
.app-nav::-webkit-scrollbar-track {
  background: #F9F9FC;
}
.app-nav::-webkit-scrollbar-thumb {
  background: #CACCE2;
  cursor: pointer;
}
.app-nav::-webkit-scrollbar-thumb:hover {
  background: #CACCE2;
}

.app-nav-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: #DFE0EE;
  position: absolute;
  z-index: 102;
  width: 85px;
}

.app-nav-divider {
  border-top: 1px solid #E1E1E1;
  margin: 20px 5px 0px 5px;
}

.app-nav-collapser {
  position: absolute;
  bottom: 0px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  margin: 20px 16px;
  z-index: 103;
}

.app-nav-section, .app-driving-direction-nav-section, .app-analytics-nav-section {
  position: absolute;
  left: 85px;
}
.app-nav-section .app-bar-container, .app-driving-direction-nav-section .app-bar-container, .app-analytics-nav-section .app-bar-container {
  margin: 7px 7px 7px 7px;
}

.app-driving-direction-nav-section {
  width: 330px;
  left: 0 !important;
}
.app-driving-direction-nav-section .app-bar-container {
  margin: 0px;
}

.app-navigation-button {
  display: block;
  margin-top: 15px;
}

.app-navigation-button-badge {
  top: -10px;
  position: absolute;
  font-size: 16px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 100px;
  color: #FFFFFF;
}

.app-navigation-button-badge-processing {
  border: 1px solid #2B328C;
  left: -7px;
  background: #2B328C;
}

.app-navigation-button-badge-complete {
  border: 1px solid #5AB88D;
  right: -7px;
  background: #5AB88D;
}

.app-legend-menu-actions {
  border-bottom: 1px solid #E1E1E1;
  height: 55px;
  line-height: 55px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: center;
}

.app-legend-menu-actions-button {
  margin: 0 10px;
}

.app-legend-menu-footer {
  border-top: 1px solid #E1E1E1;
  height: 75px;
}

.app-legend-menu-selected {
  border-top: 1px solid #E1E1E1;
  position: absolute;
  bottom: 75px;
  width: 280px;
  max-height: 200px;
  padding: 10px;
  border-top-style: solid;
  border-top-width: 1px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.app-legend-menu-item {
  margin: 7px;
}
.app-legend-menu-item .app-bar {
  background-color: #F4F5F9;
}
.app-legend-menu-item .app-bar.app-clickable-hovered {
  background-color: #E0E997;
}
.app-legend-menu-item .app-bar-body {
  border: none;
  border-top: 1px solid #E1E1E1;
}
.app-legend-menu-item .app-clickable-active {
  background-color: #F3F6D5;
}

.app-legend-menu-body {
  padding: 7px;
}
.app-legend-menu-body .app-bar-container {
  margin-bottom: 7px;
}

.app-legend-layer-table {
  height: 100%;
  width: 100%;
  text-align: center;
}
.app-legend-layer-table .app-button {
  color: #565656;
}
.app-legend-layer-table .app-legend-layer-competitive-insights-grade-A .app-button {
  color: rgb(26, 150, 65) !important;
}
.app-legend-layer-table .app-legend-layer-competitive-insights-grade-B .app-button {
  color: rgb(166, 217, 106) !important;
}
.app-legend-layer-table .app-legend-layer-competitive-insights-grade-C .app-button {
  color: rgb(249, 212, 45) !important;
}
.app-legend-layer-table .app-legend-layer-competitive-insights-grade-D .app-button {
  color: rgb(242, 109, 32) !important;
}
.app-legend-layer-table .app-legend-layer-competitive-insights-grade-F .app-button {
  color: rgb(202, 20, 32) !important;
}

.app-legend-layer-body {
  padding-left: 15px;
}

.app-legend-layer-body-toolbar {
  background-color: #F4F5F9;
  padding: 5px 0;
}

.app-legend-layer .app-bar {
  background-color: #F4F5F9;
  font-size: 14px;
}
.app-legend-layer .app-bar.app-clickable-hovered {
  background-color: #E0E997;
}
.app-legend-layer .app-bar.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-legend-layer-zoom .app-bar {
  color: #EC5D20;
  background-color: #FDEFE8;
  border-color: #F5AE8F;
}
.app-legend-layer-zoom .app-bar.app-clickable-hovered {
  background-color: #FBDFD3;
}
.app-legend-layer-zoom .app-bar-body {
  border-top-color: #F5AE8F;
}
.app-legend-layer-zoom .app-bar-body .app-bar {
  color: #898989;
}

.app-legend-layer-empty .app-bar {
  color: #898989;
  border-color: #E1E1E1;
}
.app-legend-layer-empty .app-bar.app-clickable-hovered {
  background-color: #E0E997;
}
.app-legend-layer-empty .app-bar.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}
.app-legend-layer-empty .app-bar-body {
  border-top-color: #898989;
}
.app-legend-layer-empty .app-bar-body .app-bar {
  color: #898989;
}

.app-legend-layer-chain {
  border-top: 1px solid #E1E1E1;
}
.app-legend-layer-chain:first-child {
  border-top: none;
}
.app-legend-layer-chain .app-bar {
  border: none;
  font-size: 13px;
  color: #565656;
  background-color: #FFFFFF;
  border-radius: 0;
  padding: 7px 10px;
}
.app-legend-layer-chain .app-bar .app-bar-icon-container {
  padding-left: 10px;
}
.app-legend-layer-chain .app-bar.app-clickable-hovered {
  box-shadow: none;
  background-color: #E0E997;
}
.app-legend-layer-chain .app-bar.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}
.app-legend-layer-chain .app-bar-body {
  border: none;
  border-top: 1px solid #E1E1E1;
}
.app-legend-layer-chain.app-bar-container {
  margin: 0px;
}
.app-legend-layer-chain.app-bar-container:last-child .app-bar {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.app-legend-layer-chain-icon {
  color: #888;
}

.app-legend-layer-point.app-bar-container {
  margin: 7px;
}
.app-legend-layer-point.app-bar-container .app-bar-text {
  padding-left: 7px;
}
.app-legend-layer-point .app-bar {
  border: 1px solid #E1E1E1;
  background-color: #F9F9FC;
  border-radius: 4px;
  font-size: 11px;
  padding: 0px 10px;
}

.app-legend-layer-action-half-active .app-button.app-clickable-active {
  color: #A6D5BF !important;
}

.app-legend-layer-toolbar-action {
  color: #565656;
  margin: 0 7px 5px 7px;
}
.app-legend-layer-toolbar-action .app-button-icon {
  font-size: 16px !important;
}
.app-legend-layer-toolbar-action .app-button-text {
  margin-left: 2px !important;
  font-size: 16px !important;
}

.app-legend-layer-toolbar-input {
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  margin: 5px 7px 5px 7px;
  width: 80px;
}

.app-legend-layer-draggable .app-bar {
  padding-left: 0;
}

.app-legend-layer-dnd-icon-container {
  text-align: center;
  width: 20px;
  font-size: 20px;
}

.app-legend-layer-dnd-button {
  width: 20px;
  height: 25px;
  border: none;
  padding: 0;
  background: transparent;
  cursor: grab;
}

.app-legend-layer-edit-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.app-legend-layer-edit-container .app-legend-layer-edit-row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.app-legend-layer-edit-container .app-legend-layer-edit-row .app-legend-layer-edit-input {
  margin: auto;
  width: 100%;
  padding-right: 5px;
}

.app-nav-section-layers .app-panel-body {
  overflow: visible;
}

.app-legend-actions {
  border-bottom: 1px solid #E1E1E1;
  background-color: #F9F9FC;
  color: #565656;
  padding: 8px;
}
.app-legend-actions .app-legend-menu-button {
  margin-right: 7px;
  margin-bottom: 5px;
}

.app-layers-favorite-add {
  margin: 7px;
}
.app-layers-favorite-add .app-button {
  height: 30px;
  line-height: 28px;
  width: 30px;
  border-radius: 3px;
}

.app-layers-favorite-add-text {
  display: inline-block;
  width: 270px;
}

.app-layers-favorite-star {
  color: #F59C28;
}

.app-legend-filters {
  border: 1px solid #898989;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: #565656;
  padding: 5px 15px;
}
.app-legend-filters .app-button-container {
  margin: 0 7px;
}
.app-legend-filters .app-button-container .app-button .app-button-icon {
  font-size: 18px;
}
.app-legend-filters .app-button-split-container {
  margin: 0 7px;
}
.app-legend-filters .app-button-split-container .app-button-container {
  margin: 0;
}
.app-legend-filters .app-button-split-container .app-button-container .app-button .app-button-icon {
  font-size: 18px;
}

.app-legend-actions-button {
  margin: 0 5px;
}

.app-legend-layers-scroll {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-legend-layers-scroll::-webkit-scrollbar {
  width: 13px;
}
.app-legend-layers-scroll::-webkit-scrollbar-track {
  background: #F4F5F9;
}
.app-legend-layers-scroll::-webkit-scrollbar-thumb {
  background: #EAEAF3;
  cursor: pointer;
}
.app-legend-layers-scroll::-webkit-scrollbar-thumb:hover {
  background: #DFE0EE;
}

.app-legend-filtered .app-bar {
  background-color: #F4F5F9;
  border-style: dashed;
  border-width: 1px;
  font-size: 12px;
  color: #898989;
}
.app-legend-filtered .app-bar.app-clickable-hovered {
  background-color: #E0E997;
}
.app-legend-filtered .app-bar.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-quick-reports {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
  padding: 15px;
  position: relative;
}
.app-quick-reports::-webkit-scrollbar {
  width: 13px;
}
.app-quick-reports::-webkit-scrollbar-track {
  background: #F4F5F9;
}
.app-quick-reports::-webkit-scrollbar-thumb {
  background: #EAEAF3;
  cursor: pointer;
}
.app-quick-reports::-webkit-scrollbar-thumb:hover {
  background: #DFE0EE;
}

.app-quick-reports-description {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-top: 15px;
}

.app-quick-reports-source {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  margin: 15px 0px;
}

.app-quick-reports-table {
  width: 100%;
  margin-top: 15px;
}

.app-demographic-report-container {
  padding: 10px;
}

.app-demographic-report-trade-area {
  display: inline-block;
  width: 255px;
  margin-left: 80px;
}

.app-demographic-report-title {
  display: inline-block;
  margin-left: 10px;
  width: 255px;
}

.app-demographic-report-output {
  display: inline-block;
  width: 150px;
  margin-left: 10px;
}

.app-small-demographic-report-container {
  padding: 10px;
}

.app-small-demographic-report-trade-area {
  display: inline-block;
  width: 300px;
  margin-left: 80px;
}

.app-small-demographic-report-title {
  margin-left: 10px;
  width: 300px;
}

.app-small-demographic-report-output {
  display: inline-block;
  width: 150px;
  margin-left: 10px;
}

.app-small-demographic-report-save {
  margin-right: 10px;
  margin-top: 5px;
}

.app-small-demographic-report-header {
  display: flex;
  justify-content: space-between;
}

.app-small-demographic-report-scrolling-container {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  overflow-y: scroll;
  height: 100%;
}
.app-small-demographic-report-scrolling-container::-webkit-scrollbar {
  width: 13px;
}
.app-small-demographic-report-scrolling-container::-webkit-scrollbar-track {
  background: #F4F5F9;
}
.app-small-demographic-report-scrolling-container::-webkit-scrollbar-thumb {
  background: #EAEAF3;
  cursor: pointer;
}
.app-small-demographic-report-scrolling-container::-webkit-scrollbar-thumb:hover {
  background: #DFE0EE;
}

.app-small-demographic-report-scrolling-container * {
  overflow-y: visible;
}

.app-management-report-step-1-container {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  flex-flow: column;
  width: 850px;
  height: calc(100% - 100px);
}
.app-management-report-step-1-container::-webkit-scrollbar {
  width: 0px;
}
.app-management-report-step-1-container::-webkit-scrollbar-track {
  background: transparent;
}
.app-management-report-step-1-container::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-management-report-step-1-container::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-management-report-rows {
  display: flex;
  flex-flow: row;
}

.app-management-report-labels {
  margin: 17px 17px 0 17px;
}

.app-management-report-available-reports {
  margin: 17px 17px 0 17px;
}

.app-management-report-empty-panel {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.app-chat-bot-container {
  position: absolute;
  bottom: 125px;
  right: 30px;
  z-index: 1;
  text-align: right;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.app-chat-bot-button {
  position: absolute;
  bottom: 65px;
  right: 25px;
  z-index: 100;
}

.app-chat-bot {
  background: rgba(255, 255, 255, 0);
}
.app-chat-bot .dx-popup-title {
  text-align: left;
}
.app-chat-bot .dx-popup-normal {
  opacity: auto !important;
  background-color: rgba(255, 255, 255, 0);
}
.app-chat-bot .app-chat-bot-content {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
  padding: 10px;
  background: rgba(255, 255, 255, 0.9);
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body::-webkit-scrollbar {
  width: 13px;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body::-webkit-scrollbar-track {
  background: #F4F5F9;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body::-webkit-scrollbar-thumb {
  background: #EAEAF3;
  cursor: pointer;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body::-webkit-scrollbar-thumb:hover {
  background: #DFE0EE;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body .app-chat-bot-loader-dot {
  background-color: #FFFFFF;
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 0 3px;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body .app-chat-bot-message {
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #CCC;
  padding: 5px;
  margin-bottom: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body .app-chat-bot-message .app-chat-bot-message-line {
  margin: 5px 0;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body .app-chat-bot-message .app-chat-bot-message-title {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin: 7px 0;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body .app-chat-bot-message .app-chat-bot-message-sub-title {
  display: inline-block;
  margin: 4px 0;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body .app-chat-bot-message.app-chat-bot-message-user {
  text-align: right;
  float: right;
  background-color: #F9F9FC;
  color: #000000;
  margin-left: 50px;
  float: right;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-body .app-chat-bot-message.app-chat-bot-message-bot {
  text-align: left;
  background-color: #2B328C;
  color: #FFFFFF;
  margin-right: 50px;
  float: left;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-message-input {
  padding: 5px;
  border-top: 1px solid #CCC;
  background-color: #F9F9FC;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-message-input .app-chat-bot-send-button {
  float: right;
  margin-top: 5px;
  color: #2B328C;
}
.app-chat-bot .app-chat-bot-content .app-chat-bot-message-input .app-chat-bot-reset-button {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}

.app-task-detail {
  padding: 5px;
  display: flex;
  flex-flow: column;
}
.app-task-detail .app-task-detail-header {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
}
.app-task-detail .app-task-detail-header .app-task-detail-header-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
}
.app-task-detail .app-task-detail-header .app-task-detail-header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-task-detail .app-task-detail-header .app-task-detail-header-actions .app-button-container:last-child {
  margin-left: 5px;
}
.app-task-detail .app-task-detail-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.app-task-detail .app-task-detail-content .app-task-detail-content-row {
  display: flex;
  flex: 1;
  width: 100%;
}
.app-task-detail .app-task-detail-content .app-task-detail-content-row-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
}
.app-task-detail .app-task-detail-label {
  display: inline-flex;
  padding-right: 10px;
}
.app-task-detail .app-task-detail-created-by {
  display: inline-flex;
  padding-left: 5px;
  max-width: 145px;
  white-space: nowrap;
  overflow: hidden;
}

.app-task-list-header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  padding: 7px 0px;
}
.app-task-list-header .app-task-list-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-task-list-header .app-task-list-button-container * {
  padding-left: 5px;
}
.app-task-list-header .app-task-list-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-task-list-header .app-task-list-filters .app-radio-button-set {
  padding-right: 10px;
}
.app-task-list-header .app-task-list-filters .app-button {
  width: auto;
  padding: 5px;
}

.app-task-list {
  overflow: visible;
}
.app-task-list .dx-datagrid {
  overflow: visible;
}
.app-task-list .dx-datagrid .dx-row-focused:hover > td,
.app-task-list .dx-datagrid .dx-row:hover > td,
.app-task-list .dx-datagrid .dx-row-alt:hover > td {
  background-color: #E0E997 !important;
}
.app-task-list .dx-datagrid .dx-row-focused > td:not(.dx-focused) {
  background-color: #F3F6D5;
  border-bottom: 1px solid #ddd;
}
.app-task-list .dx-datagrid .dx-datagrid-filter-row:hover > td {
  background-color: inherit !important;
}
.app-task-list .dx-datagrid .app-data-grid-column {
  overflow: visible;
}
.app-task-list .dx-datagrid .app-data-grid-column .app-task-list-action-container {
  display: flex;
  overflow: visible;
}
.app-task-list .dx-datagrid .app-data-grid-column .app-task-list-action-container .app-task-list-action {
  padding-right: 5px;
}
.app-task-list .dx-datagrid .word-wrap {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.app-task-share-container {
  flex-flow: column;
  height: calc(100% - 50px);
}
.app-task-share-container .app-task-share-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.app-task-share-container .app-task-share-header .app-task-share-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
}
.app-task-share-container .app-task-share-header .app-task-share-buttons * {
  padding-left: 5px;
}
.app-task-share-container .app-task-share-body {
  height: calc(100% - 60px);
}

.app-tasks {
  display: flex;
  border: 5px solid #2B328C;
  height: calc(100% - 10px);
}
.app-tasks .app-task-list-section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  padding: 5px;
  border-right: 5px solid #2B328C;
  max-height: 100%;
}
.app-tasks .app-datagrid-container {
  height: 90%;
}
.app-tasks .app-task-support-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.app-tasks .app-task-support-section .app-task-section-empty-header {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  padding-bottom: 5px;
}
.app-tasks .app-task-support-section .app-task-detail-section {
  padding: 10px;
  border-bottom: 5px solid #2B328C;
  height: 500px;
}
.app-tasks .app-task-support-section .app-task-detail-section .app-task-section-empty-body {
  height: calc(100% - 45px);
}
.app-tasks .app-task-support-section .app-task-detail-section .app-task-detail {
  padding: 0px;
}
.app-tasks .app-task-support-section .app-task-detail-section .app-task-detail .app-task-detail-header {
  padding-left: 0px;
}
.app-tasks .app-task-support-section .app-task-action-section {
  padding: 10px;
  position: relative;
  height: calc(95% - 535px);
}
.app-tasks .app-task-support-section .app-task-action-section .app-task-section-empty-body {
  height: calc(100% - 45px);
}
.app-tasks .app-task-support-section .app-task-action-section .app-task-history-header {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  padding-bottom: 5px;
}
.app-tasks .app-task-support-section .app-task-action-section .app-task-history-header .app-task-history-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
}
.app-tasks .app-task-support-section .app-task-action-section .app-task-history-header .app-task-detail-header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-tasks .app-task-support-section .app-task-action-section .app-task-history-header .app-task-detail-header-actions .app-button-container:last-child {
  margin-left: 5px;
}
.app-tasks .app-task-support-section .app-task-action-section .app-task-history-container {
  height: calc(100% - 85px);
  overflow: auto;
}
.app-tasks .app-task-support-section .app-task-action-section .app-task-history-container .app-memo-message {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.25px;
}
.app-tasks .app-task-support-section .app-task-action-section .app-task-history-container .app-memo-date,
.app-tasks .app-task-support-section .app-task-action-section .app-task-history-container .app-memo-user {
  font-size: 14px;
}
.app-tasks .app-task-support-section .app-task-action-section .dx-list-item.dx-state-hover {
  background-color: #E0E997;
}
.app-tasks .app-task-support-section .app-task-action-section .dx-list-item.dx-state-focused,
.app-tasks .app-task-support-section .app-task-action-section .dx-list-item.dx-list-item-selected {
  background-color: #F3F6D5 !important;
  color: #333;
}

.app-tasks-user-image {
  border-radius: 100px;
  margin-top: -2px;
  width: 30px;
  height: 30px;
}

.app-user-badge {
  border: 1px solid #898989;
  background-color: #FFFFFF;
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 8px;
  z-index: 102;
  top: 50px;
  right: 65px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
}

.app-user-badge-title {
  border-bottom: 1px solid #898989;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  background-color: #EAEAF3;
  color: #2B328C;
  height: 55px;
  line-height: 55px;
  padding-left: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: left;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.app-user-badge-body {
  padding: 15px;
}

.app-user-badge-userName {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #2B328C;
}

.app-user-badge-accountName {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  margin-Top: 10px;
}

.app-user-badge-userName,
.app-user-badge-accountName {
  margin-Top: 10px;
}

.app-user-badge-footer {
  margin-top: 20px;
}

.app-user-badge-footer .app-button {
  margin: 0 5px;
}

.app-user-badge-userImage {
  background-image: url("https://cdn.tradeareasystems.net/Images/Components/UserBadge/DefaultUser96x96.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 30px;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: 1px solid #2B328C;
}

.app-user-preferences {
  display: flex;
  flex-flow: column;
  background-color: #FFFFFF;
  border-radius: 8px;
  position: absolute;
  top: 50px;
  right: 25px;
  width: 665px;
  z-index: 200;
}
.app-user-preferences .app-description {
  padding: 10px;
  margin-top: 20px;
  text-align: left;
}

.app-user-preferences-only-password {
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-user-preferences-only-password::-webkit-scrollbar {
  width: 13px;
}
.app-user-preferences-only-password::-webkit-scrollbar-track {
  background: #F4F5F9;
}
.app-user-preferences-only-password::-webkit-scrollbar-thumb {
  background: #EAEAF3;
  cursor: pointer;
}
.app-user-preferences-only-password::-webkit-scrollbar-thumb:hover {
  background: #DFE0EE;
}
.app-user-preferences-only-password .app-description {
  margin-top: 15px;
}

.app-user-preferences-title {
  border-bottom: 1px solid #898989;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  background-color: #EAEAF3;
  color: #2B328C;
  height: 55px;
  line-height: 55px;
  padding-left: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: left;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.app-user-preferences-actions {
  background-color: #F4F5F9;
}

.app-user-preferences .app-tabbed-content {
  height: 486px;
}
.app-user-preferences .app-tabbed-content .app-card-body {
  height: 99% !important;
  padding-bottom: 0 !important;
}

.app-user-preferences-back {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin: 15px 0 15px 20px;
  color: #2B328C;
}
.app-user-preferences-back .app-large-panel-back-link {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #2B328C;
  text-decoration: none;
  cursor: pointer;
}
.app-user-preferences-back .app-large-panel-back-link:hover {
  text-decoration: underline;
}

.app-user-preferences-actions-icon {
  margin-right: 5px;
}

.app-user-preferences-close {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-right: 11px;
  margin-top: 11px;
}

.app-user-preferences-save {
  text-align: right;
}

.app-user-preferences-card {
  display: flex;
  flex-flow: column;
  margin: 20px;
  width: 526px;
}

.app-user-preferences-inputs {
  width: 100%;
}
.app-user-preferences-inputs .dx-selectbox {
  margin-bottom: 20px;
}
.app-user-preferences-inputs.app-bar-container {
  margin-bottom: 20px;
}
.app-user-preferences-inputs .app-bar-table {
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-user-preferences-inputs .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
}
.app-user-preferences-inputs .app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-user-preferences-inputs .app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-user-preferences-sections {
  display: flex;
  flex-flow: column;
  margin: 20px;
  width: 526px;
}
.app-user-preferences-sections .app-collapsible-section {
  border-radius: 8px 8px 0 0;
  margin: 0;
}
.app-user-preferences-sections .app-collapsible-section-content {
  padding: 20px;
}
.app-user-preferences-sections .app-collapsible-section-content .app-text-box {
  margin-bottom: 20px;
}

.app-user-preferences-section {
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  margin-bottom: 20px;
}

.app-user-profile {
  display: flex;
  flex-flow: row;
}

.app-user-profile-image {
  display: flex;
  flex-flow: column;
  margin: 0 10px 20px 20px;
}

.app-user-profile-image-btns {
  margin: 20px 0 0 17px;
}
.app-user-profile-image-btns .app-button-container:last-child {
  margin-left: 10px;
}

.app-user-profile-details {
  flex-grow: 2;
  margin: 20px 20px 20px 10px;
}
.app-user-profile-details .app-text-box {
  margin-bottom: 20px;
}

.app-user-profile-userImage {
  background-image: url("https://cdn.tradeareasystems.net/Images/Components/UserBadge/DefaultUser96x96.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 30px;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: 1px solid #2B328C;
  cursor: pointer;
}

.app-user-profile-image-upload-container {
  padding: 20px;
  height: 200px;
  text-align: center;
}

.app-user-profile-image-upload-location {
  padding-top: 75px;
  height: 382px;
  text-align: center;
}

.app-user-profile-image-modal {
  border-radius: 8px;
  width: 510px;
  height: 382px;
}

.app-user-profile-image-upload-actions {
  padding: 7px 10px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.app-user-profile-image-upload-actions-buttons {
  padding-bottom: 10px;
}

.app-form-component-table {
  width: 100%;
}

.app-form-required-column {
  width: 10px;
  display: inline-block;
  color: #ff0000;
  font-size: 10px;
  line-height: 1.35715;
}
.app-form-required-column .app-form-required {
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
}

.app-form-prefix-column-empty {
  display: none;
}

.app-form-prefix-column {
  display: inline-block;
  font-size: 20px;
  padding-top: 3px;
  padding-right: 3px;
}
.app-form-prefix-column .app-form-required {
  color: #ff0000;
}
.app-form-prefix-column .app-form-warning {
  color: #ffa500;
}
.app-form-prefix-column .app-form-tooltip {
  color: #0000ff;
}

.app-form-content-jd-test {
  width: 100%;
}

.app-form-error > * {
  border-color: #ff0000;
}
.app-form-error .app-textbox,
.app-form-error .app-date-box,
.app-form-error .dx-textbox,
.app-form-error .dx-numberbox {
  border-color: #ff0000 !important;
}

.app-form-warning > * {
  border-color: #ffa500;
}
.app-form-warning .app-textbox,
.app-form-warning .app-date-box,
.app-form-warning .dx-textbox,
.app-form-warning .dx-numberbox {
  border-color: #ffa500 !important;
}

.app-form-error.app-date-box {
  margin-left: 5px;
}

.app-form-warning.app-date-box {
  margin-left: 5px;
}

.app-form-checkbox-container {
  width: 1px;
}

.app-add-form-drop-down {
  margin-top: -3px;
}

.app-tmx-avg-daily-traffic {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #2B328C;
  margin: 0 10px 5px 10px;
  text-align: center;
}

.app-tmx-sel-daily-traffic {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #2B328C;
  margin: 5px 10px 5px 10px;
  text-align: center;
}

.app-tmx-labels {
  margin: 4px 10px 0 20px;
}

.app-tmx-week-day {
  color: #2B328C;
  background-color: #F3F6D5;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  align-content: center;
  margin: 10px;
}

.app-tmx-default-bar {
  width: 45px;
}

.app-tmx-input-container {
  display: flex;
  flex-flow: row;
  margin: 10px 10px 10px 10px;
  text-align: center;
  justify-content: center;
}
.app-tmx-input-container .app-option-selector {
  margin: 4px 10px 0 0;
}
.app-tmx-input-container .app-button-container {
  margin-right: 10px;
}
.app-tmx-input-container .app-clickable-active {
  color: #F3F6D5;
  background-color: #2B328C;
}
.app-tmx-input-container .app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-tmx-input-container .app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-preset-drop-down {
  margin-top: 5px;
  margin-right: 10px;
}

.app-direction-drop-down {
  margin-right: 10px;
}

.app-tmx-grid-row td {
  padding: 1px 1px;
  text-align: center;
}

.app-tmx-grid-cell {
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  border-radius: 1px;
  width: 16px;
  height: 16px;
  margin: auto;
  filter: alpha(opacity=100);
  opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
}

.app-slider {
  cursor: pointer;
  width: 240px;
  padding-top: 3px;
}

.app-slider .app-slider-track {
  top: 13px;
  height: 4px;
  background-color: #DFE0EE;
}

.app-slider-track.app-slider-track-0 {
  background-color: #DFE0EE;
}

.app-slider-track.app-slider-track-1 {
  background-color: #2B328C;
}

.app-slider-track.app-slider-track-2 {
  background-color: #DFE0EE;
}

.app-slider .app-slider-thumb {
  cursor: pointer;
  background-color: #2B328C;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  outline: none;
  font-size: 0.9em;
  text-align: center;
  align-content: center;
  color: #FFFFFF;
}

.app-slider .app-slider-thumb:hover {
  box-shadow: 0 0 0 4px #CACCE2;
}

.app-tmx-save-modal {
  background: rgba(125, 125, 125, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.app-tmx-save-modal .app-modal-card {
  width: 400px;
  height: 130px;
  top: 25%;
  left: 25%;
}
.app-tmx-save-modal .app-modal-card .app-modal-card-body {
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
  border: 1px solid #CCC;
  padding-bottom: 0 !important;
}
.app-tmx-save-modal .app-modal-card .app-modal-card-body .app-card-body {
  padding-bottom: 0 !important;
}

.app-tmx-save-container {
  display: flex;
  flex-flow: column;
  margin: 20px 20px 5px 0;
}

.app-tmx-save-row {
  display: flex;
  flex-flow: row;
}

.app-tmx-save-stretch {
  flex-grow: 2;
}

.app-tmx-save-actions-rows {
  margin: 20px 0 0 0;
  display: flex;
  flex-flow: row;
  justify-content: right;
}

.app-tmx-save-button {
  margin: 0 0 12px 12px;
}
.app-tmx-save-button .app-button-icon {
  font-size: 22px !important;
}

.app-tmx-tool-tip {
  text-align: center;
  font-weight: bold !important;
  color: #2B328C !important;
  font-size: 13px !important;
}

.app-push-pin-edit-form-container {
  padding: 7px;
}
.app-push-pin-edit-form-container .app-push-pin-edit-form-header {
  display: flex;
  justify-content: space-between;
}
.app-push-pin-edit-form-container .app-push-pin-edit-form-header-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
}
.app-push-pin-edit-form-container .app-push-pin-edit-form-body .app-push-pin-edit-form-field {
  margin-bottom: 5px;
}

.app-form {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.app-form-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
}

.app-form-no-tabs .app-form-body-left-column {
  display: none;
}

.app-form-tabs {
  border-bottom: 1px solid #E1E1E1;
  height: 45px;
  line-height: 45px;
}

.app-form-tab {
  background-color: #F3F6D5;
  cursor: pointer;
  width: 75px;
}

.app-form-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
  position: relative;
  padding: 7px;
  padding-bottom: 0 !important;
}
.app-form-content::-webkit-scrollbar {
  width: 13px;
}
.app-form-content::-webkit-scrollbar-track {
  background: #F4F5F9;
}
.app-form-content::-webkit-scrollbar-thumb {
  background: #EAEAF3;
  cursor: pointer;
}
.app-form-content::-webkit-scrollbar-thumb:hover {
  background: #DFE0EE;
}
.app-form-content .app-bar-container {
  margin-bottom: 7px;
}
.app-form-content .app-bar-container .app-bar {
  padding: 0px 10px;
}

.app-form-content-section {
  width: 100%;
}
.app-form-content-section .app-form-content-row .app-form-content-column {
  padding: 3px;
}
.app-form-content-section .app-form-content-row .app-form-content-column:first-child {
  padding-left: 10px;
}
.app-form-content-section .app-form-content-row .app-form-content-column:last-child {
  padding-right: 10px;
}
.app-form-content-section .app-form-content-row.app-form-content-row-first .app-form-content-column {
  padding-top: 10px;
}
.app-form-content-section .app-form-content-row.app-form-content-row-last .app-form-content-column {
  padding-bottom: 10px;
}

.app-form-page {
  padding: 10px;
}

.app-form-page-flatten {
  padding: 0;
}

.app-form-content-tabs {
  height: 100%;
  display: flex;
  flex-flow: column;
  border-right: 1px solid #E1E1E1;
  background-color: #F4F5F9;
  padding: 5px 3px 5px 3px;
}

.app-form-tab-container {
  position: relative;
}
.app-form-tab-container .app-form-content-tab {
  margin: 6px 5px 0 5px;
}
.app-form-tab-container .app-form-content-tab img {
  margin-top: -2px;
  max-width: 22px;
  max-height: 22px;
}
.app-form-tab-container .app-form-content-tab .app-bar {
  background-color: #EAEAF3;
  min-height: 24px;
}
.app-form-tab-container .app-form-content-tab .app-bar .app-bar-table {
  min-height: 27px;
}
.app-form-tab-container .app-form-content-tab .app-clickable-active {
  background-color: #F3F6D5;
}
.app-form-tab-container .app-form-content-tab .app-bar-text {
  font-size: 13px;
  max-width: 150px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.app-form-tab-container .app-form-content-tab-error {
  border: 1px solid #ff0000;
  border-radius: 3px;
}
.app-form-tab-container .app-form-content-tab-warning {
  border: 1px solid #ffa500;
  border-radius: 3px;
}
.app-form-tab-container .app-form-content-tab-badge {
  top: 0px;
  right: 0px;
  position: absolute;
  font-size: 12px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 100px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
}
.app-form-tab-container .app-form-content-tab-badge-error {
  border: 1px solid #ff0000;
  background: #ff0000;
}
.app-form-tab-container .app-form-content-tab-badge-warning {
  border: 1px solid #ffa500;
  background: #ffa500;
}

.app-form-image .app-form-content-tab .app-bar-icon-container {
  padding-right: 0;
}
.app-form-image .app-form-content-tab .app-bar-text {
  display: none;
}

.app-form-text .app-form-content-tab .app-bar-icon-container {
  display: none;
}

.app-form-body-table {
  width: 100%;
  height: 100%;
}

.app-form-body-left-column {
  width: 1px;
  vertical-align: top;
}

.app-form-label-container {
  width: 1px;
}

.app-form-label {
  white-space: nowrap;
  padding: 5px 7px 5px 7px;
  border-radius: 3px;
}

.app-form-label-flatten {
  padding: 5px 0 0 0;
  white-space: normal;
}

.app-form-value {
  padding: 5px 7px 5px 7px;
  border-radius: 3px;
  white-space: pre-wrap;
}

.app-form-content-page {
  margin: 10px 10px 10px 0px;
}

.app-form-loader {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.app-form-photos-gallery {
  width: 100px;
  height: 100px;
}

.app-form-photos-right-nav {
  padding-left: 70px;
}

.app-edit-form-header {
  border-bottom: 1px solid #E1E1E1;
  padding: 7px;
  text-align: right;
  display: flex;
  justify-content: space-between;
}
.app-edit-form-header .app-edit-form-header-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
}
.app-edit-form-header .app-edit-form-header-actions .app-button-container:last-child {
  margin-left: 5px;
}

.app-shape-edit-container {
  padding: 7px;
}
.app-shape-edit-container .app-button-container {
  padding-left: 5px;
}

.app-shape-edit-button-container {
  display: flex;
  justify-content: flex-end;
}

.app-shape-edit-line-width-image {
  padding: 10px;
  max-width: 125px;
}

.delete-button .app-button {
  background-color: #ff0000;
  color: #FFFFFF;
  border-color: #ff0000;
}

.app-photos {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.app-photos-header {
  border-bottom: 1px solid #E1E1E1;
  padding: 10px 10px;
}

.app-photos-search {
  float: left;
  padding-left: 5px;
}

.app-photos-actions {
  float: right;
}
.app-photos-actions .app-button {
  margin-left: 10px;
}

.app-photos-body {
  padding: 10px 0px 0px 15px;
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-photos-body::-webkit-scrollbar {
  width: 0px;
}
.app-photos-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-photos-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-photos-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-photos-container {
  display: grid;
  -moz-column-gap: 5px;
       column-gap: 5px;
  row-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  height: 380px;
}

.app-photos-empty {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #565656;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.app-photos-search-input {
  height: 40px;
  width: 330px;
  display: inline-block;
}

.app-photos-search-button {
  margin: 0 !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  width: 50px !important;
}
.app-photos-search-button .app-button {
  height: 40px !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.app-photos-actions-container {
  padding: 25px;
  position: absolute;
  right: 30px;
  z-index: 1000;
  padding-right: 310px;
}

.app-photos-actions-container-group {
  padding: 10px;
}

.app-photos-actions-container-group-icon {
  padding-left: 15px;
}

.app-photos-details {
  position: absolute;
  height: 200px;
  color: #FFFFFF;
  bottom: 0px;
  z-index: 1000;
  pointer-events: none;
  width: 100%;
}

.app-photos-details-title {
  padding: 10px;
}

.app-photos-details-description {
  padding: 30px 10px;
}

.app-photos-details-user-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
  padding-left: 10px;
}

.app-photos-details-user-section-name {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding-left: 15px;
  overflow-wrap: break-all;
}

.app-photos-details-user-section-date {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding-right: 100px;
  float: right;
}

.app-photos-details-user-image {
  border-radius: 100px;
  margin-top: -2px;
  width: 30px;
  height: 30px;
}

.app-photos-list-view-column, .app-photos-list-view-column-text {
  vertical-align: middle !important;
}

.app-photos-list-view-column-text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.app-photos-thumb-nail-photo {
  width: 65px;
  height: 55px;
  border-radius: 8px;
}

.app-photos-list-view-actions {
  display: flex;
  align-items: center;
}

.app-photos-list-view-actions-buttons {
  position: absolute;
}

.app-photos-list-view-actions-button {
  margin-left: 10px;
  color: #565656;
}

.app-photos-upload {
  height: 350px;
}

.image-gallery-icon {
  color: #fff;
  transition: all 0.3s ease-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a);
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}
.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 28px;
  width: 28px;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 15px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}
@media (max-width: 480px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 10px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}

.image-gallery-fullscreen-button {
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}
@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}
@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}
.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: 0.6;
  pointer-events: none;
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}

.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
}
.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}
.image-gallery-content.fullscreen {
  background: #000;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
}
.image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image, .image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}

.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-slide-wrapper.image-gallery-thumbnails-left, .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
  display: inline-block;
  width: calc(100% - 110px);
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.image-gallery-thumbnails-left, .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
    width: calc(100% - 87px);
  }
}
.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-slide.image-gallery-center {
  position: relative;
}
.image-gallery-slide .image-gallery-image {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}
@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: 0.8em;
    padding: 8px 15px;
  }
}

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0 2px 2px #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  transition: all 0.2s ease-out;
}
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}
@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}
.image-gallery-bullets .image-gallery-bullet:focus {
  transform: scale(1.2);
  background: #337ab7;
  border: 1px solid #337ab7;
}
.image-gallery-bullets .image-gallery-bullet.active {
  transform: scale(1.2);
  border: 1px solid #fff;
  background: #fff;
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-bullets .image-gallery-bullet:hover {
    background: #337ab7;
    border: 1px solid #337ab7;
  }
  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: #337ab7;
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  touch-action: pan-y;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
  touch-action: pan-x;
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
    width: 81px;
  }
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 2px;
}
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
  margin: 0 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left, .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
    margin: 0 3px;
  }
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 81px;
  }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: block;
  position: relative;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #337ab7;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    outline: none;
    border: 4px solid #337ab7;
  }
}
@media (hover: hover) and (pointer: fine) and (max-width: 768px) {
  .image-gallery-thumbnail:hover {
    border: 3px solid #337ab7;
  }
}

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 0 2px 2px #1a1a1a;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: 0.8em;
    line-height: 0.8em;
  }
}

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .image-gallery-index {
    font-size: 0.8em;
    padding: 5px 10px;
  }
}

.app-thumb-nail-photo-container {
  position: relative;
}

.app-thumb-nail-photo {
  width: 170px;
  height: 127px;
  border-radius: 8px;
  cursor: pointer;
}

.app-thumb-nail-photo-hover {
  width: 170px;
  height: 127px;
  position: absolute;
  top: 0px;
  color: #FFFFFF;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  pointer-events: none;
}

.app-thumb-nail-photo-title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  padding: 10px 10px;
  width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-thumb-nail-photo-date {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding: 0px 10px;
}

.app-thumb-nail-photo-user-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
  padding-left: 10px;
}

.app-thumb-nail-photo-user-name {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  padding-left: 15px;
  overflow-wrap: break-all;
}

.app-thumb-nail-photo-user-image {
  border-radius: 100px;
  margin-top: -2px;
  width: 30px;
  height: 30px;
}

.app-photos-edit-details {
  position: fixed;
  display: block;
  margin: auto;
  color: #FFFFFF;
  left: 25%;
  top: 25%;
  z-index: 1000;
}

.app-photos-details-textbox-title {
  width: 1000px;
}

.app-photos-details-textbox-description {
  width: 1000px;
}

.app-photos-details-textbox-tags {
  width: 1000px;
}

.app-photos-edit-details-actions-section {
  padding-top: 20px;
  float: right;
}

.app-photos-edit-details-actions-section-icon {
  padding-left: 15px;
}

.app-comments {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.app-comments-empty {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #565656;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.app-comments-body {
  flex-grow: 2;
  padding: 10px 10px;
  overflow-y: scroll;
  padding-bottom: 75px !important;
  display: flex;
  flex-direction: column-reverse;
  overflow-anchor: auto !important;
  padding-bottom: 0 !important;
}
.app-comments-body::-webkit-scrollbar {
  width: 0px;
}
.app-comments-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-comments-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-comments-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-comments-container {
  padding: 5px 5px;
}

.app-comments-footer {
  border-top: 1px solid #E1E1E1;
  padding: 10px 10px;
}

.app-comments-new {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 10px 10px;
}

.app-comments-new-input {
  flex-grow: 2;
}
.app-comments-new-input .dx-editor-outlined.dx-texteditor-with-label {
  margin-top: 0;
  padding-top: 5px;
}

.app-comments-new-button {
  margin: 0 !important;
  width: 50px !important;
  padding-left: 10px;
}
.app-comments-new-button .app-button {
  height: 40px !important;
}

.app-comment {
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  width: 80%;
  padding: 10px;
  margin-bottom: 5px;
}

.app-comment-with-subcomments {
  background-color: #EAEAF3;
  margin-bottom: 15px;
}

.app-comment-align-right {
  margin-left: auto;
}

.app-comment-container {
  display: flex;
  flex-flow: row;
}

.app-comment-subcomments {
  margin-left: 50px;
}
.app-comment-subcomments .app-comment {
  width: 100%;
  padding: 0;
  margin-bottom: 0;
}

.app-comment-image {
  margin: 20px 10px 0 0;
}

.app-comment-userImage {
  background-image: url("https://cdn.tradeareasystems.net/Images/Components/UserBadge/DefaultUser96x96.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 1px solid #2B328C;
}

.app-comment-details {
  flex-grow: 2;
  margin-bottom: 5px;
}

.app-comment-header {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  padding: 4px;
  display: flex;
  flex-flow: row;
}

.app-comment-user {
  flex-grow: 2;
}

.app-comment-message {
  border: 1px solid #F9F9FC;
  border-radius: 8px;
  background-color: #F9F9FC;
  padding: 5px;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.app-comment-display-text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  white-space: pre-line;
  padding: 5px;
  flex-grow: 2;
  height: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
}

.app-comment-edit-input {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  padding: 5px;
  flex-grow: 2;
}
.app-comment-edit-input .dx-editor-outlined.dx-texteditor-with-label {
  margin-top: 0;
  padding-top: 5px;
}

.app-comment-edit-buttons {
  color: #565656;
  padding: 4px;
  display: flex;
  flex-flow: row;
  margin-left: auto;
}

.app-comments-container > .app-comment:last-child:not(:has(.app-comment)) .app-comment-edit-buttons .app-button-menu-container,
.app-comments-container > .app-comment:last-child .app-comment:last-child .app-comment-edit-buttons .app-button-menu-container {
  position: static;
}

.app-comments-container > .app-comment:last-child:not(:has(.app-comment)) .app-comment-edit-buttons .app-button-menu,
.app-comments-container > .app-comment:last-child .app-comment:last-child .app-comment-edit-buttons .app-button-menu {
  margin-right: 30px;
}

.app-comment-edit-button {
  margin-left: auto;
}

.app-comments-edit-save-button {
  margin: 0 !important;
  width: 50px !important;
  padding-left: 10px;
}
.app-comments-edit-save-button .app-button {
  height: 40px !important;
}

.app-comment-reply-button {
  color: #565656;
  margin-left: 5px;
}

.app-trade-areas {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.app-trade-areas-actions {
  margin: 0 10px 10px 0;
  text-align: right;
}
.app-trade-areas-actions .app-button {
  padding: 5px;
}
.app-trade-areas-actions .app-trade-areas-zoom-bar {
  width: 150px;
  margin: 0 7px 0 0 !important;
}
.app-trade-areas-actions .app-trade-areas-zoom-bar .app-bar {
  padding: 5px;
}
.app-trade-areas-actions .app-trade-areas-zoom-bar .app-bar-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.app-trade-areas-actions .app-trade-areas-zoom-bar .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
}
.app-trade-areas-actions .app-trade-areas-zoom-bar .app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-trade-areas-actions .app-trade-areas-zoom-bar .app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-trade-areas-header {
  border-bottom: 1px solid #E1E1E1;
  padding: 10px;
  display: flex;
  flex-flow: column;
}
.app-trade-areas-header .app-trade-areas-name {
  display: inline-block;
  margin-right: 5px;
  width: 185px;
}
.app-trade-areas-header .app-trade-areas-generation {
  display: flex;
  justify-content: flex-end;
}
.app-trade-areas-header .app-trade-areas-template {
  display: inline-block;
  width: 200px;
  vertical-align: bottom;
}
.app-trade-areas-header .app-trade-areas-template .dx-selectbox .dx-label-after {
  border-right: none !important;
  border-radius: 0 !important;
}
.app-trade-areas-header .app-trade-areas-template .dx-selectbox .dx-label-before {
  border-right: none !important;
  border-radius: 0 !important;
}
.app-trade-areas-header .app-button-container.app-button-action {
  height: auto;
  width: auto;
  line-height: normal;
  margin-top: 7px;
}
.app-trade-areas-header .app-button-container.app-button-action .app-button {
  padding: 5px;
}
.app-trade-areas-header .app-trade-areas-template-favorite .app-button {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.app-trade-areas-header .app-trade-areas-template-reload .app-button,
.app-trade-areas-header .app-trade-areas-template-clear .app-button,
.app-trade-areas-header .app-trade-areas-template-save .app-button {
  border-radius: 0;
  border-right: none;
}
.app-trade-areas-header .app-trade-areas-template-delete .app-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.app-trade-areas-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 0 10px 10px 10px;
}

.app-trade-areas-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  border: 1px solid #E1E1E1;
  background-color: #F9F9FC;
  height: 294px;
}

.app-trade-area-row {
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  background-color: #FFFFFF;
  padding: 10px;
}
.app-trade-area-row:nth-child(2n+1) {
  border: none;
  background-color: #F9F9FC;
}
.app-trade-area-row .app-button-icon {
  color: #565656;
}

.app-trade-area-type {
  display: inline-block;
  width: 75px;
  margin-left: 10px;
}
.app-trade-area-type .dx-selectbox .dx-label-after {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.app-trade-area-row-user-drawn .app-trade-area-type,
.app-trade-area-row-saved-shape .app-trade-area-type {
  width: 200px;
}
.app-trade-area-row-user-drawn .app-trade-area-type .dx-selectbox .dx-label-after,
.app-trade-area-row-saved-shape .app-trade-area-type .dx-selectbox .dx-label-after {
  border-right: 1px solid !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.app-trade-area-distance {
  display: inline-block;
  width: 52px;
}
.app-trade-area-distance .dx-numberbox {
  text-align: center;
  border-right: none;
  border-left: none;
  border-radius: 0;
}

.app-trade-area-minutes {
  display: inline-block;
  width: 73px;
}
.app-trade-area-minutes .dx-textbox {
  text-align: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #666666;
}
.app-trade-area-minutes .dx-textbox input {
  cursor: pointer !important;
  background-color: #CCC;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.app-trade-area-measurement {
  display: inline-block;
  width: 73px;
}
.app-trade-area-measurement .dx-selectbox {
  border-left: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.app-trade-area-geography {
  display: inline-block;
  width: 125px;
}
.app-trade-area-geography .dx-selectbox .dx-label-before {
  border-left: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.app-trade-area-name,
.app-trade-area-settings {
  display: inline-block;
  width: 170px;
  margin: 0px 0px 0 10px;
}

.app-trade-area-name {
  width: 155px;
}

.app-trade-area-row-ring .app-trade-area-name,
.app-trade-area-row-user-drawn .app-trade-area-name {
  width: 335px;
}

.app-trade-area-style {
  display: inline-block;
  position: relative;
  border: 1px solid #565656;
  border-radius: 4px;
  margin: 0 10px;
  height: 30px;
  width: 34px;
  text-align: center;
}
.app-trade-area-style .app-trade-area-style-label {
  position: absolute;
  top: -12px;
  left: 2px;
  color: #000;
  font-size: 12px;
  background: #FFF;
}
.app-trade-area-style .app-trade-area-style-color {
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 4px;
  border: 1px solid #000;
  background-color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  height: 26px;
  width: 26px;
}
.app-trade-area-style .app-trade-area-style-text {
  position: absolute;
  top: 5px;
  left: 40px;
  border-radius: 4px;
  border: 1px solid #000;
  background-color: #000;
  text-align: center;
  color: #FFF;
  display: inline-block;
  height: 26px;
  width: 26px;
  line-height: 26px;
}

.app-trade-area-row:nth-child(2n+1) .app-trade-area-style .app-trade-area-style-label {
  background: #F9F9FC;
}

.app-trade-areas-empty {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #565656;
  text-align: center;
  margin: auto;
}

.app-trade-area-actions {
  display: inline-block;
}

.app-dashboards {
  height: 100%;
  display: flex;
  flex-flow: column;
  margin: 30px;
}

.app-dashboards-dropdown {
  margin-bottom: 20px;
}

.app-dashboards-powerbi {
  flex: 2;
}

.app-driving-directions-travel-modes {
  padding: 10px 0px;
  margin-left: 15px;
}

.app-driving-directions-travel-mode-button {
  margin: 0 5px;
}

.app-driving-directions-buttons {
  border-top: 1px solid #E1E1E1;
  height: 75px;
}

.app-driving-directions-confim {
  padding: 12px;
}

.app-driving-directions-close-button {
  margin: 0 11px;
}

.app-driving-directions-destinations {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  min-height: 175px;
  max-height: 340px;
}
.app-driving-directions-destinations::-webkit-scrollbar {
  width: 0px;
}
.app-driving-directions-destinations::-webkit-scrollbar-track {
  background: transparent;
}
.app-driving-directions-destinations::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-driving-directions-destinations::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-destinations-actions-button {
  color: #2B328C;
  margin: 10px 0px 10px 20px;
}

.app-driving-directions-panel {
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-driving-directions-panel::-webkit-scrollbar {
  width: 0px;
}
.app-driving-directions-panel::-webkit-scrollbar-track {
  background: transparent;
}
.app-driving-directions-panel::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-driving-directions-panel::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-driving-directions-direction-settings {
  width: 100%;
}
.app-driving-directions-direction-settings .app-bar {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  border: 1px solid #E1E1E1;
  background-color: #FFFFFF;
  border-radius: 0px;
}
.app-driving-directions-direction-settings .app-bar-body {
  border: 0px;
  height: 100%;
}

.app-driving-directions-direction-settings-label {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding: 10px 10px;
}

.app-driving-directions-direction-settings-item {
  margin-bottom: 7px;
  padding: 5px 5px;
}
.app-driving-directions-direction-settings-item .app-clickable {
  width: 243px;
  margin-left: 25px;
  background-color: #E1E1E1;
  border-color: #F9F9FC;
}
.app-driving-directions-direction-settings-item .app-bar {
  background-color: #F9F9FC;
  border-color: #E1E1E1;
}
.app-driving-directions-direction-settings-item .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
  border-color: #C1D42F;
}

.app-driving-directions-direction-settings-units {
  margin-bottom: 7px;
  padding: 5px 5px;
}
.app-driving-directions-direction-settings-units .app-clickable {
  width: 105px;
  background-color: #E1E1E1;
  border-color: #F9F9FC;
}
.app-driving-directions-direction-settings-units .app-bar {
  background-color: #F9F9FC;
  border-color: #E1E1E1;
}
.app-driving-directions-direction-settings-units .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
  border-color: #C1D42F;
  border-radius: 8px;
}

.app-driving-directions-direction-settings-units {
  display: flex;
  margin-left: 25px;
}

.app-driving-directions-destination-move {
  width: 25px;
  height: 25px;
  border-width: 0;
  padding-top: 10px;
  background: transparent;
  cursor: grab;
}

.app-driving-directions-destination {
  display: inline-block;
  width: 250px !important;
  margin: 0 10px;
}

.app-driving-directions-destination-row {
  background-color: #FFFFFF;
  padding: 2px 0px 10px 5px;
}
.app-driving-directions-destination-row .app-button-icon {
  color: #565656;
}

.adp-listheader {
  display: none;
}

.adp-list {
  border: 0px !important;
  padding: 0px !important;
}

.adp-listinfo {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #2B328C;
  padding: 10px 10px 10px 10px;
  display: inline-block;
  border-bottom: 1px solid #E1E1E1;
}

.adp-listsel {
  background-color: #E0E997 !important;
}

.adp-marker2 {
  background-color: #FFFFFF;
  padding: 5px;
}

.adp-text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  padding: 10px 10px 10px 10px;
  background-color: #FFFFFF;
}

.adp-placemark {
  border: 0px !important;
}

.adp button {
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 1px solid #E1E1E1 !important;
  border-top: 1px solid #E1E1E1 !important;
}

.adp-summary {
  padding: 10px 10px 10px 10px !important;
}

.app-photos-edit-photo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  z-index: 1000;
}

.app-photos-edit-photo-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.app-photos-edit-photo-controls {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 20px;
  height: 40px;
  align-items: center;
}

.app-photos-edit-photo-slider-label {
  padding-right: 10px;
}

.app-photos-edit-photo-slider-container {
  padding-right: 15px;
}

.app-photos-edit-photo-slider {
  padding: 22px 0px;
  caret-color: transparent;
}

.app-photos-edit-photo-button {
  border-radius: 0px;
  color: #565656;
  background-color: transparent;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.app-photos-edit-photo-save-button {
  position: absolute;
  right: 15px;
  bottom: 100px;
}

.app-photos-edit-photo-close-button {
  position: absolute;
  right: 15px;
  top: 15px;
}

.app-photos-upload-photos-description {
  display: inline-block;
  width: 550px;
  padding: 10px;
  margin-left: 10px;
  margin-top: 10px;
  border-style: none;
  height: 75px;
  text-align: left;
}

.app-photos-upload-photos-actions {
  float: right;
  padding: 24px 8px;
}
.app-photos-upload-photos-actions .app-button {
  margin-left: 10px;
}

#dropzone-external {
  height: 350px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  padding: 10px;
}

#dropzone-external > * {
  pointer-events: none;
}

#dropzone-external.dropzone-active {
  border-style: solid;
}

.dropzone-text-full {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #565656;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dropzone-text {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #565656;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-photos-upload-photos {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  display: inline-block;
}
.app-photos-upload-photos::-webkit-scrollbar {
  width: 0px;
}
.app-photos-upload-photos::-webkit-scrollbar-track {
  background: transparent;
}
.app-photos-upload-photos::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-photos-upload-photos::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-photos-upload-photos-body {
  padding: 10px 10px;
  height: 300px;
}

.app-photos-upload-photos-content {
  border-radius: 8px;
  background-color: #F9F9FC;
}

.app-photos-edit-photo-upload-photo-row {
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  background-color: #FFFFFF;
  padding: 20px;
}
.app-photos-edit-photo-upload-photo-row:nth-child(2n+1) {
  border: none;
  background-color: #F9F9FC;
}
.app-photos-edit-photo-upload-photo-row .app-button-icon {
  color: #565656;
}

.app-photos-edit-photo-upload-photo {
  border-radius: 8px;
  width: 118.5px;
  height: 100px;
  padding-right: 20px;
}

.app-photos-edit-photo-upload-photo-img {
  border-radius: 8px;
  width: 90px;
  height: 80px;
}

.app-photos-edit-photo-upload-edit-details {
  display: flex;
}

.app-photos-edit-photo-upload-edit-details-textbox-description {
  width: 500px;
}

.app-photos-edit-photo-upload-edit-details-actions {
  width: 120px;
}

.app-photos-edit-photo-upload-edit-details-actions-button {
  margin-left: 10px;
  color: #565656;
}

.app-photos-edit-photo-upload-edit-details-file {
  margin-left: 10px;
  color: #565656;
  width: 40px;
  height: 40px;
}

.app-geofences {
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 100%;
}

.app-geofences-header {
  border-bottom: 1px solid #E1E1E1;
}

.app-geofences-datagrid {
  margin: 17px;
}

.app-geofences-actions {
  float: right;
  padding: 10px 16px;
}
.app-geofences-actions .app-button {
  margin-left: 10px;
}

.app-geofences-grid-actions {
  display: flex;
  align-items: center;
}

.app-geofences-grid-actions-buttons {
  position: absolute;
}

.app-geofences-grid-actions-button {
  margin-left: 8px;
  color: #565656;
}

.app-geofence-job {
  display: flex;
  flex-flow: column;
  background-color: #FFFFFF;
}
.app-geofence-job .app-description {
  padding: 8px;
  margin: 10px 0 0 0;
}

.app-geofence-job-header {
  border-bottom: 1px solid #E1E1E1;
  padding: 10px 20px;
  display: flex;
  flex-flow: row;
}

.app-geofence-job-datagrid {
  margin: 17px 27px;
}

.app-geofence-job-title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  flex-grow: 2;
}

.app-geofence-job-maintenance {
  margin: 0 30px;
}

.app-geofence-job-actions .app-button {
  margin-left: 10px;
}

.app-geofence-job-status {
  border: 1px solid #E1E1E1;
  border-radius: 100px;
  margin-left: 16px;
  width: 14px;
  height: 14px;
}

.app-geofence-job-status.pending {
  background-color: #898989;
}

.app-geofence-job-status.processing {
  background-color: #ffa500;
}

.app-geofence-job-status.completed {
  background-color: #008000;
}

.app-geofence-job-status.completed-with-errors {
  background-color: #ffff00;
}

.app-geofence-job-status.failed {
  background-color: #ff0000;
}

.app-geofence-job-details {
  display: flex;
  flex-flow: column;
}

.app-geofence-job-details-description {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #565656;
}

.app-geofence-job-details-error {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #902153;
  margin-top: 10px;
  margin-right: 20px;
  white-space: break-spaces;
}

.app-geofence-job-details-header {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  border-radius: 8px;
  background-color: #E6F6FA;
  color: #2B328C;
  display: flex;
  flex-flow: row;
  padding: 8px;
  margin-top: 5px;
}

.app-geofence-job-details-info {
  display: flex;
  flex-flow: column;
  flex-grow: 2;
}

.app-geofence-job-details-row {
  display: flex;
  flex-flow: row;
}

.app-geofence-job-details-btn-row {
  display: flex;
  flex-flow: row;
  margin-top: 10px;
  justify-content: right;
}

.app-geofence-job-details-label {
  padding: 3px;
  width: 150px;
  display: flex;
  justify-content: right;
}

.app-geofence-job-details-value {
  padding: 3px;
  flex-grow: 2;
}

.app-geofences-job-details-grid-actions {
  display: flex;
  align-items: center;
  width: 126px;
}

.app-geofence-job-details-action-container {
  display: flex;
  flex-flow: column;
  margin-top: 5px;
}

.app-geofences-job-details-button {
  margin-left: 6px;
  color: #565656;
}

.app-geofence-job-details-action-row {
  flex-grow: 2;
  margin: 5px 0;
}

.app-geofence-job-details-bottom-row {
  margin-bottom: 30px;
}

.app-geofence-job-renderers-row-options {
  display: flex;
  flex-flow: row;
  margin-top: 5px;
  justify-content: space-evenly;
}
.app-geofence-job-renderers-row-options .app-option-selector {
  margin: 5px;
}
.app-geofence-job-renderers-row-options .app-selectable-items-body {
  width: 100%;
  margin: 7px;
}
.app-geofence-job-renderers-row-options .app-selectable-items-body .app-option-selector-icon {
  display: flex;
  justify-content: center;
}

.app-geofence-job-renderers-col-options {
  display: flex;
  flex-flow: column;
}

.app-geofence-job-renderers-section {
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  margin: 5px 0;
  padding: 8px;
}

.app-geofence-job-renderers-label {
  display: flex;
  justify-content: center;
  padding: 3px;
  width: 190px;
}

.app-geofence-job-renderers-type-label {
  width: 100px;
}

.app-geofence-job-renderers-warning {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #565656;
}

.app-geofence-job-renderers-radio {
  margin: 5px;
}

.app-geofence-job-renderers-name {
  margin-left: 5px;
}

.app-geofence-job-renderers-bar-buttons {
  width: 140px;
}
.app-geofence-job-renderers-bar-buttons .app-bar-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
}
.app-geofence-job-renderers-bar-buttons .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
}
.app-geofence-job-renderers-bar-buttons .app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-geofence-job-renderers-bar-buttons .app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-geofence-job-create-body {
  display: flex;
  flex-flow: column;
  padding: 10px 10px;
}

.app-geofence-job-create-row {
  padding: 8px;
  margin-bottom: 5px;
}

.app-geofence-job-create-dates {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
}

.app-geofence-job-create-label {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding: 3px;
}

.app-geofence-job-create-warning {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #902153;
  display: flex;
  align-items: center;
  flex-grow: 2;
}

.app-geofence-job-create-confirm-header {
  justify-content: right;
}

.app-geofence-job-create-confirm-warning {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: #902153;
}

.app-ci-dashboards {
  margin: 20px;
  display: flex;
  flex-flow: column;
}
.app-ci-dashboards .dxc-title text:first-child {
  font-size: 24px !important;
  font-weight: 400 !important;
  letter-spacing: 0.18px !important;
  font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif !important;
}
.app-ci-dashboards .dxc-title text:last-child {
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0.1px !important;
  font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif !important;
}
.app-ci-dashboards .dx-header-row .tasDxCustom,
.app-ci-dashboards .dx-header-row .center {
  text-align: center !important;
}
.app-ci-dashboards .dx-header-row .right,
.app-ci-dashboards .dx-row .right {
  text-align: right !important;
}
.app-ci-dashboards .dx-header-row .bottom {
  vertical-align: bottom !important;
}
.app-ci-dashboards .dx-row .tasDxCustom.focused,
.app-ci-dashboards .dx-row-alt .tasDxCustom.focused {
  background-color: #F3F6D5 !important;
}
.app-ci-dashboards .dx-data-row td.tasDxCustom.tasDxCategory {
  border-color: #F3F6D5 !important;
  background: #F3F6D5 !important;
}
.app-ci-dashboards .dx-toolbar .dx-button .dx-icon {
  color: #2B328C;
}

.app-ci-dashboards-wrapper .dx-popup-content {
  padding: 0;
}

.app-ci-dashboards-input {
  display: flex;
  flex-flow: row;
  padding: 0 0 20px 0;
}

.app-ci-dashboards-generate {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: right;
}

.app-ci-dashboards-header {
  margin: 20px 0;
  text-align: center;
}

.app-ci-dashboards-report-header {
  margin: 0 0 20px 0;
  text-align: left;
}

.app-ci-dashboards-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
}

.app-ci-dashboards-subtitle {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
}

.app-ci-dashboards-labels {
  margin: 10px 10px 0 10px;
}

.app-ci-dashboards-report-labels {
  margin: 10px 10px 0 10px;
  width: 200px;
}

.app-ci-dashboards-report-options {
  flex-grow: 2;
}

.app-ci-dashboards-filter-wrapper .dx-popup-content {
  padding: 0;
  overflow: hidden;
}

.app-ci-dashboards-channel-filter {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 10px;
}
.app-ci-dashboards-channel-filter .app-competitive-insights-channel-list {
  height: 100%;
  margin: 10px 0 0 0;
}

.app-ci-dashboards-filter-actions {
  border-bottom: 1px solid #E1E1E1;
  padding: 7px 10px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.app-ci-dashboards-filter-buttons {
  margin-left: 15px;
}

.app-competitive-insights-channel-filter {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 10px;
}

.app-competitive-insights-channel-list {
  height: 100%;
  display: flex;
  flex-flow: column;
  margin: 10px 0 20px;
  height: 88%;
}

.app-nav-section, .app-analytics-nav-section, .app-driving-direction-nav-section {
  position: absolute;
  left: 85px;
}
.app-nav-section .app-competitive-insights-channel-list-container .app-bar-container, .app-analytics-nav-section .app-competitive-insights-channel-list-container .app-bar-container, .app-driving-direction-nav-section .app-competitive-insights-channel-list-container .app-bar-container {
  margin: 0px 0px 10px 0px;
}

.app-competitive-insights-show-only-selected-button {
  float: right;
}

.app-option-selector {
  margin: 5px 0 0 0;
}

.app-competitive-insights-channel-filter-buttons {
  border-bottom: 1px solid #E1E1E1;
  padding: 7px 5px;
}
.app-competitive-insights-channel-filter-buttons .app-button-container {
  margin: 0 5px;
}
.app-competitive-insights-channel-filter-buttons .app-button-secondary.app-button-small {
  width: 90px;
  height: 45px;
  line-height: 45px;
}
.app-competitive-insights-channel-filter-buttons .app-button-primary.app-button-small {
  width: 94px;
  height: 49px;
  line-height: 49px;
}

.app-competitive-insights-channel-filter-row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.app-competitive-insights-channel-filter-icon {
  margin: 2px 4px 0 0;
}

.app-mobility-data-step-1-container {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  flex-flow: column;
  width: 850px;
  height: calc(100% - 100px);
}
.app-mobility-data-step-1-container::-webkit-scrollbar {
  width: 0px;
}
.app-mobility-data-step-1-container::-webkit-scrollbar-track {
  background: transparent;
}
.app-mobility-data-step-1-container::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-mobility-data-step-1-container::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-mobility-data-step-2-container {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  flex-flow: row;
  width: 850px;
  height: calc(100% - 100px);
}

.app-mobility-data-step-3-container {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  flex-flow: row;
  width: 850px;
  height: 100%;
  padding-top: 20px;
}

.app-mobility-data-step-2-column {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  flex-flow: column;
  width: 430px;
}
.app-mobility-data-step-2-column::-webkit-scrollbar {
  width: 0px;
}
.app-mobility-data-step-2-column::-webkit-scrollbar-track {
  background: transparent;
}
.app-mobility-data-step-2-column::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-mobility-data-step-2-column::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-mobility-data-vertical-line {
  border-left: 1px solid #E1E1E1;
  height: 95%;
  margin: 17px 0 17px 0;
}

.app-mobility-data-labels {
  margin: 17px 17px 0 17px;
}

.app-mobility-data-inputs {
  margin: 17px 17px 10px 17px;
}

.app-mobility-data-warning {
  color: #902153;
}

.app-mobility-data-report-rows {
  display: flex;
  flex-flow: row;
}

.app-mobility-data-report-columns {
  display: flex;
  flex-flow: column;
}
.app-mobility-data-report-columns .app-selectable-items-selected {
  max-height: none;
}
.app-mobility-data-report-columns .app-selectable-items-body {
  margin: 3px;
}
.app-mobility-data-report-columns .app-selectable-items-body .app-option-selector-icon {
  display: flex;
  justify-content: center;
}

.app-mobility-search-container {
  display: flex;
  flex-flow: row;
  margin: 17px 17px 10px 17px;
}

.app-mobility-search-input {
  flex-grow: 2;
  margin: 0;
}
.app-mobility-search-input .dx-texteditor {
  border-radius: 4px 0 0 4px;
}

.app-mobility-search-button {
  margin: 0 !important;
  width: 50px !important;
}
.app-mobility-search-button .app-button {
  height: 40px !important;
  border-radius: 0 4px 4px 0;
}

.app-mobility-name-input {
  margin: 10px 10px 10px 17px;
}

.app-mobility-data-rows-container {
  padding: 10px;
}

.app-mobility-data-bar-long {
  width: 370px;
}

.app-mobility-data-bar-short {
  width: 166px;
}

.app-mobility-data-bar-excel .app-bar-icon-container {
  color: green;
}

.app-mobility-data-bar-pdf .app-bar-icon-container {
  color: red;
}

.app-mobility-data-bar-buttons .app-bar-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.app-mobility-data-bar-buttons .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
}
.app-mobility-data-bar-buttons .app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-mobility-data-bar-buttons .app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-mobility-data-bar-edit {
  margin: 17px 17px 0 17px;
}
.app-mobility-data-bar-edit .app-bar-text {
  white-space: pre;
  padding-left: 30px;
}
.app-mobility-data-bar-edit .app-bar-table {
  justify-content: left;
  height: 70px;
}

.app-ad-hoc-creation-step-container {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  padding: 15px;
}

.app-ad-hoc-creation-data-labels {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.app-ad-hoc-creation-data-labels.bold {
  font-weight: bold;
}

.app-ad-hoc-creation-field-header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.app-ad-hoc-creation-geocoder-container {
  display: flex;
  flex-flow: column;
  background-color: #F9F9FC;
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  margin-bottom: 10px;
}

.app-ad-hoc-creation-geocoder-row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 10px;
}

.app-ad-hoc-right-aligned-button-container {
  float: inline-end;
}

.app-ad-hoc-columns-table-container {
  border: 1px solid #E1E1E1;
  display: flex;
  flex-grow: 1;
  border-radius: 8px;
  background-color: #F9F9FC;
  overflow-y: auto;
  margin-top: 10px;
  height: 0;
}
.app-ad-hoc-columns-table-container .dx-list-item-after-bag {
  display: none;
}

.app-ad-hoc-columns-table-empty {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #565656;
  text-align: center;
  margin: auto;
}

.app-ad-hoc-columns-table {
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  padding: 15px;
  background: #F4F5F9;
}
.app-ad-hoc-columns-table:nth-child(2n+1) {
  border: none;
  background: #FFFFFF;
}

.app-ad-hoc-columns-table-column {
  padding: 0 3px;
}
.app-ad-hoc-columns-table-column .app-bar-container {
  margin: 0;
}
.app-ad-hoc-columns-table-column .app-option-selector {
  margin: 0px;
}
.app-ad-hoc-columns-table-column .app-bar {
  padding: 0px;
  padding-left: 10px;
  margin-top: 5px;
}

.app-ad-hoc-columns-bar-button {
  width: 165px;
  margin: 0;
}

.app-ad-hoc-column-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
}
.app-ad-hoc-column-actions .app-button-container {
  margin-right: 5px;
}

.app-ad-hoc-column-move {
  width: 25px;
  height: 40px;
  border-width: 0;
  background: transparent;
  cursor: grab;
}

.app-ad-hoc-creation-step3-textbox {
  width: 400px;
}

.app-ad-hoc-creation-step3-dropdown {
  width: 400px;
}

.app-ad-hoc-creation-review-block {
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  padding: 15px;
  margin: 15px;
  background: #F4F5F9;
  height: 40px;
  width: 300px;
}

.app-ad-hoc-creation-review-table {
  width: 600px;
}

.app-ad-hoc-import-container {
  padding: 15px;
  display: flex;
  flex-flow: column;
}

.app-ad-hoc-spatial-import-dropdown {
  padding-right: 10px;
}

.app-ad-hoc-spatial-import-textbox {
  padding-right: 10px;
}

.app-ad-hoc-spatial-import-textarea {
  padding-top: 10px;
  padding-bottom: 10px;
}

.app-ad-hoc-manager-header {
  padding: 2px;
}

.app-ad-hoc-manager-header .app-button-menu-container {
  float: inline-end;
  margin-right: 5px;
  margin-top: 2px;
}

.app-ad-hoc-manager-header .app-description {
  float: inline-start;
  width: 825px;
  text-align: left;
  padding: 5px 5px;
  margin: 5px;
}

.app-tools-bulk-container {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  flex-flow: column;
  width: 808px;
  height: 100%;
}
.app-tools-bulk-container::-webkit-scrollbar {
  width: 0px;
}
.app-tools-bulk-container::-webkit-scrollbar-track {
  background: transparent;
}
.app-tools-bulk-container::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-tools-bulk-container::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.app-tools-bulk-container .app-description {
  padding: 10px;
  margin: 20px 17px 0 17px;
}

.app-tools-bulk-divided-container {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  flex-flow: row;
  width: 850px;
  height: 100%;
}
.app-tools-bulk-divided-container .app-uploader-container {
  padding: 17px;
  width: 370px;
  height: 200px;
}
.app-tools-bulk-divided-container .app-uploader-container .app-uploader-file-region {
  padding: 8px;
  text-align: center;
}

.app-tools-bulk-divided-container-wide {
  width: 1104px;
}

.app-tools-bulk-container-top {
  padding-top: 20px;
}

.app-tools-bulk-divided-column {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  flex-flow: column;
  width: 430px;
  height: calc(100% - 100px);
}
.app-tools-bulk-divided-column::-webkit-scrollbar {
  width: 0px;
}
.app-tools-bulk-divided-column::-webkit-scrollbar-track {
  background: transparent;
}
.app-tools-bulk-divided-column::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-tools-bulk-divided-column::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-tools-bulk-divided-column-wide {
  width: 704px;
}

.app-tools-bulk-vertical-line {
  border-left: 1px solid #E1E1E1;
  height: 95%;
  margin: 17px 0 17px 0;
}

.app-tools-bulk-rows {
  display: flex;
  flex-flow: row;
}

.app-tools-bulk-columns {
  display: flex;
  flex-flow: column;
}
.app-tools-bulk-columns .app-selectable-items-selected {
  max-height: none;
}
.app-tools-bulk-columns .app-selectable-items-body {
  margin: 3px;
}
.app-tools-bulk-columns .app-selectable-items-body .app-option-selector-icon {
  display: flex;
  justify-content: center;
}

.app-tools-bulk-file-uploader {
  border-radius: 4px;
  margin: 17px 17px 0 17px;
}

.app-tools-bulk-labels {
  margin: 17px 17px 0 17px;
}

.app-tools-bulk-sub-labels {
  margin: 0 17px 17px 17px;
}

.app-tools-bulk-note {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  margin: 5px 17px 0 17px;
}

.app-tools-bulk-warning {
  color: #902153;
}

.app-tools-bulk-text-input {
  margin: 10px 17px 10px 17px;
}

.app-tools-bulk-selectable-items {
  padding: 10px;
}

.app-tools-bulk-bar-long {
  width: 370px;
}

.app-tools-bulk-bar-medlong {
  width: 302px;
}

.app-tools-bulk-bar-medium {
  width: 235px;
}

.app-tools-bulk-bar-short {
  width: 166px;
}

.app-tools-bulk-bar-buttons .app-bar-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.app-tools-bulk-bar-buttons .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
}
.app-tools-bulk-bar-buttons .app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-tools-bulk-bar-buttons .app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-tools-bulk-download_button {
  color: #565656;
  margin: 0 17px;
}
.app-tools-bulk-download_button .app-button-container {
  text-align: left;
}

.app-tools-bulk-bar-edit {
  margin: 17px 17px 0 17px;
}
.app-tools-bulk-bar-edit .app-bar-text {
  white-space: pre;
  padding-left: 30px;
}
.app-tools-bulk-bar-edit .app-bar-table {
  justify-content: left;
  height: 70px;
}

.app-tools-bulk-drop-down-icon-item {
  padding-right: 5px;
}

.app-tools-bulk-drop-down-icon-field {
  padding-left: 10px;
  flex-grow: 0;
}

.app-tools-bulk-trade-areas {
  margin: 17px 17px 0 17px;
  width: 100%;
}
.app-tools-bulk-trade-areas .app-trade-area-type {
  width: 115px;
}
.app-tools-bulk-trade-areas .app-trade-area-actions {
  margin-left: 8px;
}

.app-tools-bulk-trade-areas-add {
  margin: 17px 17px 0 0;
}

.app-geo-feeds-creation-wizard-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 15px;
  display: flex;
  flex-flow: column;
}
.app-geo-feeds-creation-wizard-container .app-description {
  padding: 10px;
  margin-top: 20px;
  text-align: left;
  margin-bottom: 15px;
}

.app-geo-feeds-creation-wizard-bar-buttons .app-bar-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.app-geo-feeds-creation-wizard-bar-buttons .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
}
.app-geo-feeds-creation-wizard-bar-buttons .app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-geo-feeds-creation-wizard-bar-buttons .app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-geo-feeds-creation-wizard-bar-long {
  width: 150px;
}

.app-geo-feeds-creation-wizard-source-type-icon {
  display: flex;
  align-items: center;
}

.app-geo-feeds-creation-wizard-source-type {
  padding-top: 5px;
  color: #565656;
  width: 25px;
  height: 25px;
}

.app-geo-feeds-creation-wizard-photo-upload {
  height: 350px;
}

.app-geo-feeds-creation-wizard-sources {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-geo-feeds-creation-wizard-sources::-webkit-scrollbar {
  width: 0px;
}
.app-geo-feeds-creation-wizard-sources::-webkit-scrollbar-track {
  background: transparent;
}
.app-geo-feeds-creation-wizard-sources::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-geo-feeds-creation-wizard-sources::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.app-geo-feeds-creation-wizard-sources .app-geo-feeds-creation-wizard-source-row {
  display: flex;
  flex-flow: row;
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  background-color: #FFFFFF;
  padding: 10px;
}

.app-geo-feeds-creation-wizard-add {
  margin: 15px;
}

.app-geo-feeds-creation-wizard-text {
  display: flex;
  align-items: center;
}

.app-geo-feeds-creation-wizard-actions {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.app-geo-feeds-creation-wizard-actions .app-button {
  padding-left: 5px;
}

.app-geo-feeds-creation-wizard-row {
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  background-color: #FFFFFF;
  padding: 10px;
}

.app-geo-feeds-creation-wizard-name {
  margin: 5px 15px;
  width: 300px;
}

.app-geo-feeds-creation-wizard-url {
  margin: 5px 15px;
  width: 350px;
}

.app-geo-feeds-creation-wizard-empty {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.app-geo-feeds-creation-wizard-rows {
  display: flex;
  flex-flow: row;
}

.app-geo-feeds-creation-wizard-geo-feed-name {
  margin: 5px 15px;
  width: 500px;
}

.app-geo-feeds-creation-wizard-source-overlay-type {
  padding: 10px 0px;
  margin-left: 15px;
}

.app-geo-feeds {
  margin: 15px;
}

.app-geo-feeds-header {
  padding: 15px;
}

.app-large-panel-tool-bar {
  height: 70px;
}

.app-geo-feeds-instructions {
  float: left;
  width: 900px;
}

.app-geo-feeds-actions {
  float: right;
}

.app-geo-feeds-divided-container {
  display: flex;
  flex-flow: row;
}

.app-geo-feeds-divided-left-column {
  display: flex;
  flex-flow: column;
}

.app-geo-feeds-divided-column {
  display: flex;
  flex-flow: column;
}

.app-geo-feeds-column-text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.app-geo-feeds-user-image {
  border-radius: 100px;
  margin-top: -2px;
  width: 30px;
  height: 30px;
}

.app-geo-feeds-username {
  padding-left: 10px;
}

.app-map-book-manager-list-item {
  display: flex;
  justify-content: space-between;
}
.app-map-book-manager-list-item .app-button {
  color: #565656;
}

.app-map-book-manager-list-name-container {
  display: flex;
  flex-flow: row;
}

.app-map-book-icon {
  color: #565656;
}

.app-map-book-manager-list-name {
  padding-left: 10px;
}

.app-map-book-manager-filter-drop-down {
  width: 224px;
}

.app-map-book-manager-header-button {
  margin-top: 7px;
}

.app-map-book-manager-tool-bar {
  display: flex;
  justify-content: space-between;
  padding: 2px;
}

.app-map-book-manager-panel .app-panel-body {
  overflow: visible;
}
.app-map-book-manager-panel .app-panel-body .app-map-book-manager-list .dx-scrollable-container {
  overflow: visible;
}
.app-map-book-manager-panel .app-panel-body .app-map-book-manager-list .dx-scrollable-container .dx-list-item {
  overflow: visible;
}
.app-map-book-manager-panel .app-panel-body .app-map-book-manager-list .dx-scrollable-container .dx-list-item-content {
  overflow: visible;
}

.app-map-book-error-container {
  margin: 10px;
}

.app-map-book-error-title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  padding-bottom: 10px;
}

.app-map-book-error-description {
  text-align: left;
  margin-bottom: 10px;
}

.app-map-book-error-modal {
  background: rgba(125, 125, 125, 0.3);
  width: 500px;
  height: 100%;
  position: absolute;
}
.app-map-book-error-modal .app-modal-card {
  width: 460px;
  height: 500px;
  top: 18%;
}
.app-map-book-error-modal .app-modal-card .app-card-body {
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
  border: 1px solid #CCC;
  height: 495px;
}

.app-map-book-error-list-container {
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-map-book-error-list-container::-webkit-scrollbar {
  width: 0px;
}
.app-map-book-error-list-container::-webkit-scrollbar-track {
  background: transparent;
}
.app-map-book-error-list-container::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-map-book-error-list-container::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-map-book-error-list {
  height: 300px;
}

.app-map-book-error-mid {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding-bottom: 5px;
}

.app-map-book-error-1st {
  padding-bottom: 5px;
}

.app-map-book-error-2nd {
  margin-left: 20px;
  padding-bottom: 5px;
}

.app-map-book-error-warning {
  color: #565656;
  margin-left: 20px;
  margin-right: 10px;
}

.app-map-book-error-actions {
  margin: 20px 0px;
  float: right;
}

.app-map-book-error-error-pages-container {
  margin-top: 10px;
}

.app-map-book-container {
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  height: calc(100% - 135px);
}

.app-map-book-builder-panel-container {
  display: flex;
  flex-flow: column;
}

.app-map-book-adhoc-map-panel-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.app-map-book-builder-content-defintion-container {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.app-map-book-builder-panel-row {
  display: flex;
  flex-flow: row;
  margin: 7px 12px 7px 12px;
}

.app-map-book-builder-panel-stretch {
  flex-grow: 2;
}

.app-map-book-builder-row-button {
  margin-top: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  border-radius: 4px;
  flex-grow: 2;
}
.app-map-book-builder-row-button:hover {
  border-color: #5AB88D !important;
}

.app-map-book-builder-pages-container {
  display: flex;
  flex-flow: column;
}

.app-map-book-pages-header {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.app-map-book-builder-button-container {
  margin: 15px 0 0 0;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  border-bottom: 1px solid #E1E1E1;
  padding: 0 20px 15px 20px;
}

.app-map-book-builder-action-button {
  margin-top: 5px;
  margin-left: 15px;
}

.app-map-book-builder-button {
  margin-top: 5px;
  margin-left: 5px;
}

.app-map-book-builder-page-move {
  cursor: grab;
}

.app-map-book-builder-pages-table {
  widtH: 100%;
}

.app-map-book-builder-pages-container .dx-list-item-after-bag {
  display: none;
}

.app-map-book-builder-pages-container .dx-list-item {
  min-height: 285px;
}

.app-reports-point-detail-trade-area {
  display: inline-block;
  width: 210px;
  margin-left: 10px;
}

.app-reports-point-detail-trade-area-header {
  background-color: #F4F5F9;
  border-top: 1px solid #898989;
  border-bottom: 1px solid #898989;
  padding: 2px 2px 7px 2px;
}

.app-reports-point-detail-output {
  display: inline-block;
  width: 170px;
  margin-left: 10px;
}

.app-reports-point-detail-name {
  display: inline-block;
  width: 305px;
  margin-left: 10px;
}

.app-reports-point-detail-row-delete {
  display: inline-block;
  margin-left: 5px;
}
.app-reports-point-detail-row-delete .app-button-icon {
  font-size: 20px !important;
}

.app-reports-point-detail-actions {
  padding-left: 10px;
}

.app-reports-point-add {
  float: right;
  margin: 10px;
}

.app-reports-point-actions {
  display: flex;
  align-items: center;
}

.app-reports-point-actions-buttons {
  position: absolute;
}

.app-reports-point-actions-button {
  margin-left: 5px;
  color: #565656;
}

.app-reports-point-detail-empty {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #565656;
  text-align: center;
  margin: auto;
}

.app-reports-point-detail-row {
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  background-color: #FFFFFF;
  padding: 7px;
}
.app-reports-point-detail-row:nth-child(2n+1) {
  border: none;
  background-color: #F9F9FC;
}
.app-reports-point-detail-row:nth-child(2n+1) .app-reports-point-detail-job-label {
  background: #F9F9FC !important;
}
.app-reports-point-detail-row .app-button-icon {
  color: #565656;
}
.app-reports-point-detail-row .app-reports-point-detail-trade-area {
  width: 285px;
  margin: 0;
}
.app-reports-point-detail-row .app-reports-point-detail-output {
  width: 285px;
  margin: 0;
}
.app-reports-point-detail-row .app-bar-body {
  padding: 7px;
}

.app-reports-point-detail-row-advanced {
  margin: 7px 0 0 0 !important;
  width: 300px;
  display: inline-block;
}

.app-reports-point-detail-row-void {
  margin: 7px 0 0 7px !important;
  width: 300px;
  display: inline-block;
}

.app-reports-point-detail-actions {
  display: inline-block;
}
.app-reports-point-detail-actions .app-button {
  height: 44px;
  line-height: 44px;
}

.app-reports-point-detail-actions-primary {
  display: inline-block;
  margin-left: 7px;
}
.app-reports-point-detail-actions-primary .app-button {
  height: 47px;
  line-height: 47px;
}

.app-reports-point-detail-query {
  display: inline-block;
  width: 300px;
}

.app-reports-point-detail-report {
  display: inline-block;
  width: 300px;
  margin-left: 7px;
}

.app-reports-point-detail-report-name {
  display: inline-block;
  width: 350px;
  margin-left: 7px;
}

.app-reports-point-detail-job {
  display: inline-block;
  position: relative;
  border: 1px solid #565656;
  border-radius: 4px;
  margin-left: 10px;
  height: 45px;
  width: 55px;
  top: 0px;
  text-align: center;
}
.app-reports-point-detail-job .app-reports-point-detail-job-label {
  position: absolute;
  top: -8px;
  left: 8px;
  color: #000;
  font-size: 12px;
  background: #FFF;
}
.app-reports-point-detail-job .app-reports-point-detail-job-color {
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 4px;
  border: 1px solid #000;
  background-color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  height: 26px;
  width: 26px;
}
.app-reports-point-detail-job .app-reports-point-detail-job-text {
  position: absolute;
  top: 5px;
  left: 40px;
  border-radius: 4px;
  border: 1px solid #000;
  background-color: #000;
  text-align: center;
  color: #FFF;
  display: inline-block;
  height: 26px;
  width: 26px;
  line-height: 26px;
}

.app-reports-point-detail-job-select {
  padding-top: 13px;
}

.app-reports-point-detail-body {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  height: 414px;
  background-color: #F9F9FC;
  border: 1px solid #898989;
  margin: 0 10px 20px 10px;
}
.app-reports-point-detail-body::-webkit-scrollbar {
  width: 13px;
}
.app-reports-point-detail-body::-webkit-scrollbar-track {
  background: #F4F5F9;
}
.app-reports-point-detail-body::-webkit-scrollbar-thumb {
  background: #EAEAF3;
  cursor: pointer;
}
.app-reports-point-detail-body::-webkit-scrollbar-thumb:hover {
  background: #DFE0EE;
}

.app-reports-point-detail-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-reports-point-detail-content::-webkit-scrollbar {
  width: 0px;
}
.app-reports-point-detail-content::-webkit-scrollbar-track {
  background: transparent;
}
.app-reports-point-detail-content::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-reports-point-detail-content::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-reports-point-detail-void-aggregate,
.app-reports-point-detail-void-measurement {
  margin-left: 5px;
}

.app-reports-point-detail-body-null {
  height: 100%;
  display: flex;
  flex-flow: column;
  background: #F4F5F9;
  border: 1px solid #CCC;
  border-radius: 5px;
  padding: 5px;
  margin: 15px;
}

.app-reports-point-detail-body-empty {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #666666;
  text-align: center;
  margin: auto;
}

.app-trip-2-trade {
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 100%;
}

.app-trip-2-trade-header {
  border-bottom: 1px solid #E1E1E1;
  padding: 10px 16px;
}

.app-trip-2-trade-job-container {
  padding: 15px;
}

.app-trip-2-trade-renderers-empty {
  height: 100%;
  display: flex;
  flex-flow: column;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #666666;
  text-align: center;
  background: #F9F9FC;
  margin: 10px;
  border: 1px solid #CCC;
  justify-content: center;
  align-items: center;
}

.app-trip-2-trade-renderers-search {
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 535px;
  display: inline-block;
}

.app-trip-2-trade-renderers-row {
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  background-color: #FFFFFF;
  padding: 2px 0px 10px 10px;
}
.app-trip-2-trade-renderers-row:nth-child(2n+1) {
  border: none;
  background-color: #F9F9FC;
}
.app-trip-2-trade-renderers-row .app-button-icon {
  color: #565656;
}

.app-trip-2-trade-renderers-name {
  display: inline-block;
  width: 450px;
  padding-left: 20px;
}

.app-trip-2-trade-renderers-icon {
  padding-left: 20px;
  color: #565656;
}

.app-trip-2-trade-actions-button {
  padding-left: 15px;
  float: right;
}

.app-trip-2-trade-renderers-actions {
  padding-left: 30px;
}

.app-trip-2-trade-renderers-actions-buttons {
  position: absolute;
  padding-right: 10px;
}

.app-trip-2-trade-renderers-actions-button {
  padding-left: 10px;
  top: -10px;
  color: #565656;
}

.app-trip-2-trade-actions-logo {
  float: left;
}

.app-trip-2-trade-job-detail {
  width: 100%;
}

.app-trip-2-trade-job-detail-layout {
  display: table;
  list-style: none;
  padding-left: 0;
}

.app-trip-2-trade-job-status {
  position: relative;
  top: -50px;
}

.app-trip-2-trade-job-detail-layout > li {
  display: table-row;
}

.app-trip-2-trade-job-detail-layout > li > * {
  display: table-cell;
  padding: 5px;
}

.app-trip-2-trade-job-detail-layout label {
  font-weight: bold;
  text-align: right;
}

.app-trip-2-trade-job-detail-layout label:after {
  content: ":";
}

.app-trip-2-trade-job-header {
  border-bottom: 1px solid #E1E1E1;
}

.app-trip-2-trade-job-actions {
  float: right;
  padding: 10px 16px;
}
.app-trip-2-trade-job-actions .app-button {
  margin-left: 10px;
}

.app-trip-2-trade-job-actions-label {
  float: left;
}

.app-trip-2-trade-body {
  padding: 15px;
}

.app-trip-2-trade-name {
  padding-bottom: 10px;
}

.app-trip-2-trade-description {
  padding-bottom: 10px;
}

.app-trip-2-trade-radius-number {
  display: inline-block;
  width: 237px;
  padding-bottom: 10px;
}

.app-trip-2-trade-radius-radius-units {
  display: inline-block;
  width: 230px;
  padding-left: 15px;
}

.app-trip-2-trade-trip-type {
  display: inline-block;
  width: 230px;
  padding-left: 10px;
}

.app-trip-2-trade-renderer-action {
  display: flex;
  justify-content: space-between;
}

.app-trip-2-trade-renderers-label {
  padding-top: 24px;
}

.app-trip-2-trade-job-status-actions {
  padding-left: 15px;
}

.app-trip-2-trade-body {
  padding: 10px 25px;
}

.app-trip-2-trade-name {
  padding-bottom: 10px;
}

.app-trip-2-trade-renderer-wizard-name {
  display: inline-block;
  width: 695px;
}

.app-trip-2-trade-renderer-wizard-description {
  width: 695px;
}

.app-trip-2-trade-renderer-wizard-provider-type {
  display: inline-block;
  width: 695px;
}

.app-trip-2-trade-renderer-wizard-grid-type {
  display: inline-block;
  width: 250px;
}

.app-trip-2-trade-renderer-wizard-grid-size {
  display: inline-block;
  width: 435px;
  margin-left: 10px;
}

.app-trip-2-trade-renderer-wizard-visualization-type {
  display: inline-block;
  width: 695px;
}

.app-trip-2-trade-renderer-class-breaks-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
  padding: 15px;
}
.app-trip-2-trade-renderer-class-breaks-body::-webkit-scrollbar {
  width: 0px;
}
.app-trip-2-trade-renderer-class-breaks-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-trip-2-trade-renderer-class-breaks-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-trip-2-trade-renderer-class-breaks-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-trip-2-trade-renderer-class-breaks-add {
  float: right;
  padding: 10px;
}

.app-trip-2-trade-renderer-class-breaks-percentile {
  display: inline-block;
  width: 250px;
  padding-left: 45px;
}

.app-trip-2-trade-renderer-class-breaks-color {
  display: inline-block;
  width: 300px;
  padding-left: 15px;
}

.app-trip-2-trade-renderer-class-breaks-action {
  padding-left: 25px;
}

.app-trip-2-trade-renderer-wizard-filter-type {
  width: 720px;
}

.app-trip-2-trade-renderer-wizard-filter-limit {
  display: inline-block;
  width: 610px;
}

.app-trip-2-trade-renderer-wizard-filter-limit-units {
  display: inline-block;
  width: 100px;
}

.app-trip-2-trade-renderer-wizard-day-parts {
  width: 720px;
}

.app-trip-2-trade .app-large-panel {
  width: 100%;
}

.app-trip-2-trade-renderer-day-parts-body {
  width: 700px;
  height: 400px;
  overflow-y: scroll;
  padding-bottom: 75px !important;
}
.app-trip-2-trade-renderer-day-parts-body::-webkit-scrollbar {
  width: 0px;
}
.app-trip-2-trade-renderer-day-parts-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-trip-2-trade-renderer-day-parts-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-trip-2-trade-renderer-day-parts-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-trip-2-trade-renderer-day-parts-scheduler {
  padding: 10px 10px 10px 50px;
}

.app-projections {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.app-projections-toolbar {
  border-bottom: 1px solid #CCC;
  padding-left: 5px;
  padding-bottom: 9px;
  padding-top: 5px;
}
.app-projections-toolbar .app-projections-toolbar-action {
  height: 28px;
  width: 28px;
  line-height: 28px;
  margin-right: 5px;
}
.app-projections-toolbar .app-projections-toolbar-action.app-button-primary {
  height: 30px;
  width: 30px;
  line-height: 30px;
}
.app-projections-toolbar .app-projections-toolbar-action.app-projections-toolbar-action-dropdown {
  width: 32px;
}
.app-projections-toolbar .app-projections-toolbar-execute {
  height: 28px;
  width: 28px;
  line-height: 28px;
}
.app-projections-toolbar .app-projections-toolbar-execute.app-button-primary {
  height: 30px;
  width: 30px;
  line-height: 30px;
}
.app-projections-toolbar .app-projections-toolbar-execute .app-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid #CCC;
}
.app-projections-toolbar .app-projections-toolbar-execute-load {
  height: 28px;
  width: 32px;
  line-height: 28px;
  margin-right: 5px;
}
.app-projections-toolbar .app-projections-toolbar-execute-load.app-button-primary {
  height: 30px;
  width: 30px;
  line-height: 30px;
}
.app-projections-toolbar .app-projections-toolbar-execute-load .app-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.app-projections-toolbar .app-projections-dropdown {
  display: inline-block;
  margin-right: 5px;
}
.app-projections-toolbar .app-option-selector-small {
  margin-right: 5px;
}
.app-projections-toolbar .app-option-selector-small .app-bar {
  padding: 0px 7px;
  margin-top: 6px;
}
.app-projections-toolbar .app-option-selector-small .app-option-selector-check {
  z-index: 1;
  right: -5px;
}
.app-projections-toolbar .app-projections-selection-tools {
  margin-right: 5px;
}
.app-projections-toolbar .app-projections-selection-tools .app-button-action {
  height: 28px;
  width: 28px;
  line-height: 28px;
}
.app-projections-toolbar .app-projections-selection-tools .app-radio-button:first-child .app-button {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.app-projections-toolbar .app-projections-selection-tools .app-radio-button:last-child .app-button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
}

.app-projections-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 10px;
  position: relative;
}

.app-standard-geography .app-projections-content {
  padding-bottom: 40px;
}

.app-proposed-site-header {
  border-bottom: 1px solid #E1E1E1;
  padding: 7px;
  text-align: right;
}
.app-proposed-site-header .app-button-container {
  margin-left: 7px;
}

.app-projections-geography-toggle.app-button-container {
  width: 100%;
  height: 34px;
  text-align: left;
}
.app-projections-geography-toggle.app-button-container .app-button {
  width: auto;
  padding: 5px;
  font-size: 16px;
}

.app-projections-geography-toggle-container {
  display: block;
  margin-bottom: 5px;
}

.app-projections-execute-job {
  margin-bottom: 10px;
  text-align: right;
}

.app-projections-edit-window .dx-popup-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 0;
}
.app-projections-edit-window .dx-popup-content-scrollable {
  overflow: visible;
}

.app-projections-save-shape-window .dx-popup-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 0px;
}
.app-projections-save-shape-window .app-radio-button-set {
  margin-left: 12px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.app-projections-save-shape-window .app-projections-save-shape-section-button {
  padding: 10px;
  border-bottom: 1px solid #CCC;
  text-align: right;
}
.app-projections-save-shape-window .app-projections-save-shape-section {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  padding: 7px 0;
}
.app-projections-save-shape-window .app-projections-save-shape-body {
  padding: 10px;
}
.app-projections-save-shape-window .dx-popup-content-scrollable {
  overflow: visible;
}

.app-projections-debug-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  margin: 10px;
}

.app-projections-scenarios {
  background: #F9F9FC;
  border-bottom: 1px solid #CCC;
  padding: 5px;
}
.app-projections-scenarios .app-button-container {
  margin-right: 5px;
}
.app-projections-scenarios .app-button-container.app-button-action {
  height: auto;
  width: auto;
}
.app-projections-scenarios .app-button-container .app-button {
  padding: 3px;
}
.app-projections-scenarios .app-button-container .app-button .app-button-text {
  margin-left: 2px;
}

.app-projections-scenario-name {
  display: inline-block;
  margin-left: 5px;
}

.app-projections-scenario-save {
  margin-bottom: 10px;
  text-align: right;
}
.app-projections-scenario-save .app-button {
  padding: 5px;
  text-align: left;
  margin-left: 5px;
}

.app-projections-polygon .app-projections-polygon-content {
  padding: 7px;
}
.app-projections-polygon .app-projections-polygon-content .app-simple-table {
  width: 100%;
}

.app-projections-polygon-generated .app-projections-define-trade-area {
  display: none;
}

.app-projections-define-trade-area {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  background: #FFFFFF;
  color: #565656;
  text-align: center;
  padding-top: 10%;
}

.app-bulk-info {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 10px;
}

.app-bulk-info-zoom {
  margin-left: 11px;
  margin-bottom: 2px;
}

.app-tools-custom-channels {
  margin: 10px;
}
.app-tools-custom-channels .dx-datagrid-nowrap {
  overflow: visible;
}
.app-tools-custom-channels .dx-datagrid-nowrap .dx-scrollable-container {
  overflow: visible;
}
.app-tools-custom-channels .dx-datagrid-nowrap .app-data-grid-column {
  overflow: visible;
}

.app-tools-custom-channels-action-table {
  width: 100%;
  color: #565656;
}

.app-tools-custom-channels {
  margin: 15px;
}

.app-tools-custom-channels-header {
  padding: 15px;
}

.app-large-panel-tool-bar {
  height: 70px;
}

.app-tools-custom-channels-instructions {
  float: left;
  width: 900px;
}

.app-tools-custom-channels-actions {
  float: right;
}

.app-tools-custom-channels-column-text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.app-tools-custom-channels-editor-medium-panel {
  max-width: 450px;
}

.app-tools-custom-channels-editor-button-container {
  margin: 15px 0 0 0;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  border-bottom: 1px solid #E1E1E1;
  padding: 0 20px 15px 20px;
}

.app-tools-custom-channels-editor-action-button {
  margin-top: 5px;
  margin-left: 15px;
}

.app-tools-custom-channels-editor-container {
  display: flex;
  flex-flow: column;
}

.app-tools-custom-channels-editor-panel-container {
  display: flex;
  flex-flow: column;
}

.app-tools-custom-channels-editor-panel-row {
  display: flex;
  flex-flow: row;
  margin: 7px 12px 7px 12px;
}

.app-tools-custom-channels-editor-panel-stretch {
  flex-grow: 2;
}

.app-custom-channels-selector-list,
.app-custom-chains-selector-list {
  height: 100%;
  display: flex;
  flex-flow: column;
  margin: 10px 0 20px;
  height: 88%;
}

.dx-selection.dx-row:not(.dx-row-focused) > td {
  border-color: transparent !important;
  background-color: transparent !important;
}

.app-save-map-title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  border-bottom: 1px solid #E1E1E1;
  color: #FFFFFF;
  background-color: #2B328C;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
  position: relative;
  padding: 15px;
}

.app-save-map {
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  width: 380px;
  height: 555px;
  position: absolute;
  top: 20%;
  left: 30%;
  z-index: 200;
}

.app-save-map-close {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-right: 11px;
  margin-top: 11px;
}

.app-save-map-rows {
  display: flex;
  flex-flow: row;
}

.app-save-map-actions-rows {
  margin-top: 20px;
  display: flex;
  flex-flow: row;
  justify-content: right;
}

.app-save-map-labels {
  margin: 10px 15px;
}

.app-save-map-inputs {
  margin: 5px 15px;
  width: 350px;
}

.app-save-map-button {
  margin: 15px 10px;
}

.app-option-selector {
  margin-left: 15px;
  margin-top: 15px;
}

.app-save-map-bar-buttons .app-bar-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.app-save-map-bar-buttons .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
}
.app-save-map-bar-buttons .app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-save-map-bar-buttons .app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-save-map-bar-short {
  width: 166px;
}

.app-projects-available-maps-body {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  height: 100%;
  padding: 10px;
}
.app-projects-available-maps-body::-webkit-scrollbar {
  width: 0px;
}
.app-projects-available-maps-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-projects-available-maps-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-projects-available-maps-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.app-projects-available-maps-body .app-option-selector-text {
  margin-top: 10px;
  width: 180px;
}

.app-projects-available-maps-date {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.5px;
  padding-top: 3px;
}

.app-projects-available-maps-container {
  border: 1px solid #E1E1E1;
  border-radius: 8px;
}

.app-projects-available-maps-add-folder {
  display: flex;
  flex-flow: row;
  justify-content: right;
  padding: 10px 10px 0px 10px;
}

.app-projects-available-maps-list-item {
  display: flex;
  justify-content: space-between;
}
.app-projects-available-maps-list-item .app-button {
  color: #565656;
  padding-left: 5px;
}

.app-projects-available-maps-map {
  border: 1px solid;
  border-color: #F4F5F9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.app-collapsible-section-content {
  padding-bottom: 20px;
}

.app-projects-available-maps-map-title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  padding: 10px 10px;
  width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-projects-available-maps-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin: 10px;
  color: #2B328C;
  text-overflow: ellipsis;
  width: 175px;
  overflow: hidden;
}

.app-projects-available-maps-actions {
  border-top: 1px solid #E1E1E1;
  background-color: #F4F5F9;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-projects-available-maps-actions .app-button {
  color: #565656;
  padding: 5px;
}

.app-projects-available-maps-actions-right-buttons {
  display: flex;
  margin-left: auto;
}
.app-projects-available-maps-actions-right-buttons .app-button-icon {
  font-size: 12px !important;
}

.app-projects-available-maps-map-actions-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 70px;
}
.app-projects-available-maps-map-actions-section .app-button {
  padding: 10px;
}

.app-projects-available-maps-rows-container {
  padding: 10px;
}

.app-projects-available-maps-header {
  border-bottom: 1px solid #E1E1E1;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
}

.app-projects-available-maps-sort-dropdown,
.app-projects-available-maps-search {
  padding-top: 5px;
}

.app-projects-available-maps-search-input {
  width: 330px;
}

.app-projects-available-maps-details-button {
  color: #565656;
}

.app-projects-available-maps-drag-item {
  cursor: grab;
  border-radius: 8px;
}

.app-projects-available-maps-modal-card-default {
  width: 100%;
  background: rgba(125, 125, 125, 0.3);
  overflow: visible;
}
.app-projects-available-maps-modal-card-default .app-modal-card-body {
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
  height: 190px;
  width: 400px;
  margin: auto;
  background: #FFFFFF;
  border: 1px solid #CCC;
  overflow: visible;
}

.app-projects-available-maps-usage-loader-container {
  height: 210px;
  top: 60px;
  position: absolute;
  background-color: #FFFFFF !important;
  border-radius: 8px;
}

.app-projects-available-maps-usage-header {
  border-bottom: 1px solid #E1E1E1;
  padding: 10px 20px;
  display: flex;
  flex-flow: row;
}

.app-projects-available-maps-usage-title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  flex-grow: 2;
}

.app-projects-available-maps-usage-actions .app-button {
  margin-left: 10px;
}

.app-projects-available-maps-usage {
  display: flex;
  flex-flow: column;
  background-color: #FFFFFF;
  padding: 10px;
}

.app-projects-available-maps-usage-layout {
  display: table;
  list-style: none;
  padding-left: 0;
}

.app-projects-available-maps-usage-layout > li {
  display: table-row;
}

.app-projects-available-maps-usage-layout > li > * {
  display: table-cell;
  padding: 5px;
}

.app-projects-available-maps-usage-layout label {
  font-weight: bold;
  text-align: right;
}

.app-projects-available-maps-usage-layout label:after {
  content: ":";
}

.app-projects-available-maps-folder-edit-header {
  text-align: right;
}
.app-projects-available-maps-folder-edit-header .app-button-container {
  margin-left: 7px;
}

.app-projects-available-maps-folder-edit-name {
  width: 410px;
}

.app-projects-available-maps-folder-edit-button {
  margin-top: 20px;
  float: right;
}

.app-job-management-pending {
  color: #2B328C;
}

.app-job-management-processing {
  animation: spin infinite 4s linear;
  color: #2B328C;
}

.app-job-management-completed {
  color: #5AB88D;
}

.app-job-management-completed_with_errors {
  color: #C1D42F;
}

.app-job-management-failed {
  color: #902153;
}

.app-job-management-mark-unread .app-button-icon {
  color: #2B328C;
}

.app-job-management-mark-read .app-button-icon {
  color: #E1E1E1;
}

.app-job-actions {
  text-align: right;
  padding: 10px 10px 0 10px;
}
.app-job-actions .app-job-management-action {
  margin-left: 10px;
}

.app-job-management-icon-wrapper {
  cursor: pointer;
  font-size: 22px;
  color: #565656;
}

.app-job-management-name-wrapper {
  white-space: wrap;
}

.app-job-management-action-table {
  width: 100%;
  color: #565656;
}

.app-jobs-panel .app-large-panel-container {
  height: calc(100% - 75px);
  max-height: calc(100% - 75px);
}
.app-jobs-panel .app-datagrid-container {
  height: calc(100% - 75px);
  max-height: calc(100% - 75px);
}
.app-jobs-panel .app-job-management {
  margin: 10px;
}
.app-jobs-panel .app-job-management .dx-datagrid-nowrap {
  overflow: hidden !important;
}
.app-jobs-panel .app-job-management .dx-datagrid-nowrap .dx-scrollable-container {
  overflow: hidden !important;
}

.app-projects-print-medium-panel {
  max-width: 620px;
}

.app-projects-print-header {
  border-bottom: 1px solid #E1E1E1;
  padding: 0 20px 0 20px;
}

.app-projects-print-body {
  overflow-y: scroll;
  padding-bottom: 75px !important;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  flex-flow: column;
  height: calc(100% - 135px);
  margin: 12px;
}
.app-projects-print-body::-webkit-scrollbar {
  width: 0px;
}
.app-projects-print-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-projects-print-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-projects-print-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.app-projects-print-panel-rows {
  display: flex;
  flex-flow: row;
  height: 100%;
}

.app-projects-print-rows {
  display: flex;
  flex-flow: row;
}
.app-projects-print-rows .app-option-selector {
  margin-top: 0;
}
.app-projects-print-rows .dx-colorbox {
  margin-top: 20px;
  margin-bottom: 5px;
}
.app-projects-print-rows .app-drag-and-drop-content {
  height: 230px;
}

.app-projects-print-content-rows {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.app-projects-print-selection-rows {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 10px;
}
.app-projects-print-selection-rows .dx-colorbox {
  margin-left: 25px;
  margin-right: 12px;
}
.app-projects-print-selection-rows .app-projects-print-labels {
  margin: 7px 0 0 22px;
}

.app-projects-print-centered-rows {
  margin: 0 0 15px 0;
  justify-content: center;
  align-items: center;
}

.app-projects-print-cols {
  display: flex;
  flex-flow: column;
  align-self: start;
}

.app-projects-print-zoom-cols {
  display: flex;
  flex-flow: column;
  align-self: start;
}
.app-projects-print-zoom-cols .app-option-selector {
  margin: 12px 0 0 0;
}

.app-projects-print-actions-rows {
  margin: 15px 0 0 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.app-projects-print-actions-rows .app-option-selector {
  margin-top: 0;
}
.app-projects-print-actions-rows .app-bar-container {
  margin: 0 7px 7px 7px;
}

.app-projects-print-adornment-actions-rows {
  margin: 20px 20px 0 0;
  display: flex;
  flex-flow: row;
  justify-content: right;
}

.app-projects-print-apply-actions-rows {
  margin: 10px 20px 0 0;
  display: flex;
  flex-flow: row;
  justify-content: right;
}

.app-projects-print-button {
  margin: 0 0 12px 15px;
}

.app-projects-print-small-button {
  margin: 5px 0 12px 15px;
}

.app-projects-print-apply-button {
  margin: 10px 5px 0 10px;
}

.app-projects-print-dnd-button {
  margin-left: 10PX;
}

.app-projects-print-labels {
  margin: 20px 12px 0 12px;
}

.app-projects-print-input {
  margin: 12px 12px 0 12px;
}

.app-projects-print-align-right {
  margin-left: auto;
}

.app-projects-print-stretch {
  flex-grow: 2;
}

.app-projects-print-error {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin: 22px 22px 7px 22px;
  color: #902153;
}

.app-projects-print-refresh-bar {
  width: 166px;
}

.app-projects-print-zoom-bar {
  width: 40px;
  margin: 7px 7px 0 0 !important;
}
.app-projects-print-zoom-bar .app-bar {
  padding: 5px 5px 5px 15px;
  justify-content: center;
  align-items: center;
}
.app-projects-print-zoom-bar .app-bar-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.app-projects-print-zoom-bar .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
}
.app-projects-print-zoom-bar .app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-projects-print-zoom-bar .app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-projects-print-bar-short {
  width: 200px;
}

.app-projects-print-bar-long {
  width: 300px;
}

.app-projects-print-bar-left .app-bar-text {
  text-align: left;
}

.app-projects-print-bar-center .app-bar-text {
  text-align: center;
}

.app-projects-print-bar-right .app-bar-text {
  text-align: right;
}

.app-projects-print-content-buttons .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
}
.app-projects-print-content-buttons .app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-projects-print-content-buttons .app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-projects-print-preview {
  max-height: 405px;
  margin: 5px 40px 15px 40px;
  border: 1px solid #E1E1E1;
  box-shadow: 10px 10px 21px 1px rgba(0, 0, 0, 0.4);
}

.app-projects-print-adornment-modal {
  background: rgba(125, 125, 125, 0.3);
  width: 620px;
  height: 100%;
  top: 75px;
  left: 0;
  position: absolute;
  overflow: visible;
}
.app-projects-print-adornment-modal .app-modal-card {
  width: 480px;
  height: 290px;
  top: 12%;
  left: 70px;
  overflow: visible;
}
.app-projects-print-adornment-modal .app-modal-card .app-modal-card-body {
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
  border: 1px solid #CCC;
  padding-bottom: 0 !important;
  overflow: visible;
}
.app-projects-print-adornment-modal .app-modal-card .app-modal-card-body .app-card {
  overflow: visible;
}
.app-projects-print-adornment-modal .app-modal-card .app-modal-card-body .app-card .app-card-body {
  padding-bottom: 0 !important;
  overflow: visible;
}

.app-projects-print-content-options {
  margin: 20px 12px 0 4px;
}

.app-projects-print-preview-point {
  display: none;
}

.app-projects-print-preview-point-subtext {
  color: #565656;
  font-style: italic;
}

.app-projects-print-textbox {
  width: 94px;
}

.app-projects-print-drag-container {
  margin: 12px 0 0 7px;
}

.app-multimedia-url-edit {
  flex-flow: column;
}

.app-multimedia-header {
  border-bottom: 1px solid #E1E1E1;
  padding: 0px 15px;
}

.app-multimedia-url-edit-button {
  margin-left: 15px;
  margin-bottom: 20px;
}

.app-multimedia-url-edit-rows {
  display: flex;
  flex-flow: row;
}

.app-multimedia-url-edit-actions-rows {
  margin-top: 20px;
  display: flex;
  flex-flow: row;
  justify-content: right;
}

.app-multimedia-url-edit-inputs {
  margin: 5px 15px;
  width: 260px;
}

.app-multimedia-url-edit-inputs-url {
  margin: 5px 15px;
  width: 720px;
}

.app-multimedia-url-edit-inputs-description {
  margin: 5px 15px;
  width: 720px;
}

.app-multimedia-details-edit {
  flex-flow: column;
}

.app-multimedia-header {
  border-bottom: 1px solid #E1E1E1;
  padding: 0px 15px;
}

.app-multimedia-details-edit-button {
  margin-left: 15px;
  margin-bottom: 20px;
}

.app-multimedia-details-edit-rows {
  display: flex;
  flex-flow: row;
}

.app-multimedia-details-edit-actions-rows {
  margin-top: 20px;
  display: flex;
  flex-flow: row;
  justify-content: right;
}

.app-multimedia-details-edit-body {
  padding: 15px;
}

.app-multimedia {
  margin: 10px;
}

.app-multimedia-instructions {
  margin: 10px;
  padding: 5px;
  text-align: left;
  height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.app-multimedia-instructions-note {
  float: left;
  padding-left: 10px;
  width: 550px;
}

.app-multimedia-actions {
  float: right;
  margin-right: 25px;
}

.app-multimedia-list-view-column-text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.app-multimedia-user-image {
  border-radius: 100px;
  margin-top: -2px;
  width: 30px;
  height: 30px;
}

.app-multimedia-username {
  padding-left: 10px;
}

.app-multimedia-file-name {
  padding-bottom: 8px;
}

.app-multimedia-photo-upload {
  height: 350px;
}

.app-multimedia-grid-detail-description {
  margin-bottom: 5px;
}

.app-multimedia-grid-detail-actions {
  padding-right: 10px;
  color: #565656;
}

.app-multimedia-grid-detail-delete-action {
  padding-right: 10px;
  position: absolute;
  color: #565656;
}

.app-shape-downloader-container {
  padding: 10px;
}

.app-shape-downloader-container > * + * {
  margin-top: 5px;
}

.app-selections-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  height: 490px;
}

.app-selections-actions {
  border-bottom: 1px solid #E1E1E1;
  padding: 10px;
  text-align: right;
  height: 60px;
}
.app-selections-actions .app-button-container {
  margin-left: 5px;
}
.app-selections-actions .app-selections-cancel {
  margin-right: 17px;
  margin-left: 0;
}

.app-selections {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 10px;
}

.app-selections-geographies {
  width: 225px;
}
.app-selections-geographies .app-button-container {
  margin-top: 10px;
  margin-right: 5px;
}

.app-map-book-runner-header {
  padding: 7px;
  display: flex;
  justify-content: space-between;
}

.app-map-book-runner-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
}

.app-map-book-runner-actions-button {
  padding-left: 15px;
  float: right;
}

.app-map-book-runner-map-book-actions-button {
  padding-left: 10px;
  top: -10px;
  float: right;
  color: #565656;
}

.app-map-book-runner-list-name {
  padding-left: 10px;
}

.app-map-book-runner-list-item {
  display: flex;
  justify-content: space-between;
}

.app-map-book-runner-textBox {
  padding: 7px;
}

.app-map-book-runner-list-container {
  padding: 7px;
  height: calc(100% - 100px);
  display: flex;
  flex-flow: column;
  position: relative;
}

.app-map-book-job-textBox {
  padding: 5px 15px;
}

.app-map-book-job-dropdown {
  padding: 5px 15px;
}

.app-map-book-job-point-selections {
  padding: 5px 15px;
}

.app-map-book-job-row-button {
  margin-right: 10px;
  margin-top: 10px;
  float: right;
}

.app-map-book-job-bar-buttons .app-bar-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.app-map-book-job-bar-buttons .app-clickable-active {
  background-color: #F3F6D5;
  color: #2B328C;
}
.app-map-book-job-bar-buttons .app-clickable-active.app-clickable-hovered {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.app-map-book-job-bar-buttons .app-clickable-active.app-clickable-pressed {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.app-map-book-job-bar-short {
  width: 166px;
}

.app-point-scenarios-title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  position: relative;
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #CCC;
}

.app-point-scenarios-close {
  float: right;
  margin-right: 10px;
}

.app-projections-scenario-name {
  font-size: 16px;
  margin-bottom: 5px;
}

.app-job-management {
  margin: 10px;
}
.app-job-management .dx-datagrid-nowrap {
  overflow: visible;
}
.app-job-management .dx-datagrid-nowrap .dx-scrollable-container {
  overflow: visible;
}
.app-job-management .dx-datagrid-nowrap .app-data-grid-column {
  overflow: visible;
}

.app-point-scenarios-create-button {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}

.app-point-scenarios-create .app-modal-card-body {
  width: 400px;
  height: 305px;
}
.app-point-scenarios-create .app-point-scenarios-create-title {
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  line-height: 38px;
  border: 1px solid #CCC;
}
.app-point-scenarios-create .app-point-scenarios-create-title .app-button-container {
  float: right;
  margin-left: 5px;
}
.app-point-scenarios-create .app-point-scenarios-create-body {
  padding: 10px;
}

.app-scenarios {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.app-scenarios .dx-filemanager {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.app-scenario-modal {
  width: 1060px;
  height: 96%;
}

.app-scenarios-search {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.app-scenarios-search .app-scenarios-search-title {
  background-color: #EAEAF3;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.18px;
  margin-bottom: 10px;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #CCC;
}
.app-scenarios-search .app-scenarios-search-title .app-scenarios-search-title-close {
  float: right;
}
.app-scenarios-search .app-scenarios-search-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding-top: 5px;
  margin: 5px;
  border: 1px solid #CCC;
  border-radius: 5px;
}
.app-scenarios-search .app-scenarios-search-body .dx-treelist-search-panel {
  margin-right: 10px;
  margin-top: 3px;
}

.dx-filemanager-toolbar {
  background: #F4F5F9 !important;
}
.dx-filemanager-toolbar .dx-toolbar {
  background: #F4F5F9 !important;
}

.dx-filemanager-items-panel .dx-filemanager-breadcrumbs {
  background: #F9F9FC;
  color: #FFFFFF;
}

.dx-filemanager .dx-filemanager-focused-item {
  background-color: #F3F6D5 !important;
}
.dx-filemanager .dx-state-hover {
  background-color: #E0E997 !important;
}

.app-filters-create-large-panel {
  z-index: 100;
}

.app-filters-create-header {
  margin: 10px 15px 0px 15px;
}
.app-filters-create-header .app-filters-create-instructions {
  float: left;
  width: 942px;
}
.app-filters-create-header .app-filters-create-actions {
  display: flex;
  flex-flow: row;
  justify-content: right;
}
.app-filters-create-header .app-filters-create-actions .app-button-container {
  margin: 0px 0px 0px 10px;
}

.app-filters-container {
  margin: 15px;
}

.app-filters-create-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 5px 15px 15px 15px;
}
.app-filters-create-container .app-filters-create-row {
  display: flex;
  flex-flow: row;
}
.app-filters-create-container .app-filters-create-row .app-filters-create-row-item {
  margin-top: 5px;
  width: 100%;
}
.app-filters-create-container .app-filters-create-tab-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  margin-top: 20px;
}
.app-filters-create-container .app-filters-create-tab-container .app-filters-create-filter-builder-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 10px;
  background-color: #E1E1E1;
  overflow: auto;
}
.app-filters-create-container .app-filters-create-tab-container .app-filters-create-filter-builder-container .dx-filterbuilder-group {
  background-color: #E1E1E1;
}
.app-filters-create-container .app-filters-create-tab-container .app-filters-create-filter-builder-container .dx-filterbuilder-group .dx-filterbuilder-item-value {
  background-color: #FFFFFF;
  color: #000000;
  font-style: italic;
}
.app-filters-create-container .app-filters-create-tab-container .app-filters-create-grid-container {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.app-filters-create-modal {
  width: 1060px;
  height: calc(100% - 85px);
}
.app-filters-create-modal .app-filters-create-title {
  background-color: #EAEAF3;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.18px;
  margin-bottom: 10px;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #CCC;
}
.app-filters-create-modal .app-filters-create-title .app-filters-create-title-close {
  float: right;
}

.app-filters-large-panel {
  z-index: 100;
}

.app-filters-header {
  margin: 10px 15px 0px 15px;
}
.app-filters-header .app-filters-instructions {
  float: left;
  width: 1002px;
}
.app-filters-header .app-filters-actions {
  display: flex;
  flex-flow: row;
  justify-content: right;
}
.app-filters-header .app-filters-actions .app-button-container {
  margin: 0px 0px 0px 10px;
}

.app-filters-divided-container {
  height: 100%;
  display: flex;
  flex-flow: column;
  display: flex;
  flex-flow: row;
  margin: 15px 0px 5px 0px;
}
.app-filters-divided-container .app-filters-divided-column {
  height: 100%;
  display: flex;
  flex-flow: column;
  display: flex;
  flex-flow: column;
}
.app-filters-divided-container .app-filters-divided-column.right {
  margin-right: 15px;
}
.app-filters-divided-container .app-filters-divided-column.right .dx-datagrid-expand .dx-datagrid-group-opened, .app-filters-divided-container .app-filters-divided-column.right .dx-datagrid-expand .dx-datagrid-group-closed {
  visibility: hidden;
}
.app-filters-divided-container .app-filters-divided-column.right .app-description-secondary {
  text-align: left;
}
.app-filters-divided-container .app-filters-divided-column.right .app-filters-button-container {
  margin: -10px 0px 0px 0px;
  position: fixed;
  z-index: 100;
}
.app-filters-divided-container .app-filters-column-text {
  text-overflow: ellipsis;
  overflow: hidden;
}
.app-filters-divided-container .app-filters-user-image {
  border-radius: 100px;
  margin-top: -2px;
  width: 30px;
  height: 30px;
}
.app-filters-divided-container .app-filters-username {
  padding-left: 10px;
}

.app-location-chat-shield {
  color: #5AB88D;
  margin-top: 10px;
  float: right;
  font-size: 26px;
  cursor: pointer;
  width: 215px;
}

.app-location-chat-shield-float {
  float: right;
  margin-right: 5px;
}

.app-location-chat-shield-table {
  width: 100%;
}

.app-location-chat-shield-text {
  font-size: 14px;
  line-height: 26px;
  margin-top: 5px;
  color: #5AB88D;
}

.app-location-chat-panel .app-loader-dot {
  background-color: #000000 !important;
}

.app-location-chat-button-disclaimer {
  margin-top: 3px;
  font-size: 14px;
  text-align: right;
}

.app-location-chat {
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 10px;
}
.app-location-chat .app-description {
  text-align: left;
}

.app-location-chat-button {
  margin: 5px 0;
  text-align: right;
}

.app-location-chat-body-null {
  height: 100%;
  display: flex;
  flex-flow: column;
  background: #F4F5F9;
  border: 1px solid #CCC;
  border-radius: 5px;
  padding: 5px;
}

.app-location-chat-body-empty {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #666666;
  text-align: center;
  margin: auto;
}

.app-location-chat-body {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  padding-bottom: 75px !important;
  height: 60%;
  border-bottom: 1px solid #CCC;
}
.app-location-chat-body::-webkit-scrollbar {
  width: 0px;
}
.app-location-chat-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-location-chat-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-location-chat-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.app-location-chat-body .app-simple-table {
  border-top: none;
}

.app-competitive-insights-report-object {
  padding: 10px 25px;
}

.app-competitive-insights-report-object-instructions {
  padding: 5px;
  text-align: left;
  overflow: hidden;
  display: inline-block;
  width: 525px;
}

.app-competitive-insights-report-object-actions {
  float: right;
  padding: 20px 8px;
}
.app-competitive-insights-report-object-actions .app-button {
  margin-left: 10px;
}

.app-competitive-insights-report-object-reason {
  display: inline-block;
  margin-top: 20px;
  width: 695px;
}

.app-competitive-insights-report-object-description {
  margin-top: 20px;
  width: 695px;
}

.app-reports-management-reports-criteria-container {
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  height: 100%;
}

.app-reports-management-reports-criteria-container-panel-container {
  display: flex;
  flex-flow: column;
}

.app-reports-management-reports-criteria-empty {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  color: #565656;
  text-align: center;
  margin: auto;
}

.management-reports-date-range {
  margin: 10px;
}

.app-reports-management-reports-criteria-item {
  margin: 10px;
}

.app-shared-users-modal-card {
  width: 100%;
  background: rgba(125, 125, 125, 0.3);
  overflow: visible;
  padding-top: 25px;
}
.app-shared-users-modal-card .app-modal-card-body {
  box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
  height: 600px;
  width: 700px;
  margin: auto;
  background: #FFFFFF;
  border: 1px solid #CCC;
  overflow: visible;
}

.app-shared-users-header {
  border-bottom: 1px solid #E1E1E1;
  height: 60px;
  padding: 10px 16px 10px 0;
}

.app-shared-users-title {
  float: left;
  padding-left: 5px;
  padding-top: 10px;
}

.app-shared-users-actions {
  float: right;
}
.app-shared-users-actions .app-button {
  margin-left: 10px;
}

.app-shared-users-actions-button {
  padding-left: 15px;
  padding-top: 5px;
}

.app-shared-users-container {
  padding: 10px;
}

.app-shared-users-rows {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.app-shared-users-dropdown {
  padding-top: 15px;
}

.app-shared-users-selected-label {
  margin-left: 10px;
}

.app-shared-users-selected-count {
  margin-left: 10px;
  line-height: 26px;
  vertical-align: middle;
}

.app-shared-peer-types-dropdown {
  padding-bottom: 10px;
  width: 150px;
}

.app-shared-users-user-image {
  border-radius: 100px;
  margin-top: -2px;
  width: 30px;
  height: 30px;
}

.app-shared-users-username {
  padding-left: 10px;
}

.app-notifications {
  border: 1px solid #898989;
  display: flex;
  flex-flow: column;
  background-color: #FFFFFF;
  position: absolute;
  width: 450px;
  height: 75%;
  border-radius: 8px;
  z-index: 102;
  top: 50px;
  right: 65px;
  border-width: 1px;
  border-style: solid;
}

.app-notification-container {
  display: flex;
  flex-flow: row;
  min-height: 95px;
}
.app-notification-container .app-clickable.app-clickable-hovered {
  background-color: #E0E997;
  box-shadow: none;
}

.app-notification-container-expand {
  height: auto;
}

.app-notifications-title-container {
  border-bottom: 1px solid #898989;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  background-color: #EAEAF3;
  color: #2B328C;
  height: 55px;
  line-height: 55px;
  padding-left: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-flow: row;
}

.app-notifications-title {
  text-align: left;
  flex-grow: 2;
}

.app-notifications-close-button {
  padding-right: 10px;
}

.app-notifications-actions {
  border-bottom: 1px solid #E1E1E1;
  display: flex;
  flex-flow: row;
  padding: 10px;
  justify-content: flex-end;
}

.app-notifications-filters {
  flex-grow: 2;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  align-content: center;
}

.app-notifications-mark-read {
  padding-right: 5px;
}

.app-notifications-body {
  display: flex;
  flex-flow: column;
  flex-grow: 2;
  overflow-y: scroll;
  padding-bottom: 75px !important;
  padding-bottom: 0 !important;
}
.app-notifications-body::-webkit-scrollbar {
  width: 0px;
}
.app-notifications-body::-webkit-scrollbar-track {
  background: transparent;
}
.app-notifications-body::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
}
.app-notifications-body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.app-notifications-body .dx-list-item.dx-state-focused {
  background-color: #F3F6D5 !important;
  color: #333 !important;
}
.app-notifications-body .dx-list-item-content {
  padding: 0 !important;
}

.app-notifications-footer {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  flex-grow: 2;
  align-content: center;
  padding-bottom: 5px;
}

.app-map-notification {
  display: flex;
  flex-flow: row;
  flex-grow: 2;
  margin: 0;
  padding: 15px;
}
.app-map-notification.app-clickable-hovered {
  background-color: #E0E997;
  box-shadow: none;
}

.app-notification-new {
  background-color: #DEF1E8;
}

.app-notification-viewed-container {
  padding: 35px 5px 0 5px;
  background-color: #F9F9FC;
}

.app-notification-announcement {
  width: 340px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.app-notification-button-badge {
  top: 5px;
  position: absolute;
  font-size: 16px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 100px;
  color: #FFFFFF;
  border: 1px solid #5AB88D;
  right: -7px;
  background: #5AB88D;
}

.app-report-manager-header {
  border-bottom: 1px solid #E1E1E1;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
}

.app-report-manager-search-input {
  width: 330px;
  margin-top: 5px;
}

.app-report-manager-cell-display {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.app-report-manager-days-left {
  color: #ff0000;
}

.app-analogs {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.app-analogs .dx-row .app-data-grid-column.focused,
.app-analogs .dx-row-alt .app-data-grid-column.focused {
  background-color: #F3F6D5 !important;
}
.app-analogs .dx-row .app-data-grid-column.highlight,
.app-analogs .dx-row-alt .app-data-grid-column.highlight {
  background-color: #DEF1E8 !important;
}

.app-analogs-toolbar {
  display: flex;
  flex-flow: row;
}

.app-analogs-template {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding: 12px 0 0 8px;
}

.app-analogs-inputs {
  flex-grow: 2;
  padding: 8px 8px 0 8px;
}

.app-analogs-outputs {
  padding: 8px 8px 0 0;
}

.app-analogs-format {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  padding: 0 8px 0 0;
}

.app-analogs-format-dropdown {
  width: 150px;
}

.app-analogs-zoom {
  margin-left: 4px;
  margin-bottom: 2px;
}

.app-analogs-popup-wrapper .dx-popup-content {
  padding: 0;
  overflow: hidden;
}

.app-analogs-popup-actions {
  border-bottom: 1px solid #E1E1E1;
  padding: 7px 10px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.app-analogs-popup-buttons {
  margin-left: 15px;
}

.app-analogs-popup-content {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  flex-flow: column;
  height: 90%;
  padding: 10px;
}

.app-analogs-popup-reason {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  margin-top: 20px;
  flex-grow: 2;
}
.app-analogs-popup-reason .dx-editor-outlined.dx-texteditor-with-label {
  margin-top: 0;
  padding-top: 5px;
}

.app-analogs-filter-recalculate {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-left: 16px;
}

.app-analogs-filters {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 8px;
  height: 100%;
}

.app-analogs-filter-items {
  display: flex;
  flex-flow: column;
  min-width: 400px;
}

.app-analogs-filter-row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.app-analogs-filter-row .app-button-action {
  margin: 5px;
}
.app-analogs-filter-row .app-legend-menu-item {
  flex-grow: 2;
}

.app-analogs-filter-details {
  display: flex;
  flex-flow: column;
  flex-grow: 2;
  padding: 8px;
}

.app-analogs-filter-details-content {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.app-analogs-filter-details-content .app-description {
  white-space: pre-wrap;
  text-align: left;
}

.app-analogs-filter-details-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  padding-bottom: 8px;
}

.app-analogs-filter-details-input {
  margin: 12px 4px 15px 4px;
}

.app-analogs-filter-details-datalist {
  height: 100%;
  display: flex;
  flex-flow: column;
  height: calc(75% - 50px);
  margin: 12px 0 12px 0;
}

.app-analogs-filter-changes-warning-icon {
  padding-right: 4px;
}

.app-analogs-filter-changes-error-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  padding-top: 16px;
  color: #ff0000;
}

.app-analogs-filter-changes-warning-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  padding-top: 16px;
  color: #ffa500;
}

.app-analogs-filter-changes-error-button .app-button {
  background-color: #ff0000;
}

.app-analogs-filter-changes-warning-button .app-button {
  background-color: #ffa500;
}

.app-analog-template-container {
  padding: 7px;
  display: flex;
  justify-content: space-between;
}

.app-analog-list-container {
  padding: 7px;
  flex: 1;
  display: flex;
  flex-flow: column;
  position: relative;
  height: 100%;
}

.app-analog-list-container .dx-datagrid {
  flex: 1; /* Allow the DataGrid to grow and fill the container */
  height: 100%; /* Ensure the DataGrid takes the full height */
}

.app-analog-list-item {
  display: flex;
  justify-content: space-between;
}
.app-analog-list-item .app-analog-list-name {
  padding-left: 10px;
}

.add-notes-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.add-notes-container .app-notes-container {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.add-notes-container .app-notes-container::-webkit-scrollbar {
  width: 0px;
}
.add-notes-container .app-notes-container .app-analog-note {
  margin-bottom: 3px;
}
.add-notes-container .app-notes-container .app-no-notes {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.add-notes-container .app-new-note-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-bottom: 10px;
}
.add-notes-container .app-new-note-container .app-new-note-input {
  margin: auto;
  width: 100%;
  padding-right: 5px;
}

.app-analog-note {
  display: flex;
  flex-flow: row;
  width: 100%;
  border: 1px solid #2B328C;
  border-radius: 3px;
  padding: 3px;
}

.app-analog-note-details {
  display: flex;
  flex-flow: column;
  margin-left: 10px;
  width: 100%;
}
.app-analog-note-details .app-analog-note-footer {
  display: flex;
  flex-flow: row;
}
.app-analog-note-details .app-analog-note-footer .app-analog-note-user-container {
  display: flex;
  align-items: flex-end;
}
.app-analog-note-details .app-analog-note-footer .app-analog-note-user-container .app-analog-note-userImage {
  background-image: url("https://cdn.tradeareasystems.net/Images/Components/UserBadge/DefaultUser96x96.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 1px solid #2B328C;
  margin-right: 5px;
}
.app-analog-note-details .app-analog-note-footer .app-analog-note-user-container .app-analog-note-user {
  font-weight: bold;
  color: #2B328C;
}
.app-analog-note-details .app-analog-note-footer .app-analog-note-date {
  color: #898989;
  font-size: 12px;
  padding-right: 3px;
}
.app-analog-note-details .app-analog-note-footer-right {
  margin-top: auto;
}

.app-analog-note-edit-input {
  padding-bottom: 3px;
}

.app-analog-note-display-text {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
}
.app-analog-note-display-text .app-analog-note-edit-input {
  width: 100%;
  padding-right: 5px;
}

.app-viewer-information {
  padding: 15px;
  font-size: 16px;
}
.app-viewer-information a {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #2B328C;
  margin-right: 5px;
}

.app-task-action-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.app-task-action-header .app-task-action-header-title {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
}

.app-task-action-container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  height: calc(100% - 50px);
}

.app-task-action-container-no-header {
  display: flex;
  flex-flow: row;
  justify-content: center;
  height: 100%;
}

.app-task-action-form {
  flex-grow: 2;
  border: 1px solid #E1E1E1;
}

.app-task-action-form-edit .app-form-body {
  height: calc(100% - 54px);
}

.app-task-action-map {
  margin: 10px;
  border: 1px solid #E1E1E1;
}

.app-element {
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
}

.app-label {
  font-weight: bold;
}

.app-empty-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  border: 1px solid #E1E1E1;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.18px;
  align-content: center;
  justify-content: center;
  background-color: #F9F9FC;
  color: #565656;
  text-align: center;
  margin: 10px;
}

#launcher {
  display: none;
}/*# sourceMappingURL=index.css.map */