.app-projects-print-medium-panel{
    max-width: 620px;
}

.app-projects-print-header{
    @include border-bottom-normal;
    padding: 0 20px 0 20px;
}
 
.app-projects-print-body{    
    @include vertical-scroll;
    @include text-bar;
    display: flex;
    flex-flow: column;
    height: calc(100% - 135px);     // use calc to help with scrolling in container with header
    margin: 12px;
}

.app-projects-print-panel-rows{    
    display: flex;
    flex-flow: row;
    height: 100%;
}

.app-projects-print-rows{    
    display: flex;
    flex-flow: row;

    .app-option-selector{
        margin-top: 0;
    }

    .dx-colorbox{
        margin-top: 20px;
        margin-bottom: 5px;
    }

    .app-drag-and-drop-content{
        height: 230px;
    }
}

.app-projects-print-content-rows{    
    display: flex;
    flex-flow: row;
    width: 100%;
}

.app-projects-print-selection-rows{    
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-top: 10px;

    .dx-colorbox{
        margin-left: 25px;
        margin-right: 12px;
    }

    .app-projects-print-labels{
        margin: 7px 0 0 22px;
    }
}

.app-projects-print-centered-rows{
    margin: 0 0 15px 0;
    justify-content: center;
    align-items: center;
}

.app-projects-print-cols{    
    display: flex;
    flex-flow: column;
    align-self: start;
}

.app-projects-print-zoom-cols{    
    display: flex;
    flex-flow: column;
    align-self: start;

    .app-option-selector{
        margin: 12px 0 0 0;
    }
}

.app-projects-print-actions-rows{
    margin: 15px 0 0 0;
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .app-option-selector{
        margin-top: 0;
    }

    .app-bar-container{
        margin: 0 7px 7px 7px;
    }
}

.app-projects-print-adornment-actions-rows{
    margin: 20px 20px 0 0;
    display: flex;
    flex-flow: row;
    justify-content: right;
}

.app-projects-print-apply-actions-rows{
    margin: 10px 20px 0 0;
    display: flex;
    flex-flow: row;
    justify-content: right;
}

.app-projects-print-button{
    margin: 0 0 12px 15px;
}

.app-projects-print-small-button{
    margin: 5px 0 12px 15px;
}

.app-projects-print-apply-button{
    margin: 10px 5px 0 10px;
}

.app-projects-print-dnd-button{
    margin-left: 10PX;
}

.app-projects-print-labels{
    margin: 20px 12px 0 12px;
}

.app-projects-print-input{
    margin: 12px 12px 0 12px;
}

.app-projects-print-align-right{
    margin-left: auto;
}

.app-projects-print-stretch{
    flex-grow: 2;
}

.app-projects-print-error{
    @include text-sub-header2;
    margin: 22px 22px 7px 22px;
    color: $color-septenary;
}

.app-projects-print-refresh-bar{    
    width: 166px;
}

.app-projects-print-zoom-bar{    
    width: 40px;
    margin: 7px 7px 0 0 !important;
    
    .app-bar{
        padding: 5px 5px 5px 15px;
        justify-content: center;
        align-items: center;
        //height: 30px;
    }

    .app-bar-table{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
    }

    .app-clickable-active{
        background-color: $color-tertiary20;
        color: $color-primary;    

        &.app-clickable-hovered{
            @include shadow-light;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }        
    }    
}

.app-projects-print-bar-short{    
    width: 200px;
}

.app-projects-print-bar-long{    
    width: 300px;
}

.app-projects-print-bar-left{    
    .app-bar-text{
        text-align: left;
    }
}

.app-projects-print-bar-center{    
    .app-bar-text{
        text-align: center;
    }
}

.app-projects-print-bar-right{    
    .app-bar-text{
        text-align: right;
    }
}

.app-projects-print-content-buttons{    

    .app-clickable-active{
        background-color: $color-tertiary20;
        color: $color-primary;    

        &.app-clickable-hovered{
            @include shadow-light;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }        
    }    
}

.app-projects-print-preview{
    max-height: 405px;
    margin: 5px 40px 15px 40px;
    border: 1px solid $color-light-gray;
    -webkit-box-shadow: 10px 10px 21px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: 10px 10px 21px 1px rgba(0,0,0,0.4);
    box-shadow: 10px 10px 21px 1px rgba(0,0,0,0.4);
}

.app-projects-print-adornment-modal{
    background: $color-translucent;
    width: 620px;       // assumes the panel is 620px wide
    height: 100%;
    top: 75px;
    left: 0;
    position: absolute;
    overflow: visible;

    .app-modal-card{
        width: 480px;
        height: 290px;
        top: 12%;
        left: 70px;    // assumes the modal is 620px wide and card is 480px wide
        overflow:visible;

        .app-modal-card-body {
            @include shadow-medium;
            background: $color-white;
            border: 1px solid #CCC;
            padding-bottom: 0 !important;
            overflow: visible;

            .app-card {
                overflow:visible;

                .app-card-body{
                    padding-bottom: 0 !important;
                    overflow: visible;
                }
            }
        }
    }
}

.app-projects-print-content-options{
    margin: 20px 12px 0 4px;
}

.app-projects-print-preview-point{
    display: none;      // hide the preview point button - selecting points is not part of the 2.0 workflow
}

.app-projects-print-preview-point-subtext{
    color: $color-dark-gray;
    font-style: italic;
}

.app-projects-print-textbox{
    width: 94px;
}

.app-projects-print-drag-container{
    margin: 12px 0 0 7px;
}