.app-color-swatch-container{
    @include border-normal($color-white);
    border-radius: $border-radius-light;

    &.app-clickable-active{
        @include border-normal($color-dark-gray);
        border-radius: $border-radius-light;
    }    
}

.app-color-swatch{
    @include border-normal();
    border-radius: $border-radius-light;
    margin: 5px;
    width: 24px;
    height: 24px;
}
