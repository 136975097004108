.app-task-detail{
    padding: 5px;
    display: flex;
    flex-flow: column;

    .app-task-detail-header {
        display: flex;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 5px;

        .app-task-detail-header-title {
            @include text-header3;
        }

        .app-task-detail-header-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .app-button-container:last-child{
                margin-left: 5px;
            }
        }
    }

    .app-task-detail-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .app-task-detail-content-row {
            display: flex;
            flex: 1;
            width: 100%
        }

        .app-task-detail-content-row-item{
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            padding: 5px;
        }
    }

    .app-task-detail-label {
        display: inline-flex;
        padding-right: 10px;
    }

    .app-task-detail-created-by { 
        display: inline-flex;
        padding-left: 5px;
        max-width: 145px;
        white-space: nowrap;
        overflow: hidden; 
    }
    
}