.app-dual-panel{
    @include fill-height;
    @include shadow-heavy;
    background-color: $color-white;
    width: 1000%;
    
    &.left-panel {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 500px;
    }

    &.right-panel {
        position: absolute;
        top: 0;
        left: 500px;
        border-left: 1px solid #ccc;
        background: $color-primary3;
    }
}

.app-dual-panel-title{
    @include text-header3;
    background-color: $color-primary10;
    text-align: center;
    padding: 15px 0;
    position: relative;    
}

.app-dual-panel-tool-bar{
    @include border-bottom-normal($color-light-gray);
    background-color: $color-primary5;
    height: 55px;
}

.app-dual-panel-body{
    @include fill-height;
}

.app-dual-panel-back{
    @include text-sub-header2;
    position: absolute;
    top: 20px;
    left: 20px;
    color: $color-primary;

    .app-dual-panel-back-link{
        @include default-link($color-primary);
    }
}

.app-dual-panel-back-icon{
    margin-right: 5px;
}

.app-dual-panel-container{
    position: relative;
    display:flex;
    flex-flow:column;
    flex-grow:1;
    height:0;
}