.app-analog-note{
    display: flex;
    flex-flow: row;
    width: 100%;
    border: 1px solid $color-primary;
    border-radius: 3px;
    padding: 3px;
}

.app-analog-note-details{
    display: flex;
    flex-flow: column;
    margin-left: 10px;
    width: 100%;

    .app-analog-note-footer{
        display:flex;
        flex-flow: row;

        .app-analog-note-user-container{
            display: flex;
            align-items: flex-end;

            .app-analog-note-userImage{
                @include default-user-image;
                border-radius: 50%;
                height: 20px;
                width: 20px;    
                border: 1px solid $color-primary;
                margin-right: 5px;
            }
        
            .app-analog-note-user{
                font-weight: bold;
                color: $color-primary;
            }
        }
    
        .app-analog-note-date{
            color: $color-gray;
            font-size: 12px;
            padding-right: 3px;
        }
    }
    .app-analog-note-footer-right{
        margin-top: auto;
    }   
}

.app-analog-note-edit-input{
    padding-bottom: 3px;
}

.app-analog-note-display-text{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;

    .app-analog-note-edit-input{
        width: 100%;
        padding-right: 5px;
    }
}