.app-large-panel{
    @include fill-height;
    @include shadow-medium;
    background-color: $color-white;
    width: 1000%;
    max-width: 1120px;
    position: absolute;
    top: 0;
    left: 0;
}

.app-large-panel-title{
    @include text-header3;
    background-color: $color-primary10;
    text-align: center;
    padding: 15px 0;
    position: relative;    
}

.app-large-panel-tool-bar{
    @include border-bottom-normal($color-light-gray);
    background-color: $color-primary5;
    height: 55px;
}

.app-large-panel-body{
    @include fill-height;
    height:0;
    position: relative;
    flex-grow:1;
}

.app-large-panel-back{
    @include text-sub-header2;
    position: absolute;
    top: 20px;
    left: 20px;
    color: $color-primary;

    .app-large-panel-back-link{
        @include default-link($color-primary);
    }
}

.app-large-panel-back-icon{
    margin-right: 5px;
}

.app-large-panel-container{
    @include fill-height;
    /*height: calc(100% - 60px);*/
    flex-grow:1;
    position: relative;
}