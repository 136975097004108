.app-save-map-title{
    @include text-sub-header2;
    @include border-bottom-normal;
    color: $color-white;
    background-color: $color-primary;
    border-radius: $border-radius-light;
    border-bottom-left-radius: $border-radius-none;
    border-bottom-right-radius: $border-radius-none;
    cursor: pointer;
    position: relative;
    padding: 15px;
}

.app-save-map{
    @include fill-height;
    background-color: $color-white;
    border-radius: $border-radius-normal;
    display: flex;
    flex-flow: column;
    width: 380px;
    height: 555px;
    position: absolute;
    top: 20%;
    left: 30%;
    z-index: 200;
}

.app-save-map-close{    
    position: absolute;
    right: 0px;
    top: 0px;
    margin-right:11px;
    margin-top:11px;
}

.app-save-map-rows{    
    display: flex;
    flex-flow: row;
}

.app-save-map-actions-rows{
    margin-top: 20px;
    display: flex;
    flex-flow: row;
    justify-content: right;
}

.app-save-map-labels{
    margin: 10px 15px;
}

.app-save-map-inputs{
    margin: 5px 15px;
    width: 350px;
}

.app-save-map-button{
    margin: 15px 10px;
}

.app-option-selector{
    margin-left: 15px;
    margin-top: 15px;
}

.app-save-map-bar-buttons{    
    
    .app-bar-table{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }

    .app-clickable-active{
        background-color: $color-tertiary20;
        color: $color-primary;    

        &.app-clickable-hovered{
            @include shadow-light;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }        
    }    
}

.app-save-map-bar-short{    
    width: 166px;
}
