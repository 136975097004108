.app-radio-button-set{
    display: inline-block;

    .app-button{
        border-radius: 0;
    }

    &.app-radio-button-set-horizontal{
        .app-radio-button:first-child{
            .app-button{
                border-top-left-radius: $border-radius-normal;
                border-bottom-left-radius: $border-radius-normal;
                border-right: none;
            }
            
            .app-button-menu{
                .app-button{
                    border-radius:unset;
                }
            }
        }

        .app-radio-button:not(:first-child):not(:last-child){
            .app-button{
                border-left: none;
                border-right: none;
            }
        }

        .app-radio-button:last-child{
            .app-button{
                border-top-right-radius: $border-radius-normal;
                border-bottom-right-radius: $border-radius-normal;
                border-left: none;

                &.app-button-row{
                    border-top-right-radius: 0;
                }
            }

            .app-button-menu{
                .app-button{
                    border-radius:unset;                    
                }
            }
        }
    }

    &.app-radio-button-set-vertical{
        .app-radio-button:first-child{
            .app-button:first-child{
                border-top-left-radius: $border-radius-normal;
                border-top-right-radius: $border-radius-normal;
            }
        }

        .app-radio-button:last-child{
            .app-button{
                border-bottom-left-radius: $border-radius-normal;
                border-bottom-right-radius: $border-radius-normal;
            }
        }
    }
    .app-radio-button{
        .app-button-row{        
            .app-button{
                border-radius: 0
            }
        }
    }
}