.app-mobility-data-step-1-container{    
    @include vertical-scroll;
    @include text-bar;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    width: 850px;
}

.app-mobility-data-step-2-container{
    @include text-bar;
    display: flex;
    flex-flow: row;
    flex-grow: 1;
    width: 850px;
}

.app-mobility-data-step-3-container{    
    @include text-bar;
    display: flex;
    flex-flow: row;
    width: 850px;
    height: 100%;
    padding-top: 20px;
}

.app-mobility-data-step-2-column{    
    @include vertical-scroll;
    @include text-bar;
    display: flex;
    flex-flow: column;
    width: 430px;
}

.app-mobility-data-vertical-line{
    border-left: 1px solid $color-light-gray;
    height: 95%;
    margin: 17px 0 17px 0;
}

.app-mobility-data-labels{
    margin: 17px 17px 0 17px;
}

.app-mobility-data-inputs{
    margin: 17px 17px 10px 17px;
}

.app-mobility-data-warning{
    color: $color-septenary;
}

.app-mobility-data-report-rows{    
    display: flex;
    flex-flow: row;
}

.app-mobility-data-report-columns{    
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    .app-selectable-items-selected{
        max-height: none;
    }

    .app-selectable-items-body{
        margin: 3px;

        .app-option-selector-icon{
            display: flex;
            justify-content: center;
        }
    }
}

.app-mobility-search-container{
    display: flex;
    flex-flow: row;
    margin: 17px 17px 10px 17px;
}

.app-mobility-search-input{
    flex-grow: 2;
    margin: 0;

    .dx-texteditor{
        border-radius: 4px 0 0 4px;
    }
}

.app-mobility-search-button{
    margin: 0 !important;
    width: 50px !important;

    .app-button{
        height: 40px !important;
        border-radius: 0 4px 4px 0;
    }
}

.app-mobility-name-input{
    margin: 10px 10px 10px 17px;
}

.app-mobility-data-rows-container{
    padding: 10px;
}

.app-mobility-data-bar-long{    
    width: 370px;
}

.app-mobility-data-bar-short{    
    width: 166px;
}

.app-mobility-data-bar-excel .app-bar-icon-container{
    color: green;
}

.app-mobility-data-bar-pdf .app-bar-icon-container{   
    color: red;
}

.app-mobility-data-bar-buttons{    
    
    .app-bar-table{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }

    .app-clickable-active{
        background-color: $color-tertiary20;
        color: $color-primary;    

        &.app-clickable-hovered{
            @include shadow-light;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }        
    }    
}

.app-mobility-data-bar-edit{    
    margin: 17px 17px 0 17px;

    .app-bar-text{
        white-space: pre;
        padding-left: 30px;
    }

    .app-bar-table{
        justify-content: left;
        height: 70px;
    }
}
