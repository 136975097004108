.app-activity-hub{
    @include fill-height;
    background-color: $color-white;
    border-radius: $border-radius-normal;
    position: absolute;
    top: 30%;
    left: 30%;
    width: 750px;
    height: 560px;
    z-index: 200; 
}

.app-activity-hub-body{
    @include fill-height;
    position: relative;
    overflow: hidden;    
}

.app-activity-hub-body-content{
    @include fill-height;
    position: relative;
    overflow: hidden;    
}

.app-activity-hub-title{
    @include text-sub-header2;
    @include border-bottom-normal;
    color: $color-white;
    background-color: $color-primary;
    border-radius: $border-radius-light;
    border-bottom-left-radius: $border-radius-none;
    border-bottom-right-radius: $border-radius-none;
    cursor: pointer;
    position: relative;
    padding: 15px;
}

.app-activity-hub-footer-tab{
    
    width: 30px;
    height: 40px;    
    margin: 0 5px;

    .app-button{
        border-radius: 0px;
        color: $color-dark-gray;
        background-color: transparent;
        width: 30px;
        height: 30px;
        line-height: 30px;

        &.app-clickable-disabled{
            background-color: transparent;
        }

        &.app-clickable-hovered{
            @include shadow-none;
            color: $color-tertiary;
        }

        &.app-clickable-pressed{
            @include shadow-none;
            color: $color-tertiary50;
        }

        &.app-clickable-active{
            color: $color-primary;    

            &.app-clickable-hovered{
                @include shadow-light;
            }
    
            &.app-clickable-pressed{
                @include shadow-inset;
            }        
        }

        .app-button-icon{
            @include text-primary-button;
        }
    }

    .app-button-active-element{
        border-radius: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        left: initial;
        bottom: 0;
        width: 30px;
        height: 5px;
    }
}

.app-activity-hub-footer-drown-down{
        
    margin: 0px 5px;

    .app-button{
        border-radius: 0px;
        color: $color-dark-gray;
        background-color: transparent;

        &.app-clickable-disabled{
            background-color: transparent;
        }

        &.app-clickable-hovered{
            @include shadow-none;
            color: $color-tertiary;
        }

        &.app-clickable-pressed{
            @include shadow-none;
            color: $color-tertiary50;
        }

        &.app-clickable-active{
            color: $color-primary;    

            &.app-clickable-hovered{
                @include shadow-light;
            }
    
            &.app-clickable-pressed{
                @include shadow-inset;
            }        
        }

        .app-button-icon{
            @include text-primary-button;
        }
    }
}

.app-activity-hub-actions{
    @include border-top-normal();
    background-color: $color-primary5;
    border-radius: $border-radius-light;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-top: 5px;
}

.app-activity-hub-close{    
    position: absolute;
    right: 0px;
    top: 0px;
    margin-right:11px;
    margin-top:11px;
}