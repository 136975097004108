@import './analytics/analytics';
@import './activityHub/activityHub';
@import './header/header';
@import './map/map';
@import './navigation/navigation';
@import './layers/layers';
@import './quickReports/quickReports';
@import './reports/reports';
@import './chatBot/chatBot';
@import './tasks/tasks';
@import './userBadge/userBadge';
@import './userPreferences/userPreferences';
@import './userPreferences/userProfile/userProfile';
@import './forms/forms';
@import './shapeEdit/shapeEdit';
@import './photos/photos';
@import './photos/thumbNailPhoto/thumbNailPhoto';
@import './photos/editPhotoDetails/editPhotoDetails';
@import './comments/comments';
@import './comments/comment/comment'; 
@import './tradeAreas/tradeAreas'; 
@import './dashboards/dashboards';
@import './drivingDirections/drivingDirections';
@import './drivingDirections/directionSettings/directionSettings';
@import './drivingDirections//destination/destination';
@import '../../modules/google/googleDrivingDirections';
@import './photos/editPhoto/editPhoto';
@import './photos/uploadPhotos/uploadPhotos';
@import './photos//editPhotoUpload/editPhotoUpload';
@import './geofences/geofences';
@import './geofences/geofenceJobs/geofenceJobs';
@import './geofences/geofenceJobs/geofenceJobDetail';
@import './geofences/geofenceJobs/geofenceJobRenderers';
@import './geofences/geofenceJobs/geofenceJobCreate';
@import './mobilityData/competitiveInsights/dashboards/ciDashboards';
@import './mobilityData/competitiveInsights/ciChannelFilter';
@import './mobilityData/mobilityReports/mobilityReports';
@import './tools/tools';
@import './reports/pointDetail/pointDetail';
@import './trip2Trade/trip2Trade';
@import './trip2Trade/trip2TradeJob/trip2TradeJob';
@import './trip2Trade/rendererWizard/rendererWizard';
@import './projections/projections';
@import './bulkInfo/bulkInfo';
@import './mobilityData/competitiveInsights/competitiveInsights';
@import './saveMap/saveMap';
@import './projects/projects';
@import './projects/availableMaps/availableMaps';
@import './projects/availableMaps/mapUsage';
@import './projects/availableMaps/folderEditForm';
@import './jobs/jobs';
@import './projects/printMap/printMap';
@import './multimedia/multimedia';
@import './shapeDownloader/shapeDownloader';
@import './selections/selections';
@import './mapBookRunner/mapBookRunner';
@import './mapBookJob/mapBookJob';
@import './pointScenarios/pointScenarios';
@import './scenarios/scenarios';
@import './filters/filters';
@import './artificialIntelligence/artificialIntelligence';
@import './competitiveInsightsReportObject/competitiveInsightsReportObject';
@import './reports/managementReports/managementReportsStep2';
@import './sharedUsers/sharedUsers';
@import './notifications/notifications';
@import './reports/reportManager/reportManager';
@import './analogs/analogs';
@import './analogs/analogSelector';
@import './analogs/analogNotes/analogNotes';
@import './analogs/analogNotes/analogNote/analogNote';
@import './viewer/viewInformation';
@import './tasks/taskAction/taskAction';


.app-element{
    @include fill-height;
    position: relative;
}

.app-label{
    font-weight: bold;
}

.app-empty-content{
    @include fill-height;
    @include border-normal;
    @include text-header3;
    align-content: center;
    justify-content: center;
    background-color: $color-primary3;
    color: $color-dark-gray;
    text-align: center;    
    margin: 10px;
}