@import './urlEdit/urlEdit';
@import './detailsEdit/detailsEdit';

.app-multimedia{
    margin: 10px;
}

.app-multimedia-instructions{
    margin: 10px;
    padding: 5px;
    text-align: left;
    height: 50px;
    text-overflow: ellipsis;  
    overflow: hidden;     
  }

.app-multimedia-instructions-note{
    float: left;
    padding-left: 10px;
    width: 550px;
}

.app-multimedia-actions{
    float: right;
    margin-right: 25px;
}

.app-multimedia-list-view-column-text{
    text-overflow: ellipsis;  
    overflow: hidden;  
  }

.app-multimedia-user-image{
    @include user-image-small; 
}

.app-multimedia-username {
    padding-left: 10px;
}

.app-multimedia-file-name {
    padding-bottom: 8px;
}

.app-multimedia-photo-upload{
    height: 350px;
}

.app-multimedia-grid-detail-description{
    margin-bottom: 5px;
}

.app-multimedia-grid-detail-actions{
    padding-right: 10px;    
    color: $color-dark-gray;
}

.app-multimedia-grid-detail-delete-action{
    padding-right: 10px;   
    position: absolute;    
    color: $color-dark-gray;
}



