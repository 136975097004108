.app-multimedia-details-edit{
    flex-flow: column;
}

.app-multimedia-header{
    @include border-bottom-normal;
    padding: 0px 15px;
}

.app-multimedia-details-edit-button{
    margin-left: 15px;
    margin-bottom: 20px;
}

.app-multimedia-details-edit-rows{    
    display: flex;
    flex-flow: row;
}

.app-multimedia-details-edit-actions-rows{
    margin-top: 20px;
    display: flex;
    flex-flow: row;
    justify-content: right;
}

.app-multimedia-details-edit-body{
    padding: 15px;
}