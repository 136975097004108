.app-analog-template-container{
    padding:7px;
    display: flex;
    justify-content: space-between;
}

.app-analog-list-container{
    padding: 7px;
    flex: 1;
    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;
}

.app-analog-list-container .dx-datagrid {
    flex: 1; /* Allow the DataGrid to grow and fill the container */
    height: 100%; /* Ensure the DataGrid takes the full height */
}

.app-analog-list-item{
    display: flex;
    justify-content: space-between;

    .app-analog-list-name{
        padding-left: 10px;
    }
}