.app-task-list-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    padding: 7px 0px;

    .app-task-list-button-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        * {
            padding-left: 5px;
        }
    }

    .app-task-list-filters {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .app-radio-button-set{
            padding-right: 10px;
        }

        .app-button {
            width: auto;
            padding: 5px;
        }
    }
}

.app-task-list {
    overflow: visible;

    .dx-datagrid {
        overflow: visible;

        .dx-row-focused:hover > td,
        .dx-row:hover > td,
        .dx-row-alt:hover > td {
            background-color: $color-tertiary50 !important;
        }

        .dx-row-focused > td:not(.dx-focused) {
            background-color: $color-tertiary20;
            border-bottom: 1px solid #ddd;
        }

        .dx-datagrid-filter-row:hover > td {
            background-color: inherit !important;
        }

        .app-data-grid-column {
            overflow: visible;
        
            .app-task-list-action-container {
                display: flex;
                overflow: visible;
            
                .app-task-list-action {
                    padding-right: 5px;
                }
            }
        }

        .word-wrap {
            white-space: normal;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    }
}

