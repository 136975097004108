.app-user-preferences{
    display: flex;
    flex-flow: column;
    background-color: $color-white;
    border-radius: $border-radius-normal;
    position: absolute;
    top: 50px;
    right: 25px;
    width: 665px;
    z-index: 200;

    .app-description{
        padding: 10px;
        margin-top: 20px;
        text-align: left;
    }
}

.app-user-preferences-only-password{
    @include vertical-scroll-color;

    .app-description{
        margin-top: 15px;
    }
}

.app-user-preferences-title{
    @include border-bottom-normal($color-gray);
    @include text-sub-header1;
    background-color: $color-primary10;
    color: $color-primary;
    height: 55px;
    line-height: 55px;
    padding-left: 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    text-align: left;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.app-user-preferences-actions{
    background-color: $color-primary5;
}

.app-user-preferences .app-tabbed-content{
    height: 486px;

    .app-card-body{
        height: 99% !important;
        padding-bottom: 0 !important;
    }
}
    
.app-user-preferences-back{
    @include text-sub-header2;
    margin: 15px 0 15px 20px;
    color: $color-primary;

    .app-large-panel-back-link{
        @include default-link($color-primary);
    }
}

.app-user-preferences-actions-icon{
    margin-right: 5px;
}

.app-user-preferences-close{    
    position: absolute;
    right: 0px;
    top: 0px;
    margin-right: 11px;
    margin-top: 11px;
}

.app-user-preferences-save{    
    text-align: right;
}

.app-user-preferences-card{
    display: flex;
    flex-flow: column;
    margin: 20px;
    width: 526px;
}

.app-user-preferences-inputs{    
    width: 100%;
    
    .dx-selectbox{
        margin-bottom: 20px;
    }    

    &.app-bar-container{
        margin-bottom: 20px;
    }
    
    .app-bar-table{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .app-clickable-active{
        background-color: $color-tertiary20;
        color: $color-primary;    

        &.app-clickable-hovered{
            @include shadow-light;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }        
    }    
}

.app-user-preferences-sections{
    display: flex;
    flex-flow: column;
    margin: 20px;
    width: 526px;

    .app-collapsible-section{
        border-radius: 8px 8px 0 0;
        margin: 0;
    }

    .app-collapsible-section-content{
        padding: 20px;
    
        .app-text-box {
            margin-bottom: 20px;
        }
    }
}

.app-user-preferences-section{
    @include border-normal($color-light-gray);
    border-radius: $border-radius-normal;
    margin-bottom: 20px;
}