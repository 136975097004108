@import './mapTabBar/mapTabBar';
@import './mapControl/mapControl';
@import './mapLocator/mapLocator';
@import './mapTooltip/mapTooltip';
@import './mapEntityTooltip/mapEntityTooltip'; 
@import './mapModeBanner/mapModeBanner';
@import './mapSearch/mapSearch'; 
@import './mapEntityLabelEditor/mapEntityLabelEditor';

.app-map-container{
    @include fill-height;
    position: relative;
} 

.app-map-logo-container{
    float: right;

    .app-map-logo{
        position: absolute;
        border: none;
        top: 10px;
        right: 30px;
        z-index: 1;
    }
} 

.app-map-triview-row{
    display: flex;
    flex-flow: row;
    height: 100%;
    width: 100%;
}

.app-map-triview-column{
    height: 100%;
    width: 100%;
}

.app-map{
    height: 100%;
    width: 100%;
    cursor: grab;
}

.app-map-triview{
    height: 50%;
    width: 100%;
    cursor: grab;
}

.app-street-map{
    height: 50%;
    width: 100%;
    cursor: grab;
}

.app-aerial-map{
    height: 100%;
    width: 100%;
    cursor: grab;
}

.app-map-horizonatal-divider{
    border-top: 1px solid $color-black;
    border-bottom: 1px solid $color-black;
    width: 100%;
    height: 5px;
}

.app-map-vertical-divider{
    border-left: 1px solid $color-black;
    border-right: 1px solid $color-black;
    width: 8px;
    height: 100%;
}