.app-simple-table{
    @include text-caption;
    border: 1px solid $color-gray;

    tr{
        border-bottom: 1px solid $color-gray;
    }

    td,
    th{
        padding: 10px;
        text-align: center;
    }

    thead:first-child{
        background-color: $color-primary15;
    }

    tbody tr:nth-child(2n+1){
        background-color: $color-primary5;
    }
}