.app-date-box-container {
    display: flex;
    align-items: center;
    
    .required {
        width: 10px;
        color: $color-red;
        font-size: 10px;
        line-height: 1.35715;
    
        .app-form-required {
            padding-left: 5px;
            padding-top: 5px;
            padding-right: 5px;
        }
    }
}

.app-date-box {
    .dx-datebox {
        width: 100%;
        color: $color-dark-gray !important;
        border-color: $color-dark-gray !important;    

        &:hover{
            border-color: $color-secondary !important;
        }
   
        .dx-label-before,
        .dx-label,
        .dx-label-after{
            color: $color-dark-gray !important;
            border-color: $color-dark-gray !important;
            
        }

        &.dx-state-hover{
            .dx-label-before,
            .dx-label,
            .dx-label-after{
                color: $color-secondary !important;
                border-color: $color-secondary !important;
            }
        }
        
        &.dx-state-readonly{
            border-style: solid !important;

            .dx-label-before,
            .dx-label,
            .dx-label-after{
                color: $color-dark-gray !important;                
                border-color: $color-dark-gray !important;
            }

            input{
                background-color: $color-light-gray !important;
            }
        }
    }

    .dx-button.dx-button-textBoxButton {
        height: 25px;
        width: 25px;
        border: 0px;
        margin-right: 10px;
        background: transparent;

        .dx-svg-icon path {
            fill: $color-dark-gray;
        }
    }
}