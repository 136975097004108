.app-projects-available-maps-folder-edit-header{
    text-align: right;

    .app-button-container{
        margin-left: 7px;
    }
}

.app-projects-available-maps-folder-edit-name{
    width: 410px;
}

.app-projects-available-maps-folder-edit-button {
    margin-top: 20px;
    float: right;
  }

