.app-collapsible-section{
    @include text-sub-header2;
    background-color: $color-primary10;
    border-radius: $border-radius-normal;
    padding: 0 15px;
    margin-bottom: 5px;
    line-height: 40px;

    &.app-clickable-hovered{
        background-color: $color-primary15;
    }
}

.app-collapsible-section-content{
    display: block;
}

.app-collapsible-section-icon{    
    margin-right: 10px;
}

.app-collapsible-section-count{
    background-color: $color-primary25;
    display: inline-block;
    margin-right: 10px;
    border-radius: 100px;    
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 25px;
}

.app-collapsible-section-selected{
    float: right;
}

.app-collapsible-section-action{
    float: right;
    padding-left: 8px;

    .app-button{
        color: $color-dark-gray;
    }
}

.app-collapsible-section-drag-is-over{
    background-color: $color-tertiary50;
}