.app-legend-menu-actions{
    @include border-bottom-normal;
    height: 55px;
    line-height: 55px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    text-align: center;
}

.app-legend-menu-actions-button{
    margin: 0 10px;
}

.app-legend-menu-footer{
    @include border-top-normal;
    height: 75px;
}

.app-legend-menu-selected{
    @include border-top-normal;
    position: absolute;
    bottom: 75px;
    width: 280px;
    max-height: 200px;
    padding: 10px;
    border-top-style: solid;
    border-top-width: 1px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.app-legend-menu-item{
    
    margin: 7px;

    .app-bar{
        background-color: $color-primary5;

        &.app-clickable-hovered{            
            background-color: $color-tertiary50;
        }
    }

    .app-bar-body{        
        border: none;
        @include border-top-normal;        
    }

    .app-clickable-active{
        background-color: $color-tertiary20;
    }    
}

.app-legend-menu-body{
    padding: 7px;

    .app-bar-container{
        margin-bottom: 7px;
    }
}