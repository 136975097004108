.app-filters-create-large-panel
{
    z-index: 100;
}

.app-filters-create-header
{
    margin: 10px 15px 0px 15px;
    
    .app-filters-create-instructions
    {
        float: left;
        width: 942px;
    }

    .app-filters-create-actions {
        display: flex;
        flex-flow: row;
        justify-content: right;
    
        .app-button-container {
            margin: 0px 0px 0px 10px;
        }
    }
}

.app-filters-container {
    margin: 15px;
}
.app-filters-create-container {
    @include fill-height;
    padding: 5px 15px 15px 15px;

    .app-filters-create-row {
        display: flex;
        flex-flow: row;

        .app-filters-create-row-item {
            margin-top: 5px;
            width: 100%;
        }
    }
    
    .app-filters-create-tab-container {
        @include fill-height;
        margin-top: 20px;

        .app-filters-create-filter-builder-container {
            @include fill-height;
            padding: 10px;
            background-color: $color-light-gray;
            overflow: auto;
    
            .dx-filterbuilder-group {
                background-color: $color-light-gray;
    
                .dx-filterbuilder-item-value
                {
                    background-color: $color-white;
                    color: $color-black;
                    font-style: italic;
                }
            }
        }
    
        .app-filters-create-grid-container {
            @include fill-height;
        }
    }
}

.app-filters-create-modal
{
    width: 1060px;
    height: calc(100% - 85px);

    .app-filters-create-title
    {
        background-color: $color-primary10;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.18px;
        margin-bottom: 10px;
        padding: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: 1px solid #CCC;

        .app-filters-create-title-close
        {
            float: right;
        }
    }
}