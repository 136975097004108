.app-scenarios{
    @include fill-height;    

    .dx-filemanager{
        @include fill-height;
    }
}

.app-scenario-modal{
    width: 1060px;
    height: 96%;
}

.app-scenarios-search{
    @include fill-height;    

    .app-scenarios-search-title{
        background-color: $color-primary10;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.18px;
        margin-bottom: 10px;
        padding: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: 1px solid #CCC;

        .app-scenarios-search-title-close{
            float: right;
        }
    }

    .app-scenarios-search-body{
        @include fill-height;  
        padding-top: 5px;       
        margin: 5px;
        border: 1px solid #CCC;
        border-radius: 5px;

        .dx-treelist-search-panel{
            margin-right: 10px;
            margin-top: 3px;
        }
    }    
}

.dx-filemanager-toolbar{
    background: $color-primary5 !important;
    .dx-toolbar
    {
        background: $color-primary5 !important;
    }
}

.dx-filemanager-items-panel{
    .dx-filemanager-breadcrumbs
    {
        background: $color-primary3;
        color: $color-white;
    }
}


.dx-filemanager{    

    .dx-filemanager-focused-item{
        background-color: $color-tertiary20 !important;
    }

    .dx-state-hover{
        background-color: $color-tertiary50 !important;
    }
    
}