.app-tmx-avg-daily-traffic{
    @include text-header3;
    color: $color-primary;
    margin: 0 10px 5px 10px;
    text-align: center;
}

.app-tmx-sel-daily-traffic{
    @include text-sub-header0;
    color: $color-primary;
    margin: 5px 10px 5px 10px;
    text-align: center;
}

.app-tmx-labels{
    margin: 4px 10px 0 20px
}

.app-tmx-week-day{
    color: $color-primary;
    background-color: $color-tertiary20;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    align-content: center;
    margin: 10px;
}

.app-tmx-default-bar{
    width: 45px;
}

.app-tmx-input-container{
    display: flex;
    flex-flow: row;
    margin: 10px 10px 10px 10px;
    text-align: center;
    justify-content: center;

    .app-option-selector{
        margin: 4px 10px 0 0;
    }

    .app-button-container{
        margin-right: 10px;
    }

    .app-clickable-active{
        color: $color-tertiary20;
        background-color: $color-primary;    

        &.app-clickable-hovered{
            @include shadow-light;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }        
    }    
}

.app-preset-drop-down{
    margin-top: 5px;
    margin-right: 10px;
}

.app-direction-drop-down{
    margin-right: 10px;
}

.app-tmx-grid-row td{
    padding: 1px 1px;
    text-align: center;
}

.app-tmx-grid-cell{
    border-width: 1px;
    border-style: solid;
    border-color: #000000;
    -moz-border-radius: 1px;
    border-radius: 1px;
    width: 16px;
    height: 16px;
    margin: auto;
    filter: alpha(opacity=100);
    opacity: 1.0;
    -khtml-opacity: 1.0;
    -moz-opacity: 1.0;
}

.app-slider{
    cursor: pointer;
    width: 240px;
    padding-top: 3px;
}

.app-slider .app-slider-track {
    top: 13px;
    height: 4px;
    background-color: $color-primary15;
}

.app-slider-track.app-slider-track-0 {
    background-color: $color-primary15;    
}

.app-slider-track.app-slider-track-1 {
    background-color: $color-primary;
}

.app-slider-track.app-slider-track-2 {
    background-color: $color-primary15;
}

.app-slider .app-slider-thumb {
    cursor: pointer;
    background-color: $color-primary;    
    width: 24px;
    height: 24px;
    border-radius: 100%;
    outline: none;
    font-size: 0.9em;
    text-align: center;
    align-content: center;
    color: $color-white;
}
  
.app-slider .app-slider-thumb:hover{
    box-shadow: 0 0 0 4px $color-primary25;
}

.app-tmx-save-modal{
    background: $color-translucent;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;

    .app-modal-card{
        width: 400px;
        height: 130px;
        top: 25%;
        left: 25%;

        .app-modal-card-body{
            @include shadow-medium;
            background: $color-white;
            border: 1px solid #CCC;
            padding-bottom: 0 !important;

            .app-card-body{
                padding-bottom: 0 !important;
            }
        }
    }
}

.app-tmx-save-container{
    display: flex;
    flex-flow: column;
    margin: 20px 20px 5px 0;
}

.app-tmx-save-row{
    display: flex;
    flex-flow: row;
}

.app-tmx-save-stretch{
    flex-grow: 2;
}

.app-tmx-save-actions-rows{
    margin: 20px 0 0 0;
    display: flex;
    flex-flow: row;
    justify-content: right;
}

.app-tmx-save-button{
    margin: 0 0 12px 12px;

    .app-button-icon{
        font-size: 22px !important;
    }
}

.app-tmx-tool-tip{
    text-align: center;
    font-weight: bold !important;
    color: $color-primary !important;
    font-size: 13px !important;
}
