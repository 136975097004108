.app-report-manager-header{
    @include border-bottom-normal;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
}

.app-report-manager-search-input{
    width: 330px;
    margin-top: 5px;
}

.app-report-manager-cell-display{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.app-report-manager-days-left{
    color: $color-red;
}

.app-datagrid-container{
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    height: 0;
}


.app-saved-report-management{
    margin: 10px 10px 0px 10px;
    height: calc(100% - 10px) !important;
    
    .dx-datagrid-nowrap{
        overflow: hidden !important; 

        .dx-scrollable-container{
            overflow: hidden !important; 
        }

        //.app-data-grid-column {            
        //    overflow: visible;
        //}
    }
}