.app-shape-edit-container {
    padding:7px;

    .app-button-container{
        padding-left: 5px;
    }
}

.app-shape-edit-button-container {
    display: flex;
    justify-content: flex-end;
}

.app-shape-edit-line-width-image {
    padding: 10px;
    max-width: 125px;
}

.delete-button{
    .app-button{
        background-color: $color-red;
        color: $color-white;
        border-color: $color-red;
    }
}