@import './modelWizard/modelWizard';
@import './hotSpotAnalysis/hotSpotAnalysis';
@import './marketOptimization/marketOptimization';

.app-analytics-form{
    padding: 10px;
    text-align: right;
    
    .app-analytics-form-element{
        margin-bottom: 10px;
    }
}

.app-analytics-form-button{
    margin-right:10px;
}