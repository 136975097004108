.app-task-action-header{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    
    .app-task-action-header-title {
        @include text-header3;
    }
}

.app-task-action-container{    
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: center;
}

.app-task-action-container-no-header{    
    display: flex;
    flex-flow: row;
    justify-content: center;
    height: 100%;
}

.app-task-action-form{
    flex-grow: 2;
    border: 1px solid $color-light-gray;
}

.app-task-action-form-edit{
    .app-form-body{
        height: calc(100% - 54px);
    }
}

.app-task-action-map{
    margin: 10px;
    border: 1px solid $color-light-gray;
    max-height: 350px;
}