.app-drag-and-drop-content{
    @include border-normal;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 212px;
}

.app-drag-and-drop-header{
    display: flex;
    flex-flow: row;
    padding: 4px 12px 4px 12px;
    background-color: $color-primary10;
}

.app-drag-and-drop-body{
    @include vertical-scroll;
}

.app-drag-and-drop-label{
    @include text-bar;
    flex-grow: 2;
    align-content: center;
}

.app-drag-and-drop-row{
    display: flex;
    flex-flow: row;
}

.app-drag-and-drop-empty{
    @include text-header3;
    color: $color-dark-gray;
    text-align: center;
    margin: auto;
    margin-top: 10px;
}

.app-drag-and-drop-item{
    flex-grow: 2;
}

.app-drag-and-drop-button{
    margin: 12px 0 0 0;
}

.app-drag-and-drop-move-button{
    width: 20px;
    height: 25px;
    border-width: 0;
    padding: 10px 0 0 7px;
    background: transparent;
    cursor: grab;
} 

.app-drag-and-drop-basic-move-button{
    width: 20px;
    height: 25px;
    border-width: 0;
    padding-left: 10px;
    background: transparent;
    cursor: grab;
} 
