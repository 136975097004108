.app-user-profile{
    display: flex;
    flex-flow: row;
}

.app-user-profile-image{
    display: flex;
    flex-flow: column;
    margin: 0 10px 20px 20px;
}

.app-user-profile-image-btns{
    margin: 20px 0 0 17px;

    .app-button-container:last-child{
        margin-left: 10px;
    }
}

.app-user-profile-details{
    flex-grow: 2;    
    margin: 20px 20px 20px 10px;

    .app-text-box{
        margin-bottom: 20px;
    }
}

.app-user-profile-userImage{
    @include default-user-image;
    margin-top: 30px;
    border-radius: 50%;
    height: 100px;
    width: 100px;    
    border: 1px solid $color-primary;
    cursor: pointer;
}

.app-user-profile-image-upload-container{
    padding: 20px;
    height: 200px;
    text-align: center;
  }
.app-user-profile-image-upload-location{
    padding-top: 75px;
    height: 382px;
    text-align: center;
}

.app-user-profile-image-modal{
    border-radius: $border-radius-normal;
    width: 510px;
    height: 382px;
}

.app-user-profile-image-upload-actions{
    padding: 7px 10px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}

.app-user-profile-image-upload-actions-buttons{
    padding-bottom: 10px;
}
