.app-sign-in-container{
    height: 100%;
    position: relative;
}

.app-sign-in{
    height: 100%;    
    position: absolute;
    width: 500px;    
    background: #FFF;
    border-right: 1px solid #CCC;
}

.app-sign-in-logo{
    width: 150px;
    margin: 20px;
}

.app-sign-in-form{
    position: absolute; 
    top: 30%; 
    width: 460px;
    text-align: center; 
    padding: 0 20px;
    margin-top: 50%;
}

.app-sign-in-title{
    @include text-header3;
}

.app-sign-in-description-container{
    text-align: left;
    font-size: 22px;
    margin-bottom: 20px;
    margin-left: 5px;
}

.app-sign-in-description{
    @include text-sub-header2;
    margin-top: 10px;
}

.app-sign-in-splash{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url('https://cdn.tradeareasystems.net/Images/Rebrand/Login.png');
}

.app-sign-in-input{
    width: 430px;
    height: 30px;
    border: 1px solid #000;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
}

.app-sign-in-button{

    &.app-button-large{
        width: 460px;
    }    

    .app-button{
        width: 460px;
    }
}

.app-sign-in-footer{
    @include default-link;
    position: absolute;
    bottom: 0;
    width: 460px;
    margin: 20px 20px 10px 20px;
}

.app-sign-in-footer-left{
    float: left;
}

.app-sign-in-footer-right{
    float: right;
    text-align: right;
}

.app-sign-in-footer-right a{ 
    @include default-link;   
    margin-left: 10px;
}

.app-sign-in-footer-version{
    clear: both;
    text-align: right;
    font-size: 10px;
    padding-top: 10px;
}