.app-map-book-manager-list-item{
    display: flex;
    justify-content: space-between;

    .app-button{
        color: $color-dark-gray;
    }
}

.app-map-book-manager-list-name-container{
    display: flex;
    flex-flow: row;
}

.app-map-book-icon{
    color: $color-dark-gray;
}

.app-map-book-manager-list-name{
    padding-left: 10px;
}

.app-map-book-manager-filter-drop-down{
    width: 224px;
}

.app-map-book-manager-header-button{
    margin-top: 7px;
}

.app-map-book-manager-tool-bar{
    display: flex;
    justify-content: space-between;
    padding: 2px;
}

