.app-work-bench{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 300px;
    background: #FFF;
    z-index: 102;
    width: 70%;
    margin: 0 20px;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.app-work-bench-container{
    @include fill-height;
    background-color: gray;
} 