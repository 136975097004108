.app-projects-available-maps-body{
    @include vertical-scroll;
    display:flex;
    flex-grow:1;
    height:0;
    padding: 10px;

    .app-selectable-items-body-no-footer{
        height:100%;
    }

    .app-option-selector-text{
        margin-top: 10px;
        width: 180px;
    }
}

.app-projects-available-maps-date{
    @include text-overline;
    padding-top: 3px
}

.app-projects-available-maps-container{
    @include border-normal($color-light-gray);
    border-radius: $border-radius-normal;
}

.app-projects-available-maps-add-folder{
    display: flex;
    flex-flow: row;
    justify-content: right;
    padding: 10px 10px 0px 10px;
}

.app-projects-available-maps-list-item{
    display: flex;
    justify-content: space-between;

    .app-button{
        color: $color-dark-gray;
        padding-left: 5px;
    }
}

.app-projects-available-maps-map{
    border: 1px solid;
    border-color: #F4F5F9;// $color-primary5;
    border-top-left-radius: $border-radius-normal;
    border-top-right-radius: $border-radius-normal;
}

.app-collapsible-section-content{
    padding-bottom: 20px
}

.app-projects-available-maps-map-title{
    @include text-bar;
    padding: 10px 10px;
    width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.app-projects-available-maps-text{
    @include text-sub-header2;
    margin: 10px;
    color: $color-primary;
    text-overflow: ellipsis;  
    width:175px;
    overflow: hidden;  
}

.app-projects-available-maps-actions{
    @include border-top-normal();
    background-color: $color-primary5;
    border-top-right-radius: $border-radius-none;
    border-top-left-radius: $border-radius-none;
    border-bottom-right-radius: $border-radius-normal;
    border-bottom-left-radius: $border-radius-normal;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .app-button{
        color: $color-dark-gray;
        padding: 5px;
    }    
}

.app-projects-available-maps-actions-right-buttons {
    display: flex;
    margin-left: auto;

    .app-button-icon{
        font-size: 12px !important;
    }
}

.app-projects-available-maps-map-actions-section{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 70px;

    .app-button{
        padding: 10px;
    }
}

.app-projects-available-maps-rows-container{
    padding: 10px;
}

.app-projects-available-maps-header{
    @include border-bottom-normal;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
}

.app-projects-available-maps-sort-dropdown,
.app-projects-available-maps-search{
    padding-top: 5px;
}

.app-projects-available-maps-search-input{
    width: 330px;
}

.app-projects-available-maps-details-button{
    color: $color-dark-gray;
}

.app-projects-available-maps-drag-item{
    cursor: grab;
    border-radius: $border-radius-normal;
}
