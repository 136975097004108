.app-job-management-pending{
    color: $color-primary;
} 

.app-job-management-processing{
    @include spin-slow;
    color: $color-primary;
} 

.app-job-management-completed{    
    color: $color-secondary;
} 

.app-job-management-completed_with_errors{    
    color: $color-tertiary;
} 

.app-job-management-failed{    
    color: $color-septenary;
}

.app-job-management-mark-unread{
    .app-button-icon{
        color: $color-primary;
    }
}

.app-job-management-mark-read{
    .app-button-icon{
        color: $color-light-gray;
    }    
}



.app-job-actions{
    text-align: right;
    padding: 10px 10px 0 10px;
    
    .app-job-management-action{
        margin-left: 10px;
    }
}

.app-job-management-icon-wrapper{
    cursor: pointer;
    font-size: 22px;
    color: $color-dark-gray;
}

.app-job-management-name-wrapper{
    white-space: wrap;
}

.app-job-management-action-table{
    width: 100%;
    color: $color-dark-gray;
}

.app-jobs-panel{
    /*.app-large-panel-container{
        height: calc(100% - 75px);
        max-height: calc(100% - 75px);
    }*/

    .app-datagrid-container{
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        height: 0;
    }
    
    .app-job-management{
        margin: 10px 10px 0px 10px;
        height: calc(100% - 10px) !important;

        .dx-datagrid-nowrap{
            overflow: hidden !important; 
    
            .dx-scrollable-container{
                overflow: hidden !important; 
            }

            //.app-data-grid-column {            
            //    overflow: visible;
            //}
        }
    }
}