.app-wizard-tool-bar{
    .app-large-panel-tool-bar{
        height: 60px;
    }
}

.app-wizard-header{
    display: flex;
    flex-flow: row;
    padding-top: 10px;
    padding-bottom: 10px;
}

.app-wizard-step-text{
    @include text-sub-header1;
    padding: 7px 0 0 20px;
    width: 300px;
    align-self: center;
}

.app-wizard-step-progress{
    flex-grow: 2;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.app-wizard-step-indicator{
    border-radius: $border-radius-light;
    background-color: $color-primary25;
    margin: 5px;
    width: 48px;
    height: 5px;
    cursor: pointer;
}

.app-wizard-step-indicator-active{
    background-color: $color-primary;
}

.app-wizard-step-actions{
    display: flex;
    flex-flow: row;
    justify-content: right;
    align-items: center;
    width: 300px;
}

.app-wizard-header-small{
}

.app-wizard-step-text-small{
    
}

.app-wizard-step-progress-small{
    margin-top: 5px;
    flex-grow: 2;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.app-wizard-step-actions-small{
    margin-top: 5px;
    flex-grow: 2;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    .app-button-container{
        margin: 3px;
    }
}

.app-wizard-header .app-button-container{
    margin-right: 10px;
}
