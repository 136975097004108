.app-selections-container{
    @include fill-height; 
    height: 490px;
}

.app-selections-actions{
    @include border-bottom-normal;
    padding: 10px;
    text-align: right;
    height: 60px;

    .app-button-container{
        margin-left: 5px;
    }

    .app-selections-cancel{
        margin-right: 17px;
        margin-left: 0;
    }
}

.app-selections{  
    @include fill-height;  
    padding: 10px;   
}

.app-selections-geographies{
    
    width: 225px;

    .app-button-container{
        margin-top: 10px;
        margin-right: 5px;
    }
}