.app-geofences{
  @include fill-height;
  width: 100%;    
}

.app-geofences-header{
  @include border-bottom-normal;
}

.app-geofences-datagrid{
  margin: 17px
}

.app-geofences-actions{
  float: right;
  padding: 10px 16px;

  .app-button{
    margin-left: 10px;
  }
}

.app-geofences-grid-actions{
  display: flex;
  align-items: center;  
}

.app-geofences-grid-actions-buttons{
  position: absolute;
}

.app-geofences-grid-actions-button{
  margin-left: 8px;
  color: $color-dark-gray;
}
