.app-map-entity-tooltip {
    @include border-normal;
    @include shadow-light;
    position: absolute;
    border-radius: $border-radius-light;
    z-index: 10000;
    min-width: 225px;
    min-height: 125px;
    background-color: $color-white;    
}

.app-map-entity-tooltip-title {
    @include text-sub-header2;
    @include border-bottom-normal;
    color: $color-black;
    background-color: $color-white;
    border-radius: $border-radius-light;
    border-bottom-left-radius: $border-radius-none;
    border-bottom-right-radius: $border-radius-none;
    cursor: pointer;
    position: relative;
    padding: 10px;    
}

.app-map-entity-tooltip-section {
    @include text-caption;
    padding: 7px;
    min-height: 47px;

    .app-map-entity-tooltip-section-new-line{
        margin-top: 5px;   
    }
}

.app-map-entity-tooltip-arrow-bottom{
    @include border-normal;
    border-top: none;
    border-right: none;
    width: 15px;
    height: 15px;
    bottom: -9px;
    left: 102px;
    position: absolute;
    background-color: $color-white;
    transform: rotate(-45deg);
}

.app-map-entity-tooltip-arrow-top{
    @include border-normal;
    border-bottom: none;
    border-left: none;
    width: 15px;
    height: 15px;
    top: -9px;
    left: 102px;
    position: absolute;
    background-color: $color-white;
    transform: rotate(-45deg);
}