.app-management-report-step-1-container{    
    @include vertical-scroll;
    @include text-bar;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    width: 850px;

}

.app-management-report-rows{    
    display: flex;
    flex-flow: row;
}

.app-management-report-labels{
    margin: 17px 17px 0 17px;
}

.app-management-report-available-reports{
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    margin: 17px 17px 0 17px;
}

.app-management-report-empty-panel{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}