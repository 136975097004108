.app-task-share-container{
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    height: 0;

    .app-task-share-header{
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        
        .app-task-share-title {
            @include text-header3;
        }

        .app-task-share-buttons {
            * {
                padding-left: 5px;
            }
        }
    }

    .app-task-share-body{
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        height: 0;
    }
}