.app-comments{
    @include fill-height;
}

.app-comments-empty{
    @include text-header3;
    color: $color-dark-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.app-comments-body{
    flex-grow: 2;    
    padding: 10px 10px;
    @include vertical-scroll;
    display: flex;
    flex-direction: column-reverse;
    overflow-anchor: auto !important;
    padding-bottom: 0 !important;
}

.app-comments-container{
    padding: 5px 5px;
}

.app-comments-footer{
    @include border-top-normal;
    padding: 10px 10px;
}

.app-comments-new{
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 10px 10px;
}

.app-comments-new-input{
    flex-grow: 2;

    .dx-editor-outlined.dx-texteditor-with-label{
        margin-top: 0;
        padding-top: 5px;
    }
}

.app-comments-new-button{
    margin: 0 !important;
    width: 50px !important;
    padding-left: 10px;

    .app-button{
        height: 40px !important;
    }
}
