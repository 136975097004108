.app-map-mode-banner{    
    width: 100%;    
    position: absolute;
    bottom: 0px;
    z-index: 1000;    
}

.app-map-mode-banner-content{
    background: $color-secondary;
    color: $color-white;    
    width: 60%;
    margin: 10px auto;
    border-radius: 5px;
    border: 1px solid #666666;
    @include shadow-medium;
}

.app-map-mode-banner-title{
    padding: 10px;
    border-bottom: 1px solid #666666;
    position: relative;
    height: 26px;
    line-height: 26px;
}

.app-map-mode-banner-body{
    padding: 10px;
    border-bottom: 1px solid #666666;
    background: $color-secondary75;
}