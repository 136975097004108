.app-competitive-insights-channel-filter{
    @include fill-height;
    padding: 10px;
    display: flex;
    flex-flow: column;
    flex-grow:1;
    height: 0;
}

.app-competitive-insights-channel-list{
    @include fill-height;
    margin: 10px 0 20px;
    //height: 88%;
}

.app-nav-section{
    position: absolute;
    left: 85px;
    .app-competitive-insights-channel-list-container{
        .app-bar-container{
            margin: 0px 0px 10px 0px;
        }
    }    
}

.app-competitive-insights-show-only-selected-button{
    float: right;
}

.app-option-selector{
    margin: 5px 0 0 0;
}

.app-competitive-insights-channel-filter-buttons{
    @include border-bottom-normal;
    padding: 7px 5px;

    .app-button-container{
        margin: 0 5px;        
    }

    .app-button-secondary.app-button-small{
        width: 90px;
        height: 45px;
        line-height: 45px;
    }

    .app-button-primary.app-button-small{
        width: 94px;
        height: 49px;
        line-height: 49px;
    }
}

.app-competitive-insights-channel-filter-row{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.app-competitive-insights-channel-filter-icon{
    margin: 2px 4px 0 0;
}