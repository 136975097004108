.app-tools-bulk-container{    
    @include vertical-scroll;
    @include text-bar;
    display: flex;
    flex-flow: column;
    width: 808px;
    height: 100%;

    .app-description{
        padding: 10px;
        margin: 20px 17px 0 17px;
    }
}

.app-tools-bulk-divided-container{
    @include text-bar;
    display: flex;
    flex-flow: row;
    width: 850px;
    height: 100%;

    .app-uploader-container{
        padding: 17px;
        width: 370px;
        height: 200px;

        .app-uploader-file-region{
            padding: 8px;
            text-align: center;
        }
    }
}

.app-tools-bulk-divided-container-wide{    
    width: 1104px;
}

.app-tools-bulk-container-top{    
    padding-top: 20px;
}

.app-tools-bulk-divided-column{    
    @include vertical-scroll;
    @include text-bar;
    display: flex;
    flex-flow: column;
    width: 430px;
}

.app-tools-bulk-divided-column-wide{    
    width: 704px;
}

.app-tools-bulk-vertical-line{
    border-left: 1px solid $color-light-gray;
    height: 95%;
    margin: 17px 0 17px 0;
}

.app-tools-bulk-rows{    
    display: flex;
    flex-flow: row;
}

.app-tools-bulk-columns{    
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    
    .app-selectable-items-selected{
        max-height: none;
    }

    .app-selectable-items-body{
        margin: 3px;

        .app-option-selector-icon{
            display: flex;
            justify-content: center;
        }
    }
}

.app-tools-bulk-file-uploader{
    border-radius: $border-radius-light;
    margin: 17px 17px 0 17px;
}

.app-tools-bulk-labels{
    margin: 17px 17px 0 17px;
}

.app-tools-bulk-sub-labels{
    margin: 0 17px 17px 17px;
}

.app-tools-bulk-note{ 
    @include text-caption;
    margin: 5px 17px 0 17px;
}

.app-tools-bulk-warning{
    color: $color-septenary;
}

.app-tools-bulk-text-input{
    margin: 10px 17px 10px 17px;
}

.app-tools-bulk-selectable-items{
    padding: 10px;
}

.app-tools-bulk-bar-long{    
    width: 370px;
}

.app-tools-bulk-bar-medlong{    
    width: 302px;
}

.app-tools-bulk-bar-medium{    
    width: 235px;
}

.app-tools-bulk-bar-short{    
    width: 166px;
}

.app-tools-bulk-bar-buttons{    
    
    .app-bar-table{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }

    .app-clickable-active{
        background-color: $color-tertiary20;
        color: $color-primary;    

        &.app-clickable-hovered{
            @include shadow-light;
        }

        &.app-clickable-pressed{
            @include shadow-inset;
        }        
    }    
}

.app-tools-bulk-download_button{
    color: $color-dark-gray;
    margin: 0 17px;

    .app-button-container{
        text-align: left;
    }
}

.app-tools-bulk-bar-edit{    
    margin: 17px 17px 0 17px;

    .app-bar-text{
        white-space: pre;
        padding-left: 30px;
    }

    .app-bar-table{
        justify-content: left;
        height: 70px;
    }
}

.app-tools-bulk-drop-down-icon-item{
    padding-right: 5px;
}

.app-tools-bulk-drop-down-icon-field{
    padding-left: 10px;
    flex-grow: 0;
}

.app-tools-bulk-trade-areas{
    margin: 17px 17px 0 17px;
    width: 100%;

    .app-trade-area-type{
        width: 115px;
    }

    .app-trade-area-actions{
        margin-left: 8px;
    }
}

.app-tools-bulk-trade-areas-add{
    margin: 17px 17px 0 0;
}
