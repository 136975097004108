.app-ci-dashboards{
    
    margin: 20px;
    display: flex;
    flex-flow: column;       

    .dxc-title text:first-child{
        font-size: 24px !important;
        font-weight: 400 !important;
        letter-spacing: 0.18px !important;
        font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif !important;
    }
    .dxc-title text:last-child{
        font-size: 14px !important;
        font-weight: 400 !important;
        letter-spacing: 0.1px !important;
        font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif !important;
    }

    .dx-header-row .tasDxCustom,
    .dx-header-row .center{
        text-align: center !important;
    }

    .dx-header-row .right,
    .dx-row .right {
        text-align: right !important;
    }
    
    .dx-header-row .bottom {
        vertical-align: bottom !important;
    }

    .dx-row .tasDxCustom.focused,
    .dx-row-alt .tasDxCustom.focused {
        background-color: $color-tertiary20 !important;
    }

    .dx-data-row td.tasDxCustom.tasDxCategory {
        border-color: $color-tertiary20 !important;
        background: $color-tertiary20 !important;
    }

    .dx-toolbar .dx-button {
        .dx-icon {
            color: $color-primary;
        }
    }
}

.app-ci-dashboards-wrapper{
    .dx-popup-content{
        padding: 0;
    }
}

.app-ci-dashboards-input{
    display: flex;
    flex-flow: row;
    padding: 0 0 20px 0;
}

.app-ci-dashboards-generate{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: right;
}

.app-ci-dashboards-header{
    margin: 20px 0;
    text-align: center;
}

.app-ci-dashboards-report-header{
    margin: 0 0 20px 0;
    text-align: left;
}

.app-ci-dashboards-title{
    @include text-header3;
}

.app-ci-dashboards-subtitle{
    @include text-sub-header2;
}

.app-ci-dashboards-labels{
    margin: 10px 10px 0 10px;
}

.app-ci-dashboards-report-labels{
    margin: 10px 10px 0 10px;
    width: 200px;
}

.app-ci-dashboards-report-options{
    flex-grow: 2;
}

.app-ci-dashboards-filter-wrapper{
    .dx-popup-content{
        padding: 0;
        overflow: hidden;
    }
}

.app-ci-dashboards-channel-filter{
    @include fill-height;
    padding: 10px;

    .app-competitive-insights-channel-list{
        height: 100%;
        margin: 10px 0 0 0;
    }
}

.app-ci-dashboards-filter-actions{
    @include border-bottom-normal;
    padding: 7px 10px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}

.app-ci-dashboards-filter-buttons{
    margin-left: 15px;
}
