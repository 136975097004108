.app-quick-reports{
    @include fill-height;
    @include vertical-scroll-color;
    padding: 15px;
    position: relative;
}

.app-quick-reports-description{
    @include text-sub-header2;
    margin-top: 15px;
}

.app-quick-reports-source{
    @include text-caption;
    margin: 15px 0px;
}

.app-quick-reports-table{
    width: 100%;
    margin-top:15px;
}