.app-analytics-projection-wizard{
    &.app-panel{
        /*width: 400px;*/
    }
}

.modelWizardStep1InputSourceFilter{
    margin-bottom:15px;
}

.modelWizardStep2SiteForm{
    @include fill-height;    

    .app-bar-body{
        @include fill-height;
    }

    .app-form-content{
        padding: 0;    
    }
}

.modelWizardHeader{
    @include border-bottom-normal;
    padding: 5px;
    text-align: center;

    .app-button-secondary.app-button-medium{
        width: 150px;
    }
}

.modelWizardStep1 {
    @include fill-height;

    .modelWizardBody{
        @include fill-height;
        @include vertical-scroll;
        height: 90%
    }

    .app-bar-container{
        margin-bottom: 0;
    }
    .app-bar-body{
        padding: 7px
    }

    .dx-autocomplete.dx-texteditor{
        border: 0;
    }
}

.modelWizardStep1Longitude{
    margin-top: 15px;
}

.modelWizardStep1Input{
    margin: 7px;
}

.modelWizardStep1InputSave{
    margin-top: 7px;
    display: block;
    text-align: right;

    .app-button-container{
        margin-left: 5px;
    }
}

.modelWizardStep1Description{
    text-align: left;
}

.modelWizardStep2DescriptionRecap{
    margin: 5px;
}

.modelWizardStep1Mode{
    margin: 7px 0px;
}

.modelWizardStep1Button{
    margin-top: 5px;
    margin-left: 7px;
    
    .app-button{
        padding: 7px;
        .app-button-text{
            margin-left: 3px;
        }
    }

    &.app-button-primary{
        .app-button{
            padding: 9px;
        }
    }
}

.modelWizardStep1Options{
    font-size: 14px;
    td{
        padding: 5px;
    }
}

.modelWizardStep2Options{
    
    margin: 7px 7px 0 7px !important;

    .modelWizardStep2OptionsTable{
        margin: 5px;

        td{
            padding: 5px;
        }
    }   

    .app-bar-body{
        padding: 7px;
    }
}

.modelWizardStep2Description{    
    text-align: left;
    margin: 2px;
}

.modelWizardSummaryTitle-nopadding{
    font-size: 18px;
}

.modelWizardSummaryTitle{
    font-size: 18px;
    padding-top: 15px !important;
}