.app-trip-2-trade-job-header{
    @include border-bottom-normal;
  }

.app-trip-2-trade-job-actions {
    float: right;
    padding: 10px 16px;
  
    .app-button{
      margin-left: 10px;
    }
  }

.app-trip-2-trade-job-actions-label{
    float: left;
}

.app-trip-2-trade-body{  
    padding: 15px;
}

.app-trip-2-trade-name{
    padding-bottom: 10px;
}

.app-trip-2-trade-description{
    padding-bottom: 10px;
}

.app-trip-2-trade-radius-number{
    display: inline-block;
    width: 237px;
    padding-bottom: 10px;
}

.app-trip-2-trade-radius-radius-units{
    display: inline-block;
    width: 230px;
    padding-left: 15px;
}

.app-trip-2-trade-trip-type{
    display: inline-block;
    width: 230px;
    padding-left: 10px;
}

.app-trip-2-trade-renderer-action{
    display: flex;
    justify-content: space-between;
}

.app-trip-2-trade-renderers-label{
    padding-top: 24px;
}

.app-trip-2-trade-job-status-actions{
    padding-left: 15px;
}