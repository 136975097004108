.app-smart-text-box{
    width:100%;

    .app-smart-text-box-container {
        width: 100%;
        position: relative;  
    
        .app-smart-text-box-label {
            position: absolute;
            top: -8px;
            left: 10px; 
            background: $color-white;
            padding: 0px;
            font-size: 12px;
            color: $color-dark-gray !important;
            font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
            z-index: 1;
        }
    
        &:hover .app-smart-text-box-label {
            color: $color-secondary !important;
        }
    
        .app-smart-input {
            input {
                color: $color-dark-gray;
                border-color: $color-dark-gray;
                border-width: 1px;
                background-color: $color-white;
                border-radius: 4px;
                min-height: 34px;
                font-weight: 400;
                font-size: 14px !important;
                font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif !important;
                line-height: 1.35715;
                padding-left: 7px;
    
                &:focus {
                    outline: none;
                }
    
                &:hover {
                    border-color: $color-secondary !important;
                }

                &::placeholder {
                    color: #999;
                }
            }

            div {
                div {
                    padding-left: 7px;

                    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif !important;
                    font-size: 14px !important;
                    font-weight: 400;
                    line-height: 34px;

                    strong {
                        background-color: $color-tertiary20;
                    }
                }
            }
        }

        

        .mention-dropdown-item {
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 4px;
            //position: fixed; /* or fixed, depending on your use case */
            z-index: 1000; /* Ensure it appears above other elements */
            overflow: visible; /* Allow overflow */
            max-height: 200px; /* Set a max height if needed */
            overflow-y: auto; /* Enable scrolling if the content exceeds max height */
        }
        
        .mention-dropdown-item--active {
            background-color: $color-tertiary20;
        }
    }
}