.app-trip-2-trade{
  @include fill-height;
  width: 100%;    
}

.app-trip-2-trade-header{
  @include border-bottom-normal;
  padding: 10px 16px;
}

.app-trip-2-trade-job-container{
  padding: 15px;
}

.app-trip-2-trade-renderers-empty{
  @include fill-height;
  @include text-header3;    
  color: #666666;
  text-align: center;
  background: #F9F9FC;
  margin: 10px;
  border: 1px solid #CCC;
  justify-content: center;
  align-items: center;
  
}

.app-trip-2-trade-renderers-search{
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 535px;
  display: inline-block;
}

.app-trip-2-trade-renderers-row{
  @include border-top-normal;
  @include border-bottom-normal;
  background-color: $color-white;
  padding: 2px 0px 10px 10px;
      
  &:nth-child(2n+1){
      border: none;
      background-color: $color-primary3;
  }

  .app-button-icon{
      color: $color-dark-gray;
  }
}

.app-trip-2-trade-renderers-name{
  display: inline-block;
  width: 450px;
  padding-left: 20px;
}

.app-trip-2-trade-renderers-icon{
  padding-left: 20px;
  color: $color-dark-gray;
}

.app-trip-2-trade-actions-button {
  padding-left: 15px;
  float: right;
}

.app-trip-2-trade-renderers-actions{
  padding-left: 30px;
}

.app-trip-2-trade-renderers-actions-buttons{
  position: absolute;
  padding-right: 10px;
}

.app-trip-2-trade-renderers-actions-button {
  padding-left: 10px;
  top: -10px;
  color: $color-dark-gray;
}

.app-trip-2-trade-actions-logo {
  float: left;
}

.app-trip-2-trade-job-detail {
  width: 100%;
}

.app-trip-2-trade-job-detail-layout {
  display:table;
  list-style:none;
  padding-left:0;
}

.app-trip-2-trade-job-status {
    position: relative;
    top: -50px;
}

.app-trip-2-trade-job-detail-layout > li 
 {
  display:table-row;
 }
 
 .app-trip-2-trade-job-detail-layout > li > *
 {
  display:table-cell;
  padding:5px;
 }
 
 
 .app-trip-2-trade-job-detail-layout label {
  font-weight:bold;
  text-align:right;
 }
 
 .app-trip-2-trade-job-detail-layout label:after {
  content: ':';
 }
