$color-primary: #2B328C;
$color-primary25: #CACCE2;
$color-primary15: #DFE0EE;
$color-primary10: #EAEAF3;
$color-primary5: #F4F5F9;
$color-primary3: #F9F9FC;
$color-secondary: #5AB88D;
$color-secondary75: #6DC09A;
$color-secondary50: #A6D5BF;
$color-secondary20: #DEF1E8;
$color-tertiary: #C1D42F;
$color-tertiary50: #E0E997;
$color-tertiary20: #F3F6D5;
$color-quaternary: #F59C28;
$color-quinary: #EC5D20;
$color-quinary10: #FDEFE8;
$color-quinary20: #FBDFD3;
$color-quinary50: #F5AE8F;
$color-senary: #00A5D3;
$color-senary10: #E6F6FA;
$color-senary20: #CDEDF6;
$color-senary50: #80D2E9;
$color-septenary: #902153;
$color-white: #FFFFFF;
$color-light-gray: #E1E1E1;
$color-gray: #898989;
$color-dark-gray: #565656;
$color-black: #000000;
$color-brown: #856404;
$color-pale-yellow: #FFEEBA; 
$color-yellow: #ffff00;
$color-blue: #0000ff;
$color-green: #008000;
$color-red: #ff0000;
$color-orange: #ffa500;
$color-astral-blue: #337ab7;
$color-translucent: rgba(125,125,125,.3);
$color-black-translucent: rgba(0,0,0,.3);
$font-size1: 60px;
$font-size2: 34px;
$font-size3: 24px;
$font-size4: 16px;
$font-size5: 14px;
$font-size6: 12px;
$font-size7: 10px;
$font-weight1: 700;
$font-weight2: 500;
$font-weight3: 400;
$font-weight4: 300;
$letter-spacing1: 1.5px;
$letter-spacing2: .5px;
$letter-spacing3: .25px;
$letter-spacing4: .18px;
$letter-spacing5: .15px;
$letter-spacing6: .1px;
$letter-spacing7: 0px;
$letter-spacing8: -.5px;
$border-radius-none: 0px;
$border-radius-light: 4px;
$border-radius-normal: 8px;
$border-radius-circle: 100px;

@mixin border-dotted($color: $color-gray){
    border: 2px dashed $color;
}

@mixin border-normal($color: $color-light-gray){
    border: 1px solid $color;
}

@mixin border-bottom-normal($color: $color-light-gray){
    border-bottom: 1px solid $color;
}

@mixin border-top-normal($color: $color-light-gray){
    border-top: 1px solid $color;
}

@mixin border-thick($color: $color-light-gray){
    border: 2px solid $color;
}

@mixin border-bottom-thick($color: $color-light-gray){
    border-bottom: 2px solid $color;
}

@mixin border-top-thick($color: $color-light-gray){
    border-top: 2px solid $color;
}

@mixin not-licensed{
    @include border-dotted;
    color: #AAA;
    background-color: #F1F1F1;
}

@mixin coming-soon{    
    @include border-thick($color-senary);
    color: $color-senary;
    background-color: $color-secondary20;
}

@mixin text-template($size, $weight, $spacing){
    font-size: $size;
    font-weight: $weight;
    letter-spacing: $spacing;
}

@mixin text-header1 {
    @include text-template($font-size1, $font-weight4, $letter-spacing8);
}

@mixin text-header2 { 
    @include text-template($font-size2, $font-weight3, $letter-spacing7);
}

@mixin text-header3 {
    @include text-template($font-size3, $font-weight3, $letter-spacing4);
} 

@mixin text-sub-header0 {
    @include text-template($font-size4, $font-weight2, $letter-spacing5);
}

@mixin text-sub-header1 {
    @include text-template($font-size4, $font-weight2, $letter-spacing5);
}

@mixin text-sub-header2 {
    @include text-template($font-size5, $font-weight3, $letter-spacing6);
}

@mixin text-body1 {
    @include text-template($font-size3, $font-weight4, $letter-spacing2);
}

@mixin text-body2 {
    @include text-template($font-size3, $font-weight3, $letter-spacing3);
}

@mixin text-primary-button {
    @include text-template($font-size4, $font-weight1, $letter-spacing2);
}

@mixin text-primary-link {
    @include text-template($font-size4, $font-weight1, $letter-spacing2);
}

@mixin text-secondary-link {
    @include text-template($font-size5, $font-weight3, $letter-spacing3);
}

@mixin text-bar {
    @include text-template($font-size5, $font-weight2, $letter-spacing3);
}

@mixin text-tab {
    @include text-template($font-size5, $font-weight3, $letter-spacing3);
}

@mixin text-caption {
    @include text-template($font-size6, $font-weight3, $letter-spacing3);
}

@mixin text-overline {
    @include text-template($font-size7, $font-weight2, $letter-spacing1);
}

@mixin shadow-none{
    box-shadow: none;
}

@mixin shadow-inset{
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

@mixin shadow-light{
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

@mixin shadow-medium{
    box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.25);
}

@mixin shadow-heavy{
    box-shadow: 4px 5px 5px 0px rgba(0, 0, 0, 0.25);
}

@mixin shadow-icon{
    filter: drop-shadow(1px 1px 0px rgb(0, 0, 0, .25));
}

@mixin shadow-icon-inset{
    filter: drop-shadow(-1px 1px 0px rgb(0, 0, 0, .25));
}

@mixin disabled{
    cursor: not-allowed !important;
}

@mixin user-image-small{
    border-radius: $border-radius-circle;
    margin-top: -2px;    
    width: 30px;
    height: 30px;
}

@mixin default-user-image{
    background-image: url('https://cdn.tradeareasystems.net/Images/Components/UserBadge/DefaultUser96x96.png');
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin fill-height{
    height: 100%;
    display: flex;
    flex-flow: column;
}

@mixin vertical-scroll($color: transparent){

    overflow-y: scroll; 
    //padding-bottom: 75px !important;

    &::-webkit-scrollbar {
        width: 0px;
    }

    &::-webkit-scrollbar-track {
        background: $color; 
    }

    &::-webkit-scrollbar-thumb {
        background: $color; 
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $color;
    }
}

@mixin vertical-scroll-color($mode: scroll){
    overflow-y: $mode;
    //padding-bottom: 75px !important;

    &::-webkit-scrollbar {
        width: 13px;        
    }

    &::-webkit-scrollbar-track {
        background: $color-primary5; 
    }

    &::-webkit-scrollbar-thumb {
        background: $color-primary10; 
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $color-primary15; 
    }
}

@mixin vertical-scroll-dark-color($mode: scroll){
    overflow-y: $mode;
    //padding-bottom: 75px !important;

    &::-webkit-scrollbar {
        width: 13px;        
    }

    &::-webkit-scrollbar-track {
        background: $color-primary3; 
    }

    &::-webkit-scrollbar-thumb {
        background: $color-primary25; 
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $color-primary25; 
    }
}

@mixin spin() {
    animation: spin infinite 2s linear;
}


@mixin spin-slow() {
    animation: spin infinite 4s linear;
}

@mixin zoom-in-zoom-out {
    animation: zoom-in-zoom-out 1s ease infinite;
}

@mixin default-link($color: $color-black){
    @include text-secondary-link;
    color: $color;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}


@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.5, 1.5);
    }
    100% {
        transform: scale(1, 1);
    }
}

#root,
body,
html{
	height: 100%;
}

#root{
	display: flex;
	flex-flow: column;
}

body {
	margin: 0;	
	font-family: sans-serif !important;
	font-family: 'Trebuchet MS' !important;
	font-family: 'Raleway' !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	-webkit-touch-callout:none;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
}

input, textarea, .app-selectable-text /*.contenteditable?*/ {
	-webkit-touch-callout:default; 
	-webkit-user-select:text;
	-moz-user-select:text;
	-ms-user-select:text;
	user-select:text;
    font-family: sans-serif !important;
	font-family: 'Trebuchet MS' !important;
	font-family: 'Raleway' !important;
}

img {
	vertical-align: middle;
}

table{
	border-collapse: collapse;
	border-spacing: 0;
}

table td{
	margin: 0;
	padding: 0;
	border-collapse: collapse;
	border-spacing: 0;
}