.app-projects-available-maps-modal-card-default{
    width: 100%;
    background: $color-translucent;
    overflow: visible;

    .app-modal-card-body {
        @include shadow-medium;
        height: 190px;
        width: 400px;
        margin: auto;
        background: $color-white;
        border: 1px solid #CCC;
        overflow: visible;
    }
}

.app-projects-available-maps-usage-loader-container {
    height: 210px; 
    top: 60px;
    position: absolute;
    background-color: $color-white !important;
    border-radius: $border-radius-normal;
}

.app-projects-available-maps-usage-header{
    @include border-bottom-normal;
    padding: 10px 20px;
    display: flex;
    flex-flow: row;
}

.app-projects-available-maps-usage-title{
    @include text-sub-header1;
    display: flex;
    align-items: center;
    flex-grow: 2;    
}

.app-projects-available-maps-usage-actions{

    .app-button{
        margin-left: 10px;
    }
}

.app-projects-available-maps-usage {
    display: flex;
    flex-flow: column;
    background-color: #FFFFFF;
    padding: 10px;
}

.app-projects-available-maps-usage-layout {
    display: table;
    list-style: none;
    padding-left: 0;
}

.app-projects-available-maps-usage-layout > li 
 {
  display:table-row;
 }
 
 .app-projects-available-maps-usage-layout > li > *
 {
  display:table-cell;
  padding:5px;
 }
 
 .app-projects-available-maps-usage-layout label {
  font-weight:bold;
  text-align:right; 
 }
 
 .app-projects-available-maps-usage-layout label:after {
  content: ':';
 }