.app-comment{
    border-radius: $border-radius-normal;
    display: flex;
    flex-flow: column;
    width: 80%;
    padding: 10px;
    margin-bottom: 5px;
}

.app-comment-with-subcomments{
    background-color: $color-primary10;
    margin-bottom: 15px;
}

.app-comment-align-right{
    margin-left: auto;
}

.app-comment-container{
    display: flex;
    flex-flow: row;
}

.app-comment-subcomments{
    margin-left: 50px;

    .app-comment{
        width: 100%;
        padding: 0;
        margin-bottom: 0;
    }
}

.app-comment-image{
    margin: 20px 10px 0 0;
}

.app-comment-userImage{
    @include default-user-image;
    border-radius: 50%;
    height: 40px;
    width: 40px;    
    border: 1px solid $color-primary;
}

.app-comment-details{
    flex-grow: 2;    
    margin-bottom: 5px;
}

.app-comment-header{
    @include text-caption;
    padding: 4px;
    display: flex;
    flex-flow: row;
}

.app-comment-user{
    flex-grow: 2;
}

.app-comment-message{
    @include border-normal($color-primary3);
    border-radius: $border-radius-normal;
    background-color: $color-primary3;
    padding: 5px;
    display: flex;
    flex-flow: row;
    align-items: center;
}

.app-comment-display-text{
    @include text-sub-header1;
    white-space: pre-line;
    padding: 5px;
    flex-grow: 2;
    height: auto;
	-webkit-user-select:text;
	-moz-user-select:text;
	-ms-user-select:text;
	user-select:text;
}

.app-comment-edit-input{
    @include text-sub-header1;
    padding: 5px;
    flex-grow: 2;

    .dx-editor-outlined.dx-texteditor-with-label{
        margin-top: 0;
        padding-top: 5px;
    }
}
    
.app-comment-edit-buttons{
    color: $color-dark-gray;
    padding: 4px;
    display: flex;
    flex-flow: row;
    margin-left: auto;
}

// workaround to issue with Confirm menu displaying below the footer in the comments section
// targets either the last comment w/o sub comments or the last sub comment of the last commment (but not both)
.app-comments-container > .app-comment:last-child:not(:has(.app-comment)) .app-comment-edit-buttons .app-button-menu-container,
.app-comments-container > .app-comment:last-child .app-comment:last-child .app-comment-edit-buttons .app-button-menu-container{
    position: static;
}
.app-comments-container > .app-comment:last-child:not(:has(.app-comment)) .app-comment-edit-buttons .app-button-menu,
.app-comments-container > .app-comment:last-child .app-comment:last-child .app-comment-edit-buttons .app-button-menu{
    margin-right: 30px;
}

.app-comment-edit-button{
    margin-left: auto;
}

.app-comments-edit-save-button{
    margin: 0 !important;
    width: 50px !important;
    padding-left: 10px;

    .app-button{
        height: 40px !important;
    }
}

.app-comment-reply-button{
    color: $color-dark-gray;
    margin-left: 5px;
}
